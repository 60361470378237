// frontend/src/utilities/subcategoryUtils.js

import WebIcon from '@mui/icons-material/Web';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import PsychologyIcon from '@mui/icons-material/Psychology';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BrushIcon from '@mui/icons-material/Brush';
import AnimationIcon from '@mui/icons-material/Animation';
import CreateIcon from '@mui/icons-material/Create';
import GroupIcon from '@mui/icons-material/Group';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DescriptionIcon from '@mui/icons-material/Description';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DevicesIcon from '@mui/icons-material/Devices';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SecurityIcon from '@mui/icons-material/Security';
import CloudIcon from '@mui/icons-material/Cloud';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import FlightIcon from '@mui/icons-material/Flight';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import VillaIcon from '@mui/icons-material/Villa';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GavelIcon from '@mui/icons-material/Gavel';
import CalculateIcon from '@mui/icons-material/Calculate';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupsIcon from '@mui/icons-material/Groups';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import TokenIcon from '@mui/icons-material/Token';
import HubIcon from '@mui/icons-material/Hub';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import BiotechIcon from '@mui/icons-material/Biotech';
import MemoryIcon from '@mui/icons-material/Memory';
import LanguageIcon from '@mui/icons-material/Language';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandshakeIcon from '@mui/icons-material/Handshake';

export const subcategoryGroups = {
  recommendations: {
    crypto_web3: {
      label: 'Crypto & Web3',
      subcategories: {
        defi_protocols: 'DeFi Protocols',
        nft_projects: 'NFT Projects',
        dao_governance: 'DAO Governance',
        crypto_trading: 'Crypto Trading',
        web3_infrastructure: 'Web3 Infrastructure',
        wallet_security: 'Wallet Security'
      }
    },
    tech_innovation: {
      label: 'Technology & Innovation',
      subcategories: {
        ai_tools: 'AI Tools',
        dev_tools: 'Dev Tools',
        saas_products: 'SaaS Products',
        hardware_tech: 'Hardware Tech',
        cybersecurity: 'Cybersecurity',
        productivity_apps: 'Productivity Apps'
      }
    },
    investment_business: {
      label: 'Investment & Business',
      subcategories: {
        startups: 'Startups',
        angel_investing: 'Angel Investing',
        venture_capital: 'Venture Capital',
        market_opportunities: 'Market Opportunities',
        real_estate: 'Real Estate'
      }
    },
    professional_services: {
      label: 'Professional Services',
      subcategories: {
        wealth_management: 'Wealth Management',
        legal_services: 'Legal Services',
        tax_strategy: 'Tax Strategy',
        business_consulting: 'Business Consulting',
        private_banking: 'Private Banking'
      }
    },
    lifestyle_premium: {
      label: 'Lifestyle & Premium',
      subcategories: {
        luxury_travel: 'Luxury Travel',
        fine_dining: 'Fine Dining',
        high_end_properties: 'High-End Properties',
        luxury_vehicles: 'Luxury Vehicles',
        wellness_fitness: 'Wellness & Fitness'
      }
    },
    education_networking: {
      label: 'Education & Networking',
      subcategories: {
        education_courses: 'Education Courses',
        networking_events: 'Networking Events',
        exclusive_memberships: 'Exclusive Memberships'
      }
    },
    other: {
      label: 'Other',
      subcategories: {
        miscellaneous: 'Other Recommendations'
      }
    }
  },
  jobs_projects: {
    blockchain_web3: {
      label: 'Blockchain & Web3',
      subcategories: {
        blockchain_web3: 'Blockchain & Web3',
        smart_contracts: 'Smart Contracts',
        defi_dev: 'DeFi Development',
        crypto_launch: 'Cryptocurrency & Token Projects',
        nft_project_dev: 'NFT Project Development',
        dao_development: 'DAO Development',
        web3_infrastructure: 'Web3 Infrastructure',
        crypto_operations: 'Crypto Operations & Trading'
      }
    },
    ai_ml: {
      label: 'AI & Machine Learning',
      subcategories: {
        ai_ml: 'AI & Machine Learning',
        nlp_applications: 'NLP & Language AI',
        computer_vision: 'Computer Vision',
        llm_development: 'LLM Development',
        ml_ops: 'ML Operations & Infrastructure'
      }
    },
    web_mobile: {
      label: 'Web & Mobile',
      subcategories: {
        web_mobile_dev: 'Web & Mobile Development',
        systems_infrastructure: 'Systems & Infrastructure',
        data_engineering: 'Data Engineering',
        security_engineering: 'Security Engineering',
        cloud_architecture: 'Cloud Architecture'
      }
    },
    design_creative: {
      label: 'Design & Creative',
      subcategories: {
        ui_ux_design: 'UI/UX Design',
        brand_graphic_design: 'Brand & Graphic Design',
        motion_3d_design: 'Motion & 3D Design',
        content_creation: 'Content Creation'
      }
    },
    marketing_community: {
      label: 'Marketing & Community',
      subcategories: {
        social_media_community: 'Social Media & Community',
        content_strategy: 'Content Strategy',
        growth_marketing: 'Growth Marketing',
        analytics_seo: 'Analytics & SEO'
      }
    },
    business_ops: {
      label: 'Business & Operations',
      subcategories: {
        strategy_consulting: 'Strategy Consulting',
        operations_management: 'Operations Management',
        finance_legal: 'Finance & Legal',
        product_management: 'Product Management',
        market_research: 'Market Research',
        data_analysis: 'Data Analysis'
      }
    },
    professional: {
      label: 'Professional Services',
      subcategories: {
        education_training: 'Education & Training',
        hr_recruitment: 'HR & Recruitment',
        advisory_mentorship: 'Advisory & Mentorship'
      }
    },
    other: {
      label: 'Other',
      subcategories: {
        other_professional: 'Other Professional Services'
      }
    }
  }
};

export const getSubcategoryIcon = (subcategory) => {
  const iconMap = {
    // Blockchain & Web3
    blockchain_web3: <CurrencyBitcoinIcon />,
    smart_contracts: <IntegrationInstructionsIcon />,
    defi_dev: <AccountBalanceIcon />,
    crypto_launch: <RocketLaunchIcon />,
    nft_project_dev: <TokenIcon />,
    dao_development: <GroupsIcon />,
    web3_infrastructure: <HubIcon />,
    crypto_operations: <TrendingUpIcon />,

    // AI & ML
    ai_ml: <SmartToyIcon />,
    nlp_applications: <LanguageIcon />,
    computer_vision: <BiotechIcon />,
    llm_development: <SmartToyIcon />,
    ml_ops: <MemoryIcon />,

    // Web & Mobile
    web_mobile_dev: <WebIcon />,
    systems_infrastructure: <StorageIcon />,
    data_engineering: <StorageIcon />,
    security_engineering: <SecurityIcon />,
    cloud_architecture: <CloudIcon />,

    // Design & Creative
    ui_ux_design: <DesignServicesIcon />,
    brand_graphic_design: <BrushIcon />,
    motion_3d_design: <AnimationIcon />,
    content_creation: <CreateIcon />,

    // Marketing & Community
    social_media_community: <GroupIcon />,
    content_strategy: <DescriptionIcon />,
    growth_marketing: <TrendingUpIcon />,
    analytics_seo: <BarChartIcon />,

    // Business & Operations
    strategy_consulting: <BusinessIcon />,
    operations_management: <BuildIcon />,
    finance_legal: <AccountBalanceIcon />,
    product_management: <DevicesIcon />,
    market_research: <AssessmentIcon />,
    data_analysis: <BarChartIcon />,

    // Professional Services
    education_training: <SchoolIcon />,
    hr_recruitment: <WorkIcon />,
    advisory_mentorship: <HandshakeIcon />,

    // Recommendations - Crypto & Web3
    defi_protocols: <AccountBalanceIcon />,
    nft_projects: <TokenIcon />,
    dao_governance: <GroupsIcon />,
    crypto_trading: <TrendingUpIcon />,
    wallet_security: <SecurityIcon />,

    // Recommendations - Tech & Innovation
    ai_tools: <SmartToyIcon />,
    dev_tools: <CodeIcon />,
    saas_products: <CloudIcon />,
    hardware_tech: <DevicesIcon />,
    cybersecurity: <SecurityIcon />,
    productivity_apps: <AppsIcon />,

    // Recommendations - Investment & Business
    startups: <RocketLaunchIcon />,
    angel_investing: <AccountBalanceWalletIcon />,
    venture_capital: <BusinessCenterIcon />,
    market_opportunities: <TrendingUpIcon />,
    real_estate: <HomeIcon />,

    // Recommendations - Professional Services
    wealth_management: <AccountBalanceWalletIcon />,
    legal_services: <GavelIcon />,
    tax_strategy: <CalculateIcon />,
    business_consulting: <BusinessCenterIcon />,
    private_banking: <AccountBalanceIcon />,

    // Recommendations - Lifestyle & Premium
    luxury_travel: <FlightIcon />,
    fine_dining: <RestaurantIcon />,
    high_end_properties: <VillaIcon />,
    luxury_vehicles: <DirectionsCarIcon />,
    wellness_fitness: <FitnessCenterIcon />,

    // Education & Networking
    education_courses: <SchoolIcon />,
    networking_events: <GroupsIcon />,
    exclusive_memberships: <CardMembershipIcon />,

    // Other
    other_professional: <BuildIcon />,
    miscellaneous: <MoreHorizIcon />
  };
  
  return iconMap[subcategory] || <BuildIcon />;
};

export const isValidSubcategory = (category, subcategory) => {
  const groups = subcategoryGroups[category === 'recommendation' ? 'recommendations' : 'jobs_projects'];
  return Object.values(groups).some(group => 
    Object.keys(group.subcategories).includes(subcategory)
  );
};

export const getSubcategoryLabel = (subcategory) => {
  for (const categoryType of Object.values(subcategoryGroups)) {
    for (const group of Object.values(categoryType)) {
      if (group.subcategories[subcategory]) {
        return group.subcategories[subcategory];
      }
    }
  }
  return subcategory;
};