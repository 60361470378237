// frontend/src/components/LoginPage.js

import React from 'react';
import { useAuth } from '../context/AuthContext';
import { SignInButton } from '@farcaster/auth-kit';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const LoginPage = ({ showToast }) => {
  const { isSignedIn, isDevelopmentMode, switchTestUser } = useAuth();
  const navigate = useNavigate();

  const handleTestAccountLogin = async () => {
    try {
      const user = await switchTestUser();
      if (user) {
        showToast(`Logged in as ${user.displayName || user.username}`, 'success');
        navigate('/');
      } else {
        throw new Error('Failed to switch test user');
      }
    } catch (error) {
      console.error('Error logging in with test account:', error);
      showToast('Login failed. Please try again.', 'error');
    }
  };

  // Redirect to home if already signed in
  React.useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn, navigate]);

  if (isSignedIn) {
    return null; // or a loading spinner if you prefer
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <StyledPaper>
          <Typography component="h1" variant="h4" gutterBottom>
            Log In to Vouch
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <SignInButton />
            {isDevelopmentMode && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleTestAccountLogin}
              >
                Login with Test Account
              </Button>
            )}
          </Box>
        </StyledPaper>
      </Box>
    </Container>
  );
};

export default LoginPage;