// frontend/src/components/admin/WalletManagement.js

import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, TextField, Button, CircularProgress, 
  Paper, Grid, Alert, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { getWalletBalance, transferUSDC } from '../../services/adminApi';
import { isAddress } from 'ethers';

const WalletManagement = () => {
  const [balance, setBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [transferSuccess, setTransferSuccess] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [addressError, setAddressError] = useState('');

  useEffect(() => {
    fetchBalance();
  }, []);

  const fetchBalance = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const walletBalance = await getWalletBalance();
      setBalance(walletBalance);
    } catch (err) {
      setError('Failed to fetch wallet balance');
    } finally {
      setIsLoading(false);
    }
  };

  const validateAddress = (address) => {
    if (!isAddress(address)) {
      setAddressError('Invalid Ethereum address');
      return false;
    }
    setAddressError('');
    return true;
  };

  const handleTransfer = async () => {
    if (!validateAddress(toAddress)) {
      return;
    }
    if (isNaN(amount) || parseFloat(amount) <= 0) {
      setError('Invalid amount');
      return;
    }
    setIsConfirmationOpen(true);
  };

  const confirmTransfer = async () => {
    setIsConfirmationOpen(false);
    setIsLoading(true);
    setError(null);
    setTransferSuccess(null);
    try {
      const result = await transferUSDC(toAddress, amount);
      if (result.message === 'Transfer initiated successfully') {
        setTransferSuccess('Transfer initiated successfully. It may take some time to complete.');
        // Refresh balance after transfer initiation
        await fetchBalance();
      } else {
        setError('Transfer failed: ' + (result.error || 'Unknown error'));
      }
    } catch (err) {
      setError('Failed to initiate transfer: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Wallet Management</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Current USDC Balance: {isLoading ? <CircularProgress size={20} /> : balance}
          </Typography>
          <Button 
            onClick={fetchBalance} 
            disabled={isLoading}
            sx={{ ml: 2 }}
          >
            Refresh Balance
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="To Address"
            value={toAddress}
            onChange={(e) => {
              setToAddress(e.target.value);
              validateAddress(e.target.value);
            }}
            error={!!addressError}
            helperText={addressError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Amount (USDC)"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            onClick={handleTransfer} 
            disabled={isLoading || !!addressError}
          >
            Transfer USDC
          </Button>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        {transferSuccess && (
          <Grid item xs={12}>
            <Alert severity="success">{transferSuccess}</Alert>
          </Grid>
        )}
      </Grid>

      <Dialog open={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
        <DialogTitle>Confirm Transfer</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to transfer {amount} USDC to {toAddress}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={confirmTransfer} variant="contained" color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default WalletManagement;