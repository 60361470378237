// src/data/exampleData.js

export const HIGH_END_EXAMPLES = {
    recommendations: {
      request: {
        title: "Strategic Analysis: Institutional DeFi Adoption Paths",
        description: `Seeking comprehensive strategic analysis of institutional DeFi adoption, focusing on:

- Market entry strategies for traditional financial institutions
- Risk assessment framework and mitigation approaches
- Technical infrastructure requirements and recommendations
- Regulatory compliance pathways and jurisdictional considerations
- Timeline and milestone recommendations for phased adoption

Looking for detailed, actionable recommendations backed by deep expertise in both traditional finance and DeFi. Analysis should include specific technical solutions, compliance frameworks, and implementation strategies.`,
        category: "recommendation",
        bounty: 50000,
        creator: "venture.capital",
        status: "active",
        subcategory: "defi_protocols",
        totalBounty: 50000,
        createdAt: new Date().toISOString(),
        recommendationDeadline: "2024-12-31",
        creatorDetails: {
          username: "venture.capital",
          displayName: "Quantum Ventures",
          avatarUrl: null,
        }
      },
      bids: [
        {
          bidder: "defi.expert",
          bidderDetails: {
            username: "defi.expert",
            avatarUrl: null,
            displayName: "Grace Chen",
          },
          proposal: `Based on my experience as former Head of Digital Assets at [Major Bank] and advisor to multiple institutional DeFi integrations, I recommend the following comprehensive strategy:

1. Technical Infrastructure
- Implement hybrid custody solution combining Fireblocks for hot wallets and [Institutional Custody Provider] for cold storage
- Deploy custom smart contract monitoring system with [Security Provider] integration
- Establish dedicated RPC nodes across multiple chains with [Infrastructure Provider]

2. Risk Management Framework
- Multi-layered security architecture with 3-4-5 multisig governance
- Automated circuit breakers for position sizes >$10M
- Real-time compliance monitoring via [ComplianceTech] integration
- Insurance coverage through [Crypto Insurance Provider]

3. Regulatory Strategy
- Utilize Wyoming SPDI charter for initial operations
- Implement full reporting stack for FATF Travel Rule compliance
- Establish dedicated market surveillance team

4. Recommended Timeline
Month 1-3: Technical infrastructure setup
Month 4-6: Risk management implementation
Month 7-9: Regulatory compliance buildout
Month 10-12: Pilot program with select clients

Prior Success: Led successful DeFi integration for [Major Bank], now processing $2B+ monthly volume.`,
          status: "pending",
          totalVouchAmount: 75000,
          created_at: new Date().toISOString(),
          vouches: [
            {
              voucher: "defi.institutional",
              amount: 25000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "defi.institutional",
                displayName: "Alex Rivera",
                bio: "Head of Digital Assets, [Major Investment Bank]"
              }
            },
            {
              voucher: "crypto.compliance",
              amount: 20000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "crypto.compliance",
                displayName: "Ada Lovelace",
                bio: "Chief Compliance Officer, [Leading Crypto Exchange]"
              }
            },
            {
              voucher: "institutional.crypto",
              amount: 30000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "institutional.crypto",
                displayName: "Michelle Chang",
                bio: "Managing Director, [Top Investment Firm]"
              }
            }
          ],
        },
        {
          bidder: "blockchain.strategist",
          bidderDetails: {
            username: "blockchain.strategist",
            displayName: "David Park",
            avatarUrl: null,
          },
          proposal: `Drawing from my experience as former DeFi Integration Lead at [Major Prime Broker], I recommend:

1. Infrastructure Strategy
- Partner with [Enterprise Chain Provider] for dedicated subnets
- Implement cross-chain settlement layer using [L2 Solution]
- Deploy institutional-grade oracle network with [Oracle Provider]

2. Risk & Compliance Architecture
- Custom risk scoring system for DeFi protocols
- Real-time portfolio monitoring with [Risk Analytics Provider]
- Automated compliance reporting system integrated with existing stack

3. Market Access Framework
- Tiered protocol access based on risk categorization
- Smart contract-based position limits
- Automated reconciliation with traditional systems

4. Implementation Roadmap
Q1: Infrastructure deployment
Q2: Risk system integration
Q3: Compliance framework
Q4: Limited pilot launch

Track Record: Architected DeFi integration frameworks for 3 tier-1 banks, with current combined TVL of $5B+.`,
          status: "pending",
          totalVouchAmount: 45000,
          created_at: new Date().toISOString(),
          vouches: [
            {
              voucher: "protocol.head",
              amount: 15000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "protocol.head",
                displayName: "Elena Santos",
                bio: "Protocol Lead, [Major DeFi Project]"
              }
            },
            {
              voucher: "institutional.defi",
              amount: 30000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "institutional.defi",
                displayName: "Mark Johnson",
                bio: "DeFi Integration Lead, [Investment Bank]"
              }
            }
          ],
        }
      ],
    },
    projects: {
      request: {
        title: "Zero-Knowledge ML Model Deployment Platform",
        description: `Seeking development team to build a groundbreaking platform enabling private machine learning model deployment using zero-knowledge proofs. Key requirements:
  
  - ZK-proof implementation for model validation
  - Secure model deployment infrastructure
  - Privacy-preserving inference engine
  - Cross-chain compatibility
  - Developer-friendly SDK and documentation
  
  Project requires expertise in both ZK-proofs and machine learning systems. Looking for team with proven track record in both domains.`,
        category: "project",
        bounty: 75000,
        creator: "defi.protocol",
        status: "active",
        subcategory: "ai_ml",
        totalBounty: 75000,
        budget: {
          min: 400000,
          max: 800000,
          isFlexible: false
        },
        createdAt: new Date().toISOString(),
        bidDeadline: "2024-12-31",
        creatorDetails: {
          username: "defi.protocol",
          displayName: "PrivacyFirst Protocol",
          avatarUrl: null,
        }
      },
      bids: [
        {
          bidder: "zk.team",
          bidderDetails: {
            username: "zk.team",
            displayName: "ZK Systems",
            avatarUrl: null,
          },
          proposal: `Our team of ZK researchers and ML engineers proposes a comprehensive development plan:
  
  Phase 1: Architecture & Core Implementation (3 months)
  - ZK circuit design for model verification
  - Core infrastructure setup
  - Basic proof generation system
  
  Phase 2: ML Integration & Optimization (4 months)
  - ML model compatibility layer
  - Optimized proof generation
  - Initial SDK development
  
  Phase 3: Cross-chain & Production Ready (5 months)
  - Multi-chain support
  - Production hardening
  - Complete SDK and documentation
  
  Team Highlights:
  - Led development of [Major ZK Protocol]
  - Published research on efficient ZK circuits
  - Built ML deployment systems at [Tech Giant]
  
  Proposed Budget: $650,000
  Timeline: 12 months
  Team: 6 full-time engineers`,
          amount: 650000,
          status: "pending",
          totalVouchAmount: 120000,
          created_at: new Date().toISOString(),
          vouches: [
            {
              voucher: "zk.researcher",
              amount: 50000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "zk.researcher",
                displayName: "Dr. Wei Zhang",
                bio: "ZK Research Lead, [Major Blockchain]"
              }
            },
            {
              voucher: "ml.infrastructure",
              amount: 40000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "ml.infrastructure",
                displayName: "Rachel Cohen",
                bio: "ML Infrastructure Lead, [Tech Unicorn]"
              }
            },
            {
              voucher: "privacy.dev",
              amount: 30000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "privacy.dev",
                displayName: "Claude Shannon",
                bio: "Privacy Tech Lead, [Major Protocol]"
              }
            }
          ],
        },
        {
          bidder: "ml.systems",
          bidderDetails: {
            username: "ml.systems",
            displayName: "ML Systems Lab",
            avatarUrl: null,
          },
          proposal: `We propose a novel approach combining our ML deployment expertise with recent ZK research:
  
  Technical Approach:
  1. Optimized ZK Circuit Design
  - Custom circuits for common ML operations
  - Batched proof generation
  - Recursive proof composition
  
  2. ML Model Support
  - Popular framework support (PyTorch, TensorFlow)
  - Automated model conversion
  - Quantization optimization
  
  3. Developer Experience
  - Interactive documentation
  - CLI tools
  - CI/CD integration
  
  Team Credentials:
  - Created [Popular ML Framework]
  - Contributed to [ZK Protocol]
  - Multiple peer-reviewed papers
  
  Budget: $750,000
  Duration: 10 months
  Team Size: 8 engineers`,
          amount: 750000,
          status: "pending",
          totalVouchAmount: 90000,
          created_at: new Date().toISOString(),
          vouches: [
            {
              voucher: "systems.architect",
              amount: 50000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "systems.architect",
                displayName: "Donald Knuth",
                bio: "Systems Architect, [Major Cloud Provider]"
              }
            },
            {
              voucher: "crypto.security",
              amount: 40000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "crypto.security",
                displayName: "Lisa Brown",
                bio: "Security Lead, [Top Crypto Project]"
              }
            }
          ],
        }
      ],
    },
    jobs: {
      request: {
        title: "Chief Blockchain Architect - L2 Scaling Protocol",
        description: `Seeking a visionary technical leader to drive the architecture and development of our next-generation L2 scaling protocol. Key responsibilities:
  
  - Technical architecture leadership
  - Protocol design and optimization
  - Security and scalability strategy
  - Research direction and implementation
  - Technical team leadership (15+ engineers)
  
  Ideal candidate will have deep expertise in L2 scaling solutions, distributed systems, and cryptographic protocols.`,
        category: "job",
        bounty: 100000,
        creator: "scaling.protocol",
        status: "active",
        subcategory: "web3_infrastructure",
        totalBounty: 100000,
        salaryRange: {
          min: 400000,
          max: 700000,
          isFlexible: true
        },
        createdAt: new Date().toISOString(),
        applicationDeadline: "2024-12-31",
        creatorDetails: {
          username: "scaling.protocol",
          displayName: "ScaleChain Labs",
          avatarUrl: null,
        }
      },
      bids: [
        {
          bidder: "scaling.expert",
          bidderDetails: {
            username: "scaling.expert",
            displayName: "Dr. Robert Kim",
            avatarUrl: null,
          },
          proposal: `Currently Lead Architect at [Major L1], I bring extensive experience in:
  
  Technical Expertise:
  - Designed and implemented novel L2 scaling solutions
  - Led development of [Significant Protocol] processing 10M+ TX/day
  - Published research on optimistic rollup optimization
  - Contributed to multiple EIPs
  
  Leadership Experience:
  - Built and led 25-person protocol engineering team
  - Established technical roadmap and architecture processes
  - Drove successful launch of mainnet protocol
  - Regular speaker at major blockchain conferences
  
  Recent Achievements:
  - Reduced L2 settlement costs by 65%
  - Implemented novel fraud proof system
  - Multiple peer-reviewed publications
  
  Compensation Range: $600,000-650,000`,
          salary: 625000,
          status: "pending",
          totalVouchAmount: 200000,
          created_at: new Date().toISOString(),
          vouches: [
            {
              voucher: "protocol.founder",
              amount: 100000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "protocol.founder",
                displayName: "Alan Turing",
                bio: "Founder, [Major L1 Protocol]"
              }
            },
            {
              voucher: "l2.researcher",
              amount: 50000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "l2.researcher",
                displayName: "Dr. Emma Wilson",
                bio: "Research Lead, [Blockchain Research Lab]"
              }
            },
            {
              voucher: "eth.core",
              amount: 50000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "eth.core",
                displayName: "John Smith",
                bio: "Core Developer, Ethereum"
              }
            }
          ],
        },
        {
          bidder: "consensus.architect",
          bidderDetails: {
            username: "consensus.architect",
            displayName: "Barbara Liskov",
            avatarUrl: null,
          },
          proposal: `As former Chief Architect at [Leading L2] and researcher at [Prestigious Lab]:
  
  Technical Background:
  - Designed novel consensus mechanisms
  - Implemented cross-chain communication protocols
  - Optimized proof generation systems
  - Deep expertise in ZK-rollups
  
  Leadership & Vision:
  - Led protocol design for $2B+ TVL project
  - Managed 20+ person research team
  - Published 5 papers on scaling solutions
  - Regular contributor to protocol standards
  
  Key Innovations:
  - Pioneered new batching technique
  - Reduced proof size by 40%
  - Created novel bridge architecture
  
  Desired Compensation: $550,000`,
          salary: 550000,
          status: "pending",
          totalVouchAmount: 150000,
          created_at: new Date().toISOString(),
          vouches: [
            {
              voucher: "research.lead",
              amount: 75000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "research.lead",
                displayName: "Dr. David Lee",
                bio: "Research Director, [Crypto Research Institute]"
              }
            },
            {
              voucher: "scaling.dev",
              amount: 75000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "scaling.dev",
                displayName: "Whitfield Diffie",
                bio: "Tech Lead, [Major L2 Protocol]"
              }
            }
          ],
        }
      ],
    }
  };