// frontend/src/components/admin/PaymentsList.js

import React from 'react';
import GenericList from './GenericList';
import { getPaymentsList } from '../../services/adminApi';

const columns = [
  { id: 'type', label: 'Type', minWidth: 100 },
  { id: 'amount', label: 'Amount', minWidth: 100, format: (value) => `$${value.toFixed(2)}` },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'from', label: 'From', minWidth: 150 },
  { id: 'for', label: 'For', minWidth: 200 },
  { 
    id: 'date', 
    label: 'Date', 
    minWidth: 150, 
    format: (value) => new Date(value).toLocaleDateString()
  },
];

const PaymentsList = () => {
  return <GenericList fetchData={getPaymentsList} columns={columns} />;
};

export default PaymentsList;