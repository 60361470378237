// frontend/src/components/Leaderboard.js

import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Paper,
  CircularProgress,
  Avatar,
  Chip,
  Tooltip,
  Button,
  Alert,
  Fade,
  alpha,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupIcon from '@mui/icons-material/Group';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FarcasterUsername from './FarcasterUsername';
import { getLeaderboardData } from '../services/api';
import PageTitle from './PageTitle';

const LeaderboardContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(6),
  },
}));

const CategoryContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(0, 1),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: 0,
  },
}));

const CategoryChip = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.1),
  color: active ? theme.palette.primary.contrastText : theme.palette.primary.main,
  border: `1px solid ${active ? 'transparent' : alpha(theme.palette.primary.main, 0.3)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  height: 48,
  width: '100%',
  fontSize: '0.875rem',
  fontWeight: 600,
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.dark : alpha(theme.palette.primary.main, 0.2),
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9375rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  },
}));

const LeaderCard = styled(motion.div)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(10px)',
  transition: 'all 0.3s ease-in-out',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 8px 32px ${alpha(theme.palette.primary.main, 0.15)}`,
  },
}));

const RankBadge = styled(Box)(({ theme, rank }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '1rem',
  backgroundColor: 
    rank === 1 ? alpha(theme.palette.warning.main, 0.2) :
    rank === 2 ? alpha(theme.palette.grey[400], 0.2) :
    rank === 3 ? alpha('#CD7F32', 0.2) :
    alpha(theme.palette.primary.main, 0.1),
  color: 
    rank === 1 ? theme.palette.warning.main :
    rank === 2 ? theme.palette.grey[400] :
    rank === 3 ? '#CD7F32' :
    theme.palette.primary.main,
  border: `2px solid ${
    rank === 1 ? theme.palette.warning.main :
    rank === 2 ? theme.palette.grey[400] :
    rank === 3 ? '#CD7F32' :
    theme.palette.primary.main
  }`,
  [theme.breakpoints.up('sm')]: {
    width: 40,
    height: 40,
    fontSize: '1.2rem',
  },
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  [theme.breakpoints.up('sm')]: {
    width: 48,
    height: 48,
  },
}));

const AmountDisplay = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
  transition: 'all 0.2s ease-in-out',
  minWidth: 160,
  justifyContent: 'flex-end',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
  '& .amount': {
    fontWeight: 600,
    fontSize: '1rem',
  },
  '& .suffix': {
    color: alpha(theme.palette.text.primary, 0.7),
    marginLeft: theme.spacing(1),
    fontSize: '0.875rem',
  },

  [theme.breakpoints.up('sm')]: {
    minWidth: 200,
    padding: theme.spacing(1.25, 2.5),
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
    '& .amount': {
      fontSize: '1.125rem',
    },
  },

  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.5, 1),
    minWidth: 100,
    gap: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
    '& .amount': {
      fontSize: '0.875rem',
    },
    '& .suffix': {
      fontSize: '0.75rem',
    },
  },

  // Custom adjustments for very small screens (under 400px)
  '@media (max-width: 450px)': {
    padding: theme.spacing(0.25, 0.75),
    minWidth: 80,
    gap: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      fontSize: '0.875rem',
    },
    '& .amount': {
      fontSize: '0.75rem',
    },
    '& .suffix': {
      fontSize: '0.625rem',
    },
  },
}));

const UserSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  flex: 1,
  minWidth: 0, // Prevents flex item from overflowing
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(4),
  },
}));

const categories = [
  { id: 'requesters', label: 'Top Requesters', icon: <EmojiEventsIcon /> },
  { id: 'vouchers', label: 'Top Vouchers', icon: <MonetizationOnIcon /> },
  { id: 'earners', label: 'Top Earners', icon: <GroupIcon /> },
];

const tooltips = {
  requesters: "Total bounty amount paid as the requester on requests",
  vouchers: "Total amount vouched on recommendations, project bids, and job applications",
  earners: "Total amount earned from successful vouches and recommendations"
};

const Leaderboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeCategory, setActiveCategory] = useState('requesters');
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    fetchLeaderboardData();
  }, [refreshKey]);

  const fetchLeaderboardData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await getLeaderboardData();
      setLeaderboardData(data);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      setError('Failed to load leaderboard data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
  };

  const getCurrentData = () => {
    if (!leaderboardData) return [];
    switch (activeCategory) {
      case 'requesters':
        return leaderboardData.topRequesters;
      case 'vouchers':
        return leaderboardData.topVouchers;
      case 'earners':
        return leaderboardData.topEarners;
      default:
        return [];
    }
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getAmountSuffix = (category) => {
    switch (category) {
      case 'requesters':
        return 'Bounty';
      case 'vouchers':
        return 'Vouched';
      case 'earners':
        return 'Earned';
      default:
        return '';
    }
  };

  if (isLoading) {
    return (
      <LeaderboardContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      </LeaderboardContainer>
    );
  }

  return (
    <LeaderboardContainer maxWidth="lg">
      <PageTitle 
      title="Leaderboard" 
      description="Discover top performers on Vouch - View leading requesters, earners, and vouchers in the Vouch ecosystem."
      />
      <HeaderSection>
        <Typography 
          variant="h3" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            color: 'text.primary',
            fontSize: { xs: '2rem', sm: '3rem' },
          }}
        >
          Vouch Leaderboard
        </Typography>
        <Typography 
          variant="h6" 
          color="text.secondary"
          gutterBottom
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            fontSize: { xs: '1rem', sm: '1.25rem' },
          }}
        >
          Recognizing our top contributors
          <Tooltip title="Rankings are based on total USDC value of contributions">
            <InfoOutlinedIcon 
              sx={{ 
                ml: 1, 
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                cursor: 'pointer',
              }} 
            />
          </Tooltip>
        </Typography>
      </HeaderSection>

      {error ? (
        <Alert 
          severity="error" 
          sx={{ mb: 4 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={handleRefresh}
              startIcon={<AutorenewIcon />}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      ) : (
        <>
          <CategoryContainer>
            {categories.map((category) => (
              <CategoryChip
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                active={activeCategory === category.id}
                variant={activeCategory === category.id ? "contained" : "outlined"}
                startIcon={category.icon}
              >
                {category.label}
              </CategoryChip>
            ))}
          </CategoryContainer>

          <Fade in={true}>
            <Box>
              {getCurrentData().map((item, index) => (
                <LeaderCard
                  key={item.username}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <ContentWrapper>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: 0 }}>
                      <RankBadge rank={index + 1}>
                        {index + 1}
                      </RankBadge>
                      <UserSection>
                        <UserAvatar src={item.avatarUrl} alt={item.username} />
                        <Box sx={{ minWidth: 0 }}>
                          <FarcasterUsername username={item.username} />
                          {!isMobile && item.bio && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ 
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {item.bio}
                            </Typography>
                          )}
                        </Box>
                      </UserSection>
                    </Box>
                    <Tooltip title={tooltips[activeCategory]}>
                      <AmountDisplay>
                        <MonetizationOnIcon />
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                          <span className="amount">${formatAmount(item.amount)}</span>
                          {!isMobile && (
                            <span className="suffix">{getAmountSuffix(activeCategory)}</span>
                          )}
                        </Box>
                      </AmountDisplay>
                    </Tooltip>
                  </ContentWrapper>
                </LeaderCard>
              ))}
              
              {getCurrentData().length === 0 && (
                <Box textAlign="center" py={4}>
                  <Typography color="text.secondary">
                    No data available for this category
                  </Typography>
                </Box>
              )}
            </Box>
          </Fade>
        </>
      )}
    </LeaderboardContainer>
  );
};

export default Leaderboard;