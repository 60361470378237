// frontend/src/components/CommonStyledCard.js

import { Card, Typography, Box, alpha, styled } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  borderRadius: theme.shape.borderRadius * 2,
  background: 'none',
  boxShadow: 'none',
  position: 'relative',
  
  '&:hover': {
    transform: 'translateY(-4px)',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius * 2,
    padding: '1px',
    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.2)}, ${alpha(theme.palette.primary.main, 0.1)})`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none',
  },
}));

export const CardInner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  
  background: `linear-gradient(135deg, 
  ${alpha(theme.palette.background.paper, 0.95)} 0%,
  ${alpha(theme.palette.background.paper, 0.8)} 100%)`,

// Add subtle animated gradient border
'&::before': {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: 'inherit',
  padding: '1px',
  background: `linear-gradient(45deg, 
    ${alpha(theme.palette.primary.main, 0.3)},
    ${alpha(theme.palette.secondary.main, 0.3)},
    ${alpha(theme.palette.primary.main, 0.3)})`,
  WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  WebkitMaskComposite: 'xor',
  maskComposite: 'exclude',
  animation: 'borderGradient 4s linear infinite',
},

// Add subtle glow effect on hover
'&:hover': {
  '&::before': {
    background: `linear-gradient(45deg, 
      ${alpha(theme.palette.primary.main, 0.5)},
      ${alpha(theme.palette.secondary.main, 0.5)},
      ${alpha(theme.palette.primary.main, 0.5)})`,
  },
  boxShadow: `
    0 8px 32px ${alpha(theme.palette.common.black, 0.15)},
    0 0 0 1px ${alpha(theme.palette.primary.main, 0.1)},
    0 0 64px ${alpha(theme.palette.primary.main, 0.1)}
  `,
},

'@keyframes borderGradient': {
  '0%': {
    backgroundPosition: '0% 50%',
  },
  '50%': {
    backgroundPosition: '100% 50%',
  },
  '100%': {
    backgroundPosition: '0% 50%',
  },
},
}));

export const StyledAmountDisplay = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  
  backdropFilter: 'blur(8px)',
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.6)} 0%,
    ${alpha(theme.palette.background.paper, 0.3)} 100%)`,
  
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  
  '& .amount-text': {
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    display: 'inline-block',
    transformOrigin: 'left center',
  },

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)}
    `,
    
    '& .amount-text': {
      transform: 'scale(1.05)',
      textShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.5)}`,
    },
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: `linear-gradient(90deg, 
      ${alpha(theme.palette.primary.main, 0)},
      ${theme.palette.primary.main},
      ${alpha(theme.palette.primary.main, 0)}
    )`,
    backgroundSize: '200% 100%',
    animation: 'shimmer 3s infinite linear',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: `radial-gradient(circle at 50% 50%, 
      ${alpha(theme.palette.primary.main, 0.1)} 0%,
      transparent 70%)`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    pointerEvents: 'none',
    mixBlendMode: 'soft-light',
  },

  '&:hover::after': {
    opacity: 1,
    animation: 'pulse 2s infinite',
  },

  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: '200% 0',
    },
    '100%': {
      backgroundPosition: '-200% 0',
    },
  },

  '@keyframes pulse': {
    '0%': {
      opacity: 0.4,
      transform: 'scale(1)',
    },
    '50%': {
      opacity: 0.6,
      transform: 'scale(1.02)',
    },
    '100%': {
      opacity: 0.4,
      transform: 'scale(1)',
    },
  },

  // Animated particles
  '& .particles': {
    position: 'absolute',
    inset: 0,
    pointerEvents: 'none',
    overflow: 'hidden',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      background: alpha(theme.palette.primary.main, 0.3),
      boxShadow: `0 0 10px ${alpha(theme.palette.primary.main, 0.5)}`,
      animation: 'float 4s infinite',
      opacity: 0,
    },
    '&::before': {
      left: '10%',
      top: '20%',
      animationDelay: '0s',
    },
    '&::after': {
      right: '15%',
      bottom: '30%',
      animationDelay: '2s',
    },
  },

  '@keyframes float': {
    '0%': {
      transform: 'translateY(0) scale(1)',
      opacity: 0,
    },
    '50%': {
      transform: 'translateY(-20px) scale(1.2)',
      opacity: 0.5,
    },
    '100%': {
      transform: 'translateY(-40px) scale(1)',
      opacity: 0,
    },
  },

  // Enhanced inner shadow on hover
  '&:hover': {
    '&::before': {
      animation: 'shimmer 2s infinite linear',
    },
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.background.paper, 0.7)} 0%,
      ${alpha(theme.palette.background.paper, 0.4)} 100%)`,
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)},
      inset 0 0 20px ${alpha(theme.palette.primary.main, 0.05)}
    `,
  },

  // Responsive adjustments
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    '& .particles': {
      display: 'none', // Disable particles on mobile for better performance
    },
  },

  // Ensure content remains visible
  '& > *': {
    position: 'relative',
    zIndex: 1,
  },
}));

export const UnifiedCardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: {
    xs: '1.25rem',    // Good readable size on mobile
    sm: '1.35rem',    // Slight increase for tablets
    md: '1.5rem'      // Comfortable size for desktop
  },
  lineHeight: 1.4,
  color: theme.palette.text.primary,
  transition: 'all 0.2s ease-in-out',
  textAlign: 'left',
  width: '100%',
  overflow: 'hidden',
  wordBreak: 'break-word',
  display: 'inline-block',
  
  '&:hover': {
    background: theme => `linear-gradient(135deg, 
      ${theme.palette.text.primary} 0%, 
      ${alpha(theme.palette.text.primary, 0.8)} 100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    transform: 'translateY(-1px)',
  }
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start', // Changed from 'center' to better align with multiline text
  width: '100%',
  marginBottom: theme.spacing(2),
  
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
    marginTop: '0.2rem' // Adds a small offset to align icon with first line of text
  }
}));
