export const HOME_PAGE_EXAMPLES = {
    recommendations: {
      request: {
        title: "Strategic Analysis: Enterprise AI Infrastructure Stack",
        description:
          "Seeking comprehensive analysis and recommendations for enterprise AI infrastructure providers, focusing on:\n\n- LLM deployment and fine-tuning capabilities\n- Cost-efficiency at scale\n- Data privacy and security measures\n- Integration capabilities\n- Performance benchmarking\n\nNeed detailed comparison of major providers and emerging players with specific technical recommendations for a large-scale deployment.",
        category: "recommendation",
        bounty: 100000,
        creator: "tech.ventures",
        status: "active",
        subcategory: "ai_tools",
        totalBounty: 100000,
        totalBids: 28,
        totalVouches: 64,
        createdAt: new Date().toISOString(),
        recommendationDeadline: "2024-12-31",
        creatorDetails: {
          username: "tech.ventures",
          displayName: "TechFund Ventures",
          avatarUrl: null,
        },
      },
      bids: [
        {
          bidder: "ai.infrastructure",
          bidderDetails: {
            username: "ai.infrastructure",
            avatarUrl: null,
            displayName: "Ava Lawson", // Subtle reference to Ada Lovelace
          },
          proposal:
            "As former Head of AI Infrastructure at [Major Tech Company] and advisor to multiple enterprise AI deployments, I can provide comprehensive analysis based on direct experience scaling AI operations from $1M to $50M+ monthly compute spend.",
          status: "pending",
          totalVouchAmount: 85000,
          vouches: [
            {
              voucher: "mlops.lead",
              amount: 35000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "mlops.lead",
                displayName: "Grace Harper", // Subtle reference to Grace Hopper
                bio: "ML Infrastructure Lead, [Large Tech Company]",
              },
            },
            {
              voucher: "ai.architect",
              amount: 30000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "ai.architect",
                displayName: "Claudia Chen", // Subtle reference to Claude Shannon
                bio: "Principal AI Architect, [Major Cloud Provider]",
              },
            },
            {
              voucher: "ml.scale",
              amount: 20000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "ml.scale",
                displayName: "Jeffrey Hamilton", // Subtle reference to Geoffrey Hinton
                bio: "VP of ML Operations, [AI Unicorn]",
              },
            },
          ],
        },
      ],
    },
    projects: {
      request: {
        title: "Zero-Knowledge Cross-Chain NFT Infrastructure",
        description:
          "Seeking development team to build next-generation NFT marketplace infrastructure with:\n\n- Cross-chain support (Ethereum, Base, Optimism)\n- Zero-knowledge proofs for private listings and transfers\n- Automated royalty enforcement\n- Advanced trading features\n- High-performance matching engine\n\nLooking for team with proven track record in zkSNARKs and cross-chain bridges.",
        category: "project",
        bounty: 150000,
        creator: "nft.protocol",
        status: "active",
        subcategory: "blockchain_web3",
        totalBounty: 150000,
        totalBids: 34,
        totalVouches: 82,
        budget: {
          min: 750000,
          max: 1500000,
          isFlexible: false,
        },
        createdAt: new Date().toISOString(),
        bidDeadline: "2024-12-31",
        creatorDetails: {
          username: "nft.protocol",
          displayName: "NFT Protocol DAO",
          avatarUrl: null,
        },
      },
      bids: [
        {
          bidder: "zk.protocol.dev",
          bidderDetails: {
            username: "zk.protocol.dev",
            displayName: "George Szabo", // Subtle reference to Nick Szabo
            avatarUrl: null,
          },
          proposal:
            "Our team has built multiple successful cross-chain protocols and marketplaces. Recently completed privacy-focused DEX using zkSNARKs with $500M+ TVL. Expertise in sustainable scaling and secure bridge architectures.",
          amount: 950000,
          status: "pending",
          totalVouchAmount: 120000,
          vouches: [
            {
              voucher: "defi.security",
              amount: 45000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "defi.security",
                displayName: "Robert Finney", // Subtle reference to Hal Finney
                bio: "Head of Security, [Major DeFi Protocol]",
              },
            },
            {
              voucher: "nft.tech",
              amount: 40000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "nft.tech",
                displayName: "Wayne Dai", // Subtle reference to Wei Dai
                bio: "Tech Lead, [Leading NFT Platform]",
              },
            },
            {
              voucher: "crosschain.dev",
              amount: 35000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "crosschain.dev",
                displayName: "Adam Beckett", // Subtle reference to Adam Back
                bio: "Cross-chain Infrastructure Lead, [Blockchain Foundation]",
              },
            },
          ],
        },
      ],
    },
    jobs: {
      request: {
        title: "Chief Zero-Knowledge Research Lead",
        description:
          "Seeking experienced ZK researcher to lead our zero-knowledge proof research and implementation team. Key responsibilities:\n\n- Lead research into novel ZK applications\n- Develop efficient proof systems\n- Scale ZK infrastructure\n- Coordinate with protocol teams\n- Drive ZK adoption and education\n\nIdeal candidate will have deep expertise in ZK theory and practical implementations.",
        category: "job",
        bounty: 200000,
        creator: "zk.foundation",
        status: "active",
        subcategory: "blockchain_web3",
        totalBounty: 200000,
        totalBids: 42,
        totalVouches: 96,
        salaryRange: {
          min: 400000,
          max: 800000,
          isFlexible: true,
        },
        createdAt: new Date().toISOString(),
        applicationDeadline: "2024-12-31",
        creatorDetails: {
          username: "zk.foundation",
          displayName: "ZK Research Foundation",
          avatarUrl: null,
        },
      },
      bids: [
        {
          bidder: "zk.researcher",
          bidderDetails: {
            username: "zk.researcher",
            displayName: "Whitney Fields", // Subtle reference to Whitfield Diffie
            avatarUrl: null,
          },
          proposal:
            "Led zero-knowledge research at [Major Protocol], developed novel proof systems reducing verification time by 90%. Published multiple papers on efficient ZK circuits and recursive proof composition.",
          salary: 650000,
          status: "pending",
          totalVouchAmount: 160000,
          vouches: [
            {
              voucher: "crypto.pioneer",
              amount: 60000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "crypto.pioneer",
                displayName: "Henry Merkle", // Subtle reference to Ralph Merkle
                bio: "Founder, [ZK Protocol]",
              },
            },
            {
              voucher: "zk.expert",
              amount: 55000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "zk.expert",
                displayName: "Martin Hillman", // Subtle reference to Martin Hellman
                bio: "Head of Research, [Privacy Protocol]",
              },
            },
            {
              voucher: "protocol.lead",
              amount: 45000,
              paymentStatus: "completed",
              voucherDetails: {
                username: "protocol.lead",
                displayName: "Adrian Shamir", // Subtle reference to Adi Shamir
                bio: "Protocol Lead, [ZK Platform]",
              },
            },
          ],
        },
      ],
    },
    aiProject: {
        request: {
            title: "Custom LLM Development",
            description:
            "Seeking an experienced AI development team to build a custom Large Language Model (LLM) trained on our proprietary enterprise knowledge base. Requirements include:\n\n- Model training and fine-tuning using our data\n- Deployment in a secure and scalable environment\n- Integration with existing enterprise applications\n- Ensuring data privacy and compliance\n- Performance optimization for low-latency responses\n\nLooking for a team with proven experience in LLM development and deployment at scale.",
            category: "project",
            bounty: 200000,
            creator: "global.corp",
            status: "active",
            subcategory: "llm_development",
            totalBounty: 200000,
            totalBids: 40,
            totalVouches: 90,
            budget: {
            min: 1000000,
            max: 2000000,
            isFlexible: false,
            },
            createdAt: new Date().toISOString(),
            bidDeadline: "2024-12-31",
            creatorDetails: {
            username: "global.corp",
            displayName: "GlobalCorp Inc.",
            avatarUrl: null,
            },
        },
        bids: [
            {
            bidder: "llm.experts",
            bidderDetails: {
                username: "llm.experts",
                displayName: "Dr. Aisha Khan", // Subtle reference to AI pioneers
                avatarUrl: null,
            },
            proposal:
                "Our team specializes in developing and deploying custom LLMs for Fortune 500 companies. Recently delivered a project reducing customer support response time by 70% using an LLM integrated with enterprise systems.",
            amount: 1500000,
            status: "pending",
            totalVouchAmount: 180000,
            vouches: [
                {
                voucher: "enterprise.ai",
                amount: 70000,
                paymentStatus: "completed",
                voucherDetails: {
                    username: "enterprise.ai",
                    displayName: "Dr. Elena Turing", // Subtle reference to Alan Turing
                    bio: "Chief AI Officer, [Global Tech Company]",
                },
                },
                {
                voucher: "ml.ops",
                amount: 60000,
                paymentStatus: "completed",
                voucherDetails: {
                    username: "ml.ops",
                    displayName: "Michael Neumann", // Subtle reference to John von Neumann
                    bio: "Head of ML Operations, [Leading AI Firm]",
                },
                },
                {
                voucher: "secure.ai",
                amount: 50000,
                paymentStatus: "completed",
                voucherDetails: {
                    username: "secure.ai",
                    displayName: "Dr. Grace Lovelace", // Combined reference to Grace Hopper and Ada Lovelace
                    bio: "Director of AI Security, [Enterprise Solutions]",
                },
            },
          ],
        },
      ],
    },
  };