// frontend/src/services/adminApi.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_NGROK_URL || process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const adminApi = axios.create({
  baseURL: `${API_URL}/admin`,
  withCredentials: true,
});

const baseApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const handleApiError = (error) => {
  console.error('API Error:', error.response ? error.response.data : error.message);
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    window.location.href = '/login';
    throw new Error('Unauthorized. Please log in as an admin.');
  }
  throw error;
};

export const verifyTwoFactor = async (token) => {
  try {
    const response = await baseApi.post('/auth/admin/verify-2fa', { token });
    return response.data;
  } catch (error) {
    console.error('Error verifying 2FA:', error);
    throw error;
  }
};

export const getDashboardStats = async () => {
  try {
    const response = await adminApi.get('/dashboard-stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard stats:', error);
    if (error.response && error.response.status === 403 && error.response.data.requiresTwoFactor) {
      // Redirect to 2FA verification
      window.location.href = '/admin/verify-2fa';
    }
    throw error;
  }
};

export const getUsersList = async () => {
  try {
    const response = await adminApi.get('/users');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getRequestsList = async () => {
  try {
    const response = await adminApi.get('/requests');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getBidsList = async () => {
  try {
    const response = await adminApi.get('/bids');
    const formattedBids = response.data.map(bid => ({
      ...bid,
      requestId: bid.requestId ? { _id: bid.requestId._id, title: bid.requestId.title } : 'N/A'
    }));
    return formattedBids;
  } catch (error) {
    handleApiError(error);
  }
};

export const getVouchesList = async () => {
  try {
    const response = await adminApi.get('/vouches');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getPaymentsList = async () => {
  try {
    const response = await adminApi.get('/payments');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getWalletBalance = async () => {
  try {
    const response = await adminApi.get('/wallet-balance');
    return response.data.balance;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const transferUSDC = async (toAddress, amount) => {
  try {
    const response = await adminApi.post('/transfer-usdc', { toAddress, amount });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getFailedPayments = async () => {
  try {
    const response = await adminApi.get('/failed-payments');
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const retryFailedPayment = async (paymentId) => {
  try {
    const response = await adminApi.post(`/retry-payment/${paymentId}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const toggleUserStatus = async (username) => {
  try {
    const response = await adminApi.post(`/users/${username}/toggle-status`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getHighValueRequests = async () => {
  try {
    const response = await adminApi.get('/high-value-requests');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};