// frontend/src/components/ProcessCompletionMessage.js

import React from 'react';
import { Box, Typography, Alert, CircularProgress, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  alignItems: 'center',
  '& .MuiAlert-message': {
    width: '100%',
  },
  opacity: 1,
  transition: 'opacity 0.3s ease-in-out',
}));

const ProcessingIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const RotatingIcon = styled(SyncIcon)(({ theme }) => ({
  animation: 'spin 2s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const ProcessCompletionMessage = ({ processType }) => {
  const getMessage = (type) => {
    switch (type) {
      case 'bidding':
        return {
          title: 'Selection Process Complete',
          mainMessage: 'Your selection has been recorded and the following updates are being processed:',
          steps: [
            'Marking the selected bid as accepted',
            'Processing refunds for vouches on other bids',
            'Updating all bid and vouch statuses'
          ],
          note: 'You can safely close this window now. Status updates and vouch refunds will continue processing in the background, and all participants will be notified once complete.'
        };
      default:
        return {
          title: 'Bounty Decision Recorded',
          mainMessage: 'Your decision has been recorded and the following actions are being processed:',
          steps: [
            'Processing bounty distributions',
            'Updating payment records',
            'Synchronizing transaction statuses'
          ],
          note: 'You can safely close this window now. Bounty distributions will continue processing in the background, and all participants will be notified once complete.'
        };
    }
  };

  const message = getMessage(processType);

  return (
    <StyledAlert 
      severity="info" 
      icon={<InfoOutlinedIcon />}
    >
      <Box>
        <Typography variant="h6" gutterBottom>
          {message.title}
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message.mainMessage}
        </Typography>

        <Box sx={{ pl: 2 }}>
          {message.steps.map((step, index) => (
            <Typography 
              key={index} 
              variant="body2" 
              sx={{ 
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                '&:before': {
                  content: '"•"',
                  color: 'primary.main',
                  pr: 1,
                  fontWeight: 'bold'
                }
              }}
            >
              {step}
            </Typography>
          ))}
        </Box>

        <Box sx={{ 
          mt: 3, 
          p: 2, 
          bgcolor: 'background.paper', 
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          <Typography 
            variant="body2" 
            sx={{ 
              display: 'flex', 
              alignItems: 'flex-start',
              color: 'text.primary'
            }}
          >
            <CheckCircleIcon 
              sx={{ 
                mr: 1.5, 
                mt: 0.2,
                fontSize: 20, 
                color: 'success.main' 
              }} 
            />
            {message.note}
          </Typography>
        </Box>

        <ProcessingIndicator>
          <RotatingIcon color="info" fontSize="small" />
          <Typography variant="body2" color="text.secondary">
            Background processing in progress
          </Typography>
        </ProcessingIndicator>
      </Box>
    </StyledAlert>
    
  );
};

export default React.memo(ProcessCompletionMessage);