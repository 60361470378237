// frontend/src/components/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Paper, 
  Tabs, 
  Tab, 
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardStats from './admin/DashboardStats';
import UsersList from './admin/UsersList';
import RequestsList from './admin/RequestsList';
import BidsList from './admin/BidsList';
import VouchesList from './admin/VouchesList';
import PaymentsList from './admin/PaymentsList';
import WalletManagement from './admin/WalletManagement';
import TwoFactorVerify from './admin/TwoFactorVerify';
import FailedPaymentsList from './admin/FailedPaymentsList';
import HighValueRequests from './admin/HighValueRequests';
import { getDashboardStats } from '../services/adminApi';
import { useAuth } from '../context/AuthContext';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
}));

const ScrollableTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& .MuiTabs-scroller': {
      overflow: 'auto !important',
      scrollbarWidth: 'none', // Firefox
      '&::-webkit-scrollbar': {  // Webkit
        display: 'none'
      }
    }
  }
}));

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    sx={{ mt: 3 }}
  >
    {value === index && children}
  </Box>
);

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [needsVerification, setNeedsVerification] = useState(false);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const { user } = useAuth();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const tabComponents = [
    { label: "Users", component: <UsersList /> },
    { label: "Requests", component: <RequestsList /> },
    { label: "Bids", component: <BidsList /> },
    { label: "Vouches", component: <VouchesList /> },
    { label: "Payments", component: <PaymentsList /> },
    { label: "Failed Payments", component: <FailedPaymentsList /> },
    { label: "High Value", component: <HighValueRequests /> },
    { label: "Wallet", component: <WalletManagement /> },
  ];

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  const fetchDashboardStats = async () => {
    try {
      setIsLoading(true);
      const data = await getDashboardStats();
      setStats(data);
      setNeedsVerification(false);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
      if (error.response?.status === 403 && error.response?.data?.requiresTwoFactor) {
        setNeedsVerification(true);
      } else {
        setError('Failed to load dashboard stats');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    handleMobileMenuClose();
  };

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleVerified = () => {
    fetchDashboardStats();
  };

  if (needsVerification) {
    return (
      <Container maxWidth="sm">
        <TwoFactorVerify onVerified={handleVerified} />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  const renderTabs = () => {
    if (isMobile) {
      return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleMobileMenuClick}
            startIcon={<MenuIcon />}
            sx={{ width: '100%', mb: 2 }}
          >
            {tabComponents[activeTab].label}
          </Button>
          <Menu
            anchorEl={mobileMenuAnchor}
            open={Boolean(mobileMenuAnchor)}
            onClose={handleMobileMenuClose}
          >
            {tabComponents.map((tab, index) => (
              <MenuItem
                key={index}
                onClick={() => handleTabChange(null, index)}
                selected={activeTab === index}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
    }

    return (
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        width: '100%',
        overflow: isTablet ? 'auto' : 'hidden'
      }}>
        <ScrollableTabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant={isTablet ? "scrollable" : "standard"}
          scrollButtons={isTablet ? "auto" : false}
          allowScrollButtonsMobile
          sx={{
            maxWidth: '100%',
            '& .MuiTab-root': {
              minWidth: isTablet ? 'auto' : 120,
              px: isTablet ? 2 : 3,
            }
          }}
        >
          {tabComponents.map((tab, index) => (
            <Tab 
              key={index} 
              label={tab.label}
              sx={{
                fontSize: isTablet ? '0.875rem' : 'inherit',
                minHeight: isTablet ? 48 : 'inherit'
              }}
            />
          ))}
        </ScrollableTabs>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledPaper>
            <DashboardStats stats={stats} />
          </StyledPaper>
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 4, width: '100%' }}>
        {renderTabs()}
        <Box sx={{ mt: 2 }}>
          {tabComponents[activeTab].component}
        </Box>
      </Box>
    </Container>
  );
};

export default AdminDashboard;