// src/components/ConnectionAlert.js

import React from 'react';
import { Alert, IconButton, Tooltip } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const StyledAlert = styled(Alert)(({ theme }) => ({
  backdropFilter: 'blur(10px)',
  backgroundColor: `rgba(25, 25, 25, 0.8)`,
  border: `1px solid ${theme.palette.warning.main}20`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2),
  '& .MuiAlert-message': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const RefreshButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
  padding: theme.spacing(0.5),
  color: theme.palette.warning.light,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    transform: 'rotate(30deg)',
  },
  '&.refreshing': {
    animation: `${spin} 1s linear infinite`,
    backgroundColor: 'transparent',
  },
}));

const ConnectionAlert = ({ onRefresh, isRefreshing = false }) => {
  return (
    <StyledAlert
      severity="warning"
      action={
        <Tooltip title="Refresh data">
          <RefreshButton
            size="small"
            onClick={onRefresh}
            disabled={isRefreshing}
            className={isRefreshing ? 'refreshing' : ''}
          >
            {isRefreshing ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <RefreshIcon fontSize="small" />
            )}
          </RefreshButton>
        </Tooltip>
      }
    >
      Not connected to real-time updates. Some information may be outdated.
    </StyledAlert>
  );
};

export default ConnectionAlert;