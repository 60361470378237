// frontend/src/components/RequestHeader.js

import React from 'react';
import {
  Typography,
  Box,
  Chip,
  Grid,
  Paper,
  Tooltip,
} from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import RecommendIcon from '@mui/icons-material/Recommend';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HistoryIcon from '@mui/icons-material/History';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FarcasterUsername from './FarcasterUsername';
import FarcasterAvatar from './FarcasterAvatar';
import {
  formatSubcategory,
  formatRequestStatus,
} from '../utilities/formatters';
import { getStatusColor } from '../utilities/statusUtils';
import { getSubcategoryIcon } from '../utilities/subcategoryUtils';
import CategoryItem from './CategoryItem';

const TitleBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),  // Increased from 4
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),  // Increased from 3
  },
}));

const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4), // Add more space after title row
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(3), // Slightly less space but still significant on mobile
  },
}));

const HeaderPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  // Add a subtle gradient background
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 1)} 0%,
    ${alpha(theme.palette.background.paper, 0.95)} 100%)`,
  
  // Add a subtle inner shadow
  boxShadow: `inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
              0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
  
  // Enhanced hover state
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)}
    `,
    
    '& .header-title': {
      transform: 'translateY(-2px)',
    },
    
    '& .header-category': {
      transform: 'translateX(4px)',
    }
  },

  // Add decorative element
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      radial-gradient(circle at 0% 0%, ${alpha(theme.palette.primary.main, 0.03)} 0%, transparent 50%),
      radial-gradient(circle at 100% 0%, ${alpha(theme.palette.secondary.main, 0.03)} 0%, transparent 50%),
      radial-gradient(circle at 100% 100%, ${alpha(theme.palette.primary.main, 0.03)} 0%, transparent 50%),
      radial-gradient(circle at 0% 100%, ${alpha(theme.palette.secondary.main, 0.03)} 0%, transparent 50%)
    `,
    opacity: 0.6,
    pointerEvents: 'none',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, 0.03)}, 
      transparent 70%)`,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const color = getStatusColor(status, theme);
  const pulseAnimationName = `statusPulse_${status}`;
  
  return {
    minWidth: 'auto',
    height: '36px',
    padding: '0 20px',
    backgroundColor: alpha(color, 0.1),
    border: `1px solid ${alpha(color, 0.2)}`,
    color: color,
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: '0.02em',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    
    '&:hover': {
      backgroundColor: alpha(color, 0.15),
      transform: 'translateY(-1px)',
      boxShadow: `0 4px 12px ${alpha(color, 0.2)}`,
    },

    '& .MuiChip-label': {
      padding: '0 12px',
    },

    // Keep the pulse animation
    [`@keyframes ${pulseAnimationName}`]: {
      '0%': {
        boxShadow: `0 0 0 0 ${alpha(color, 0.4)}`,
        borderColor: alpha(color, 0.5),
      },
      '50%': {
        borderColor: alpha(color, 0.8),
      },
      '70%': {
        boxShadow: `0 0 0 8px ${alpha(color, 0)}`,
      },
      '100%': {
        boxShadow: `0 0 0 0 ${alpha(color, 0)}`,
        borderColor: alpha(color, 0.5),
      },
    },
    animation: status === 'bounty_released' || status === 'bounty_withheld' || status === 'active' ? 
      `${pulseAnimationName} 2s infinite cubic-bezier(0.4, 0, 0.6, 1)` : 'none',

    [theme.breakpoints.down('sm')]: {
      height: '32px',
      padding: '0 16px',
      fontSize: '0.875rem',
    },
  };
});

const CategoryChip = styled(Chip)(({ theme, category }) => {
  const getColor = () => {
    switch (category) {
      case 'job': return theme.palette.primary.main;
      case 'project': return theme.palette.secondary.main;
      case 'recommendation': return theme.palette.info.main;
      default: return theme.palette.primary.main;
    }
  };

  const color = getColor();
  
  return {
    backgroundColor: alpha(color, 0.08),
    border: `1px solid ${alpha(color, 0.2)}`,
    color: color,
    fontWeight: 600,
    fontSize: '0.875rem',
    height: '32px',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    padding: '0 16px',
    
    '&:hover': {
      backgroundColor: alpha(color, 0.12),
      transform: 'translateY(-1px)',
      boxShadow: `0 4px 12px ${alpha(color, 0.15)}`,
    },

    '& .MuiChip-icon': {
      fontSize: '1.25rem',
    },

    '& .MuiChip-label': {
      padding: '0 8px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
      height: '28px',
      '& .MuiChip-icon': {
        fontSize: '1.1rem',
      },
    },
  };
});

const BountyBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  
  // Add glassmorphism effect
  backdropFilter: 'blur(8px)',
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.8)} 0%,
    ${alpha(theme.palette.background.paper, 0.4)} 100%)`,
  
  // Enhanced shadow and glow effect
  boxShadow: `
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)}
    `,
  },

  // Add animated gradient border
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: `linear-gradient(90deg, 
      ${alpha(theme.palette.primary.main, 0)},
      ${theme.palette.primary.main},
      ${alpha(theme.palette.primary.main, 0)}
    )`,
    backgroundSize: '200% 100%',
    animation: 'shimmer 3s infinite linear',
  },

  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: '200% 0',
    },
    '100%': {
      backgroundPosition: '-200% 0',
    },
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const InfoPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  
  // Add glassmorphism effect
  backdropFilter: 'blur(8px)',
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.6)} 0%,
    ${alpha(theme.palette.background.paper, 0.3)} 100%)`,
  
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)}
  `,
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)}
    `,
  },

  // Add subtle background pattern
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      radial-gradient(circle at 100% 100%, 
        ${alpha(theme.palette.primary.main, 0.05)} 0%, 
        transparent 50%
      )
    `,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CreatorInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start', // Changed from center to better align with larger text
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3.5),
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.4)} 0%,
    ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
  backdropFilter: 'blur(8px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.3s ease-in-out',
  
  '& .MuiAvatar-root': {
    width: 80, // Increased size
    height: 80,
    marginRight: theme.spacing(3),
    border: `3px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.15)}`,
    transition: 'all 0.3s ease-in-out',
  },
  
  '& .creator-info': {
    transition: 'all 0.3s ease-in-out',
    paddingTop: theme.spacing(1), // Added to align with larger avatar
  },
  
  '&:hover': {
    transform: 'translateY(-2px)',
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.background.paper, 0.5)} 0%,
      ${alpha(theme.palette.background.paper, 0.2)} 100%)`,
    boxShadow: `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
    
    '& .MuiAvatar-root': {
      transform: 'scale(1.05) rotate(3deg)',
      boxShadow: `0 0 30px ${alpha(theme.palette.primary.main, 0.25)}`,
    },
    '& .creator-info': {
      transform: 'translateX(4px)',
    },
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
    '& .MuiAvatar-root': {
      width: 64,
      height: 64,
      marginRight: theme.spacing(2),
    },
  },
}));

const TooltipIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const InfoSection = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const BountyLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  marginBottom: theme.spacing(0.5),
}));

const StatusText = styled(Typography)(({ theme, status }) => ({
  marginBottom: theme.spacing(3),
  fontSize: '0.9375rem',
  fontWeight: 500,
  color: status === 'active' ? theme.palette.success.main :
         (status === 'draft' || status === 'payment_initiated' || status === 'payment_pending') ? 
         theme.palette.warning.main : 
         theme.palette.text.secondary,
  
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.85rem',
  },

 }));

const formatLiveDate = (date) => {
  if (!date) return null;
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const formattedTime = new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
  return `${formattedDate} at ${formattedTime}`;
};

const RequestHeader = ({ request, isCreator, pendingAdditionalBounties }) => {
  const theme = useTheme();

  const formatDate = (date) => {
    if (!date) return 'No set deadline';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  if (!request) return null;

  const initialBounty = Number(request.bounty) || 0;
  const additionalBountiesSum =
    request.additionalBounties?.reduce(
      (sum, bounty) => sum + Number(bounty.amount),
      0
    ) || 0;
  const currentTotalBounty = initialBounty + additionalBountiesSum;
  const pendingBountyAmount =
    pendingAdditionalBounties?.reduce(
      (sum, bounty) => sum + Number(bounty.amount),
      0
    ) || 0;

    const getBountyExplanation = (category) => {
      switch (category) {
        case 'recommendation':
          return "This bounty compensates both the expert providing the recommendation (42.5%) and their vouchers (42.5%) for their upfront work and validation. The recommender can invest time and expertise in research and analysis before providing their recommendation.";
        case 'project':
          return "This bounty is shared among vouchers (85%) who help identify the best proposal. The actual project payment will be provided separately by the requester to the selected service provider.";
        case 'job':
          return "This bounty is shared among vouchers (85%) who help identify the best applications. The actual employment compensation will be provided separately by the requester to the selected applicant.";
        default:
          return "Total bounty amount available for distribution";
      }
    };
    
    const getBountyTooltip = () => {
      return getBountyExplanation(request.category);
    };

  const getDeadlineLabel = () => {
    if (request.category === 'job') {
      return 'Application Deadline';
    } else if (request.category === 'project') {
      return 'Bid Deadline';
    } else {
      return 'Recommendation Deadline';
    }
  };

  const getDeadlineDate = () => {
    if (request.category === 'job') {
      return formatDate(request.applicationDeadline);
    } else if (request.category === 'project') {
      return formatDate(request.bidDeadline);
    } else {
      return formatDate(request.recommendationDeadline);
    }
  };

  const getDeadlineTooltip = () => {
    const deadlineExists = request.applicationDeadline || request.bidDeadline || request.recommendationDeadline;
    const itemType = request.category === 'job' ? 'applications' :
                    request.category === 'project' ? 'bids' :
                    'recommendations';
    
    if (!deadlineExists) {
      return `This request has no set deadline. ${itemType.charAt(0).toUpperCase() + itemType.slice(1)} will be accepted until the requester selects one.`;
    }
  
    let baseText = request.category === 'job' ? 
      'Final date by which job applications will be accepted. ' :
      request.category === 'project' ? 
      'Final date by which project bids will be accepted. ' :
      'Final date by which recommendations will be accepted. ';
  
    return baseText + `The requester may close submissions early if they find a suitable ${
      request.category === 'job' ? 'candidate' :
      request.category === 'project' ? 'proposal' :
      'recommendation'
    }.`;
  };

  const renderSalaryOrBudget = () => {
    if (request.category === 'job') {
      if (request.salaryRange?.isFlexible) {
        return 'Salary: Flexible/Undetermined';
      } else if (
        request.salaryRange?.min != null &&
        request.salaryRange?.max != null
      ) {
        return `Salary: $${request.salaryRange.min.toLocaleString()} - $${request.salaryRange.max.toLocaleString()} / year`;
      }
    } else if (request.category === 'project') {
      if (request.budget?.isFlexible) {
        return 'Budget: Flexible/Undetermined';
      } else if (
        request.budget?.min != null &&
        request.budget?.max != null
      ) {
        return `Budget: $${request.budget.min.toLocaleString()} - $${request.budget.max.toLocaleString()}`;
      }
    }
    return null;
  };

  return (
    <HeaderPaper>
      <Box>
      {request.status === 'draft' || request.status === 'payment_initiated' ? (
        <StatusText status={request.status}>
          Draft - Awaiting Payment
        </StatusText>
      ) : request.status === 'payment_pending' ? (
        <StatusText status={request.status}>
          Payment Processing
        </StatusText>
      ) : request.status === 'active' ? (
        <StatusText status={request.status}>
          Live since {formatLiveDate(request.liveDate)}
        </StatusText>
      ) : request.liveDate ? (
        <StatusText status={request.status}>
          Request went live {formatLiveDate(request.liveDate)}
        </StatusText>
      ) : null}
        <TitleBox>
        <TitleRow>
        <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 700,  // Increased from 600
              lineHeight: 1.2,  // Tightened from 1.3
              wordBreak: 'break-word',
              fontSize: { 
                xs: '1.75rem',  // Increased from 1.5rem
                sm: '2.25rem',  // Increased from 1.75rem
                md: '2.75rem',  // Increased from 2rem
                lg: '3rem'      // New size for larger screens
              },
              background: `linear-gradient(135deg, 
                ${theme.palette.text.primary} 0%,
                ${alpha(theme.palette.text.primary, 0.8)} 100%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginBottom: { xs: 2, sm: 3 },  // Added explicit margin
            }}
>
            {request.title || 'Untitled Request'}
          </Typography>
          <Box>
            <StatusChip
              label={formatRequestStatus(request.status)}
              status={request.status}
            />
          </Box>
        </TitleRow>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
          <CategoryItem
            label={request.category}
            subcategoryKey={request.category}
            isCategory={true}
          />
          <CategoryItem
            label={formatSubcategory(request.subcategory)}
            subcategoryKey={request.subcategory}
          />
        </Box>

        <CreatorInfoSection sx={{ mt: 3 }}>  {/* Added explicit top margin */}
          <FarcasterAvatar
            username={request.creatorDetails?.username || request.creator}
            avatarUrl={request.creatorDetails?.avatarUrl}
            size="large"
          />
          <Box className="creator-info">
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                fontSize: { xs: '0.875rem', sm: '1rem' },
                fontWeight: 500,
                letterSpacing: '0.02em',
                marginBottom: 0.5,
                opacity: 0.8
              }}
            >
              Created by
            </Typography>
            <Box 
              sx={{ 
                // Override the FarcasterUsername styles specifically in this context
                '& a': {
                  fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                  fontWeight: 700,
                  textDecoration: 'none',
                  background: `linear-gradient(135deg, 
                    ${theme.palette.primary.main} 0%, 
                    ${theme.palette.primary.light} 100%)`,
                  backgroundSize: '200% 100%',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  transition: 'all 0.3s ease-in-out',
                  display: 'inline-block',
                  position: 'relative',
                  
                  // Enhance hover state visibility
                  '&:hover': {
                    textDecoration: 'none',
                    backgroundPosition: '100% 0',
                    transform: 'translateX(4px)',
                    
                    // Add underline animation
                    '&::after': {
                      transform: 'scaleX(1)',
                      opacity: 1,
                    },
                  },
                  
                  // Animated underline
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-4px',
                    left: 0,
                    width: '100%',
                    height: '2px',
                    background: `linear-gradient(90deg, 
                      ${theme.palette.primary.main}, 
                      ${theme.palette.primary.light})`,
                    transform: 'scaleX(0)',
                    opacity: 0,
                    transformOrigin: 'left center',
                    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                  },
                }
              }}
            >
              <FarcasterUsername username={request.creatorDetails?.username || request.creator} />
            </Box>
            {request.creatorDetails?.displayName && (
              <Typography
                component="span"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.125rem' },
                  color: 'text.secondary',
                  fontWeight: 400,
                  opacity: 0.7,
                  marginTop: 0.5,
                  display: 'block'
                }}
              >
                {request.creatorDetails.displayName}
              </Typography>
            )}
          </Box>
        </CreatorInfoSection>
      </TitleBox>
      </Box>

      <InfoSection>
        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={12} md={6}>
            <BountyBox>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AttachMoneyIcon
                    sx={{
                      color: 'primary.main',
                      mr: { xs: 0.75, sm: 1 },
                      fontSize: { xs: '1.25rem', sm: '1.5rem' },
                    }}
                  />
                  <BountyLabel>Bounty</BountyLabel>
                  <Tooltip title={getBountyTooltip()}>
                    <Box
                      sx={{
                        ml: { xs: 0.75, sm: 1 },
                        display: 'flex',
                        alignItems: 'center',
                        color: 'text.secondary',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                    >
                      <InfoOutlinedIcon sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }} />
                    </Box>
                  </Tooltip>
                </Box>
                <Box>
                <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: 800,  // Increased from 700
                      color: 'primary.main',
                      textShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',  // Enhanced shadow
                      mb: 1,  // Increased from 0.5
                      fontSize: {
                        xs: '1.5rem',    // Increased from 1.25rem
                        sm: '1.75rem',   // Increased from 1.5rem
                        md: '2.25rem',   // Increased from 1.75rem
                        lg: '2.75rem'    // Increased from 2rem
                      },
                      lineHeight: {
                        xs: 1.1,
                        sm: 1.2
                      }
                    }}
                  >
                    ${currentTotalBounty.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC
                  </Typography>
                  {pendingBountyAmount > 0 && (
                    <Typography
                      variant="body2"
                      color="warning.main"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        mt: 0.5,
                        fontSize: {
                          xs: '0.75rem',
                          sm: '0.875rem'
                        },
                        lineHeight: 1.2
                      }}
                    >
                      <Box
                        component="span"
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          whiteSpace: 'nowrap'
                        }}
                      >
                       + ${pendingBountyAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC pending
                      </Box>
                    </Typography>
                  )}
                </Box>
              </Box>
            </BountyBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={{ xs: 1.5, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <InfoPanel>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-start',
                      mb: { xs: 0.75, sm: 1 },
                      position: 'relative'
                    }}
                  >
                    <AccessTimeIcon
                      sx={{ 
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        mr: { xs: 0.75, sm: 1 },
                        color: 'text.secondary',
                        mt: '2px'
                      }}
                    />
                    <Box sx={{ flex: 1, minWidth: 0 }}>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: { xs: 0.5, sm: 1 },
                          fontWeight: 500,
                          fontSize: { 
                            xs: '0.75rem',
                            sm: '0.875rem' 
                          },
                          lineHeight: 1.4,
                          mb: { xs: 0.5, sm: 0.75 }
                        }}
                      >
                        {getDeadlineLabel()}
                        <Tooltip 
                          title={getDeadlineTooltip()}
                          placement="top"
                        >
                          <Box 
                            component="span" 
                            sx={{ 
                              display: 'inline-flex',
                              alignItems: 'center',
                              ml: 'auto',
                              cursor: 'pointer'
                            }}
                          >
                            <TooltipIcon 
                              sx={{ 
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                                transition: 'color 0.2s ease',
                                '&:hover': {
                                  color: 'primary.main'
                                }
                              }} 
                            />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 600,
                          fontSize: { 
                            xs: '0.875rem',
                            sm: '1rem',
                            md: '1.125rem' 
                          },
                          lineHeight: {
                            xs: 1.3,
                            sm: 1.4
                          },
                          color: 'text.primary',
                          wordBreak: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {getDeadlineDate()}
                      </Typography>
                    </Box>
                  </Box>
                </InfoPanel>
              </Grid>

              {(request.category === 'job' || request.category === 'project') && (
                <Grid item xs={12}>
                  <InfoPanel>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-start',
                        mb: { xs: 0.75, sm: 1 },
                        position: 'relative'
                      }}
                    >
                      {request.category === 'job' ? (
                        <WorkIcon
                          sx={{ 
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            mr: { xs: 0.75, sm: 1 },
                            color: 'text.secondary',
                            mt: '2px'
                          }}
                        />
                      ) : (
                        <BuildIcon
                          sx={{ 
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            mr: { xs: 0.75, sm: 1 },
                            color: 'text.secondary',
                            mt: '2px'
                          }}
                        />
                      )}
                      <Box sx={{ flex: 1, minWidth: 0 }}>
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ 
                            fontWeight: 500,
                            fontSize: { 
                              xs: '0.75rem',
                              sm: '0.875rem' 
                            },
                            lineHeight: 1.4,
                            mb: { xs: 0.5, sm: 0.75 }
                          }}
                        >
                          {request.category === 'job' ? 'Salary Range' : 'Budget Range'}
                        </Typography>
                        <Typography 
                          variant="subtitle1" 
                          sx={{ 
                            fontWeight: 600,
                            fontSize: { 
                              xs: '0.875rem',
                              sm: '1rem',
                              md: '1.125rem' 
                            },
                            lineHeight: {
                              xs: 1.3,
                              sm: 1.4
                            },
                            color: 'text.primary',
                            wordBreak: 'break-word',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {renderSalaryOrBudget()}
                        </Typography>
                      </Box>
                    </Box>
                  </InfoPanel>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </InfoSection>

      <Box 
        sx={{ 
          mt: { xs: 3, sm: 4 },
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '2px',
            height: '100%',
            background: (theme) => `linear-gradient(180deg, 
              ${alpha(theme.palette.primary.main, 0.3)},
              ${alpha(theme.palette.primary.main, 0.05)}
            )`,
          }
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          gutterBottom
          sx={{ 
            fontWeight: 600, 
            mb: 3,
            pl: 3,
            fontSize: { 
              xs: '1rem', 
              sm: '1.125rem', 
              md: '1.25rem' 
            },
            color: 'text.primary',
            position: 'relative',
          }}
        >
          Request Details
        </Typography>
        <Box 
          sx={{
            pl: 3,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: -12,
              left: 24,
              right: 0,
              height: '1px',
              background: (theme) => 
                `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 0.2)}, transparent)`
            }
          }}
        >
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              fontSize: { 
                xs: '1rem', 
                sm: '1.125rem', 
                md: '1.25rem' 
              },
              lineHeight: { 
                xs: 1.7, 
                sm: 1.8 
              },
              letterSpacing: '0.015em',
              '& a': { 
                color: 'primary.main',
                textDecoration: 'none',
                transition: 'all 0.2s ease-in-out',
                borderBottom: '1px dashed',
                borderColor: 'primary.main',
                '&:hover': {
                  color: 'primary.light',
                  borderColor: 'primary.light',
                }
              },
              '& p': {
                mb: { xs: 1.5, sm: 2 },
                '&:last-child': {
                  mb: 0
                }
              },
              wordBreak: 'break-word',
              maxWidth: '100%',
              overflowWrap: 'break-word',
              WebkitHyphens: 'auto',
              MozHyphens: 'auto',
              hyphens: 'auto',
              '& ul, & ol': {
                pl: { xs: 2, sm: 3 },
                mb: { xs: 1.5, sm: 2 },
                '& li': {
                  mb: 1,
                  '&:last-child': {
                    mb: 0
                  }
                }
              },
              '& blockquote': {
                borderLeft: (theme) => `3px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                pl: 2,
                py: 0.5,
                my: 2,
                fontStyle: 'italic',
                color: 'text.primary',
              }
            }}
            dangerouslySetInnerHTML={{
              __html: request.description || 'No description available',
            }}
          />
        </Box>
      </Box>
    </HeaderPaper>
  );
};

export default React.memo(RequestHeader);