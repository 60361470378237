// frontend/src/utilities/formatters.js

export const formatSubcategory = (subcategory) => {
  if (!subcategory) return ''; // Handle undefined or null
  
  // Create a mapping for special cases or abbreviations
  const specialCases = {
    // Jobs & Projects - Blockchain & Web3
    'blockchain_web3': 'Blockchain & Web3',
    'smart_contracts': 'Smart Contracts',
    'defi_dev': 'DeFi Development',
    'crypto_launch': 'Cryptocurrency & Token Projects',
    'nft_project_dev': 'NFT Project Development',
    'dao_development': 'DAO Development',
    'web3_infrastructure': 'Web3 Infrastructure',
    'crypto_operations': 'Crypto Operations & Trading',
    
    // Jobs & Projects - AI & ML
    'ai_ml': 'AI & Machine Learning',
    'nlp_applications': 'NLP & Language AI',
    'computer_vision': 'Computer Vision',
    'llm_development': 'LLM Development',
    'ml_ops': 'ML Operations & Infrastructure',
    
    // Jobs & Projects - Web & Mobile
    'web_mobile_dev': 'Web & Mobile Development',
    'systems_infrastructure': 'Systems & Infrastructure',
    'data_engineering': 'Data Engineering',
    'security_engineering': 'Security Engineering',
    'cloud_architecture': 'Cloud Architecture',
    
    // Jobs & Projects - Design & Creative
    'ui_ux_design': 'UI/UX Design',
    'brand_graphic_design': 'Brand & Graphic Design',
    'motion_3d_design': 'Motion & 3D Design',
    'content_creation': 'Content Creation',
    
    // Jobs & Projects - Marketing & Community
    'social_media_community': 'Social Media & Community',
    'content_strategy': 'Content Strategy',
    'growth_marketing': 'Growth Marketing',
    'analytics_seo': 'Analytics & SEO',
    
    // Jobs & Projects - Business & Operations
    'strategy_consulting': 'Strategy Consulting',
    'operations_management': 'Operations Management',
    'finance_legal': 'Finance & Legal',
    'product_management': 'Product Management',
    'market_research': 'Market Research',
    'data_analysis': 'Data Analysis',
    
    // Jobs & Projects - Professional Services
    'education_training': 'Education & Training',
    'hr_recruitment': 'HR & Recruitment',
    'advisory_mentorship': 'Advisory & Mentorship',
    'other_professional': 'Other Professional Services',
    
    // Recommendations - Crypto & Web3
    'defi_protocols': 'DeFi Protocols',
    'nft_projects': 'NFT Projects',
    'dao_governance': 'DAO Governance',
    'crypto_trading': 'Crypto Trading',
    'web3_infrastructure': 'Web3 Infrastructure',
    'wallet_security': 'Wallet Security',
    
    // Recommendations - Tech & Innovation
    'ai_tools': 'AI Tools',
    'dev_tools': 'Dev Tools',
    'saas_products': 'SaaS Products',
    'hardware_tech': 'Hardware Tech',
    'cybersecurity': 'Cybersecurity',
    'productivity_apps': 'Productivity Apps',
    
    // Recommendations - Investment & Business
    'startups': 'Startups',
    'angel_investing': 'Angel Investing',
    'venture_capital': 'Venture Capital',
    'market_opportunities': 'Market Opportunities',
    'real_estate': 'Real Estate',
    
    // Recommendations - Professional Services
    'wealth_management': 'Wealth Management',
    'legal_services': 'Legal Services',
    'tax_strategy': 'Tax Strategy',
    'business_consulting': 'Business Consulting',
    'private_banking': 'Private Banking',
    
    // Recommendations - Lifestyle & Premium
    'luxury_travel': 'Luxury Travel',
    'fine_dining': 'Fine Dining',
    'high_end_properties': 'High-End Properties',
    'luxury_vehicles': 'Luxury Vehicles',
    'wellness_fitness': 'Wellness & Fitness',
    
    // Recommendations - Education & Networking
    'education_courses': 'Education Courses',
    'networking_events': 'Networking Events',
    'exclusive_memberships': 'Exclusive Memberships',
    
    // Recommendations - Other
    'miscellaneous': 'Other Recommendations',
    
    // Legacy mappings (keep for backward compatibility)
    'markets_and_finance': 'Markets & Finance',
    'lifestyle_and_experiences': 'Lifestyle & Experiences',
    'health_and_wellness': 'Health & Wellness',
    'services_and_professionals': 'Services & Professionals',
    'tech_and_gadgets': 'Tech & Gadgets',
    'competitive_analysis': 'Competitive Analysis',
    'user_research': 'User Research'
  };

  // Check if there's a special case for this subcategory
  if (specialCases[subcategory]) {
    return specialCases[subcategory];
  }

  // For other cases, capitalize each word and replace underscores with spaces
  return subcategory
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatRequestStatus = (status) => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'completed':
      return 'Completed';
    case 'draft':
      return 'Draft';
    case 'payment_initiated':
      return 'Payment Initiated';
    case 'payment_pending':
      return 'Payment Pending';
    case 'bidding_closed':
      return 'Bidding Closed';
    case 'bounty_released':
      return 'Bounty Released';
    case 'bounty_withheld':
      return 'Bounty Withheld';
    default:
      return status ? status.charAt(0).toUpperCase() + status.slice(1) : 'Unknown';
  }
};

export const formatCurrency = (amount) => {
  if (!amount) return '$0';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};