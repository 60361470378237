// frontend/src/components/admin/BidsList.js

import React from 'react';
import GenericList from './GenericList';
import { getBidsList } from '../../services/adminApi';
import { Chip } from '@mui/material';

const columns = [
  { 
    id: 'bidder', 
    label: 'Bidder', 
    minWidth: 170,
    format: (value) => value || 'Unknown'
  },
  { 
    id: 'requestId', 
    label: 'Request', 
    minWidth: 170,
    format: (value) => value?.title || 'N/A' // Format the requestId object
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 100,
    format: (value) => value ? `${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    format: (value) => (
      <Chip 
        label={value || 'Unknown'} 
        color={value === 'accepted' ? 'success' : value === 'pending' ? 'warning' : 'default'}
        size="small"
      />
    ),
  },
  {
    id: 'created_at',
    label: 'Created At',
    minWidth: 170,
    format: (value) => value ? new Date(value).toLocaleString() : 'N/A',
  },
];

const BidsList = () => {
  return <GenericList fetchData={getBidsList} columns={columns} />;
};

export default BidsList;