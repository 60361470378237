// frontend/src/App.js

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Container, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthKitProvider } from '@farcaster/auth-kit';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '@farcaster/auth-kit/styles.css';

import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import YourRoutes from './YourRoutes';
import theme from './theme';
import Toast from './components/Toast';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';
import { initializeLogging } from './utilities/logrocketConfig';

// Create a query client with default options
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error?.response) {
          // Don't retry 4xx errors at all
          return error.response.status >= 500 && failureCount < 2;
        }
        return failureCount < 2;
      },
      staleTime: 30000,
    },
  },
});

const DEFAULT_TOAST_DURATION = 5000;
const MINIMUM_TOAST_DURATION = 3000;
const FADE_DURATION = 300;

const ToastErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasError) {
      console.error('Toast Error Boundary caught an error');
      setTimeout(() => setHasError(false), 1000);
    }
  }, [hasError]);

  return <>{children}</>;
};

const App = () => {
  const [toasts, setToasts] = useState([]);
  const toastTimeoutsRef = useRef(new Map());

  // Clean up timeouts on unmount
  useEffect(() => {
    return () => {
      toastTimeoutsRef.current.forEach((timeouts) => {
        timeouts.forEach(clearTimeout);
      });
    };
  }, []);

  useEffect(() => {
    initializeLogging();
  }, []);

  const clearToastTimeouts = useCallback((toastId) => {
    if (toastTimeoutsRef.current.has(toastId)) {
      toastTimeoutsRef.current.get(toastId).forEach(clearTimeout);
      toastTimeoutsRef.current.delete(toastId);
    }
  }, []);

  const showToast = useCallback((message, type = 'info', duration = DEFAULT_TOAST_DURATION, toastId = null) => {
    if (!message) return null;

    const actualDuration = Math.max(duration, MINIMUM_TOAST_DURATION);
    const newToastId = toastId || Date.now();
    
    // Clear any existing timeouts for this toast ID
    clearToastTimeouts(newToastId);
    
    // Create new timeouts
    const timeouts = [];
    
    // Add the toast
    setToasts(currentToasts => [
      ...currentToasts,
      { id: newToastId, message, type, visible: true }
    ]);

    // Set timeout to start fade out
    const hideTimeout = setTimeout(() => {
      setToasts(currentToasts =>
        currentToasts.map(toast =>
          toast.id === newToastId ? { ...toast, visible: false } : toast
        )
      );

      // Set timeout to remove toast after fade
      const removeTimeout = setTimeout(() => {
        setToasts(currentToasts => 
          currentToasts.filter(toast => toast.id !== newToastId)
        );
        clearToastTimeouts(newToastId);
      }, FADE_DURATION);

      timeouts.push(removeTimeout);
    }, actualDuration);

    timeouts.push(hideTimeout);
    toastTimeoutsRef.current.set(newToastId, timeouts);

    return newToastId;
  }, [clearToastTimeouts]);

  const handleToastClose = useCallback((toastId) => {
    clearToastTimeouts(toastId);
    setToasts(currentToasts =>
      currentToasts.map(toast =>
        toast.id === toastId ? { ...toast, visible: false } : toast
      )
    );
    setTimeout(() => {
      setToasts(currentToasts => 
        currentToasts.filter(toast => toast.id !== toastId)
      );
    }, FADE_DURATION);
  }, [clearToastTimeouts]);

  const handleRouteChange = useCallback(() => {
    // Clear non-persistent toasts on navigation
    toasts.forEach(toast => {
      if (!toast.persistent) {
        handleToastClose(toast.id);
      }
    });
  }, [toasts, handleToastClose]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <Router>
              <AuthProvider>
                <ErrorBoundary>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      bgcolor: 'background.default',
                      color: 'text.primary',
                      position: 'relative',
                      zIndex: 0,
                    }}
                  >
                    <Navbar />
                    <Container 
                      component="main" 
                      sx={{ 
                        mt: 4, 
                        mb: 4, 
                        flexGrow: 1,
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      <YourRoutes showToast={showToast} onRouteChange={handleRouteChange} />
                    </Container>
                    <Footer />
                  </Box>
                  <ToastErrorBoundary>
                    {toasts.map((toast) => (
                      <Toast
                        key={toast.id}
                        message={toast.message}
                        type={toast.type}
                        open={toast.visible}
                        onClose={() => handleToastClose(toast.id)}
                      />
                    ))}
                  </ToastErrorBoundary>
                </ErrorBoundary>
              </AuthProvider>
            </Router>
          </LocalizationProvider>
        </HelmetProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;