// frontend/src/components/RequestStatusDashboard.js

import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Box, 
  Card, 
  Typography, 
  Button, 
  Divider,
  Chip,
  Alert,
  IconButton,
  Collapse,
  alpha,
  useTheme,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PaymentIcon from '@mui/icons-material/Payment';
import GavelIcon from '@mui/icons-material/Gavel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SignpostIcon from '@mui/icons-material/Signpost';
import LaunchIcon from '@mui/icons-material/Launch';

const DashboardContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  marginBottom: theme.spacing(4),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderRadius: theme.shape.borderRadius * 1.5,
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'draft': return theme.palette.warning;
      case 'active': return theme.palette.info;
      case 'bidding_closed': return theme.palette.secondary;
      case 'completed': return theme.palette.primary;
      default: return theme.palette.grey;
    }
  };

  const statusColor = getStatusColor();
  return {
    backgroundColor: alpha(statusColor.main, 0.1),
    border: `1px solid ${alpha(statusColor.main, 0.2)}`,
    color: statusColor.main,
    fontWeight: 600,
    '& .MuiChip-icon': {
      color: statusColor.main,
    }
  };
});

const ActionButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'none',
  minWidth: '180px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: 'unset',
  }
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const SummaryChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(4px)',
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
  }
}));

const EmptyState = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  textAlign: 'center',
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const RequestItem = styled(Box)(({ theme, status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'draft': return theme.palette.warning.main;
      case 'active': return theme.palette.info.main;
      case 'bidding_closed': return theme.palette.secondary.main;
      case 'completed': return theme.palette.primary.main;
      default: return theme.palette.grey[500];
    }
  };

  return {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(getStatusColor(), 0.2)}`,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.6),
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[2],
    }
  };
});

const RequestStatusDashboard = ({ requests = [], myBids = [], vouches = {} }) => {
  const theme = useTheme();
  const [expandedSections, setExpandedSections] = React.useState({
    draft: true,
    active: true,
    bidding_closed: true,
    completed: true
  });

  // Calculate metrics
  const totalActiveRequests = requests.filter(r => r.status !== 'draft').length;
  const totalActiveBids = myBids.filter(bid => bid.status !== 'draft').length;
  const totalConfirmedVouches = Object.values(vouches)
    .flat()
    .filter(vouch => vouch.paymentStatus !== 'created').length;

  const getStatusIcon = (status) => {
    switch (status) {
      case 'draft': return <WarningAmberIcon />;
      case 'active': return <PendingActionsIcon />;
      case 'bidding_closed': return <GavelIcon />;
      case 'completed': return <CheckCircleIcon />;
      default: return null;
    }
  };

  const getActionMessage = (status) => {
    switch (status) {
      case 'draft':
        return 'Go to request to complete activation (24h expiry)';
      case 'active':
        return 'View responses and select a winner when ready';
      case 'bidding_closed':
        return 'Review progress and mark as completed when ready';
      case 'completed':
        return 'Review and make your bounty release decision';
      default:
        return '';
    }
  };

  const getActionButton = (status, request) => {
    const buttonProps = {
      component: Link,
      to: `/request/${request._id}`,
      variant: "contained",
      endIcon: <LaunchIcon />,
      sx: { 
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: theme.shadows[4]
        }
      }
    };

    switch (status) {
      case 'draft':
        return (
          <ActionButton
            {...buttonProps}
            color="warning"
            startIcon={<PaymentIcon />}
          >
            Complete Activation
          </ActionButton>
        );
      case 'active':
        return (
          <ActionButton
            {...buttonProps}
            color="info"
            startIcon={<GavelIcon />}
          >
            Review & Select
          </ActionButton>
        );
      case 'bidding_closed':
        return (
          <ActionButton
            {...buttonProps}
            color="secondary"
            startIcon={<DoneAllIcon />}
          >
            Mark as Completed
          </ActionButton>
        );
      case 'completed':
        return (
          <ActionButton
            {...buttonProps}
            color="primary"
            startIcon={<PaymentIcon />}
          >
            Make Bounty Decision
          </ActionButton>
        );
      default:
        return (
          <ActionButton
            {...buttonProps}
            color="primary"
            startIcon={<LaunchIcon />}
          >
            View Details
          </ActionButton>
        );
    }
  };

  const statusCounts = {
    draft: requests.filter(r => r.status === 'draft').length,
    active: requests.filter(r => r.status === 'active').length,
    bidding_closed: requests.filter(r => r.status === 'bidding_closed').length,
    completed: requests.filter(r => r.status === 'completed').length,
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderMetrics = () => (
    <Stack 
      direction={{ xs: 'column', sm: 'row' }} 
      spacing={2} 
      sx={{ mb: 3 }}
    >
      <SummaryChip
        icon={<SignpostIcon />}
        label={`${totalActiveRequests} Total ${totalActiveRequests === 1 ? 'Request' : 'Requests'}`}
        variant="outlined"
      />
      <SummaryChip
        icon={<GavelIcon />}
        label={`${totalActiveBids} Total ${totalActiveBids === 1 ? 'Response' : 'Responses'}`}
        variant="outlined"
      />
      <SummaryChip
        icon={<CheckCircleIcon />}
        label={`${totalConfirmedVouches} Total ${totalConfirmedVouches === 1 ? 'Vouch' : 'Vouches'}`}
        variant="outlined"
      />
    </Stack>
  );

  const renderEmptyState = () => (
    <EmptyState>
      <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
        No Active Requests Yet
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Your active requests and pending actions will appear here.
        {statusCounts.draft === 0 && " Start by creating a new request from the button above."}
      </Typography>
    </EmptyState>
  );

  const renderRequestSection = (status, requests) => {
    if (!requests || requests.length === 0) return null;

    return (
      <Box mb={3}>
        <Box 
          onClick={() => toggleSection(status)}
          sx={{ 
            cursor: 'pointer',
            mb: 2,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 1, sm: 2 }
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <StatusChip
              status={status}
              icon={getStatusIcon(status)}
              label={`${requests.length} ${status.replace('_', ' ').toUpperCase()}`}
              variant="outlined"
            />
            <IconButton size="small">
              {expandedSections[status] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          <Typography 
            variant="subtitle2" 
            color="text.secondary"
            sx={{ 
              fontWeight: 500,
              flexGrow: 1,
              display: 'block',
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            {getActionMessage(status)}
          </Typography>
        </Box>

        <Collapse in={expandedSections[status]}>
          {requests.map((request) => (
            <RequestItem key={request._id} status={status}>
              <Box 
                sx={{ 
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: 2, sm: 3 },
                  alignItems: { xs: 'flex-start', sm: 'center' }
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                    {request.title}
                  </Typography>
                  <Box 
                    display="flex" 
                    gap={1} 
                    alignItems="center" 
                    flexWrap="wrap"
                    sx={{ mb: { xs: 1, sm: 0 } }}
                  >
                    <Chip 
                      size="small" 
                      label={request.category}
                    />
                    {request.timeRemaining && (
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <AccessTimeIcon fontSize="small" color="warning" />
                        <Typography variant="caption" color="warning.main">
                          {Math.floor(request.timeRemaining / 3600)}h {Math.floor((request.timeRemaining % 3600) / 60)}m remaining
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box 
                  sx={{ 
                    display: 'flex',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                >
                  {getActionButton(status, request)}
                </Box>
              </Box>
            </RequestItem>
          ))}
        </Collapse>
        <Divider sx={{ mt: 2 }} />
      </Box>
    );
  };

  return (
    <DashboardContainer>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          fontWeight: 700,
          fontSize: { xs: '1.25rem', sm: '1.5rem' },
          mb: 3
        }}
      >
        Request Status Dashboard
      </Typography>

      {renderMetrics()}

      {statusCounts.draft > 0 && (
        <StyledAlert severity="warning" sx={{ mb: 3 }}>
          You have {statusCounts.draft} draft {statusCounts.draft === 1 ? 'request that needs' : 'requests that need'} to be activated within 24 hours.
        </StyledAlert>
      )}

      {statusCounts.completed > 0 && (
        <StyledAlert severity="info" sx={{ mb: 3 }}>
          You have {statusCounts.completed} completed {statusCounts.completed === 1 ? 'request' : 'requests'} awaiting your bounty decision.
        </StyledAlert>
      )}

      {totalActiveRequests === 0 && statusCounts.draft === 0 ? (
        renderEmptyState()
      ) : (
        <>
          {renderRequestSection('draft', requests.filter(r => r.status === 'draft'))}
          {renderRequestSection('active', requests.filter(r => r.status === 'active'))}
          {renderRequestSection('bidding_closed', requests.filter(r => r.status === 'bidding_closed'))}
          {renderRequestSection('completed', requests.filter(r => r.status === 'completed'))}
        </>
      )}
    </DashboardContainer>
  );
};

export default RequestStatusDashboard;