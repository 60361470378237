// frontend/src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthKitProvider } from '@farcaster/auth-kit';
import App from './App';

const isUsingNgrok = Boolean(process.env.REACT_APP_NGROK_URL);
const apiUrl = isUsingNgrok ? process.env.REACT_APP_NGROK_URL : process.env.REACT_APP_API_URL;

if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname.includes('vercel.app')
) {
  window.location.replace(
    `https://vouch.wtf${window.location.pathname}${window.location.search}`
  );
}

const config = {
  rpcUrl: process.env.REACT_APP_OP_MAINNET_RPC_URL || 'https://mainnet.optimism.io',
  relay: 'https://relay.farcaster.xyz',
  domain: window.location.hostname,
  siweUri: `${apiUrl}/auth/callback`,
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthKitProvider config={config}>
      <App />
    </AuthKitProvider>
  </React.StrictMode>
);
