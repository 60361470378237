// frontend/src/components/SlidePanel.js

import React from 'react';
import { Box, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '100%',
  maxWidth: '600px',
  height: '100%',
  overflowY: 'auto',
  zIndex: theme.zIndex.drawer,
  padding: theme.spacing(3),
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  border: 'none',
  borderLeft: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  
  // Enhanced scrollbar styling
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: alpha(theme.palette.background.paper, 0.1),
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.primary.main, 0.3),
    borderRadius: '3px',
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.5),
    },
  },

  // Gradient background
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.95)} 0%,
    ${alpha(theme.palette.background.paper, 0.85)} 100%)`,

  // Subtle shadow for depth
  boxShadow: `
    -10px 0 30px -5px ${alpha(theme.palette.common.black, 0.3)},
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)}
  `,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.text.secondary,
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.2s ease-in-out',
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transform: 'scale(1.05)',
    color: theme.palette.primary.main,
  },

  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
}));

const Backdrop = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.common.black, 0.7),
  backdropFilter: 'blur(4px)',
  zIndex: theme.zIndex.drawer - 1,
  transition: 'opacity 0.3s ease-in-out',
}));

const SlidePanel = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <Backdrop
          onClick={onClose}
          sx={{
            opacity: isOpen ? 1 : 0,
          }}
        />
      )}
      <StyledPaper
        elevation={0}
        sx={{
          transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        <StyledIconButton
          onClick={onClose}
          aria-label="close panel"
        >
          <CloseIcon />
        </StyledIconButton>
        <Box sx={{ pt: 4 }}>
          {children}
        </Box>
      </StyledPaper>
    </>
  );
};

export default SlidePanel;