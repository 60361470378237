// frontend/src/utilities/statusUtils.js

import { colors } from '@mui/material';

export const getStatusColor = (status, theme) => {
  switch (status) {
    case 'active':
      return colors.green[600];
    case 'completed':
      return colors.blue[600];
    case 'draft':
      return colors.orange[600];
    case 'payment_initiated':
    case 'payment_pending':
      return colors.amber[600];
    case 'bidding_closed':
      return colors.purple[600];
    case 'bounty_released':
      return theme.palette.success.main;
    case 'bounty_withheld':
      return colors.red[600];
    default:
      return colors.grey[600];
  }
};
