// frontend/src/components/admin/TwoFactorVerify.js

import React, { useState } from 'react';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { verifyTwoFactor } from '../../services/adminApi';
import { useNavigate } from 'react-router-dom';

const TwoFactorVerify = ({ showToast, onVerified }) => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleVerify = async () => {
    try {
      setIsLoading(true);
      setError('');
      console.log('Sending 2FA verification request with token:', token);
      const result = await verifyTwoFactor(token);
      console.log('2FA verification response:', result);
      if (result.message === '2FA verification successful') {
        if (onVerified && typeof onVerified === 'function') {
          onVerified();
        } else {
          // If onVerified is not provided, navigate to the admin dashboard
          navigate('/admin');
        }
        showToast('2FA verification successful', 'success');
      } else {
        setError('Unexpected response from server');
      }
    } catch (error) {
      console.error('2FA verification error:', error);
      setError(error.message || 'Failed to verify 2FA token');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4, p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>Two-Factor Authentication</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Please enter the 6-digit code from your authenticator app.
      </Typography>
      <TextField
        label="Enter token"
        value={token}
        onChange={(e) => setToken(e.target.value)}
        fullWidth
        margin="normal"
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
      <Button 
        onClick={handleVerify} 
        variant="contained" 
        fullWidth 
        sx={{ mt: 2 }}
        disabled={isLoading || token.length !== 6}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Verify'}
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default TwoFactorVerify;