// frontend/src/YourRoutes.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Home from './components/Home';
import Requests from './components/Requests';
import MyDashboard from './components/MyDashboard';
import RequestDetail from './components/RequestDetail';
import LoginPage from './components/LoginPage';
import AuthCallback from './components/AuthCallback';
import About from './components/About';
import RequestForm from './components/RequestForm';
import UserProfile from './components/UserProfile';
import AdminDashboard from './components/AdminDashboard';
import TwoFactorVerify from './components/admin/TwoFactorVerify';
import CloseBiddingProcess from './components/CloseBiddingProcess';
import Leaderboard from './components/Leaderboard';

const YourRoutes = ({ showToast }) => (
  <Routes>
    <Route path="/login" element={<LoginPage showToast={showToast} />} />
    <Route path="/auth/callback" element={<AuthCallback />} />
    <Route path="/requests" element={<Requests showToast={showToast} />} />
    <Route path="/request/:id" element={<RequestDetail showToast={showToast} />} />
    <Route
      path="/dashboard"
      element={
        <ProtectedRoute>
          <MyDashboard showToast={showToast} />
        </ProtectedRoute>
      }
    />
    <Route
      path="/create-request"
      element={
        <ProtectedRoute>
          <RequestForm showToast={showToast} />
        </ProtectedRoute>
      }
    />
    <Route
      path="/edit-request/:id"
      element={
        <ProtectedRoute>
          <RequestForm showToast={showToast} isEditing={true} />
        </ProtectedRoute>
      }
    />
    <Route
      path="/close-bidding/:id/:bidId"
      element={
        <ProtectedRoute>
          <CloseBiddingProcess showToast={showToast} />
        </ProtectedRoute>
      }
    />
    <Route path="/about" element={<About />} />
    <Route path="/leaderboard" element={<Leaderboard />} />
    <Route path="/" element={<Home />} />
    <Route path="/user/:username" element={<UserProfile />} />
    <Route
      path="/admin"
      element={
        <ProtectedRoute adminOnly>
          <AdminDashboard />
        </ProtectedRoute>
      }
    />
    <Route
      path="/admin/verify-2fa"
      element={<TwoFactorVerify showToast={showToast} />}
    />
  </Routes>
);

export default YourRoutes;
