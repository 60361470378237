// frontend/src/hooks/usePaymentProcessing.js

import { useState, useEffect, useCallback } from 'react';
import { createBountyCharge, cancelPaymentIntent } from '../services/api';
import { PaymentRedirectDialog, ProcessingOverlay } from '../components/PaymentProcessingUI';

const usePaymentProcessing = (showToast) => {
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const [processingMessage, setProcessingMessage] = useState("Initiating Payment Process...");
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const handleProcessPayment = async (requestId, version, requestDetailsParam = null, isFormContext = false) => {
    try {
      setIsPaymentProcessing(true);
      // Set message immediately when starting
      const message = isFormContext ? "Saving Request..." : "Initiating Payment Process...";
      setProcessingMessage(message);
      setCurrentRequestId(requestId);
      
      const result = await createBountyCharge(requestId, version);
      
      if (result && result.paymentUrl) {
        setPaymentUrl(result.paymentUrl);
        if (requestDetailsParam) {
          setRequestDetails(requestDetailsParam);
        }
        setShowPaymentDialog(true);
        return result;
      } else {
        throw new Error('Invalid payment processing result');
      }
    } catch (error) {
      console.error('Payment processing error:', error);
      showToast(error.message || 'Failed to process payment. Please try again.', 'error');
      throw error;
    } finally {
      setIsPaymentProcessing(false);
      // Reset message to default
      setProcessingMessage("Initiating Payment Process...");
    }
  };

  const handleCancelPayment = useCallback(() => {
    setShowPaymentDialog(false);
    setPaymentUrl('');
    setRequestDetails(null);
    showToast('Payment process was canceled. Request remains in its previous status.', 'info');
  }, [showToast]);

  const handleRedirectNow = useCallback(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }, [paymentUrl]);

  const PaymentUI = useCallback(({ request, showCreationSuccess = false, isEditing = false, isFormContext = false }) => {
    // Create a memoized message based on context
    const displayMessage = isFormContext ? "Saving Request..." : processingMessage;
    
    return (
      <>
        {showPaymentDialog && (
          <PaymentRedirectDialog
            open={showPaymentDialog}
            onClose={() => setShowPaymentDialog(false)}
            paymentUrl={paymentUrl}
            requestDetails={requestDetails}
            onCancel={handleCancelPayment}
            onRedirectNow={handleRedirectNow}
            showCreationSuccess={showCreationSuccess}
            isEditing={isEditing}
            isFormContext={isFormContext}
            requestId={currentRequestId}
          />
        )}
        <ProcessingOverlay 
          show={isPaymentProcessing} 
          message={displayMessage}
        />
      </>
    );
  }, [showPaymentDialog, paymentUrl, requestDetails, handleCancelPayment, handleRedirectNow, isPaymentProcessing, processingMessage, currentRequestId]);

  return {
    isPaymentProcessing,
    handleProcessPayment,
    handleCancelPayment,
    PaymentUI,
  };
};

export default usePaymentProcessing;