// frontend/src/components/admin/RequestsList.js

import React from 'react';
import GenericList from './GenericList';
import { getRequestsList } from '../../services/adminApi';

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'creator', label: 'Creator', minWidth: 100 },
  { id: 'category', label: 'Category', minWidth: 100 },
  {
    id: 'bounty',
    label: 'Bounty',
    minWidth: 100,
    format: (value) => `$${value.toFixed(2)}`,
  },
  { id: 'status', label: 'Status', minWidth: 100 },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 170,
    format: (value) => new Date(value).toLocaleString(),
  },
];

const RequestsList = () => {
  return <GenericList fetchData={getRequestsList} columns={columns} />;
};

export default RequestsList;