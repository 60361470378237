// frontend/src/components/ShareRequest.js

import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  IconButton, 
  Tooltip, 
  Typography,
  Paper,
  Snackbar,
  Collapse,
  alpha 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';
import { ReactComponent as XLogo } from '../assets/x_logo.svg';
import { ReactComponent as FarcasterLogo } from '../assets/farcaster_purple.svg';

const ShareContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  // Enhanced gradient background
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.95)} 0%,
    ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  
  // Enhanced shadow and glow
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)}
    `,
  },

  // Add subtle background pattern
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, 0.03)}, 
      transparent 70%)`,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ShareButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  padding: theme.spacing(1, 3),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  backdropFilter: 'blur(8px)',
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
    backgroundColor: alpha(theme.palette.background.paper, 0.6),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
  },
}));

const SocialButton = styled(Button)(({ theme, platform }) => {
  const getColor = () => {
    switch (platform) {
      case 'x':
        return theme.palette.common.white;
      case 'farcaster':
        return '#8A63D2';
      default:
        return theme.palette.primary.main;
    }
  };

  const color = getColor();

  return {
    marginRight: theme.spacing(2),
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    padding: theme.spacing(1, 3),
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    backdropFilter: 'blur(8px)',
    border: `2px solid ${color}`,
    color: color,

    '&:hover': {
      backgroundColor: alpha(color, 0.1),
      transform: 'translateY(-2px)',
      boxShadow: `0 4px 12px ${alpha(color, 0.2)}`,
    },

    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(1),
      '& svg, & img': {
        width: 20,
        height: 20,
      },
    },

    '& .x-icon, & .farcaster-icon': {
      width: 20,
      height: 20,
    },
  };
});

const ExpandButton = styled(IconButton)(({ theme, isExpanded }) => ({
  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.6),
    transform: `translateY(-2px) rotate(${isExpanded ? '180deg' : '0deg'})`,
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
  },
}));

const ShareRequest = ({ request }) => {
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;

  const getShareText = () => {
    const categoryText = {
      job: "job opening",
      project: "project opportunity",
      recommendation: "recommendation request"
    }[request.category] || "request";

    return `Check out this ${categoryText} on Vouch with a $${request.bounty.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC bounty:\n\n${request.title}`;
  };

  const getFarcasterText = () => {
    return `🔍 Looking for high-quality ${request.category === 'recommendation' ? 'recommendations' : 
           request.category === 'job' ? 'candidates' : 'proposals'} on @vouch\n\n💰 ${request.bounty.toFixed(2)} USDC bounty\n\n${request.title}`;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleShareX = () => {
    const text = encodeURIComponent(getShareText());
    const url = encodeURIComponent(currentUrl);
    window.open(`https://x.com/intent/tweet?text=${text}&url=${url}`, '_blank');
  };

  const handleShareFarcaster = () => {
    const text = encodeURIComponent(getFarcasterText());
    const embed = encodeURIComponent(currentUrl);
    window.open(`https://warpcast.com/~/compose?text=${text}&embeds[]=${embed}`, '_blank');
  };

  return (
    <ShareContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ShareIcon sx={{ 
            mr: { xs: 1.5, sm: 2 }, 
            color: 'primary.main',
            fontSize: { xs: 20, sm: 24 }
          }} />
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600,
              fontSize: { xs: '1rem', sm: '1.25rem' }
            }}
          >
            Share this {request.category === 'job' ? 'Job' : 
                      request.category === 'project' ? 'Project' : 
                      'Request'}
          </Typography>
        </Box>
        <ExpandButton
          onClick={() => setExpanded(!expanded)}
          isExpanded={expanded}
          size="small"
          sx={{ padding: { xs: 0.5, sm: 1 } }}
        >
          <ExpandMoreIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
        </ExpandButton>
      </Box>

      <Collapse in={expanded}>
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <SocialButton
              onClick={handleShareFarcaster}
              startIcon={<FarcasterLogo className="farcaster-icon" />}
              platform="farcaster"
            >
              Share on Farcaster
            </SocialButton>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <SocialButton
              onClick={handleShareX}
              startIcon={<XLogo className="x-icon" />}
              platform="x"
            >
              Share on X
            </SocialButton>
          </motion.div>

          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <ShareButton
              variant="outlined"
              onClick={handleCopyLink}
              startIcon={copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
            >
              {copied ? 'Copied!' : 'Copy Link'}
            </ShareButton>
          </motion.div>
        </Box>
      </Collapse>

      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
        message="Link copied to clipboard"
      />
    </ShareContainer>
  );
};

export default ShareRequest;