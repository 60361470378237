import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Button, 
  CircularProgress, 
  Alert,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  alpha,
  Slide,
  Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DOMPurify from 'dompurify';
import { markRequestCompleted, handleApiError } from '../services/api';

const ProcessContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
  boxShadow: theme.shadows[10],
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    transition: 'color 0.3s ease-in-out',
  },
  '& .MuiStepLabel-completed': {
    color: theme.palette.success.main,
  },
  '& .MuiStepIcon-root': {
    transition: 'all 0.3s ease-in-out',
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.success.main,
    transform: 'scale(1.2)',
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
    animation: 'pulse 2s infinite',
  },
}));

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const AnimatedIcon = styled(motion.div)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ProcessStatus = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
}));

const steps = [
  'Review Request',
  'Confirm Completion',
  'Prepare for Bounty Decision'
];

const MarkCompleted = ({ request, showToast, onClose, onCompleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isProcessComplete, setIsProcessComplete] = useState(false);

  if (!request) return <Alert severity="error">Request not found</Alert>;

  const getCategorySpecificText = () => {
    switch (request.category) {
      case 'job':
        return {
          title: 'Mark Job Application Process Completed',
          description: 'You are about to mark this job application process as completed. This indicates that the selected applicant has started the position and you are ready to evaluate their performance for bounty distribution.',
          confirmationTitle: 'Confirm Job Position Status',
          confirmationText: 'Are you sure the selected applicant has started the position and you\'re ready to make a decision about the bounty?',
          completionMessage: 'The job position has been marked as filled. You can now decide whether to release the bounty to the vouchers based on the applicant\'s performance.',
        };
      case 'project':
        return {
          title: 'Mark Project Completed',
          description: 'You are about to mark this project as completed. This indicates that the selected bidder has delivered the project and you are ready to evaluate the outcome for bounty distribution.',
          confirmationTitle: 'Confirm Project Completion',
          confirmationText: 'Are you sure the project has been completed and you\'re ready to make a decision about the bounty?',
          completionMessage: 'The project has been marked as completed. You can now decide whether to release the bounty to the vouchers based on the delivered results.',
        };
      case 'recommendation':
        return {
          title: 'Mark Recommendation Completed',
          description: 'You are about to mark this recommendation as completed. This indicates that you have taken action based on the recommendation and are ready to evaluate its value for bounty distribution.',
          confirmationTitle: 'Confirm Recommendation Status',
          confirmationText: 'Are you sure you have acted upon the recommendation and are ready to make a decision about the bounty?',
          completionMessage: 'The recommendation has been marked as completed. You can now decide whether to release the bounty to the recommender and vouchers based on the recommendation\'s value.',
        };
      default:
        return {
          title: 'Mark Request Completed',
          description: 'You are about to mark this request as completed.',
          confirmationTitle: 'Confirm Completion',
          confirmationText: 'Are you sure you want to mark this request as completed?',
          completionMessage: 'The request has been marked as completed.',
        };
    }
  };

  const texts = getCategorySpecificText();

  const handleMarkCompleted = async () => {
    setIsLoading(true);
    try {
      await markRequestCompleted(request._id);
      setIsProcessComplete(true);
      setActiveStep(2);
      showToast(texts.completionMessage, 'success');
      setTimeout(() => {
        onCompleted();
      }, 3000);
    } catch (error) {
      showToast('Failed to mark request as completed: ' + handleApiError(error), 'error');
    } finally {
      setIsLoading(false);
      setShowConfirmation(false);
    }
  };

  return (
    <ProcessContainer>
      <Typography variant="h4" color="primary" gutterBottom>
        {texts.title}
      </Typography>

      <StyledStepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <InfoCard>
            <Typography variant="h6" gutterBottom>
              Request Details
            </Typography>
            <Typography variant="body1" gutterBottom>
              {request.title}
            </Typography>
            <Typography variant="body2" color="text.secondary"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(request.description) }}
            />
          </InfoCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoCard>
            <Typography variant="h6" gutterBottom>
              Next Steps
            </Typography>
            <Typography variant="body1" paragraph>
              {texts.description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              After marking as completed, you will need to:
              <Box component="ul" sx={{ mt: 1 }}>
                <li>Review the overall outcome</li>
                <li>Decide whether to release or withhold the bounty</li>
                <li>Complete the process as soon as possible</li>
              </Box>
            </Typography>
          </InfoCard>
        </Grid>
      </Grid>

      {isProcessComplete ? (
        <ProcessStatus>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {texts.completionMessage}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                You will be redirected to continue with the bounty decision process.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AnimatedIcon
                initial={{ scale: 0, rotate: -180 }}
                animate={{ scale: 1, rotate: 0 }}
                transition={{ duration: 0.5 }}
              >
                <DoneAllIcon color="success" sx={{ fontSize: 48 }} />
              </AnimatedIcon>
            </Grid>
          </Grid>
        </ProcessStatus>
      ) : (
        <Box sx={{ 
          mt: 4, 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'stretch', sm: 'space-between' },
          gap: { xs: 2, sm: 1 }
        }}>
          <Button 
            onClick={onClose} 
            disabled={isLoading}
            sx={{ 
              width: { xs: '100%', sm: 'auto' },
              order: { xs: 2, sm: 1 },
              fontSize: { 
                xs: '0.875rem',
                sm: '1rem'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowConfirmation(true)}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={24} /> : <CheckCircleOutlineIcon />}
            sx={{ 
              width: { xs: '100%', sm: 'auto' },
              order: { xs: 1, sm: 2 },
              fontSize: { 
                xs: '0.875rem',
                sm: '1rem'
              },
              '& .MuiButton-startIcon': {
                marginRight: { xs: 1, sm: 2 }
              }
            }}
          >
            {isLoading ? 'Processing...' : 'Mark as Completed'}
          </Button>
        </Box>
      )}

      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <MonetizationOnIcon color="primary" sx={{ mr: 1 }} />
            {texts.confirmationTitle}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            {texts.confirmationText}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This action will:
            <Box component="ul" sx={{ mt: 1 }}>
              <li>Mark the request as completed</li>
              <li>Enable the bounty decision phase</li>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleMarkCompleted}
            color="primary"
            variant="contained"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : <CheckCircleOutlineIcon />}
          >
            {isLoading ? 'Processing...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </ProcessContainer>
  );
};

export default MarkCompleted;