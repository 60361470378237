// frontend/src/context/AuthContext.js

import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useProfile } from '@farcaster/auth-kit';
import { identifyUser } from '../utilities/logrocketConfig';

const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.vouch.wtf/api'
  : (process.env.REACT_APP_NGROK_URL || process.env.REACT_APP_API_URL || 'http://localhost:5001/api');

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { isAuthenticated, profile } = useProfile();
  // TEMPORARY: Enable test mode in production. Uncomment the line below and remove the temporary line when ready for production
  // const [isDevelopmentMode] = useState(process.env.NODE_ENV === 'development');
  const [isDevelopmentMode] = useState(true); // TEMPORARY: Remove this line and uncomment the above line when ready for production
  const [isTestUser, setIsTestUser] = useState(false);
  
  // Add suspension handling to axios interceptor
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.data?.code === 'ACCOUNT_SUSPENDED') {
          handleSignOut();
          // Show suspension message - you'll need to implement your own toast/notification system
          console.error('Account has been suspended');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && profile && !isTestUser) {
      updateUserInDatabase(profile);
    } else if (!isAuthenticated && !isTestUser) {
      setUser(null);
      setIsSignedIn(false);
      setIsAdmin(false);
    }
  }, [isAuthenticated, profile, isTestUser]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${API_URL}/auth/check`, {
          withCredentials: true
        });
        console.log('Auth check response:', response.data);
        if (response.data.authenticated) {
          // Add check for suspension in auth response
          if (response.data.user.suspended) {
            handleSignOut();
            return;
          }
          
          setUser({
            ...response.data.user,
            payoutAddress: response.data.user.payoutAddress,
          });
          setIsTestUser(response.data.user.isTestUser || false);
          setIsSignedIn(true);
          setIsAdmin(response.data.user.isAdmin || false);
          console.log('User set:', response.data.user);
          console.log('isAdmin set:', response.data.user.isAdmin);
        } else {
          setIsSignedIn(false);
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        if (error.response?.data?.code === 'ACCOUNT_SUSPENDED') {
          handleSignOut();
        }
        setIsSignedIn(false);
        setIsAdmin(false);
      }
    };
  
    checkAuth();
  }, []);

  const updateUserInDatabase = async (profile) => {
    try {
      const response = await axios.post(`${API_URL}/auth/callback`, { profile }, {
        withCredentials: true
      });
      
      // Check for suspension before setting user data
      if (response.data.user.suspended) {
        handleSignOut();
        throw new Error('Account suspended');
      }

      setUser(response.data.user);
      setIsSignedIn(true);
      setIsAdmin(response.data.user.isAdmin || false);
      identifyUser(response.data.user);
      console.log('User updated:', response.data.user);
      console.log('isAdmin updated:', response.data.user.isAdmin);
    } catch (error) {
      console.error('Error updating user in database:', error);
      if (error.response?.data?.code === 'ACCOUNT_SUSPENDED') {
        handleSignOut();
      }
      setIsSignedIn(false);
      setIsAdmin(false);
    }
  };

  const handleSignOut = async () => {
    console.log("handleSignOut called in AuthContext");
    try {
      console.log("Sending signout request to:", `${API_URL}/auth/signout`);
      const response = await axios.post(`${API_URL}/auth/signout`, {}, {
        withCredentials: true,
      });
      console.log("Signout response:", response.data);
      setUser(null);
      setIsTestUser(false);
      setIsSignedIn(false);
      setIsAdmin(false);
      console.log("User signed out successfully");
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out:', error.response ? error.response.data : error.message);
      // Still clear user data even if the signout request fails
      setUser(null);
      setIsTestUser(false);
      setIsSignedIn(false);
      setIsAdmin(false);
      window.location.href = '/';
    }
  };

  const switchTestUser = async () => {
    console.log("switchTestUser called, isDevelopmentMode:", isDevelopmentMode);
    if (isDevelopmentMode) {
      try {
        console.log("Sending switch-test-user request to:", `${API_URL}/auth/switch-test-user`);
        const response = await axios.post(`${API_URL}/auth/switch-test-user`, {}, {
          withCredentials: true,
        });
        console.log("Switch test user response:", response.data);

        setUser(response.data.user);
        setIsTestUser(true);
        setIsSignedIn(true);
        setIsAdmin(response.data.user.isAdmin || false);
        console.log("Switched to test user:", response.data.user);
        console.log("isAdmin for test user:", response.data.user.isAdmin);
      } catch (error) {
        console.error('Error switching test user:', error.response ? error.response.data : error.message);
      }
    } else {
      console.log("Not in development mode, switchTestUser not executed");
    }
  };

  const checkAdminAuth = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/check-admin`, {
        withCredentials: true
      });
      setIsAdmin(true);
      return true;
    } catch (error) {
      console.error('Error checking admin authentication:', error);
      if (error.response && error.response.status === 403 && error.response.data.requiresTwoFactor) {
        return 'requires2FA';
      }
      setIsAdmin(false);
      return false;
    }
  };

  const updateUserPayoutAddress = async (payoutAddress) => {
    try {
      console.log('AuthContext: Initiating payout address update:', payoutAddress);
      
      const response = await axios.post(
        `${API_URL}/users/update-payout-address`, 
        { payoutAddress }, // Keep the property name consistent
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('AuthContext: Server response:', response.data);
      
      // Update the user state directly with the returned user data
      if (response.data.user) {
        console.log('AuthContext: Updating user state with full user data');
        setUser(prevUser => ({
          ...prevUser,
          ...response.data.user
        }));
      } else {
        console.log('AuthContext: Updating user state with payout address only');
        setUser(prevUser => ({
          ...prevUser,
          payoutAddress
        }));
      }
  
      console.log('AuthContext: Payout address update completed');
      return response.data;
    } catch (error) {
      console.error('AuthContext: Error updating payout address:', {
        error: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      if (error.response?.status === 400) {
        throw new Error(error.response.data.message || 'Invalid payout address');
      }
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isSignedIn,
      isAdmin,
      user,
      setUser,
      profile,
      handleSignOut,
      isDevelopmentMode,
      switchTestUser,
      isTestUser,
      updateUserInDatabase,
      checkAdminAuth,
      updateUserPayoutAddress
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};