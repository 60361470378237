// frontend/src/components/RequestCard.js

import React, { useState, useEffect, useRef } from 'react';
import useWebSocket from '../hooks/useWebSocket';
import { Link } from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Chip, 
  Box, 
  Grid, 
  Tooltip,
  CircularProgress, 
  alpha,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentIcon from '@mui/icons-material/Payment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatDescription } from '../utilities/textFormatting';
import { StyledCard, CardInner, StyledAmountDisplay, UnifiedCardTitle, TitleBox } from './CommonStyledCard';
import { formatSubcategory } from '../utilities/formatters';
import { formatRequestStatus } from '../utilities/formatters';
import FarcasterUsername from './FarcasterUsername';
import FarcasterAvatar from './FarcasterAvatar';
import { getStatusColor } from '../utilities/statusUtils';
import CategoryItem from './CategoryItem';
import ActivitySummarySection from './ActivitySummarySection';

const HeaderBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  textAlign: 'center',
}));

const StatusChipBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& .MuiChip-root': {
    boxShadow: `0 2px 8px ${alpha(theme.palette.common.black, 0.1)}`,
  }
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const color = getStatusColor(status, theme);
  const pulseAnimationName = `statusPulseCard_${status}`;

  return {
    minWidth: 'auto',
    height: '32px', // Increased from 24px
    padding: '0 16px', // Increased from '0 8px'
    backgroundColor: alpha(color, 0.1),
    border: `1px solid ${alpha(color, 0.2)}`,
    color: color,
    fontWeight: 600,
    fontSize: '0.875rem', // Increased from 0.75rem
    letterSpacing: '0.02em',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    
    '& .MuiChip-label': {
      padding: '0 12px',
    },

    [`@keyframes ${pulseAnimationName}`]: {
      '0%': {
        boxShadow: `0 0 0 0 ${alpha(color, 0.4)}`,
        borderColor: alpha(color, 0.5),
      },
      '50%': {
        borderColor: alpha(color, 0.8),
      },
      '70%': {
        boxShadow: `0 0 0 8px ${alpha(color, 0)}`,
      },
      '100%': {
        boxShadow: `0 0 0 0 ${alpha(color, 0)}`,
        borderColor: alpha(color, 0.5),
      },
    },

    animation: status === 'bounty_released' || status === 'bounty_withheld' || status === 'active' ? 
      `${pulseAnimationName} 2s infinite cubic-bezier(0.4, 0, 0.6, 1)` : 'none',

    backdropFilter: 'blur(8px)',

    '&:hover': {
      backgroundColor: alpha(color, 0.15),
      transform: 'translateY(-2px)',
      boxShadow: `0 4px 12px ${alpha(color, 0.2)}`,
      borderColor: alpha(color, 0.4),
    },

    [theme.breakpoints.down('sm')]: {
      height: '28px', // Increased from 20px
      padding: '0 12px',
      fontSize: '0.8125rem', // Increased from 0.7rem
      '& .MuiChip-label': {
        padding: '0 8px',
      },
    },
  };
});

const BountyLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  marginBottom: theme.spacing(0.5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const RequestCard = ({ 
  request, 
  showManageOptions, 
  showToast, 
  onUpdate, 
  isMyRequest, 
  onProcessPayment, 
  isPaymentProcessing 
}) => {
  const [currentRequest, setCurrentRequest] = useState(request);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const previousStatusRef = useRef(request.status);
  const { lastUpdate } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, request._id);

  const [bidStats, setBidStats] = useState({
    topBid: null,
    totalBids: 0,
    totalVouches: 0,
    aggregateVouchAmount: 0
  });
  
  const calculateBidStats = (bids) => {
    if (!Array.isArray(bids)) return;
    
    // Filter out draft bids
    const activeBids = bids.filter(bid => bid.status !== 'draft');
    
    // Calculate totals using only confirmed vouches
    const stats = {
      totalBids: activeBids.length,
      totalVouches: activeBids.reduce((sum, bid) => 
        sum + (bid.confirmedVouches?.length || 0), 0
      ),
      aggregateVouchAmount: activeBids.reduce((sum, bid) => 
        sum + (bid.totalVouchAmount || 0), 0
      )
    };
    
    // Find top bid by confirmed vouch amount
    const sortedBids = [...activeBids].sort((a, b) => 
      (b.totalVouchAmount || 0) - (a.totalVouchAmount || 0)
    );
    
    // Include bidderDetails in the top bid
    stats.topBid = sortedBids[0] ? {
      ...sortedBids[0],
      bidderDetails: sortedBids[0].bidderDetails || { username: sortedBids[0].bidder }
    } : null;
    
    return stats;
  };

  useEffect(() => {
    if (lastUpdate && lastUpdate.type === 'REQUEST_UPDATE') {
      const updatedRequest = {
        ...currentRequest,
        ...lastUpdate.data,
        totalBounty: lastUpdate.data.totalBounty || currentRequest.totalBounty,
        pendingAdditionalBounties: lastUpdate.data.pendingAdditionalBounties || currentRequest.pendingAdditionalBounties,
        additionalBounties: lastUpdate.data.additionalBounties || currentRequest.additionalBounties,
        // Preserve bid information
        bids: lastUpdate.data.bids || currentRequest.bids,
        topBid: lastUpdate.data.topBid || currentRequest.topBid
      };
  
      setCurrentRequest(updatedRequest);
  
      // If status has changed and we have an onUpdate handler, notify parent
      if (lastUpdate.data.status && 
          lastUpdate.data.status !== previousStatusRef.current && 
          onUpdate) {
        onUpdate(updatedRequest);
      }
  
      previousStatusRef.current = lastUpdate.data.status || previousStatusRef.current;
    }
  }, [lastUpdate, currentRequest, onUpdate]);

  useEffect(() => {
    setCurrentRequest(request);
    previousStatusRef.current = request.status;
  }, [request]);

  useEffect(() => {
    let timer;
    if ((currentRequest.status === 'draft' || currentRequest.status === 'payment_initiated') && currentRequest.expiresAt) {
      const updateTimeRemaining = () => {
        const now = new Date();
        const expiresAt = new Date(currentRequest.expiresAt);
        const diff = expiresAt - now;
        if (diff > 0) {
          setTimeRemaining(Math.floor(diff / 1000));
        } else {
          setTimeRemaining(0);
          clearInterval(timer);
        }
      };

      updateTimeRemaining();
      timer = setInterval(updateTimeRemaining, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentRequest]);

  useEffect(() => {
    if (currentRequest.bids) {
      const stats = calculateBidStats(currentRequest.bids);
      setBidStats(stats);
    }
  }, [currentRequest.bids]);

  const initialBounty = Number(currentRequest.bounty) || 0;
  const additionalBountiesSum = currentRequest.additionalBounties?.reduce((sum, bounty) => sum + Number(bounty.amount), 0) || 0;
  const currentTotalBounty = initialBounty + additionalBountiesSum;
  const pendingBountyAmount = currentRequest.pendingAdditionalBounties?.reduce((sum, bounty) => sum + Number(bounty.amount), 0) || 0;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  const renderSalaryOrBudget = () => {
    if (currentRequest.category === 'job') {
      if (currentRequest.salaryRange?.isFlexible) {
        return 'Salary: Flexible/Undetermined';
      } else if (currentRequest.salaryRange?.min != null && currentRequest.salaryRange?.max != null) {
        return `Salary: $${currentRequest.salaryRange.min.toLocaleString()} - $${currentRequest.salaryRange.max.toLocaleString()} / year`;
      }
    } else if (currentRequest.category === 'project') {
      if (currentRequest.budget?.isFlexible) {
        return 'Budget: Flexible/Undetermined';
      } else if (currentRequest.budget?.min != null && currentRequest.budget?.max != null) {
        return `Budget: $${currentRequest.budget.min.toLocaleString()} - $${currentRequest.budget.max.toLocaleString()}`;
      }
    }
    return 'Salary/Budget: Not specified';
  };

  const renderManageButtons = () => {
    if (!showManageOptions) return null;
  
    switch (currentRequest.status) {
      case 'draft':
      case 'payment_initiated':
        return (
          <StyledButton 
            onClick={async () => {
              if (onProcessPayment) {
                await onProcessPayment(currentRequest._id, currentRequest.version, {
                  title: currentRequest.title,
                  category: currentRequest.category,  
                  bounty: currentRequest.bounty,
                });
              }
            }}
            variant="outlined" 
            color="secondary" 
            fullWidth 
            sx={{ mt: 1 }}
            disabled={isPaymentProcessing}
            startIcon={isPaymentProcessing ? <CircularProgress size={20} /> : <PaymentIcon />}
          >
            {isPaymentProcessing ? 'Processing...' : (currentRequest.status === 'draft' ? 'Process Payment' : 'Complete Payment')}
          </StyledButton>
        );
      case 'payment_pending':
        return (
          <>
            <StyledButton 
              variant="outlined" 
              color="secondary" 
              fullWidth 
              sx={{ mt: 1 }} 
              disabled
              startIcon={<CircularProgress size={20} />}
            >
              Payment Processing
            </StyledButton>
            <Typography variant="body2" color="info.main" sx={{ textAlign: 'center', mt: 1 }}>
              Your payment is being confirmed on the blockchain.
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  const getBountyTooltip = () => {
    switch (currentRequest.category) {
      case 'recommendation':
        return "For recommendations, 42.5% goes to the recommender and 42.5% is shared among vouchers of the winning recommendation. 15% is retained by the system.";
      case 'project':
      case 'job':
        return "85% of the bounty is shared among vouchers of the winning bid/application. 15% is retained by the system.";
      default:
        return "Total bounty amount available for distribution";
    }
  };

  return (
    <>
      <StyledCard>
        <CardInner>
          {isMyRequest && (currentRequest.status === 'draft' || currentRequest.status === 'payment_initiated') && timeRemaining !== null && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: 'warning.main' }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body2" fontWeight="bold">
                Time to activate: {formatTime(timeRemaining)}
              </Typography>
            </Box>
          )}
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <HeaderBox>
              <TitleBox>
                <UnifiedCardTitle
                  variant="h2"
                  component="h2" // Add this to ensure proper HTML semantics
                  sx={{
                    // Add some overrides to ensure the styling is applied
                    fontSize: {
                      xs: '1.25rem !important',
                      sm: '1.35rem !important',
                      md: '1.5rem !important'
                    },
                    // Add some additional styling for the RequestCard specifically
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: '100%',
                    mx: 'auto'
                  }}
                >
                  {currentRequest.title}
                </UnifiedCardTitle>
              </TitleBox>
              <StatusChipBox>
                <StatusChip 
                  label={formatRequestStatus(currentRequest.status)} 
                  status={currentRequest.status} 
                  sx={{ flexShrink: 0 }}
                />
              </StatusChipBox>
            </HeaderBox>
            </Grid>
        
            <Grid item xs={12}>
              <Box 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                  padding: 2,
                  borderRadius: theme => theme.shape.borderRadius * 2,
                  background: theme => `linear-gradient(135deg, 
                    ${alpha(theme.palette.background.paper, 0.4)} 0%,
                    ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                  backdropFilter: 'blur(8px)',
                  border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    background: theme => `linear-gradient(135deg, 
                      ${alpha(theme.palette.background.paper, 0.5)} 0%,
                      ${alpha(theme.palette.background.paper, 0.2)} 100%)`,
                    boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
                    '& .avatar': {
                      transform: 'scale(1.05) rotate(3deg)',
                      boxShadow: theme => `0 0 30px ${alpha(theme.palette.primary.main, 0.25)}`,
                    },
                    '& .creator-info': {
                      transform: 'translateX(4px)',
                    }
                  }
                }}
              >
                <FarcasterAvatar
                  username={currentRequest.creatorDetails?.username || currentRequest.creator}
                  avatarUrl={currentRequest.creatorDetails?.avatarUrl}
                  size="medium"
                  className="avatar"
                  sx={{ 
                    width: { xs: 48, sm: 56 },
                    height: { xs: 48, sm: 56 },
                    mr: 2,
                    border: theme => `2px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    boxShadow: theme => `0 0 20px ${alpha(theme.palette.primary.main, 0.15)}`,
                    transition: 'all 0.3s ease-in-out',
                  }}
                />
                <Box className="creator-info" sx={{ transition: 'all 0.3s ease-in-out' }}>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                      fontSize: { xs: '0.75rem', sm: '0.875rem' },
                      fontWeight: 500,
                      letterSpacing: '0.02em',
                      opacity: 0.8,
                      mb: 0.5
                    }}
                  >
                    Created by
                  </Typography>
                  <Box 
                    sx={{ 
                      '& a': {
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        fontWeight: 700,
                        textDecoration: 'none',
                        background: theme => `linear-gradient(135deg, 
                          ${theme.palette.primary.main} 0%, 
                          ${theme.palette.primary.light} 100%)`,
                        backgroundSize: '200% 100%',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        transition: 'all 0.3s ease-in-out',
                        position: 'relative',
                        display: 'inline-block',
                        
                        '&:hover': {
                          textDecoration: 'none',
                          backgroundPosition: '100% 0',
                          transform: 'translateX(4px)',
                          
                          '&::after': {
                            transform: 'scaleX(1)',
                            opacity: 1,
                          }
                        },
                        
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: '-2px',
                          left: 0,
                          width: '100%',
                          height: '2px',
                          background: theme => `linear-gradient(90deg, 
                            ${theme.palette.primary.main}, 
                            ${theme.palette.primary.light})`,
                          transform: 'scaleX(0)',
                          opacity: 0,
                          transformOrigin: 'left center',
                          transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                        }
                      }
                    }}
                  >
                    <FarcasterUsername username={currentRequest.creatorDetails?.username || currentRequest.creator} />
                  </Box>
                  {currentRequest.creatorDetails?.displayName && (
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        color: 'text.secondary',
                        fontWeight: 400,
                        opacity: 0.7,
                        display: 'block',
                        mt: 0.5
                      }}
                    >
                      {currentRequest.creatorDetails.displayName}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 3,
                  minHeight: '5em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: 'vertical',
                  lineHeight: 1.6
                }}
                dangerouslySetInnerHTML={{ __html: formatDescription(currentRequest.description) }}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledAmountDisplay>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <AttachMoneyIcon 
                      sx={{ 
                        color: 'primary.main', 
                        mr: 1,
                        fontSize: '1.5rem',
                        filter: 'drop-shadow(0 2px 4px rgba(0, 191, 165, 0.3))'
                      }} 
                    />
                    <BountyLabel>Bounty</BountyLabel>
                    <Tooltip title={getBountyTooltip()}>
                      <Box 
                        sx={{ 
                          ml: 1,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.secondary',
                          '&:hover': {
                            color: 'primary.main'
                          }
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box>
                  <Typography 
                    variant="h4" 
                    component="div"
                    className="amount-text"
                    sx={{ 
                      fontWeight: 700,
                      color: 'primary.main',
                      textShadow: theme => `0 2px 4px ${alpha(theme.palette.primary.main, 0.2)}`,
                      mb: 0.5,
                      fontSize: { 
                        xs: '1.4rem',    
                        sm: '1.75rem',   
                        md: '2rem'      
                      },
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                  >
                    ${currentTotalBounty.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC
                  </Typography>
                    {pendingBountyAmount > 0 && (
                      <Typography 
                        variant="body2" 
                        color="warning.main"
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          gap: 1,
                          mt: 0.5
                        }}
                      >
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                        + ${pendingBountyAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC pending
                        </Box>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </StyledAmountDisplay>
            </Grid>
            
            <Grid item xs={12}>
              <ActivitySummarySection request={currentRequest} isCreator={isMyRequest} />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography variant="body2" color="text.secondary">
                  {currentRequest.category === 'job' 
                    ? `Application Deadline: ${currentRequest.applicationDeadline ? formatDate(currentRequest.applicationDeadline) : 'No deadline'}`
                    : currentRequest.category === 'project'
                    ? `Bid Deadline: ${currentRequest.bidDeadline ? formatDate(currentRequest.bidDeadline) : 'No deadline'}`
                    : `Recommendation Deadline: ${currentRequest.recommendationDeadline ? formatDate(currentRequest.recommendationDeadline) : 'No deadline'}`}
                </Typography>
              </Box>
            </Grid>

            {currentRequest.category !== 'recommendation' && (
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {renderSalaryOrBudget()}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                <CategoryItem
                  label={currentRequest.category}
                  subcategoryKey={currentRequest.category}
                  isCategory={true}
                  isCompact={true}
                />
                <CategoryItem
                  label={formatSubcategory(currentRequest.subcategory)}
                  subcategoryKey={currentRequest.subcategory}
                  isCompact={true}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <StyledButton
                  component={Link}
                  to={`/request/${currentRequest._id}`}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mb: 1 }}
                >
                  {isMyRequest ? "Manage Request" : "View Details"}
                </StyledButton>
                {renderManageButtons()}
              </Box>
            </Grid>
          </Grid>
        </CardInner>
      </StyledCard>
    </>
  );
};

export default RequestCard;
