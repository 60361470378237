// frontend/src/components/FeaturedRequestsSection.js

import React from 'react';
import { Box, Container, Typography, Grid, Avatar, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import GroupIcon from '@mui/icons-material/Group';
import { HOME_PAGE_EXAMPLES } from '../data/homeExampleData';
import { StyledCard, CardInner } from './CommonStyledCard';

const BackgroundSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  background: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.light,
    0.05
  )}, ${alpha(theme.palette.secondary.light, 0.05)})`,
}));

const DecorativeBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-20%',
  right: '-10%',
  width: '400px',
  height: '400px',
  background: `radial-gradient(circle at center, ${alpha(
    theme.palette.primary.main,
    0.15
  )}, transparent 70%)`,
  transform: 'rotate(45deg)',
}));

const MetricChip = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  fontWeight: 600,
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  '& svg': {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
}));

const ExampleLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-1.5),
  right: theme.spacing(3),
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
  backdropFilter: 'blur(8px)',
  zIndex: 1,
}));

const CategoryChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  fontWeight: 600,
  color: theme.palette.secondary.main,
  fontSize: '0.875rem',
}));

const BidderInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const FeaturedRequestsSection = () => {
  const { recommendations, projects, jobs, aiProject } = HOME_PAGE_EXAMPLES;
  const examples = [recommendations, projects, jobs, aiProject];

  const formatCategory = (category) => {
    return category.charAt(0).toUpperCase() + category.slice(1).replace(/_/g, ' ');
  };

  return (
    <BackgroundSection sx={{ py: { xs: 8, md: 12 } }}>
      <DecorativeBackground />
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{
            mb: 2,
            fontWeight: 700,
            fontSize: { xs: '2.25rem', sm: '2.75rem', md: '3rem' },
          }}
        >
          Example Premium Requests
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          sx={{
            mb: { xs: 6, md: 8 },
            maxWidth: 800,
            mx: 'auto',
            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
            lineHeight: 1.6,
          }}
        >
          Explore the types of high-value opportunities possible on Vouch
        </Typography>

        <Grid container spacing={4}>
          {examples.map((example, index) => (
            <Grid item xs={12} md={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <StyledCard>
                  <CardInner>
                    <ExampleLabel>
                      <Typography variant="caption" sx={{ fontWeight: 600, color: 'secondary.main' }}>
                        EXAMPLE
                      </Typography>
                    </ExampleLabel>

                    {/* Title */}
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h5" sx={{ fontWeight: 700, lineHeight: 1.3 }}>
                        {example.request.title}
                      </Typography>
                    </Box>

                    {/* Creator Info */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: 'primary.main',
                          width: 48,
                          height: 48,
                          mr: 2,
                          border: `2px solid ${alpha('#ffffff', 0.2)}`,
                          boxShadow: `0 0 10px ${alpha('#000000', 0.1)}`,
                        }}
                      >
                        {example.request.creatorDetails.displayName[0]}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          {example.request.creatorDetails.displayName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          @{example.request.creator}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Category */}
                    <Box sx={{ mb: 2 }}>
                      <CategoryChip>{formatCategory(example.request.subcategory)}</CategoryChip>
                    </Box>

                    {/* Description */}
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 5,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          lineHeight: 1.6,
                        }}
                      >
                        {example.request.description}
                      </Typography>
                    </Box>

                    {/* Metrics */}
                    <Box sx={{ mb: 3 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        <MetricChip>
                          <AttachMoneyIcon fontSize="small" />
                          {`${example.request.bounty.toLocaleString()} USDC Bounty`}
                        </MetricChip>
                        <MetricChip>
                          <GroupIcon fontSize="small" />
                          {`${example.request.totalBids} ${
                            example.request.category === 'recommendation'
                              ? 'Recommendations'
                              : example.request.category === 'project'
                              ? 'Bids'
                              : 'Applications'
                          }`}
                        </MetricChip>
                        <MetricChip>
                          <ThumbUpIcon fontSize="small" />
                          {`Total Vouched: ${example.request.totalVouches}`}
                        </MetricChip>
                      </Box>
                    </Box>

                    {/* Featured Response */}
                    <Box sx={{ mt: 'auto', pt: 2 }}>
                      <Box
                        sx={{
                          p: 2,
                          borderRadius: 2,
                          bgcolor: alpha('#4caf50', 0.1),
                          border: `1px solid ${alpha('#4caf50', 0.2)}`,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="success.main"
                          sx={{
                            fontWeight: 600,
                            mb: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          Featured Response
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            lineHeight: 1.5,
                            mb: 2,
                          }}
                        >
                          {example.bids[0].proposal}
                        </Typography>

                        {/* Bidder Info */}
                        <BidderInfo>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: 24,
                                  height: 24,
                                  bgcolor: alpha('#4caf50', 0.2),
                                  color: 'success.main',
                                  fontSize: '0.75rem',
                                  fontWeight: 600,
                                }}
                              >
                                {example.bids[0].bidderDetails.displayName[0]}
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: 600,
                                    color: 'text.primary',
                                    display: 'block',
                                  }}
                                >
                                  {example.bids[0].bidderDetails.displayName}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: 'text.secondary',
                                    fontSize: '0.7rem',
                                  }}
                                >
                                  @{example.bids[0].bidder}
                                </Typography>
                              </Box>
                            </Box>

                            <Typography
                              variant="caption"
                              sx={{
                                fontWeight: 600,
                                color: 'success.main',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                              }}
                            >
                              <ThumbUpIcon sx={{ fontSize: 14 }} />
                              Total Vouched: {example.bids[0].totalVouchAmount.toLocaleString()} USDC
                            </Typography>
                          </Box>

                          {example.bids[0].vouches && example.bids[0].vouches[0] && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'text.secondary',
                                display: 'block',
                                fontSize: '0.7rem',
                              }}
                            >
                              Including {example.bids[0].vouches[0].voucherDetails.displayName} (
                              {example.bids[0].vouches[0].amount.toLocaleString()} USDC)
                            </Typography>
                          )}
                        </BidderInfo>
                      </Box>
                    </Box>
                  </CardInner>
                </StyledCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </BackgroundSection>
  );
};

export default FeaturedRequestsSection;
