// frontend/src/components/BidCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Chip, Link, Tooltip, Grid, alpha } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FarcasterUsername from './FarcasterUsername';
import FarcasterAvatar from './FarcasterAvatar';
import { StyledCard, CardInner, StyledAmountDisplay, UnifiedCardTitle, TitleBox } from './CommonStyledCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import RecommendIcon from '@mui/icons-material/Recommend';
import DraftsIcon from '@mui/icons-material/Drafts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DOMPurify from 'dompurify';

const HeaderBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  textAlign: 'center',
}));

const StatusChipBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& .MuiChip-root': {
    boxShadow: `0 2px 8px ${alpha(theme.palette.common.black, 0.1)}`,
  }
}));

const StatusChip = styled(Chip)(({ theme, color }) => ({
  minWidth: 100,
  height: 28,
  '& .MuiChip-label': {
    fontSize: '0.75rem',
    fontWeight: 600,
    padding: '0 12px',
  },
  backgroundColor: alpha(theme.palette[color || 'default'].main, 0.1),
  color: theme.palette[color || 'default'].main,
  border: `1px solid ${theme.palette[color || 'default'].main}`,
}));

const AmountLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  marginBottom: theme.spacing(0.5),
}));

const AmountValue = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '2rem',
  color: theme.palette.primary.main,
  textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',  // Smaller on mobile
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '1.75rem', // Medium on tablets
  }
}));

const NegotiableChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: alpha(theme.palette.info.main, 0.1),
  color: theme.palette.info.main,
  border: `1px solid ${theme.palette.info.main}`,
  height: 24,
  '& .MuiChip-label': {
    fontSize: '0.75rem',
    fontWeight: 500,
  },
}));

const TooltipIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const VouchBreakdown = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

const BidCard = ({ bid, requestCategory, requestTitle, requestId, showToast }) => {
  const getCategoryIcon = () => {
    switch (requestCategory) {
      case 'job':
        return <WorkIcon />;
      case 'project':
        return <BuildIcon />;
      case 'recommendation':
        return <RecommendIcon />;
      default:
        return null;
    }
  };

  const getCardTitle = () => {
    switch (requestCategory) {
      case 'job':
        return 'Job Application';
      case 'project':
        return 'Project Bid';
      case 'recommendation':
        return 'Recommendation';
      default:
        return 'Response';
    }
  };

  const getStatusInfo = (status) => {
    switch (status) {
      case 'draft':
        return { label: 'Draft', color: 'default' };
      case 'accepted':
        return { label: 'Accepted', color: 'success' };
      case 'pending':
        return { label: 'Pending', color: 'warning' };
      case 'not_selected':
        return { label: 'Not Selected', color: 'error' };
      default:
        return {
          label: status
            ? status
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            : 'Unknown',
          color: 'default',
        };
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  const truncateText = (text, maxLength = 100) => {
    if (!text) return 'No proposal provided';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const statusInfo = getStatusInfo(bid.status);

  return (
    <StyledCard>
      <CardInner>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeaderBox>
              <TitleBox>
                <Box 
                  sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    {getCategoryIcon()}
                    <UnifiedCardTitle
                      variant="h2"
                      component="h2"
                      sx={{
                        fontSize: {
                          xs: '1.25rem !important',
                          sm: '1.35rem !important',
                          md: '1.5rem !important'
                        },
                        textAlign: 'center',
                        display: 'inline', // Keeps the title text together
                        color: 'text.primary',
                        '&:hover': {
                          color: 'text.primary'
                        },
                        '& *': {
                          fontSize: 'inherit !important'
                        },
                        '& a': {
                          fontSize: 'inherit !important',
                          fontWeight: 'inherit'
                        }
                      }}
                    >
                      {bid.status === 'draft' ? 'Draft ' : ''}
                      {getCardTitle()}
                    </UnifiedCardTitle>
                  </Box>
                </Box>
              </TitleBox>
              <StatusChipBox>
                <StatusChip
                  icon={bid.status === 'draft' ? <DraftsIcon /> : null}
                  label={statusInfo.label}
                  color={statusInfo.color}
                  size="small"
                  sx={{
                    mt: { xs: 1, sm: 0 },
                  }}
                />
              </StatusChipBox>
            </HeaderBox>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <FarcasterAvatar
                username={bid.bidderDetails?.username || bid.bidder}
                avatarUrl={bid.bidderDetails?.avatarUrl}
                size="medium"
                sx={{ mr: 2 }}
              />
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Submitted by
                </Typography>
                <Typography variant="subtitle1">
                  <FarcasterUsername username={bid.bidder || 'Unknown Bidder'} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {bid.status === 'draft' ? 'Draft ' : ''}
                  {requestTitle || 'No Title'}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {requestCategory !== 'recommendation' && (
            <Grid item xs={12}>
              <StyledAmountDisplay>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AttachMoneyIcon sx={{ color: 'primary.main', mr: 1 }} />
                  <AmountLabel>
                    {requestCategory === 'job' ? 'Proposed Salary' : 'Bid Amount'}
                  </AmountLabel>
                  <Tooltip title={requestCategory === 'job' ? 
                    "Proposed yearly salary for this position" : 
                    "Proposed amount for completing this project"}>
                    <TooltipIcon />
                  </Tooltip>
                </Box>
                <Box>
                  <AmountValue>
                    {requestCategory === 'job'
                      ? bid.salary
                        ? `${formatCurrency(bid.salary)}/year`
                        : 'Salary not specified'
                      : bid.amount
                      ? `${formatCurrency(bid.amount)} USDC`
                      : 'Amount not specified'}
                  </AmountValue>
                  {bid.isNegotiable && (
                    <NegotiableChip label="Negotiable" size="small" />
                  )}
                </Box>
              </StyledAmountDisplay>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
              Proposal Details
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              component="div"
              sx={{
                mb: 3,
                lineHeight: 1.6,
                '& a': { color: 'primary.main' },
                wordBreak: 'break-word',
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(truncateText(bid.proposal)),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Related Request:
              </Typography>
              <Link
                component={RouterLink}
                to={`/request/${requestId || 'unknown'}`}
                color="primary"
                sx={{
                  display: 'block',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {requestTitle || 'View Request'}
              </Link>
            </Box>

            {bid.status !== 'draft' && (
              <StyledAmountDisplay>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AttachMoneyIcon sx={{ color: 'primary.main', mr: 1 }} />
                  <AmountLabel>Total Vouched</AmountLabel>
                  <Tooltip title="Total amount vouched by other users supporting this response">
                    <TooltipIcon />
                  </Tooltip>
                </Box>
                <AmountValue>
                  {formatCurrency(bid.totalVouchAmount || 0)} USDC
                  {bid.status === 'not_selected' && (
                    <Typography 
                      component="span" 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ ml: 1 }}
                    >
                      (Refunded)
                    </Typography>
                  )}
                </AmountValue>

                {bid.vouches && bid.vouches.length > 0 && (
                  <VouchBreakdown>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Vouch Breakdown:
                    </Typography>
                    {bid.pendingVouches?.length > 0 && (
                      <Typography variant="body2" color="text.secondary">
                        Pending Vouches: {formatCurrency(bid.pendingVouches.reduce((sum, v) => sum + v.amount, 0))} USDC
                      </Typography>
                    )}
                    {bid.confirmedVouches?.length > 0 && (
                      <Typography variant="body2" color="text.secondary">
                        Confirmed Vouches: {formatCurrency(bid.confirmedVouches.reduce((sum, v) => sum + v.amount, 0))} USDC
                      </Typography>
                    )}
                  </VouchBreakdown>
                )}
              </StyledAmountDisplay>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, pt: 2, borderTop: '1px solid', borderColor: 'divider' }}>
          <Typography variant="caption" color="text.secondary">
            {bid.status === 'draft' ? 'Last edited' : 'Submitted'} on:{' '}
            {bid.updated_at
              ? new Date(bid.updated_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : 'Unknown date'}
          </Typography>
        </Box>
      </CardInner>
    </StyledCard>
  );
};

BidCard.propTypes = {
  bid: PropTypes.shape({
    status: PropTypes.string.isRequired,
    bidder: PropTypes.string.isRequired,
    bidderDetails: PropTypes.shape({
      username: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
    amount: PropTypes.number,
    salary: PropTypes.number,
    isNegotiable: PropTypes.bool,
    proposal: PropTypes.string,
    totalVouchAmount: PropTypes.number,
    vouches: PropTypes.array,
    confirmedVouches: PropTypes.array,
    pendingVouches: PropTypes.array,
    updated_at: PropTypes.string,
  }).isRequired,
  requestCategory: PropTypes.string.isRequired,
  requestTitle: PropTypes.string,
  requestId: PropTypes.string,
  showToast: PropTypes.func,
};

export default BidCard;