// frontend/src/components/admin/DashboardStats.js

import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme, alert }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: alert ? theme.palette.error.dark : theme.palette.background.paper,
}));

const DashboardStats = ({ stats }) => {
  if (!stats) {
    return <Typography>Loading stats...</Typography>;
  }

  const formatNumber = (num) => {
    return num !== undefined ? num.toFixed(2) : 'N/A';
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper>
          <Typography variant="h6">{stats.totalUsers || 0}</Typography>
          <Typography variant="body2">Total Users</Typography>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper>
          <Typography variant="h6">{stats.totalRequests || 0}</Typography>
          <Typography variant="body2">Total Requests</Typography>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper>
          <Typography variant="h6">{stats.totalBids || 0}</Typography>
          <Typography variant="body2">Total Bids</Typography>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper>
          <Typography variant="h6">{stats.totalVouches || 0}</Typography>
          <Typography variant="body2">Total Vouches</Typography>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper>
          <Typography variant="h6">${formatNumber(stats.totalBountyPaid)}</Typography>
          <Typography variant="body2">Total Bounty Paid</Typography>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper>
          <Typography variant="h6">${formatNumber(stats.totalVouchAmount)}</Typography>
          <Typography variant="body2">Total Vouch Amount</Typography>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <StyledPaper alert={stats.failedPaymentsCount > 0}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: stats.failedPaymentsCount > 0 ? 'error.light' : 'text.primary'
            }}
          >
            {stats.failedPaymentsCount || 0}
          </Typography>
          <Typography 
            variant="body2"
            sx={{ 
              color: stats.failedPaymentsCount > 0 ? 'error.light' : 'text.primary'
            }}
          >
            Failed Payments
          </Typography>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default DashboardStats;