// frontend/src/components/admin/HighValueRequests.js

import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Chip,
  Alert
} from '@mui/material';
import { useQuery } from 'react-query';
import { getHighValueRequests } from '../../services/adminApi';

const HighValueRequests = () => {
  const { data: requests, isLoading, error } = useQuery(
    'highValueRequests',
    getHighValueRequests,
    {
      refetchInterval: 30000, // Refresh every 30 seconds
    }
  );

  if (isLoading) return <Box sx={{ p: 2 }}>Loading...</Box>;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        High Value Requests (≥$50,000)
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Bounty</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request._id}>
                <TableCell>{request.title}</TableCell>
                <TableCell>
                  {request.creatorDetails?.displayName || request.creator}
                </TableCell>
                <TableCell>
                  ${request.bounty.toLocaleString()}
                </TableCell>
                <TableCell>{request.category}</TableCell>
                <TableCell>
                  <Chip 
                    label={request.status}
                    color={request.status === 'active' ? 'success' : 'default'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {new Date(request.createdAt).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HighValueRequests;