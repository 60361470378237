import React, { useState } from 'react';
import { 
  Box, Typography, Grid, Paper, alpha, IconButton,
  useTheme, useMediaQuery, Button, Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getSubcategoryIcon } from '../utilities/subcategoryUtils';

const CategoryCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  background: `linear-gradient(145deg, 
    ${alpha(theme.palette.background.paper, 0.8)}, 
    ${alpha(theme.palette.background.default, 0.9)})`,
  backdropFilter: 'blur(12px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-6px)',
    boxShadow: `0 6px 24px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  }
}));

const SubcategoryItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  transition: 'all 0.2s ease',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transform: 'translateX(8px)',
  }
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: 24,
    color: theme.palette.primary.main,
  }
}));

const ExploreModeButton = styled(Button)(({ theme, active }) => ({
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius * 2,
  fontWeight: 600,
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  border: `1px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  '&:hover': {
    backgroundColor: active ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.primary.main, 0.05),
  }
}));

const RequestCategoriesExplorer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [expandedGroups, setExpandedGroups] = useState({});
  const [viewMode, setViewMode] = useState('curated');

  const categories = {
    recommendations: {
      title: "Recommendations",
      description: "Get trusted insights and objective evaluations from domain experts, backed by community validation",
      groups: {
        crypto_web3: {
          title: "Crypto & Web3",
          items: {
            defi_protocols: "DeFi Protocols",
            nft_projects: "NFT Projects",
            dao_governance: "DAO Governance",
            crypto_trading: "Crypto Trading",
            web3_infrastructure: "Web3 Infrastructure",
            wallet_security: "Wallet Security"
          }
        },
        tech_innovation: {
          title: "Technology & Innovation",
          items: {
            ai_tools: "AI Tools",
            dev_tools: "Dev Tools",
            saas_products: "SaaS Products",
            hardware_tech: "Hardware Tech",
            cybersecurity: "Cybersecurity",
            productivity_apps: "Productivity Apps"
          }
        },
        investment_business: {
          title: "Investment & Business",
          items: {
            startups: "Startups",
            angel_investing: "Angel Investing",
            venture_capital: "Venture Capital",
            market_opportunities: "Market Opportunities",
            real_estate: "Real Estate"
          }
        },
        professional_services: {
          title: "Professional Services",
          items: {
            wealth_management: "Wealth Management",
            legal_services: "Legal Services",
            tax_strategy: "Tax Strategy",
            business_consulting: "Business Consulting",
            private_banking: "Private Banking"
          }
        },
        lifestyle_premium: {
          title: "Lifestyle & Premium",
          items: {
            luxury_travel: "Luxury Travel",
            fine_dining: "Fine Dining",
            high_end_properties: "High-End Properties",
            luxury_vehicles: "Luxury Vehicles",
            wellness_fitness: "Wellness & Fitness"
          }
        },
        education_networking: {
          title: "Education & Networking",
          items: {
            education_courses: "Education Courses",
            networking_events: "Networking Events",
            exclusive_memberships: "Exclusive Memberships"
          }
        }
      }
    },
    jobs_projects: {
      title: "Jobs & Projects",
      description: "Connect with exceptional talent and opportunities across technical and professional domains",
      groups: {
        blockchain_web3: {
          title: "Blockchain & Web3",
          items: {
            blockchain_web3: "Blockchain & Web3",
            smart_contracts: "Smart Contracts",
            defi_dev: "DeFi Development",
            crypto_launch: "Crypto & Token Projects",
            nft_project_dev: "NFT Projects",
            dao_development: "DAO Development",
            web3_infrastructure: "Web3 Infrastructure",
            crypto_operations: "Crypto Operations"
          }
        },
        ai_ml: {
          title: "AI & Machine Learning",
          items: {
            ai_ml: "AI & Machine Learning",
            nlp_applications: "NLP & Language AI",
            computer_vision: "Computer Vision",
            llm_development: "LLM Development",
            ml_ops: "ML Operations"
          }
        },
        web_mobile: {
          title: "Web & Mobile",
          items: {
            web_mobile_dev: "Web & Mobile Development",
            systems_infrastructure: "Systems & Infrastructure",
            data_engineering: "Data Engineering",
            security_engineering: "Security Engineering",
            cloud_architecture: "Cloud Architecture"
          }
        },
        design_creative: {
          title: "Design & Creative",
          items: {
            ui_ux_design: "UI/UX Design",
            brand_graphic_design: "Brand & Graphic Design",
            motion_3d_design: "Motion & 3D Design",
            content_creation: "Content Creation"
          }
        },
        marketing_community: {
          title: "Marketing & Community",
          items: {
            social_media_community: "Social Media & Community",
            content_strategy: "Content Strategy",
            growth_marketing: "Growth Marketing",
            analytics_seo: "Analytics & SEO"
          }
        },
        business_ops: {
          title: "Business & Operations",
          items: {
            strategy_consulting: "Strategy Consulting",
            operations_management: "Operations Management",
            finance_legal: "Finance & Legal",
            product_management: "Product Management",
            market_research: "Market Research",
            data_analysis: "Data Analysis"
          }
        },
        professional: {
          title: "Professional Services",
          items: {
            education_training: "Education & Training",
            hr_recruitment: "HR & Recruitment",
            advisory_mentorship: "Advisory & Mentorship"
          }
        }
      }
    }
  };

  const toggleGroup = (categoryKey, groupKey) => {
    setExpandedGroups(prev => ({
      ...prev,
      [`${categoryKey}-${groupKey}`]: !prev[`${categoryKey}-${groupKey}`]
    }));
  };

  return (
    <Box sx={{ py: { xs: 8, md: 12 } }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          align="center"
          sx={{ 
            mb: 2,
            fontWeight: 700,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
          }}
        >
          Discover Possibilities
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          sx={{
            mb: 6,
            maxWidth: 800,
            mx: 'auto',
            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
          }}
        >
          Explore the diverse landscape of opportunities available on Vouch, where expertise meets validation
        </Typography>

        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <ExploreModeButton
            active={viewMode === 'curated'}
            onClick={() => setViewMode('curated')}
          >
            Curated View
          </ExploreModeButton>
          <ExploreModeButton
            active={viewMode === 'all'}
            onClick={() => setViewMode('all')}
          >
            Show All Categories
          </ExploreModeButton>
        </Box>

        <Grid container spacing={4}>
          {Object.entries(categories).map(([categoryKey, category]) => (
            <Grid item xs={12} md={6} key={categoryKey}>
              <CategoryCard>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                  {category.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {category.description}
                </Typography>

                <Box sx={{ mt: 3 }}>
                  {Object.entries(category.groups).map(([groupKey, group], groupIndex) => (
                    <Box key={groupKey} sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          mb: 2,
                        }}
                        onClick={() => toggleGroup(categoryKey, groupKey)}
                      >
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {group.title}
                        </Typography>
                        <IconButton size="small">
                          {expandedGroups[`${categoryKey}-${groupKey}`] ? 
                            <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Box>

                      <AnimatePresence>
                        {(viewMode === 'all' || expandedGroups[`${categoryKey}-${groupKey}`]) && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              {Object.entries(group.items).map(([key, label]) => (
                                <SubcategoryItem key={key}>
                                  <CategoryIcon>
                                    {getSubcategoryIcon(key)}
                                  </CategoryIcon>
                                  <Typography variant="body1">
                                    {label}
                                  </Typography>
                                </SubcategoryItem>
                              ))}
                            </Box>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Box>
                  ))}
                </Box>
              </CategoryCard>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ 
          mt: 6, 
          textAlign: 'center',
          p: 4,
          borderRadius: 2,
          bgcolor: theme => alpha(theme.palette.primary.main, 0.05),
          border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`
        }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
            Beyond Categories
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Our platform embraces innovation and unique opportunities. While these categories cover common ground, 
            we welcome specialized and emerging fields. If your needs span multiple domains or chart new territory, 
            Vouch's flexible framework adapts to support your vision. In fact, Vouch can be used for virtually any type of request.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default RequestCategoriesExplorer;