// frontend/src/components/RequestDocumentViewer.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import DocumentViewer from './DocumentViewer';

const RequestDocumentViewer = ({ documents }) => {
  if (!documents || documents.length === 0) return null;

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>Request Documents</Typography>
      {documents.map((document, index) => (
        <DocumentViewer key={index} document={document} context="request" />
      ))}
    </Box>
  );
};

export default RequestDocumentViewer;