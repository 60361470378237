// frontend/src/components/PageTitle.js

import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageTitle = ({ title, description }) => {
  const baseTitle = 'Vouch';
  const fullTitle = title ? `${title} | ${baseTitle}` : baseTitle;
  
  return (
    <Helmet>
      <title>{fullTitle}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={fullTitle} />
      {description && <meta property="og:description" content={description} />}
      <meta name="twitter:title" content={fullTitle} />
      {description && <meta name="twitter:description" content={description} />}
    </Helmet>
  );
};

export default PageTitle;