// frontend/src/components/RequestForm.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createRequest, updateRequest, getRequest, handleApiError } from '../services/api';
import { 
  Container, Typography, Box, TextField, Button, 
  Paper, Alert, InputAdornment, CircularProgress,
  FormControlLabel, Checkbox, Grid, Select, MenuItem, FormControl, InputLabel,
  RadioGroup, Radio, Stepper, Step, StepLabel, StepContent, LinearProgress,
  Tooltip, Card, CardContent, Fade, Dialog, DialogTitle, DialogContent, DialogActions,
  List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListSubheader, Switch, Collapse
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled, ThemeProvider, createTheme, alpha, keyframes } from '@mui/material/styles';
import { motion } from 'framer-motion';
import RecommendIcon from '@mui/icons-material/Recommend';
import WorkIcon from '@mui/icons-material/Work';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import usePaymentProcessing from '../hooks/usePaymentProcessing';
import NotificationModal from './NotificationModal';
import '../styles/CustomQuill.css';
import WalletSetup from './WalletSetup';
import { useAuth } from '../context/AuthContext';
import { NumericFormat } from 'react-number-format';
import { useDropzone } from 'react-dropzone';
import GuideSection from './GuideSection';
import SubcategorySelector from './SubcategorySelector';  
import { subcategoryGroups } from '../utilities/subcategoryUtils';
import BountySelector from './BountySelector';

const MIN_BOUNTY_AMOUNT = parseFloat(process.env.REACT_APP_MIN_BOUNTY_AMOUNT || '1');
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
};

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00bfa5',
    },
    secondary: {
      main: '#ff6f61',
    },
    background: {
      default: '#121212',
      paper: '#1c1c1c',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
  typography: {
    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
    h4: {
      fontWeight: 700,
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#00bfa5',
            animation: 'bounce 0.3s ease-in-out',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#242424',
          '&:hover': {
            backgroundColor: '#333',
          },
        },
      },
    },
  },
});

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: {
    xs: theme.spacing(2),
    sm: theme.spacing(3),
    md: theme.spacing(4)
  },
  marginBottom: {
    xs: theme.spacing(2),
    sm: theme.spacing(3),
    md: theme.spacing(4)
  },
  padding: {
    xs: theme.spacing(1),
    sm: theme.spacing(2),
    md: theme.spacing(3)
  },
  position: 'relative',
  zIndex: 1,
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  
  '& .MuiStepLabel-root': {
    padding: theme.spacing(1, 0),
  },
  
  '& .MuiStepContent-root': {
    borderLeft: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    marginLeft: theme.spacing(2.5),
    paddingLeft: theme.spacing(3),
  },
  
  '& .MuiStepIcon-root': {
    color: alpha(theme.palette.primary.main, 0.3),
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: alpha(theme.palette.primary.main, 0.8),
    },
  },
  
  '& .MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.95)} 0%,
    ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)}
    `,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, 0.03)}, 
      transparent 70%)`,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(8px)',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    
    '& fieldset': {
      borderColor: alpha(theme.palette.text.primary, 0.1),
      transition: 'all 0.2s ease-in-out',
    },
    
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      '& fieldset': {
        borderColor: alpha(theme.palette.primary.main, 0.3),
      },
    },
    
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.background.paper, 1),
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  
  '& .MuiInputLabel-root': {
    color: alpha(theme.palette.text.primary, 0.7),
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: alpha(theme.palette.text.primary, 0.5),
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.2s ease-in-out',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  
  '& .MuiSelect-select': {
    padding: theme.spacing(1.5),
  },
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    borderColor: alpha(theme.palette.primary.main, 0.3),
  },
  
  '&.Mui-focused': {
    backgroundColor: alpha(theme.palette.background.paper, 1),
    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(1.5, 3),
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  fontSize: '0.9375rem',
  letterSpacing: '0.02em',
  textTransform: 'none',
  lineHeight: 1.5,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.1)})`,
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
  },

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 16px ${alpha(theme.palette.primary.main, 0.25)}`,
    '&::before': {
      opacity: 1,
    },
  },

  '&:active': {
    transform: 'translateY(0)',
  },

  '&.MuiButton-contained': {
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    color: theme.palette.common.black,
    border: 'none',
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,

    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
      boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
    },
  },
}));

const QuillWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 1,

  '& .ql-container': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(8px)',
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderTop: 'none',
    transition: 'all 0.2s ease-in-out',
    fontSize: {
      xs: '0.875rem',
      sm: '0.925rem',
      md: '1rem'
    },
    position: 'relative',
    zIndex: 1
  },
  
  '& .ql-toolbar': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(8px)',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderBottom: 'none',
    padding: {
      xs: '6px',
      sm: '8px',
      md: '8px 12px'
    },
    display: 'flex',
    flexWrap: 'wrap',
    gap: {
      xs: '2px',
      sm: '4px'
    },
    position: 'relative',
    zIndex: 2,
    
    '& .ql-formats': {
      margin: {
        xs: '0 3px',
        sm: '0 5px',
        md: '0 7px'
      },
      display: 'flex',
      alignItems: 'center',
      gap: {
        xs: '2px',
        sm: '3px',
        md: '4px'
      }
    },
    
    '& button': {
      padding: {
        xs: '2px',
        sm: '3px'
      },
      width: {
        xs: '24px',
        sm: '28px',
        md: '32px'
      },
      height: {
        xs: '24px',
        sm: '28px',
        md: '32px'
      }
    },
    
    '& .ql-picker': {
      color: alpha(theme.palette.text.primary, 0.7),
      height: {
        xs: '24px',
        sm: '28px',
        md: '32px'
      },
      position: 'relative',
      
      '& .ql-picker-label': {
        padding: {
          xs: '0 2px',
          sm: '0 4px'
        },
        fontSize: {
          xs: '0.813rem',
          sm: '0.875rem',
          md: '1rem'
        }
      },
      
      '& .ql-picker-options': {
        position: 'absolute',
        top: '100%',
        left: 0,
        backgroundColor: alpha(theme.palette.background.paper, 0.95),
        backdropFilter: 'blur(10px)',
        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
        borderRadius: theme.shape.borderRadius,
        padding: {
          xs: '2px 4px',
          sm: '4px 8px'
        },
        marginTop: '4px',
        zIndex: 1000,
        minWidth: '120px',
        boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.2)}`,
        
        '& .ql-picker-item': {
          padding: '8px 12px',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          color: theme.palette.text.primary,
          
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          
          '&.ql-selected': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main,
          }
        }
      },
      
      '&.ql-expanded': {
        '& .ql-picker-options': {
          display: 'block',
        },
        '& .ql-picker-label': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        }
      }
    },
    
    '& .ql-stroke': {
      stroke: alpha(theme.palette.text.primary, 0.7),
      strokeWidth: {
        xs: '1.5px',
        sm: '2px'
      }
    },
    
    '& .ql-fill': {
      fill: alpha(theme.palette.text.primary, 0.7),
    },
    
    '& button:hover .ql-stroke': {
      stroke: theme.palette.primary.main,
    },
    
    '& button:hover .ql-fill': {
      fill: theme.palette.primary.main,
    },
    
    // Touch-friendly improvements
    '@media (max-width: 600px)': {
      '& button, & .ql-picker-label': {
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }
      }
    }
  },
  
  '& .ql-editor': {
    minHeight: 300, // Increased height
    fontSize: {
      xs: '0.875rem',
      sm: '0.925rem',
      md: '1rem'
    },
    color: theme.palette.text.primary,
    padding: {
      xs: theme.spacing(1.5),
      sm: theme.spacing(2)
    },
    
    '&::placeholder': {
      color: alpha(theme.palette.text.primary, 0.5),
    },
    
    // Improve text readability on mobile
    '& p, & h1, & h2, & h3': {
      marginBottom: {
        xs: '0.5em',
        sm: '0.75em',
        md: '1em'
      }
    },
    
    '& ol, & ul': {
      paddingLeft: {
        xs: '1.2em',
        sm: '1.5em',
        md: '2em'
      }
    }
  },
  
  boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
  marginBottom: theme.spacing(3),
  
  '&:hover': {
    '& .ql-toolbar, & .ql-container': {
      borderColor: alpha(theme.palette.primary.main, 0.4),
    },
  },
  
  '&:focus-within': {
    '& .ql-toolbar, & .ql-container': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
  
  // Mobile optimization
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    
    '& .ql-snow.ql-toolbar button': {
      padding: '2px',
    },
    
    '& .ql-formats': {
      marginRight: '0',
    }
  },
  
  // Better scrolling on mobile
  '& .ql-container': {
    WebkitOverflowScrolling: 'touch',
  },
  
  // Improved touch targets for mobile
  '@media (max-width: 600px)': {
    '& .ql-picker-options': {
      minWidth: '120px', // Ensure touch-friendly size
      '& .ql-picker-item': {
        padding: '8px 12px', // Larger touch targets
      }
    }
  }
}));

const CustomQuill = (props) => (
  <QuillWrapper>
    <ReactQuill {...props} />
  </QuillWrapper>
);

const DragDropZone = styled(Box)(({ theme }) => ({
  border: `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const CategoryCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  marginBottom: theme.spacing(2),
  
  '&:hover': {
    transform: 'translateY(-4px)',
  },
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius * 2,
    padding: '1px',
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.primary.main, 0.4)}, 
      ${alpha(theme.palette.primary.light, 0.4)})`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },

  '&:hover::before': {
    opacity: 1,
  },
}));

const CategoryCardInner = styled(Box)(({ theme, selected }) => ({
  padding: theme.spacing(4),
  minHeight: {
    xs: '220px',
    sm: '240px',
    md: '260px',
    lg: '280px'
  },
  backgroundColor: selected 
    ? alpha(theme.palette.primary.main, 0.15)
    : alpha(theme.palette.background.paper, 0.7),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  maxWidth: {
    xs: '100%',
    sm: '100%',
    md: '800px',
    lg: '900px'
  },
  margin: '0 auto',
  overflow: 'hidden',
  
  '& > *': {
    marginBottom: {
      xs: theme.spacing(2),
      sm: theme.spacing(2.5),
      md: theme.spacing(3)
    }
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, selected ? 0.15 : 0.05)} 0%, 
      transparent 70%)`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },

  boxShadow: selected 
    ? `
      inset 0 0 0 2px ${theme.palette.primary.main},
      0 8px 25px ${alpha(theme.palette.common.black, 0.15)},
      0 0 40px ${alpha(theme.palette.primary.main, 0.2)}
      `
    : `
      inset 0 0 0 1px ${alpha(theme.palette.primary.main, 0.1)},
      0 4px 20px ${alpha(theme.palette.common.black, 0.1)}
      `,
  
  '&:hover': {
    backgroundColor: selected 
      ? alpha(theme.palette.primary.main, 0.2)
      : alpha(theme.palette.background.paper, 0.9),
    '&::before': {
      opacity: 1,
    },
    boxShadow: selected
      ? `
        inset 0 0 0 2px ${theme.palette.primary.main},
        0 12px 30px ${alpha(theme.palette.common.black, 0.2)},
        0 0 50px ${alpha(theme.palette.primary.main, 0.25)}
        `
      : `
        inset 0 0 0 1px ${alpha(theme.palette.primary.main, 0.3)},
        0 8px 25px ${alpha(theme.palette.common.black, 0.15)},
        0 0 40px ${alpha(theme.palette.primary.main, 0.1)}
        `,
  },

  '& .CategoryIconWrapper': {
    transform: 'translateY(0)',
    transition: 'transform 0.3s ease-in-out',
  },

  '&:hover .CategoryIconWrapper': {
    transform: 'translateY(-4px)',
  },

  ...(selected && {
    '& .MuiSvgIcon-root': {
      filter: `drop-shadow(0 0 10px ${alpha(theme.palette.primary.main, 0.5)})`,
    }
  }),

  [theme.breakpoints.down('sm')]: {
    wordBreak: 'break-word',
    hyphens: 'auto',
    WebkitHyphens: 'auto',
    MsHyphens: 'auto',
    '& > *': {
      maxWidth: '100%',
    }
  }
}));

const CategoryIconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
  '& .MuiSvgIcon-root': {
    fontSize: {
      xs: '2rem',
      sm: '2.2rem',
      md: '2.5rem',
      lg: '2.8rem'
    },
    filter: `drop-shadow(0 2px 8px ${alpha(theme.palette.primary.main, 0.5)})`,
  },
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem !important',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.5rem !important',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.35rem !important',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem !important',
  },
  [`@media (max-width: 400px)`]: {
    fontSize: '0.9rem !important',
    lineHeight: 1.3,
  }
}));

const CategoryDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: {
    xs: '0.875rem',
    sm: '0.925rem',
    md: '1rem',
    lg: '1.1rem'
  },
  lineHeight: 1.6,
  maxWidth: '100%',
  
  [`@media (max-width: 400px)`]: {
    fontSize: '0.85rem',
    lineHeight: 1.5,
  }
}));

const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid ' + theme.palette.divider,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 2),
  },
}));

const LabelWithTooltip = styled(({ label, tooltip, ...props }) => (
  <Box display="flex" alignItems="center" {...props}>
    <Typography variant="body1">{label}</Typography>
    <InfoTooltip 
      title={typeof tooltip === 'function' ? tooltip() : tooltip} 
      placement="top-start"
      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              tether: false,
            },
          },
        ],
      }}
    >
      <HelpOutlineIcon fontSize="small" sx={{ ml: 1, cursor: 'pointer' }} />
    </InfoTooltip>
  </Box>
))(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const DetailsSection = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    md: '1fr 1fr'
  },
  gap: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
  backdropFilter: 'blur(12px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '1200px',
  margin: '0 auto',
  
  '&:hover': {
    boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.1)})`,
    opacity: 0.5,
  },

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },

  '@media (max-width: 520px)': {
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },

  '@media (max-width: 400px)': {
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  }
}));

const DeadlineSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.6)}, ${alpha(theme.palette.background.default, 0.7)})`,
  borderRadius: theme.shape.borderRadius * 1.5,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(12px)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  height: 'fit-content',

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.15)}`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },

  '& .deadline-header': {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 1),
    
    '@media (max-width: 560px)': {
      marginBottom: theme.spacing(2),
      '& > h6': {
        fontSize: '1rem',
        lineHeight: 1.3,
      },
      '& > p': {
        fontSize: '0.75rem',
        lineHeight: 1.4,
      },
    },
  },

  '& .deadline-picker': {
    width: '100%',
    marginBottom: theme.spacing(2),
    
    '& .MuiOutlinedInput-root': {
      background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
      backdropFilter: 'blur(8px)',
      fontSize: {
        xs: '0.813rem',
        sm: '0.875rem',
        md: '1rem'
      },
      transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.primary.main, 0.2),
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },

      '@media (max-width: 560px)': {
        '& .MuiInputBase-input': {
          padding: '0.75rem',
        },
      },
    },

    '& .MuiInputLabel-root': {
      fontSize: {
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem'
      },
      color: alpha(theme.palette.text.primary, 0.7),
      
      '@media (max-width: 560px)': {
        fontSize: '0.75rem',
        transform: 'translate(14px, 10px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },

      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
    },
  },

  '& .deadline-toggle': {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    padding: theme.spacing(2.5),
    background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.4)}, ${alpha(theme.palette.background.default, 0.5)})`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    marginTop: theme.spacing(2),
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',

    '&:hover': {
      background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.5)}, ${alpha(theme.palette.background.default, 0.6)})`,
    },

    '& .MuiFormControlLabel-root': {
      margin: 0,
      flexGrow: 1,
      '& .MuiFormControlLabel-label': {
        fontSize: {
          xs: '0.75rem',
          sm: '0.813rem',
          md: '0.875rem'
        },
        whiteSpace: 'normal',
        lineHeight: 1.4,
      },
    },

    '@media (max-width: 560px)': {
      padding: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    },
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
    gap: theme.spacing(2),
  },
}));

const RangeSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.6)}, ${alpha(theme.palette.background.default, 0.7)})`,
  borderRadius: theme.shape.borderRadius * 1.5,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(12px)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  height: 'fit-content',

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.15)}`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },

  '& .range-header': {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 1),

    '@media (max-width: 560px)': {
      marginBottom: theme.spacing(2),
      '& > h6': {
        fontSize: '1rem',
        lineHeight: 1.3,
      },
      '& > p': {
        fontSize: '0.75rem',
        lineHeight: 1.4,
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
    gap: theme.spacing(2),
  },
}));

const FlexibleToggle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.4)}, ${alpha(theme.palette.background.default, 0.5)})`,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  
  '&:hover': {
    background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.5)}, ${alpha(theme.palette.background.default, 0.6)})`,
  },

  '& .toggle-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    
    '@media (max-width: 520px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    }
  },

  '& .toggle-label': {
    fontSize: {
      xs: '0.813rem',  // Slightly smaller on mobile
      sm: '0.875rem',
      md: '0.925rem'
    },
    fontWeight: 500,
    color: theme.palette.text.primary,
    flex: 1,
    minWidth: 0,
    lineHeight: 1.4,
    whiteSpace: 'pre-wrap',  // Allow text to wrap
    
    '@media (max-width: 520px)': {
      marginRight: theme.spacing(1),
      fontSize: '0.75rem',
    }
  },

  '& .toggle-helper-text': {
    fontSize: {
      xs: '0.688rem',
      sm: '0.75rem',
      md: '0.813rem'
    },
    color: alpha(theme.palette.text.secondary, 0.8),
    marginTop: theme.spacing(1.5),
    lineHeight: 1.4,
  },

  '@media (max-width: 560px)': {
    padding: theme.spacing(2),
    '& .toggle-container': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
      width: '100%',
    },
    '& .MuiSwitch-root': {
      marginLeft: theme.spacing(-1),
    },
  },

  '@media (max-width: 520px)': {
    padding: theme.spacing(2),
    
    '& .MuiSwitch-root': {
      marginTop: theme.spacing(1),
      marginLeft: -theme.spacing(1),
    }
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
  },
}));

const StyledRangeInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
    backdropFilter: 'blur(8px)',
    fontSize: {
      xs: '0.875rem',
      sm: '0.925rem',
      md: '1rem'
    },
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    width: '100%',
    
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
    
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.primary.main, 0.2),
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    
    '& .MuiInputAdornment-root': {
      marginRight: '0px', // Minimal space between $ and numbers
      paddingLeft: '12px',
      '& .MuiTypography-root': {
        fontSize: 'inherit',
        color: alpha(theme.palette.text.primary, 0.7),
      },
      position: 'absolute',
      left: 0,
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      pointerEvents: 'none',
    },

    '& .MuiInputBase-input': {
      paddingLeft: '22px', // Reduced left padding
      paddingRight: '8px',
      width: '100%',
      minWidth: 0,
    },

    '@media (max-width: 560px)': {
      '& .MuiInputBase-input': {
        padding: '10px 8px 10px 20px',
        fontSize: '0.875rem',
      },
      '& .MuiInputAdornment-root': {
        paddingLeft: '8px',
        '& .MuiTypography-root': {
          fontSize: '0.875rem',
        }
      }
    },

    '@media (max-width: 420px)': {
      fontSize: '0.875rem',
      
      '& .MuiInputBase-input': {
        padding: '8px 6px 8px 18px',
        fontSize: '0.875rem',
      },
      
      '& .MuiInputAdornment-root': {
        paddingLeft: '6px',
        '& .MuiTypography-root': {
          fontSize: '0.875rem',
        }
      }
    }
  },

  '& .MuiInputLabel-root': {
    fontSize: {
      xs: '0.875rem',
      sm: '0.925rem',
      md: '1rem'
    },
    color: alpha(theme.palette.text.primary, 0.7),

    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
}));

const RangeInputsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.3)}, ${alpha(theme.palette.background.default, 0.4)})`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',

  '&:hover': {
    background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.4)}, ${alpha(theme.palette.background.default, 0.5)})`,
  },

  // Changed breakpoint from 560px to 700px
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },

  '@media (max-width: 420px)': {
    padding: theme.spacing(1.5),
    gap: theme.spacing(1.5),
  },

  '@media (max-width: 360px)': {
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  }
}));

// NumberFormat Custom Component
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      allowNegative={false}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
    />
  );
});

const RequestForm = ({ showToast, isEditing = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [requestType, setRequestType] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [documents, setDocuments] = useState([]);
  const [subcategory, setSubcategory] = useState('');
  const [deadline, setDeadline] = useState(null);
  const [noDeadline, setNoDeadline] = useState(false);
  const [isFlexible, setIsFlexible] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createdRequestId, setCreatedRequestId] = useState(null);
  const [requestVersion, setRequestVersion] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [bounty, setBounty] = useState('');
  const [salaryMin, setSalaryMin] = useState('');
  const [salaryMax, setSalaryMax] = useState('');
  const [budgetMin, setBudgetMin] = useState('');
  const [budgetMax, setBudgetMax] = useState('');
  const [showWalletSetup, setShowWalletSetup] = useState(false);
  const { user } = useAuth();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const previousRequestType = useRef(null);
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setDocuments(prevDocuments => [...prevDocuments, ...acceptedFiles]);
      
      rejectedFiles.forEach(file => {
        if (file.size > MAX_FILE_SIZE) {
          showToast(`File ${file.file.name} is too large. Maximum size is 5MB.`, 'error');
        } else {
          showToast(`File ${file.file.name} is not an allowed file type.`, 'error');
        }
      });
    },
  });

  const {
    isPaymentProcessing,
    handleProcessPayment,
    PaymentUI
  } = usePaymentProcessing(showToast);

  useEffect(() => {
    if (isEditing) {
      fetchRequest();
    }
  }, [isEditing, id]);

  const fetchRequest = async () => {
    try {
      setIsLoading(true);
      const response = await getRequest(id);
      const request = response.data;
      setTitle(request.title);
      setDescription(request.description);
      setBounty(request.bounty.toString());
      setRequestType(request.category);
      setSubcategory(request.subcategory);
      setDeadline(request.deadline ? new Date(request.deadline) : null);
      setNoDeadline(!request.deadline);
      if (request.category === 'job') {
        const salaryRange = request.salaryRange || {};
        setSalaryMin(salaryRange.min?.toString() || '');
        setSalaryMax(salaryRange.max?.toString() || '');
        setIsFlexible(salaryRange.isFlexible || false);
      } else if (request.category === 'project') {
        const budgetRange = request.budget || {};
        setBudgetMin(budgetRange.min?.toString() || '');
        setBudgetMax(budgetRange.max?.toString() || '');
        setIsFlexible(budgetRange.isFlexible || false);
      }
      if (request.documents && Array.isArray(request.documents)) {
        setDocuments(request.documents);
      } else if (request.document) {
        setDocuments([request.document]);
      } else {
        setDocuments([]);
      }
      setRequestVersion(request.version);
    } catch (error) {
      showToast('Failed to fetch request data: ' + error.message, 'error');
      navigate('/my-requests');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEditing && requestType) {
      if (previousRequestType.current !== requestType) {
        console.log(`Request type changed from ${previousRequestType.current} to ${requestType}`);
        
        const newTypeCategories = requestType === 'recommendation' ? 
          subcategoryGroups.recommendations :
          subcategoryGroups.jobs_projects;
        
        const subcategoryExists = Object.values(newTypeCategories).some(
          group => Object.keys(group.subcategories).includes(subcategory)
        );
        
        if (!subcategoryExists) {
          setSubcategory('');
        }

        if (previousRequestType.current === 'job') {
          setSalaryMin('');
          setSalaryMax('');
        }

        if (previousRequestType.current === 'project') {
          setBudgetMin('');
          setBudgetMax('');
        }

        setIsFlexible(false);

        if (deadline) {
          if (requestType === 'job') {
            setDeadline(previousRequestType.current === 'project' ? deadline : null);
          } else if (requestType === 'project') {
            setDeadline(previousRequestType.current === 'job' ? deadline : null);
          } else if (requestType === 'recommendation') {
            setDeadline(null);
          }
        }
        
        previousRequestType.current = requestType;
      }
    }
  }, [requestType, isEditing, subcategory]);

  const handleInputChange = (field, value) => {
    if (['title', 'description', 'bounty'].includes(field)) {
      setIsEdited(true);
    }
    switch (field) {
      case 'title':
        setTitle(value);
        break;
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const handleRequestTypeChange = (newType) => {
    if (newType !== requestType) {
      if (isEditing && isEdited) {
        const confirmChange = window.confirm(
          'Changing the request type may clear some fields. Are you sure you want to continue?'
        );
        if (!confirmChange) {
          return;
        }
      }
      
      setRequestType(newType);
      
      if (step > 1) {
        setStep(1);
      }
    }
  };

  const canProceedFromSubcategory = () => {
    if (!subcategory) {
      showToast('Please select a subcategory', 'error');
      return false;
    }
  
    const currentCategories = requestType === 'recommendation' ? 
      subcategoryGroups.recommendations :
      subcategoryGroups.jobs_projects;
  
    const isValid = Object.values(currentCategories).some(
      group => Object.keys(group.subcategories).includes(subcategory)
    );
  
    if (!isValid) {
      showToast('Please select a valid subcategory for this request type', 'error');
      setSubcategory('');
      return false;
    }
  
    return true;
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const validateBudgetRange = () => {
    if (requestType === 'project' && !isFlexible) {
      if (budgetMin && budgetMax && Number(budgetMax) < Number(budgetMin)) {
        return false;
      }
    }
    return true;
  };

  const validateSalaryRange = () => {
    if (requestType === 'job' && !isFlexible) {
      if (salaryMin && salaryMax && Number(salaryMax) < Number(salaryMin)) {
        return false;
      }
    }
    return true;
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
    setIsEdited(true);
  };

  const handleNotificationClose = useCallback(() => {
    setShowNotificationModal(false);
    if (createdRequestId) {
      navigate(`/request/${createdRequestId}`);
    } else {
      navigate('/dashboard');
    }
  }, [createdRequestId, navigate]);

  const handleRemoveDocument = (index) => {
    const documentToRemove = documents[index];
    
    // If it's an existing document (has filename property), add to deletedDocuments
    if (documentToRemove.filename) {
      setDeletedDocuments(prevDocs => [...prevDocs, documentToRemove.filename]);
    }
    
    setDocuments(prevDocuments => prevDocuments.filter((_, i) => i !== index));
    setIsEdited(true);
  };

  const renderDocumentList = () => (
    <List>
      {documents.map((doc, index) => {
        // Handle both File objects and stored document objects
        const fileName = doc.originalname || doc.name || 'Unknown file';
        const fileSize = doc.size ? 
          `${(doc.size / 1024 / 1024).toFixed(2)} MB` : 
          '';
        
        return (
          <ListItem key={index} sx={{ overflow: 'hidden' }}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '1rem',
                    },
                  }}
                >
                  {fileName}
                </Typography>
              }
              secondary={fileSize && `Size: ${fileSize}`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDocument(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );

  const getCategoryTerm = (category) => {
    switch (category) {
      case 'job':
        return 'application';
      case 'project':
        return 'bid';
      case 'recommendation':
        return 'recommendation';
      default:
        return 'bid';
    }
  };

  const getCategorySpecificLabel = (category) => {
    switch (category) {
      case 'job':
        return 'Job Details';
      case 'project':
        return 'Project Details';
      case 'recommendation':
        return 'Recommendation Request Details';
      default:
        return 'Request Details';
    }
  };
  
  const getCategorySpecificTooltip = (category) => {
    switch (category) {
      case 'job':
        return "Provide a detailed description of the job opening. Include responsibilities, qualifications, and any other relevant information to help potential applicants understand the role.";
      case 'project':
        return "Describe your project in detail. Include project scope, requirements, timeline, and any other relevant information to help potential bidders understand the project needs.";
      case 'recommendation':
        return "Describe your recommendation request in detail. Specify exactly what you are requesting recommendations on. Include any preferences, constraints, or specific areas you want the recommenders to focus on.";
      default:
        return "Provide a detailed description of your request. Include all relevant information to help others understand and respond to your request effectively. The more details, the better.";
    }
  };

  const getRequestDescriptionGuide = (category) => {
    switch (category) {
      case 'job':
        return {
          title: 'Job Description',
          mainDescription: 'Provide a comprehensive description of the role, including, for example:',
          keyPoints: [
            'Role overview and primary responsibilities',
            'Required qualifications and experience',
            'Desired skills and competencies',
            'Team structure and reporting relationships',
            'Growth opportunities and career development',
            'Company culture and work environment',
            'Benefits and perks (optional)',
            'Location/remote work details'
          ],
          documentSuggestions: [
            'Detailed job description document',
            'Organization chart or team structure',
            'Company overview or culture deck',
            'Benefits package details',
            'Required qualifications checklist'
          ],
          tooltip: 'A detailed job description helps attract qualified candidates and enables the community to make informed vouches.'
        };
      case 'project':
        return {
          title: 'Project Scope',
          mainDescription: 'Detail your project requirements, including, for example:',
          keyPoints: [
            'Project background and objectives',
            'Detailed scope of work',
            'Technical requirements and specifications',
            'Expected deliverables and milestones',
            'Timeline and key deadlines',
            'Required technical expertise or skills',
            'Project constraints or limitations',
            'Success criteria and acceptance metrics'
          ],
          documentSuggestions: [
            'Project brief or specification document',
            'Technical requirements documentation',
            'Design assets or mockups',
            'Project timeline or Gantt chart',
            'Reference materials or examples'
          ],
          tooltip: 'Comprehensive project details help attract qualified bidders and enable informed vouching from the community.'
        };
      case 'recommendation':
        return {
          title: 'Recommendation Request',
          mainDescription: 'Provide details as to exactly what you are requesting recommendations on, including, for example:',
          keyPoints: [
            'Exact focus of the recommendation request',
            'The context of the recommendation request',
            'Specific aspects you want recommenders to evaluate',
            'Any particular concerns or priorities',
            'Details regarding the subject matter',
            'Any solutions you have already considered',
            'Constraints or requirements that must be met',
            'How you plan to use these recommendations'
          ],
          documentSuggestions: [
            'Current solution analysis or pain points',
            'Specific requirements or constraints',
            'Background research you have done',
            'Previous experiences or attempts',
            'Relevant context documents'
          ],
          tooltip: 'Detailed context helps experts provide targeted, actionable recommendations that meet your specific needs.'
        };
      default:
        return {
          title: 'Request Details',
          mainDescription: 'Provide complete details about your request:',
          keyPoints: [
            'Background and context',
            'Specific requirements',
            'Expected outcomes',
            'Timeline and deadlines',
            'Budget considerations',
            'Success criteria'
          ],
          documentSuggestions: [
            'Requirements documentation',
            'Supporting materials',
            'Reference documents'
          ],
          tooltip: 'Detailed information helps ensure high-quality responses and informed vouching.'
        };
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    setIsSubmitting(true); 
    
    // Check if there are Word documents that will need conversion
    const hasWordDocs = documents.some(doc => 
      doc instanceof File && (
        doc.type === 'application/msword' || 
        doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      )
    );
  
    if (hasWordDocs) {
      showToast('Converting Word documents to PDF...', 'info', null, 'document-conversion');
    }
  
    try {
      // All existing validations
      if (!title) {
        throw new Error('Title is required');
      }
      if (!description) {
        throw new Error('Description is required');
      }
      const bountyValue = Number(bounty);
      if (bountyValue < MIN_BOUNTY_AMOUNT) {
        throw new Error(`Bounty must be at least ${MIN_BOUNTY_AMOUNT} USDC`);
      }
      if (!noDeadline && !deadline) {
        throw new Error(`Please select a deadline or check "No specific deadline"`);
      }
      if (!requestType || !subcategory) {
        throw new Error('Please select a request type and subcategory');
      }
      if (requestType === 'job' && !isFlexible && (!salaryMin || !salaryMax)) {
        throw new Error('Please enter a salary range or select "Flexible / Undetermined"');
      }
      if (requestType === 'project' && !isFlexible && (!budgetMin || !budgetMax)) {
        throw new Error('Please enter a budget range or select "Flexible / Undetermined"');
      }
  
      if (requestType === 'job' && !isFlexible) {
        if (!validateSalaryRange()) {
          throw new Error('Maximum salary must be greater than or equal to the minimum salary');
        }
      }
  
      if (requestType === 'project' && !isFlexible) {
        if (!validateBudgetRange()) {
          throw new Error('Maximum budget must be greater than or equal to the minimum budget');
        }
      }
  
      const currentDate = new Date();
      const sevenDaysFromNow = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
      if (!noDeadline && deadline < sevenDaysFromNow) {
        throw new Error(`The deadline must be at least 7 days from now`);
      }
  
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('bounty', bountyValue);
      formData.append('category', requestType);
      formData.append('subcategory', subcategory);
  
      // Enhanced document handling
      if (isEditing) {
        // Handle existing documents that should be kept
        const existingDocs = documents
          .filter(doc => doc.filename)
          .map(doc => ({
            filename: doc.filename,
            originalname: doc.originalname || doc.filename,
            path: doc.path,
            mimetype: doc.mimetype,
            size: doc.size
          }));
        if (existingDocs.length > 0) {
          formData.append('existingDocuments', JSON.stringify(existingDocs));
        }
      
        // Only append deletedDocuments if the array exists and has items
        if (deletedDocuments && deletedDocuments.length > 0) {
          formData.append('deletedDocuments', JSON.stringify(deletedDocuments));
        }
      }
  
      // Append new documents
      const newDocs = documents.filter(doc => doc instanceof File);
      newDocs.forEach((doc) => {
        formData.append('documents', doc);
      });
  
      // Rest of the form data
      if (requestType === 'job') {
        if (!noDeadline) {
          formData.append('applicationDeadline', deadline.toISOString());
        }
        formData.append('salaryRange', JSON.stringify({
          min: isFlexible ? null : Number(salaryMin),
          max: isFlexible ? null : Number(salaryMax),
          isFlexible
        }));
      } else if (requestType === 'project') {
        if (!noDeadline) {
          formData.append('bidDeadline', deadline.toISOString());
        }
        formData.append('budget', JSON.stringify({
          min: isFlexible ? null : Number(budgetMin),
          max: isFlexible ? null : Number(budgetMax),
          isFlexible
        }));
      } else if (requestType === 'recommendation') {
        if (!noDeadline) {
          formData.append('recommendationDeadline', deadline.toISOString());
        }
      }
  
      let response;
      if (isEditing) {
        response = await updateRequest(id, formData);
      } else {
        response = await createRequest(formData);
      }
  
      if (response && response.data) {
        const savedRequest = response.data;
        console.log('Saved request:', savedRequest);
  
        const newRequestId = savedRequest._id || savedRequest.request?._id;
        const newRequestVersion = savedRequest.version || savedRequest.request?.version;
  
        if (!newRequestId || newRequestVersion === undefined) {
          throw new Error('Request ID or version missing in server response');
        }
  
        setCreatedRequestId(newRequestId);
        setRequestVersion(newRequestVersion);
  
        showToast(`Request ${isEditing ? 'updated' : 'created'} successfully`, 'success');
  
        try {
          await handleProcessPayment(newRequestId, newRequestVersion, {
            title,
            category: requestType, 
            bounty
          });
        } catch (error) {
          console.error('Error initiating payment:', error);
          navigate(`/request/${newRequestId}`);
        }
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error(`Error ${isEditing ? 'updating' : 'creating'} request:`, err);
      const errorMessage = err.message || handleApiError(err);
      setError(errorMessage);
      showToast(`Failed to ${isEditing ? 'update' : 'create'} request: ` + errorMessage, 'error');
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
      // Clear the document conversion toast if it exists
      if (hasWordDocs) {
        showToast(null, null, 0, 'document-conversion');
      }
    }
  };

  const getSubcategoryOptions = (category) => {
    if (category === 'recommendation') {
      return [
        {
          group: 'Crypto & Web3',
          options: [
            { value: 'defi_protocols', label: 'DeFi Protocols' },
            { value: 'nft_projects', label: 'NFT Projects' },
            { value: 'dao_governance', label: 'DAO Governance' },
            { value: 'crypto_trading', label: 'Crypto Trading' },
            { value: 'web3_infrastructure', label: 'Web3 Infrastructure' },
            { value: 'wallet_security', label: 'Wallet Security' }
          ]
        },
        {
          group: 'Technology & Innovation',
          options: [
            { value: 'ai_tools', label: 'AI Tools' },
            { value: 'dev_tools', label: 'Dev Tools' },
            { value: 'saas_products', label: 'SaaS Products' },
            { value: 'hardware_tech', label: 'Hardware Tech' },
            { value: 'cybersecurity', label: 'Cybersecurity' },
            { value: 'productivity_apps', label: 'Productivity Apps' }
          ]
        },
        {
          group: 'Investment & Business',
          options: [
            { value: 'startups', label: 'Startups' },
            { value: 'angel_investing', label: 'Angel Investing' },
            { value: 'venture_capital', label: 'Venture Capital' },
            { value: 'market_opportunities', label: 'Market Opportunities' },
            { value: 'real_estate', label: 'Real Estate' }
          ]
        },
        {
          group: 'Professional Services',
          options: [
            { value: 'wealth_management', label: 'Wealth Management' },
            { value: 'legal_services', label: 'Legal Services' },
            { value: 'tax_strategy', label: 'Tax Strategy' },
            { value: 'business_consulting', label: 'Business Consulting' },
            { value: 'private_banking', label: 'Private Banking' }
          ]
        },
        {
          group: 'Lifestyle & Premium',
          options: [
            { value: 'luxury_travel', label: 'Luxury Travel' },
            { value: 'fine_dining', label: 'Fine Dining' },
            { value: 'high_end_properties', label: 'High-End Properties' },
            { value: 'luxury_vehicles', label: 'Luxury Vehicles' },
            { value: 'wellness_fitness', label: 'Wellness & Fitness' }
          ]
        },
        {
          group: 'Education & Networking',
          options: [
            { value: 'education_courses', label: 'Education Courses' },
            { value: 'networking_events', label: 'Networking Events' },
            { value: 'exclusive_memberships', label: 'Exclusive Memberships' }
          ]
        },
        {
          group: 'Other',
          options: [
            { value: 'miscellaneous', label: 'Other Recommendations' }
          ]
        }
      ];
    }
  
    return [
      {
        group: 'Blockchain & Web3',
        options: [
          { value: 'blockchain_web3', label: 'Blockchain & Web3' },
          { value: 'smart_contracts', label: 'Smart Contracts' },
          { value: 'defi_dev', label: 'DeFi Development' },
          { value: 'crypto_launch', label: 'Cryptocurrency & Token Projects' },
          { value: 'nft_project_dev', label: 'NFT Project Development' },
          { value: 'dao_development', label: 'DAO Development' },
          { value: 'web3_infrastructure', label: 'Web3 Infrastructure' },
          { value: 'crypto_operations', label: 'Crypto Operations & Trading' }
        ]
      },
      {
        group: 'AI & Machine Learning',
        options: [
          { value: 'ai_ml', label: 'AI & Machine Learning' },
          { value: 'nlp_applications', label: 'NLP & Language AI' },
          { value: 'computer_vision', label: 'Computer Vision' },
          { value: 'llm_development', label: 'LLM Development' },
          { value: 'ml_ops', label: 'ML Operations & Infrastructure' }
        ]
      },
      {
        group: 'Web & Mobile',
        options: [
          { value: 'web_mobile_dev', label: 'Web & Mobile Development' },
          { value: 'systems_infrastructure', label: 'Systems & Infrastructure' },
          { value: 'data_engineering', label: 'Data Engineering' },
          { value: 'security_engineering', label: 'Security Engineering' },
          { value: 'cloud_architecture', label: 'Cloud Architecture' }
        ]
      },
      {
        group: 'Design & Creative',
        options: [
          { value: 'ui_ux_design', label: 'UI/UX Design' },
          { value: 'brand_graphic_design', label: 'Brand & Graphic Design' },
          { value: 'motion_3d_design', label: 'Motion & 3D Design' },
          { value: 'content_creation', label: 'Content Creation' }
        ]
      },
      {
        group: 'Marketing & Community',
        options: [
          { value: 'social_media_community', label: 'Social Media & Community' },
          { value: 'content_strategy', label: 'Content Strategy' },
          { value: 'growth_marketing', label: 'Growth Marketing' },
          { value: 'analytics_seo', label: 'Analytics & SEO' }
        ]
      },
      {
        group: 'Business & Operations',
        options: [
          { value: 'strategy_consulting', label: 'Strategy Consulting' },
          { value: 'operations_management', label: 'Operations Management' },
          { value: 'finance_legal', label: 'Finance & Legal' },
          { value: 'product_management', label: 'Product Management' },
          { value: 'market_research', label: 'Market Research' },
          { value: 'data_analysis', label: 'Data Analysis' }
        ]
      },
      {
        group: 'Professional Services',
        options: [
          { value: 'education_training', label: 'Education & Training' },
          { value: 'hr_recruitment', label: 'HR & Recruitment' },
          { value: 'advisory_mentorship', label: 'Advisory & Mentorship' }
        ]
      },
      {
        group: 'Other',
        options: [
          { value: 'other_professional', label: 'Other Professional Services' }
        ]
      }
    ];
  };
  
  const categoryInfo = {
    recommendation: 
      "Access premium, validated expertise through incentivized recommendations about anything you would like a recommendation on. Industry experts provide detailed insights while the community validates their credibility through USDC-backed vouching. Successful recommendations earn both the expert recommender and their vouchers a share of the bounty, ensuring aligned interests in delivering actionable insights.",
    
    project:
      "Source exceptional talent with validated proposals for your project. Our bounty system incentivizes the community to verify both the capabilities of service providers and the quality of their specific bids, including proposed scope and pricing. While you handle project payments directly, our platform ensures you find the right provider with incentivized high-quality bids through USDC-backed community validation.",
    
    job:
      "Find and validate outstanding job candidates through community expertise. USDC bounties motivate industry insiders to vouch for exceptional talent, providing deeper insights into candidates' capabilities and fit. While employment terms are handled directly, our platform helps surface the most promising applicants through trusted, incentive-aligned validation."
  };

  const renderCategoryCards = () => (
    <Grid container spacing={3}>
      {['recommendation', 'project', 'job'].map((type) => (
        <Grid item xs={12} key={type}>
          <CategoryCard 
            elevation={0} 
            onClick={() => handleRequestTypeChange(type)}
            selected={requestType === type}
          >
            <CategoryCardInner selected={requestType === type}>
              <CategoryIconWrapper>
                {type === 'recommendation' && <RecommendIcon />}
                {type === 'project' && <CategoryIcon />}
                {type === 'job' && <WorkIcon />}
              </CategoryIconWrapper>
              <CategoryTitle component="h3">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </CategoryTitle>
              <CategoryDescription>
                {categoryInfo[type]}
              </CategoryDescription>
            </CategoryCardInner>
          </CategoryCard>
        </Grid>
      ))}
    </Grid>
  );

  const steps = [
    {
      label: 'Request Type',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ position: 'relative', zIndex: 1, mb: 2 }}>
            <LabelWithTooltip 
              label="Select Request Type:" 
              tooltip="Choose the type of request you're creating. This determines the structure and purpose of your request."
            />
          </Box>
          <Box sx={{ position: 'relative', zIndex: 0 }}>
            {renderCategoryCards()}
          </Box>
        </motion.div>
      ),
    },
    {
      label: 'Subcategory',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Select a Subcategory
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
              Choose the specific area that best matches your {requestType} request.
            </Typography>
            <SubcategorySelector
              category={requestType}
              value={subcategory}
              onChange={setSubcategory}
              subcategories={requestType ? subcategoryGroups[requestType === 'recommendation' ? 'recommendations' : 'jobs_projects'] : null}
            />
          </Box>
        </motion.div>
      ),
    },
    {
      label: 'Title',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ 
            mb: { xs: 3, sm: 4, md: 5 }, 
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ 
                fontWeight: 600,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.5rem',
                  md: '1.75rem'
                },
                mb: { xs: 1, sm: 2 }
              }}
            >
              Give Your {requestType.charAt(0).toUpperCase() + requestType.slice(1)} Request a Title
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary" 
              sx={{ 
                mb: { xs: 3, sm: 4 },
                fontSize: {
                  xs: '0.875rem',
                  sm: '1rem',
                  md: '1.1rem'
                },
                maxWidth: '600px',
                mx: 'auto',
                lineHeight: 1.6
              }}
            >
              Create a clear, descriptive title that will help others understand your request at a glance.
            </Typography>
          </Box>
          <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
            <StyledTextField
              label="Title"
              variant="outlined"
              fullWidth
              required
              value={title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  fontSize: {
                    xs: '1rem',
                    sm: '1.125rem',
                    md: '1.25rem'
                  },
                  padding: {
                    xs: '12px',
                    sm: '14px',
                    md: '16px'
                  }
                },
                '& .MuiFormLabel-root': {
                  fontSize: {
                    xs: '0.875rem',
                    sm: '1rem',
                    md: '1.125rem'
                  }
                }
              }}
              placeholder={`e.g., "${
                requestType === 'recommendation' ? 'Seeking DeFi Protocol Recommendations for Institutional Integration' :
                requestType === 'project' ? 'NFT Marketplace Development Project with Smart Contract Integration' :
                'Senior Blockchain Developer Position at Growing DeFi Protocol'
              }"`}
            />
          </Box>
        </motion.div>
      ),
    },
    {
      label: 'Description',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 3 }}>
            <GuideSection 
              title={getRequestDescriptionGuide(requestType).title}
              mainDescription={getRequestDescriptionGuide(requestType).mainDescription}
              points={getRequestDescriptionGuide(requestType).keyPoints}
              documentSuggestions={null}
            />

            <CustomQuill
              value={description}
              onChange={handleDescriptionChange}
              theme="snow"
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link'],
                  ['clean']
                ],
              }}
              formats={[
                'header',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link'
              ]}
              placeholder="Provide a detailed description of your request here..."
            />
          </Box>
        </motion.div>
      ),
    },
    {
      label: 'Supporting Documents',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 3 }}>
            <GuideSection 
              title="Supporting Documents"
              mainDescription="Attach supporting documents to help provide additional context and details for your request."
              points={[]}
              documentSuggestions={getRequestDescriptionGuide(requestType).documentSuggestions}
            />

            {documents.length > 0 && (
              <Box 
                sx={{ 
                  mb: 2,
                  p: 2,
                  backgroundColor: theme => alpha(theme.palette.background.paper, 0.6),
                  backdropFilter: 'blur(10px)',
                  borderRadius: 2,
                  border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    color: 'primary.main',
                    mb: 2
                  }}
                >
                  Attached Documents:
                </Typography>
                {renderDocumentList()}
              </Box>
            )}

            <DragDropZone 
              {...getRootProps()}
              sx={{
                border: theme => `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
                borderRadius: 2,
                transition: 'all 0.2s ease-in-out',
                background: theme => `linear-gradient(135deg, 
                  ${alpha(theme.palette.background.paper, 0.6)} 0%,
                  ${alpha(theme.palette.background.paper, 0.4)} 100%)`,
                backdropFilter: 'blur(10px)',
                '&:hover': {
                  borderColor: 'primary.main',
                  transform: 'translateY(-2px)',
                  boxShadow: theme => `0 8px 24px ${alpha(theme.palette.common.black, 0.15)}`,
                  background: theme => alpha(theme.palette.primary.main, 0.05)
                }
              }}
            >
              <input {...getInputProps()} accept=".pdf,.doc,.docx" />
              <Box sx={{ 
                py: 3, 
                textAlign: 'center',
                px: 2
              }}>
                <Typography 
                  variant="body1" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 500,
                    color: 'text.primary'
                  }}
                >
                  Drag and drop files here
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    mb: 1
                  }}
                >
                  or click to browse
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    display: 'block', 
                    mt: 1,
                    color: 'text.secondary',
                    fontSize: '0.75rem'
                  }}
                >
                  Accepted formats: PDF, Word documents (max 5MB each)
                </Typography>
              </Box>
            </DragDropZone>
          </Box>
        </motion.div>
      ),
    },
    {
      label: 'Timeline & Requirements',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <DetailsSection>
            <DeadlineSection>
              <Box className="deadline-header">
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'primary.main',
                    fontSize: { xs: '1.125rem', sm: '1.25rem' },
                    fontWeight: 600,
                    mb: 1,
                    lineHeight: 1.3,
                    letterSpacing: '0.015em',
                  }}
                >
                  Set Request Timeline
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: { xs: '0.813rem', sm: '0.875rem' },
                    lineHeight: 1.5,
                    letterSpacing: '0.01em',
                  }}
                >
                  Choose when you'd like to receive {
                    requestType === 'job' ? 'applications' :
                    requestType === 'project' ? 'bids' :
                    'recommendations'
                  }
                </Typography>
              </Box>
              
              <Box className="deadline-picker">
                <DatePicker
                  label={
                    requestType === 'job' 
                      ? "Application Deadline" 
                      : requestType === 'project' 
                        ? "Bid Deadline" 
                        : "Recommendation Deadline"
                  }
                  value={deadline}
                  onChange={(newValue) => setDeadline(newValue)}
                  disabled={noDeadline}
                  minDate={new Date()}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "medium",
                      sx: {
                        '& .MuiInputBase-input': {
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          padding: '16.5px 14px',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                        },
                        '& .MuiOutlinedInput-root': {
                          background: theme => `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
                          backdropFilter: 'blur(8px)',
                          transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                          
                          '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: theme => `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                          },
                        },
                      }
                    }
                  }}
                />
              </Box>

              <Box className="deadline-toggle">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noDeadline}
                      onChange={(e) => {
                        setNoDeadline(e.target.checked);
                        if (e.target.checked) {
                          setDeadline(null);
                        }
                      }}
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      color: 'text.primary',
                      fontWeight: 500,
                      lineHeight: 1.5,
                      letterSpacing: '0.01em',
                    }}>
                      No specific deadline
                    </Typography>
                  }
                  sx={{
                    margin: 0,
                    userSelect: 'none',
                  }}
                />
                <InfoTooltip 
                  title={
                    <Typography sx={{ 
                      fontSize: '0.75rem', 
                      p: 0.5,
                      lineHeight: 1.5 
                    }}>
                      {`If checked, the request will remain open until you select a winning ${getCategoryTerm(requestType)}, with no set deadline for submissions.`}
                    </Typography>
                  }
                >
                  <HelpOutlineIcon 
                    sx={{ 
                      color: 'primary.main',
                      opacity: 0.8,
                      cursor: 'help',
                      fontSize: { xs: '1.125rem', sm: '1.25rem' },
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        opacity: 1,
                        transform: 'scale(1.1)',
                      }
                    }} 
                  />
                </InfoTooltip>
              </Box>
            </DeadlineSection>

            {requestType === 'job' && (
              <RangeSection>
                <Box className="range-header">
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: 'primary.main',
                      fontSize: { xs: '1.125rem', sm: '1.25rem' },
                      fontWeight: 600,
                      mb: 1,
                      lineHeight: 1.3,
                      letterSpacing: '0.015em',
                    }}
                  >
                    Salary Range
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: { xs: '0.813rem', sm: '0.875rem' },
                      lineHeight: 1.5,
                      letterSpacing: '0.01em',
                    }}
                  >
                    Set the expected salary range for this position
                  </Typography>
                </Box>

                <FlexibleToggle>
                  <Box className="toggle-container">
                    <Typography className="toggle-label">
                      Flexible / Undetermined
                    </Typography>
                    <Switch
                      checked={isFlexible}
                      onChange={(e) => setIsFlexible(e.target.checked)}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'primary.main',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          },
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: 'primary.main',
                        },
                      }}
                    />
                  </Box>
                  <Typography className="toggle-helper-text">
                    Toggle on if the salary is negotiable or will be determined later
                  </Typography>
                </FlexibleToggle>

                <Collapse in={!isFlexible} timeout={300}>
                  <RangeInputsContainer>
                    <StyledRangeInput
                      label="Minimum Salary"
                      value={salaryMin}
                      onChange={(e) => setSalaryMin(e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputComponent: NumberFormatCustom,
                      }}
                      size="medium"
                    />
                    <StyledRangeInput
                      label="Maximum Salary"
                      value={salaryMax}
                      onChange={(e) => setSalaryMax(e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputComponent: NumberFormatCustom,
                      }}
                      size="medium"
                    />
                  </RangeInputsContainer>
                </Collapse>
              </RangeSection>
            )}

            {requestType === 'project' && (
              <RangeSection>
                <Box className="range-header">
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: 'primary.main',
                      fontSize: { xs: '1.125rem', sm: '1.25rem' },
                      fontWeight: 600,
                      mb: 1,
                      lineHeight: 1.3,
                      letterSpacing: '0.015em',
                    }}
                  >
                    Project Budget
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: { xs: '0.813rem', sm: '0.875rem' },
                      lineHeight: 1.5,
                      letterSpacing: '0.01em',
                    }}
                  >
                    Set the expected budget range for this project
                  </Typography>
                </Box>

                <FlexibleToggle>
                  <Box className="toggle-container">
                    <Typography className="toggle-label">
                      Flexible / Undetermined
                    </Typography>
                    <Switch
                      checked={isFlexible}
                      onChange={(e) => setIsFlexible(e.target.checked)}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'primary.main',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          },
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: 'primary.main',
                        },
                      }}
                    />
                  </Box>
                  <Typography className="toggle-helper-text">
                    Toggle on if the budget is negotiable or will be determined based on proposals
                  </Typography>
                </FlexibleToggle>

                <Collapse in={!isFlexible} timeout={300}>
                  <RangeInputsContainer>
                    <StyledRangeInput
                      label="Minimum Budget"
                      value={budgetMin}
                      onChange={(e) => setBudgetMin(e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputComponent: NumberFormatCustom,
                      }}
                      size="medium"
                    />
                    <StyledRangeInput
                      label="Maximum Budget"
                      value={budgetMax}
                      onChange={(e) => setBudgetMax(e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputComponent: NumberFormatCustom,
                      }}
                      size="medium"
                    />
                  </RangeInputsContainer>
                </Collapse>
              </RangeSection>
            )}
          </DetailsSection>
        </motion.div>
      ),
    },
    {
      label: 'Payment',
      content: (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ maxWidth: 800, mx: 'auto' }}>
            <BountySelector 
              value={Number(bounty) || 0}
              onChange={(value) => setBounty(value.toString())}
              minAmount={MIN_BOUNTY_AMOUNT}
              requestType={requestType}
            />
          </Box>
        </motion.div>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 700, color: 'text.primary' }}>
          {isEditing ? 'Edit Request' : 'Create New Request'}
        </Typography>
        <StyledPaper elevation={3}>
         <StyledStepper activeStep={step} orientation="vertical">
            {steps.map((stepItem, index) => (
              <Step key={stepItem.label}>
                <StepLabel>
                  <Typography variant="h6">{stepItem.label}</Typography>
                </StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    {stepItem.content}
                    <Box sx={{ 
                      mt: 2, 
                      display: 'flex', 
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: { xs: 2, sm: 0 },
                      justifyContent: 'space-between' 
                    }}>
                      <StyledButton
                        disabled={index === 0 || isLoading || isSubmitting}  // Add isLoading and isSubmitting here
                        onClick={handleBack}
                        variant="outlined"
                        color="primary"
                        sx={{ 
                          width: { xs: '100%', sm: 'auto' } 
                        }}
                      >
                        Back
                      </StyledButton>
                      <StyledButton
                        variant="contained"
                        onClick={async () => {
                          if (index === steps.length - 1) {
                            const savedRequest = await handleSubmit(new Event('submit'));
                            if (savedRequest) {
                              setCreatedRequestId(savedRequest._id);
                              setRequestVersion(savedRequest.version);
                            }
                          } else {
                            if (index === 1 && !canProceedFromSubcategory()) {
                              return;
                            }
                            handleNext();
                          }
                        }}
                        disabled={isLoading || isSubmitting}
                        color="secondary"
                        sx={{ 
                          width: { xs: '100%', sm: 'auto' } 
                        }}
                      >
                        {index === steps.length - 1 ? (
                          (isLoading || isSubmitting) ? (
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              gap: 1
                            }}>
                              <CircularProgress size={20} />
                              <Typography 
                                variant="body2"
                                component="span"
                              >
                                {documents.some(doc => 
                                  doc instanceof File && (
                                    doc.type === 'application/msword' || 
                                    doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                  )
                                ) ? 'Processing submission...' : 'Processing...'}
                              </Typography>
                            </Box>
                          ) : (
                            isEditing ? 'Update Request' : 'Create Request'
                          )
                        ) : (
                          'Continue'
                        )}
                      </StyledButton>
                    </Box>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </StyledStepper>
          <Box sx={{ mt: 2 }}>
            <LinearProgress variant="determinate" value={(step / (steps.length - 1)) * 100} />
          </Box>
        </StyledPaper>
        {error && (
          <Fade in={!!error}>
            <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
          </Fade>
        )}

        {(isLoading || isSubmitting) && (
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              mt: 2,
              mb: 2
            }}
          >
            <CircularProgress size={24} />
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                textAlign: 'center',
                animation: 'fade 1.5s ease-in-out infinite',
                '@keyframes fade': {
                  '0%, 100%': { opacity: 0.6 },
                  '50%': { opacity: 1 }
                }
              }}
            >
              {documents.some(doc => 
                doc instanceof File && (
                  doc.type === 'application/msword' || 
                  doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                )
              ) ? 'Submitting your request... This may take a moment while we convert your Word documents to PDF format.' : 'Processing your submission...'}
            </Typography>
          </Box>
        )}

        {/* Notification Modal */}
        <NotificationModal
          open={showNotificationModal}
          title="Request Status Update"
          message={`You have chosen not to pay the bounty to activate the request at this time.\n\nYou will be redirected to the request detail page, and the request will remain in draft status until you pay the bounty.`}
          duration={8000}
          onClose={handleNotificationClose}
        />

       {/* Payment UI */}
        <PaymentUI 
          request={{
            _id: createdRequestId,
            version: requestVersion,
            title: title,
            category: requestType, 
            bounty: bounty
          }}
          showCreationSuccess={true}
          isEditing={isEditing}
          isFormContext={true}
        />
        
        <WalletSetup 
          open={showWalletSetup} 
          onClose={() => setShowWalletSetup(false)}
          onComplete={() => setShowWalletSetup(false)}  // Just close the modal on completion
        />
      </Container>
    </ThemeProvider>
  );
};

export default RequestForm;