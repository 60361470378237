// frontend/src/components/HomeComponents.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  alpha, 
  useTheme 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

import RecommendIcon from '@mui/icons-material/Recommend';
import BuildIcon from '@mui/icons-material/Build';
import WorkIcon from '@mui/icons-material/Work';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CategoryCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(145deg, 
    ${alpha(theme.palette.background.paper, 0.8)}, 
    ${alpha(theme.palette.background.default, 0.9)})`,
  backdropFilter: 'blur(12px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: `radial-gradient(circle at center, 
      ${alpha(theme.palette.primary.main, 0)} 0%,
      ${alpha(theme.palette.primary.main, 0)} 50%,
      ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: `0 12px 40px ${alpha(theme.palette.primary.main, 0.2)}`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    '&::before': {
      opacity: 1,
    },
    '&::after': {
      opacity: 1,
    },
    '& .category-content': {
      transform: 'translateY(-4px)',
    },
    '& .category-icon': {
      transform: 'scale(1.1) rotate(5deg)',
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      boxShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.3)}`,
    },
    '& .category-title': {
      background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    '& .cta-button': {
      transform: 'translateY(0)',
      opacity: 1,
    }
  }
}));

const CategoryContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
  zIndex: 1,
  transition: 'transform 0.3s ease-in-out',
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  width: 84,
  height: 84,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  boxShadow: `0 0 0 ${alpha(theme.palette.primary.main, 0.2)}`,
  '& svg': {
    fontSize: 42,
    color: theme.palette.primary.main,
    transition: 'transform 0.3s ease-in-out',
    filter: `drop-shadow(0 2px 4px ${alpha(theme.palette.primary.main, 0.3)})`,
  }
}));

const CTAButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  textTransform: 'none',
  padding: theme.spacing(1.5, 3),
  backdropFilter: 'blur(8px)',
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.primary.main, 0.1)} 0%, 
    ${alpha(theme.palette.primary.main, 0.05)} 100%)`,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  transform: 'translateY(10px)',
  opacity: 0,
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  '& .MuiButton-endIcon': {
    marginLeft: theme.spacing(1),
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover': {
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.primary.main, 0.15)} 0%, 
      ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    '& .MuiButton-endIcon': {
      transform: 'translateX(4px)',
    },
  }
}));

const categories = [
  {
    title: 'Recommendations',
    icon: <RecommendIcon />,
    description: 'Get trusted insights from domain experts, backed by community validation.',
    ctaText: 'View Recommendation Example',
  },
  {
    title: 'Projects',
    icon: <BuildIcon />,
    description: 'Find validated talent and competitive bids for your projects through community vouching.',
    ctaText: 'View Project Example',
  },
  {
    title: 'Jobs',
    icon: <WorkIcon />,
    description: 'Source exceptional candidates with verified community endorsements.',
    ctaText: 'View Job Example',
  },
];

export const CategorySlider = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/about?category=${category.toLowerCase()}&section=examples`);
  };

  return (
    <Box sx={{ py: { xs: 8, md: 12 } }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          align="center"
          sx={{ 
            mb: 2,
            fontWeight: 700,
            fontSize: { xs: '2.25rem', sm: '2.75rem', md: '3rem' },
            background: `linear-gradient(135deg, ${theme.palette.text.primary} 0%, ${alpha(theme.palette.text.primary, 0.8)} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Request Categories
        </Typography>
        <Typography 
          variant="h5" 
          align="center"
          color="text.secondary"
          sx={{ 
            mb: { xs: 6, md: 8 },
            maxWidth: 800,
            mx: 'auto',
            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
            lineHeight: 1.6,
            opacity: 0.9,
          }}
        >
          Choose your request type and unlock the power of community-validated expertise
        </Typography>

        <Box 
          sx={{ 
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: 'repeat(3, 1fr)',
            },
            gap: 4,
          }}
        >
          {categories.map((category, index) => (
            <motion.div
              key={category.title}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <CategoryCard 
                onClick={() => handleCategoryClick(category.title)} 
                aria-label={`Explore ${category.title}`}
              >
                <CategoryContent className="category-content">
                  <CategoryIcon className="category-icon">
                    {category.icon}
                  </CategoryIcon>
                  <Typography 
                    variant="h5" 
                    className="category-title"
                    sx={{ 
                      fontWeight: 600,
                      mb: 2,
                      transition: 'all 0.3s ease-in-out',
                    }}
                  >
                    {category.title}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary"
                    sx={{ 
                      maxWidth: '90%',
                      mx: 'auto',
                      lineHeight: 1.6,
                      mb: 2,
                    }}
                  >
                    {category.description}
                  </Typography>
                  <CTAButton
                    className="cta-button"
                    endIcon={<ArrowForwardIcon />}
                  >
                    {category.ctaText}
                  </CTAButton>
                </CategoryContent>
              </CategoryCard>
            </motion.div>
          ))}
        </Box>
      </Container>
    </Box>
  );
};