// frontend/src/components/BidsList.js

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Box,
  Chip,
  List,
  ListItem,
  Divider,
  Button,
  Alert,
  Fade,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Grid,
  ListItemAvatar,
  Paper,
  alpha,
  LinearProgress,
  Collapse,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import FarcasterUsername from './FarcasterUsername';
import VouchForm from './VouchForm';
import DocumentViewer from './DocumentViewer';
import { styled, useTheme } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FarcasterAvatar from './FarcasterAvatar';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

// Update the StyledCard in BidsList.js
const StyledCard = styled(Card)(({ theme, isDraft, isSelected }) => ({
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  border: isDraft
    ? `2px dashed ${alpha(theme.palette.primary.main, 0.4)}`
    : `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  borderRadius: theme.shape.borderRadius * 2,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  backdropFilter: 'blur(8px)',

  // Enhanced background
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 1)} 0%,
    ${alpha(theme.palette.background.paper, 0.95)} 100%)`,

  // Add shadow and glow
  boxShadow: `
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)}
    ${isSelected ? `, 0 0 30px ${alpha(theme.palette.primary.main, 0.2)}` : ''}
  `,

  // Enhanced hover state
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)}
      ${isSelected ? `, 0 0 40px ${alpha(theme.palette.primary.main, 0.25)}` : ''}
    `,
  },

  // Add decorative elements
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: isDraft
      ? `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 0.3)}, ${alpha(
          theme.palette.primary.main,
          0.1
        )})`
      : `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(
          theme.palette.primary.light,
          0.3
        )})`,
    opacity: isDraft || isSelected ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  },

  ...(isSelected && {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: 'scale(1.01)',
  }),
}));

const VouchesSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backdropFilter: 'blur(8px)',
  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
    borderColor: alpha(theme.palette.primary.main, 0.15),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const EmptyVouchState = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.8)} 0%, 
    ${alpha(theme.palette.secondary.dark, 0.1)} 100%)`,
  borderRadius: theme.shape.borderRadius,
  border: `1px dashed ${alpha(theme.palette.secondary.main, 0.3)}`,
  boxShadow: `
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    0 0 20px ${alpha(theme.palette.secondary.main, 0.1)},
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)}
  `,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  width: '100%',
  boxSizing: 'border-box',
  '&:hover': {
    borderColor: alpha(theme.palette.secondary.main, 0.4),
    backgroundColor: alpha(theme.palette.secondary.light, 0.1),
    boxShadow: `
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.secondary.main, 0.15)},
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)}
    `,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const VouchItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.05)}`,
  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    transform: 'translateX(4px)',
    borderColor: alpha(theme.palette.primary.main, 0.1),
  },

  '& .vouch-content': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  '& .vouch-details': {
    flex: 1,
    minWidth: 0,
  },

  '& .vouch-amount-status': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginLeft: 'auto',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),

    '& .vouch-amount-status': {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: 0,
    },
  },
}));

const SelectWinnerButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  background: alpha(theme.palette.secondary.main, 0.03),
  color: theme.palette.secondary.main,
  padding: '14px 28px',
  fontWeight: 600,
  fontSize: '0.9375rem',
  letterSpacing: '0.03em',
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius * 1.5,
  border: `2px solid ${alpha(theme.palette.secondary.main, 0.8)}`,
  boxShadow: `
    0 0 20px ${alpha(theme.palette.secondary.main, 0.15)},
    0 0 0 1px ${alpha(theme.palette.secondary.main, 0.05)},
    inset 0 0 15px ${alpha(theme.palette.secondary.main, 0.05)}
  `,
  backdropFilter: 'blur(8px)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',

  // Subtle gradient overlay
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius * 1.5,
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.secondary.light, 0.1)},
      ${alpha(theme.palette.secondary.main, 0.05)}
    )`,
    transition: 'opacity 0.3s ease',
    opacity: 0,
  },

  // Animated border glow
  '&::after': {
    content: '""',
    position: 'absolute',
    top: -2,
    left: -2,
    right: -2,
    bottom: -2,
    borderRadius: theme.shape.borderRadius * 1.5,
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.secondary.light, 0.5)},
      ${alpha(theme.palette.secondary.main, 0.5)},
      ${alpha(theme.palette.secondary.dark, 0.5)}
    )`,
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    maskComposite: 'exclude',
    padding: 2,
    opacity: 0.5,
    transition: 'all 0.3s ease',
  },

  '&:hover': {
    background: alpha(theme.palette.secondary.main, 0.08),
    transform: 'translateY(-3px)',
    boxShadow: `
      0 0 30px ${alpha(theme.palette.secondary.main, 0.3)},
      0 0 0 1px ${alpha(theme.palette.secondary.main, 0.1)},
      inset 0 0 20px ${alpha(theme.palette.secondary.main, 0.08)}
    `,
    border: `2px solid ${theme.palette.secondary.main}`,

    '&::before': {
      opacity: 1,
    },

    '&::after': {
      opacity: 0.8,
      background: `linear-gradient(135deg, 
        ${alpha(theme.palette.secondary.light, 0.7)},
        ${alpha(theme.palette.secondary.main, 0.7)},
        ${alpha(theme.palette.secondary.dark, 0.7)}
      )`,
    }
  },

  '&:active': {
    transform: 'translateY(-1px)',
    boxShadow: `
      0 0 25px ${alpha(theme.palette.secondary.main, 0.25)},
      inset 0 0 10px ${alpha(theme.palette.secondary.main, 0.1)}
    `,
  },

  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1.5),
  },

  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    fontSize: '1.3rem',
  },

  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.15) rotate(360deg)',
    color: theme.palette.secondary.light,
  },

  // Responsive adjustments
  [theme.breakpoints.down('sm')]: {
    padding: '12px 24px',
    fontSize: '0.875rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  }
}));

const UpdateOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  borderRadius: theme.shape.borderRadius,
}));

const ProcessingAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,

  '& .MuiAlert-icon': {
    color: theme.palette.primary.main,
  },

  '& .MuiAlert-message': {
    width: '100%',
  },
}));

const RotatingIcon = styled(SyncIcon)(({ theme }) => ({
  animation: 'spin 2s linear infinite',
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}));

const ProgressIndicator = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(1),
  height: 4,
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const YourBidChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.info.main, 0.15),
  border: `1px solid ${theme.palette.info.main}`,
  color: theme.palette.info.main,
  fontWeight: 700,
  fontSize: '0.8rem',
  height: 24,
  boxShadow: `0 2px 4px ${alpha(theme.palette.info.main, 0.1)}`,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  '& .MuiChip-label': {
    padding: theme.spacing(0, 1.5),
    lineHeight: '1.5',
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.info.main, 0.2),
    transform: 'translateY(-1px)',
    boxShadow: `0 4px 8px ${alpha(theme.palette.info.main, 0.2)}`,
  },
}));

const BidStatusChip = styled(Chip)(({ theme, status }) => {
  const getColor = () => {
    switch (status) {
      case 'accepted':
        return theme.palette.success.main;
      case 'not_selected':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return {
    backgroundColor: alpha(getColor(), 0.1),
    border: `1px solid ${getColor()}`,
    color: getColor(),
    fontWeight: 600,
    fontSize: '0.75rem',
    height: '28px',
    minWidth: 'auto',
    boxShadow: `0 2px 4px ${alpha(getColor(), 0.1)}`,
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    '& .MuiChip-label': {
      padding: theme.spacing(0, 1.5),
      lineHeight: '1.5',
    },
    '&:hover': {
      backgroundColor: alpha(getColor(), 0.15),
      transform: 'translateY(-1px)',
      boxShadow: `0 4px 8px ${alpha(getColor(), 0.2)}`,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      height: '28px',
      '& .MuiChip-label': {
        padding: theme.spacing(0, 2),
      },
    },
  };
});

const VouchStatusChip = styled(Chip)(({ theme, status }) => {
  const getColor = () => {
    switch (status) {
      case 'completed':
      case 'paid':
        return theme.palette.success.main;
      case 'unpaid':
        return theme.palette.info.main;
      case 'pending':
        return theme.palette.warning.main;
      case 'refunded':
        return theme.palette.warning.main;
      case 'refund_failed':
      case 'failed':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return {
    backgroundColor: alpha(getColor(), 0.1),
    border: `1px solid ${getColor()}`,
    color: getColor(),
    fontWeight: 600,
    fontSize: '0.65rem',
    height: '20px',
    '& .MuiChip-label': {
      padding: '0 6px',
    },
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: alpha(getColor(), 0.2),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
      height: '24px',
      '& .MuiChip-label': {
        padding: '0 8px',
      },
    },
  };
});

const NegotiableChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  fontWeight: 600,
  fontSize: '0.75rem',
  height: '20px',
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

const BidsList = ({
  bids,
  userDraftBid,
  request,
  isCreator,
  currentUser,
  showToast,
  onVouchSubmitted,
  itemName,
  isClosingBids,
  onBidSelection,
  selectedBidId,
  onEditBid,
  onDeleteBid,
  isSignedIn,
  onOpenBidForm,
}) => {
  const theme = useTheme();
  const [highlightedBidId, setHighlightedBidId] = useState(null);
  const editFormRef = useRef(null);
  const [updatingBids, setUpdatingBids] = useState(new Set());
  const [processingStatus, setProcessingStatus] = useState(null);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (highlightedBidId && editFormRef.current) {
      editFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      const timer = setTimeout(() => setHighlightedBidId(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [highlightedBidId]);

  const hasBids =
    (Array.isArray(bids) && bids.length > 0) || (isSignedIn && userDraftBid);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleOpenBidForm = () => {
    if (onOpenBidForm) {
      onOpenBidForm();
    }
  };

  const getVouchStatusLabel = (status) => {
    switch (status) {
      case 'completed':
        return 'Vouch Confirmed';
      case 'refunded':
        return 'Vouch Refunded';
      case 'refund_failed':
        return 'Refund Failed';
      case 'pending':
        return 'Vouch Pending';
      case 'unpaid':
        return 'Bounty Unpaid';
      case 'paid':
        return 'Bounty Paid';
      default:
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
  };

  const getProposalLabel = () => {
    switch (request.category) {
      case 'recommendation':
        return 'Recommendation';
      case 'project':
        return 'Bid';
      case 'job':
        return 'Application';
      default:
        return 'Proposal';
    }
  };

  const getStatusMessage = (status, itemName) => {
    switch (status) {
      case 'bidding_closed':
        return `Bidding is now closed for this request. A ${itemName.toLowerCase()} has been selected.`;
      case 'in_progress':
        return `This request is in progress with the selected ${itemName.toLowerCase()}.`;
      case 'completed':
        return 'This request has been completed and is awaiting the bounty decision.';
      case 'bounty_released':
        return 'This request has been completed and the bounty has been released.';
      case 'bounty_withheld':
        return 'This request has been completed and the bounty has been withheld.';
      default:
        return null;
    }
  };

  const renderVouches = (bid) => {
    const confirmedVouches =
      bid.vouches?.filter((v) =>
        ['completed', 'paid', 'unpaid', 'refunded', 'refund_failed'].includes(
          v.paymentStatus
        )
      ) || [];
    const pendingVouches =
      bid.vouches?.filter((v) => ['pending'].includes(v.paymentStatus)) || [];

    // Only render VouchesSection if there are vouches
    if (confirmedVouches.length === 0 && pendingVouches.length === 0) {
      return null;
    }

    return (
      <VouchesSection>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.5rem' },
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          Vouches
          <Tooltip
            title={`Vouches are endorsements from other users, backing this ${
              request.category === 'job'
                ? 'application'
                : request.category === 'project'
                ? 'bid'
                : 'recommendation'
            } with USDC.`}
            placement="top"
          >
            <InfoOutlinedIcon
              fontSize="small"
              sx={{ ml: 1, verticalAlign: 'middle', cursor: 'pointer' }}
            />
          </Tooltip>
        </Typography>

        <List disablePadding>
          {confirmedVouches.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}
              >
                Confirmed Vouches:
              </Typography>
              {confirmedVouches.map((vouch, index) => (
                <React.Fragment key={vouch._id}>
                  <VouchItem>
                    <Box className="vouch-content">
                      <ListItemAvatar sx={{ minWidth: 'auto', mr: 1 }}>
                        <FarcasterAvatar
                          username={
                            vouch.voucherDetails?.username || vouch.voucher
                          }
                          avatarUrl={vouch.voucherDetails?.avatarUrl}
                          size="small"
                        />
                      </ListItemAvatar>
                      <Box className="vouch-details">
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: {
                              xs: '0.9rem',
                              sm: '1rem',
                              md: '1.1rem',
                            },
                          }}
                        >
                          <FarcasterUsername
                            username={
                              vouch.voucherDetails?.username || vouch.voucher
                            }
                          />
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                            fontSize: {
                              xs: '0.75rem',
                              sm: '0.85rem',
                              md: '0.9rem',
                            },
                          }}
                        >
                          {new Date(vouch.updated_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="vouch-amount-status">
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          fontSize: {
                            xs: '0.875rem',
                            sm: '0.9375rem',
                            md: '1rem',
                          },
                          mb: { xs: 0.5, sm: 0 }, // Add margin bottom for spacing on small screens
                        }}
                      >
                        ${formatNumber(vouch.amount.toFixed(2))} USDC
                      </Typography>
                      <VouchStatusChip
                        label={getVouchStatusLabel(vouch.paymentStatus)}
                        status={vouch.paymentStatus}
                        size="small"
                        sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 0.5, sm: 0 } }}
                      />
                    </Box>
                  </VouchItem>
                  {index < confirmedVouches.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              ))}
            </>
          )}

          {pendingVouches.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  mt: 2,
                  fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                }}
              >
                Pending Vouches:
              </Typography>
              {pendingVouches.map((vouch, index) => (
                <React.Fragment key={vouch._id}>
                  <VouchItem>
                    <Box className="vouch-content">
                      <ListItemAvatar sx={{ minWidth: 'auto', mr: 1 }}>
                        <FarcasterAvatar
                          username={
                            vouch.voucherDetails?.username || vouch.voucher
                          }
                          avatarUrl={vouch.voucherDetails?.avatarUrl}
                          size="small"
                        />
                      </ListItemAvatar>
                      <Box className="vouch-details">
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: {
                              xs: '0.9rem',
                              sm: '1rem',
                              md: '1.1rem',
                            },
                          }}
                        >
                          <FarcasterUsername
                            username={
                              vouch.voucherDetails?.username || vouch.voucher
                            }
                          />
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                            fontSize: {
                              xs: '0.75rem',
                              sm: '0.85rem',
                              md: '0.9rem',
                            },
                          }}
                        >
                          {new Date(vouch.updated_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="vouch-amount-status">
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          fontSize: {
                            xs: '0.875rem',
                            sm: '0.9375rem',
                            md: '1rem',
                          },
                          mb: { xs: 0.5, sm: 0 }, // Add margin bottom for spacing on small screens
                        }}
                      >
                        ${formatNumber(vouch.amount.toFixed(2))} USDC
                      </Typography>
                      <VouchStatusChip
                        label={getVouchStatusLabel(vouch.paymentStatus)}
                        status={vouch.paymentStatus}
                        size="small"
                        sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 0.5, sm: 0 } }}
                      />
                    </Box>
                  </VouchItem>
                  {index < pendingVouches.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </List>
      </VouchesSection>
    );
  };

  const renderEmptyVouchState = (bid) => {
    return (
      <EmptyVouchState>
        <ThumbUpIcon
          sx={{
            fontSize: { xs: 48, sm: 56, md: 64 },
            color: 'secondary.main', // Use the secondary color
            opacity: 0.8, // Slight opacity for a subtle look
            mb: { xs: 1.5, sm: 2, md: 2.5 },
            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))', // Add subtle shadow
          }}
        />
        <Box
          sx={{
            width: '100%',
            px: { xs: 2, sm: 0 },
          }}
        >
          {isCreator ? (
            <Typography
              variant="body1"
              color="text.secondary"
              align="center"
              sx={{
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                lineHeight: { xs: 1.4, sm: 1.6 },
                mb: { xs: 1, sm: 2 },
                wordBreak: 'break-word',
              }}
            >
              Waiting for community validation. Vouches from other users will help
              you evaluate this{' '}
              <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                {request.category === 'job'
                  ? 'application'
                  : request.category === 'project'
                  ? 'bid'
                  : 'recommendation'}
              </Box>
              .
            </Typography>
          ) : bid.bidder === currentUser?.username ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                align="center"
                sx={{
                  fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                  lineHeight: { xs: 1.4, sm: 1.6 },
                  mb: { xs: 1, sm: 1.5 },
                  wordBreak: 'break-word',
                }}
              >
                Share your{' '}
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  {request.category === 'job'
                    ? 'application'
                    : request.category === 'project'
                    ? 'bid'
                    : 'recommendation'}
                </Box>{' '}
                with your network to gather support.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{
                  fontSize: { xs: '0.8rem', sm: '0.875rem', md: '0.95rem' },
                  lineHeight: { xs: 1.4, sm: 1.6 },
                  wordBreak: 'break-word',
                }}
              >
                Vouches from respected community members can increase your chances
                of being selected.
              </Typography>
            </>
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              align="center"
              sx={{
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                lineHeight: { xs: 1.4, sm: 1.6 },
                wordBreak: 'break-word',
              }}
            >
              No vouches yet. Be the first to support this{' '}
              <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                {request.category === 'job'
                  ? 'application'
                  : request.category === 'project'
                  ? 'bid'
                  : 'recommendation'}
              </Box>{' '}
              with USDC.
            </Typography>
          )}
        </Box>
      </EmptyVouchState>
    );
  };  

  const renderProcessingAlert = () => {
    if (!processingStatus) return null;

    const message =
      processingStatus === 'bidding'
        ? 'Processing bid closure and vouch refunds...'
        : 'Processing bounty distribution...';

    return (
      <Collapse in={true}>
        <ProcessingAlert icon={<RotatingIcon />} severity="info">
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {message}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Please wait while we update all bids and vouches. This may take a few
              minutes.
            </Typography>
            <ProgressIndicator>
              <LinearProgress
                variant="determinate"
                value={progressValue}
                sx={{ height: '100%' }}
              />
            </ProgressIndicator>
          </Box>
        </ProcessingAlert>
      </Collapse>
    );
  };

  const renderBid = (bid, bidNumber, isDraft = false, index = null) => {
    const isUpdating = updatingBids.has(bid._id);

    return (
      <Box position="relative" key={bid._id}>
        <Fade in={isUpdating}>
          <UpdateOverlay>
            <Box
              sx={{
                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                backdropFilter: 'blur(8px)',
                p: 2,
                borderRadius: theme.shape.borderRadius,
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                boxShadow: theme.shadows[4],
              }}
            >
              <RotatingIcon sx={{ color: 'primary.main' }} />
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Updating status...
              </Typography>
            </Box>
          </UpdateOverlay>
        </Fade>
        <StyledCard
          isDraft={isDraft}
          isSelected={selectedBidId === bid._id}
          sx={{
            transition: 'all 0.3s ease-in-out',
            filter: isUpdating ? 'blur(1px)' : 'none',
          }}
        >
          <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
            {isDraft && (
              <Alert
                severity="info"
                sx={{
                  mb: 3,
                  borderRadius: 2,
                  '& .MuiAlert-icon': { alignItems: 'center' },
                }}
              >
                <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
                  This draft is only visible to you. Submit it to make it visible to
                  others and allow selection by the requester and vouching by other
                  users.
                </Typography>
              </Alert>
            )}

            <Fade in={bid._id === highlightedBidId}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '3px',
                  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(
                    theme.palette.primary.main,
                    0.3
                  )})`,
                  borderTopLeftRadius: theme.shape.borderRadius,
                  borderTopRightRadius: theme.shape.borderRadius,
                }}
              />
            </Fade>

            <Box sx={{ mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      transition: 'all 0.3s ease-in-out',
                      pt:
                        isSignedIn &&
                        bid.bidder === currentUser?.username &&
                        !isDraft
                          ? 5
                          : 0,
                    }}
                  >
                    {/* Your Bid Chip */}
                    {isSignedIn &&
                      bid.bidder === currentUser?.username &&
                      !isDraft && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                          }}
                        >
                          <YourBidChip
                            label={`Your ${itemName}`}
                            size="small"
                            sx={{
                              height: 24,
                              backgroundColor: (theme) =>
                                alpha(theme.palette.info.main, 0.15),
                              border: (theme) =>
                                `1px solid ${theme.palette.info.main}`,
                              fontWeight: 700,
                              '& .MuiChip-label': {
                                px: 1.5,
                                fontSize: '0.8rem',
                                letterSpacing: '0.02em',
                              },
                            }}
                          />
                        </Box>
                      )}

                    {/* Main Content Container */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        justifyContent: 'space-between',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      {/* Avatar and Text Container */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          flex: 1,
                        }}
                      >
                        <FarcasterAvatar
                          username={
                            bid.bidderDetails?.username || bid.bidder
                          }
                          avatarUrl={bid.bidderDetails?.avatarUrl}
                          size="medium"
                          sx={{
                            width: { xs: 40, sm: 44 },
                            height: { xs: 40, sm: 44 },
                            flexShrink: 0,
                          }}
                        />

                        {/* Text Content */}
                        <Box
                          sx={{
                            minWidth: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: {
                              xs: 0.5,  // Reduced gap for mobile
                              sm: 1,    // Regular gap for larger screens
                            },
                            // Ensure container doesn't overflow
                            maxWidth: '100%',
                            overflow: 'hidden',
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h2"
                            sx={{
                              fontSize: {
                                xs: '1rem',     // Much smaller for mobile (14.4px)
                                sm: '1.5rem',   // Medium size for tablets (22px)
                                md: '1.75rem',    // Large size for desktop (28px)
                              },
                              fontWeight: 700,
                              lineHeight: 1.3,
                              margin: 0,
                              padding: 0,
                              letterSpacing: '0.02em',
                              whiteSpace: 'nowrap',  // Force single line
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '100%',
                            }}
                          >
                            {`${getProposalLabel()} #${bidNumber}`}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              fontSize: { xs: '0.75rem', sm: '0.813rem' },
                              lineHeight: 1.4, // Slightly taller line height to improve readability
                              marginTop: 0.3, // Small margin to increase spacing from the line above
                            }}
                          >
                            by{' '}
                            <FarcasterUsername
                              username={
                                bid.bidderDetails?.username || bid.bidder
                              }
                            />
                          </Typography>
                        </Box>
                      </Box>

                      {/* Status Chip */}
                      {!isDraft && (
                        <Box sx={{ flexShrink: 0, mt: { xs: 1, sm: 0 } }}>
                          <BidStatusChip
                            label={
                              bid.status === 'accepted'
                                ? 'Accepted'
                                : bid.status === 'not_selected'
                                ? 'Not Selected'
                                : 'Pending'
                            }
                            status={bid.status}
                            size="small"
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 4 }}>
              {request.category === 'project' && (
                <Box
                  sx={{
                    mb: 3,
                    p: 3,
                    borderRadius: 2,
                    bgcolor: alpha(theme.palette.background.paper, 0.4),
                    border: `1px solid ${alpha(
                      theme.palette.primary.main,
                      0.1
                    )}`,
                  }}
                >
                  <Typography variant="body1">
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, color: 'text.primary' }}
                    >
                      Bid Amount:
                    </Box>{' '}
                    {bid.amount ? (
                      <Box
                        component="span"
                        sx={{ color: 'primary.main', fontWeight: 500 }}
                      >
                        ${formatNumber(parseFloat(bid.amount).toFixed(2))} USDC
                      </Box>
                    ) : (
                      'Not specified'
                    )}
                    {bid.isNegotiable && (
                      <NegotiableChip
                        label="Negotiable"
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Typography>
                </Box>
              )}

              {request.category === 'job' && (
                <Box
                  sx={{
                    mb: 3,
                    p: 3,
                    borderRadius: 2,
                    bgcolor: alpha(theme.palette.background.paper, 0.4),
                    border: `1px solid ${alpha(
                      theme.palette.primary.main,
                      0.1
                    )}`,
                  }}
                >
                  <Typography variant="body1">
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, color: 'text.primary' }}
                    >
                      Requested Salary:
                    </Box>{' '}
                    {bid.salary ? (
                      <Box
                        component="span"
                        sx={{ color: 'primary.main', fontWeight: 500 }}
                      >
                        ${formatNumber(parseFloat(bid.salary).toFixed(2))} / year
                      </Box>
                    ) : (
                      'Not specified'
                    )}
                    {bid.isNegotiable && (
                      <NegotiableChip
                        label="Negotiable"
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Typography>
                </Box>
              )}

              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    color: 'text.primary',
                    mb: 2,
                  }}
                >
                  {getProposalLabel()} Details
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: 1.7,
                    color: 'text.secondary',
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(bid.proposal),
                    }}
                  />
                </Typography>
              </Box>

              {!isDraft && (
                <Box
                  sx={{
                    mt: 3,
                    p: 3,
                    borderRadius: 2,
                    bgcolor: alpha(theme.palette.background.paper, 0.4),
                    border: `1px solid ${alpha(
                      theme.palette.primary.main,
                      0.1
                    )}`,
                  }}
                >
                  <Typography variant="subtitle1">
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, color: 'text.primary' }}
                    >
                      Total Vouched:
                    </Box>{' '}
                    <Box
                      component="span"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                        fontSize: { xs: '1.1rem', sm: '1.25rem' },
                      }}
                    >
                      $
                      {formatNumber(
                        bid.totalVouchAmount
                          ? parseFloat(bid.totalVouchAmount).toFixed(2)
                          : '0.00'
                      )}{' '}
                      USDC
                    </Box>
                    {bid.status === 'not_selected' && (
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 1 }}
                      >
                        (Refunded)
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}
            </Box>

            {bid.documents && bid.documents.length > 0 && (
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: 600, mb: 2 }}
                >
                  Supporting Documents
                </Typography>
                {bid.documents.map((document, docIndex) => (
                  <DocumentViewer
                    key={docIndex}
                    document={document}
                    context="bid"
                    initiallyExpanded={false}
                  />
                ))}
              </Box>
            )}

            {!isDraft && renderVouches(bid)}

            {/* Render EmptyVouchState if no vouches */}
            {!isDraft &&
              (!bid.vouches || bid.vouches.length === 0) &&
              renderEmptyVouchState(bid)}
          </CardContent>

          <CardActions
            sx={{
              px: { xs: 2, sm: 3 },
              pb: { xs: 2, sm: 3 },
              pt: 0,
              gap: 2,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'stretch',
            }}
          >
            {isSignedIn &&
              isCreator &&
              isClosingBids &&
              !isDraft &&
              bid.status !== 'not_selected' && (
                <SelectWinnerButton
                  variant="contained"
                  onClick={() => onBidSelection(bid, index)}
                  startIcon={
                    <CheckCircleOutlineIcon
                      sx={{
                        fontSize: {
                          xs: '1.1rem',
                          sm: '1.2rem',
                          md: '1.3rem',
                        },
                      }}
                    />
                  }
                  fullWidth
                  sx={{
                    py: 1.5,
                    fontSize: {
                      xs: '0.8125rem',
                      sm: '0.875rem',
                      md: '0.9375rem',
                    },
                    fontWeight: 500,
                    lineHeight: 1.3,
                    letterSpacing: '0.01em',
                  }}
                >
                  Select {itemName} #{index + 1} as Winner
                </SelectWinnerButton>
              )}

            {isSignedIn &&
              currentUser &&
              bid.bidder === currentUser.username &&
              bid.totalVouchAmount === 0 &&
              (!bid.pendingVouches || bid.pendingVouches.length === 0) &&
              (isDraft || (bid.status !== 'not_selected' && 
                          bid.status !== 'accepted' && 
                          request.status === 'active')) && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    width: '100%',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  <Button
                    startIcon={<EditIcon />}
                    onClick={() => {
                      onEditBid(bid._id);
                      setHighlightedBidId(bid._id);
                    }}
                    color="primary"
                    variant={isDraft ? 'contained' : 'outlined'}
                    fullWidth
                    sx={{ py: 1.25 }}
                  >
                    Edit {isDraft ? 'Draft' : ''}
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => onDeleteBid(bid._id)}
                    color="error"
                    variant="outlined"
                    fullWidth
                    sx={{ py: 1.25 }}
                  >
                    Delete {isDraft ? 'Draft' : ''}
                  </Button>
                </Box>
              )}

              {isSignedIn &&
                currentUser &&
                !isCreator &&
                bid.bidder !== currentUser.username &&
                request.status === 'active' &&
                !isDraft && (
                  <Box sx={{ width: '100%' }}>
                    <VouchForm
                      bidId={bid._id}
                      bidNumber={bidNumber}
                      bidderUsername={bid.bidder}
                      itemType={itemName.toLowerCase()}
                      requestId={request._id} // Add this
                      showToast={showToast}
                      onVouchSubmitted={onVouchSubmitted}
                      compact={false} // Change this to false to show the heading
                    />
                  </Box>
                )
              }
          </CardActions>
        </StyledCard>
      </Box>
    );
  };

  if (!hasBids) {
    return (
      <Paper
      elevation={0}
      sx={{
        mt: 4,
        p: { xs: 3, sm: 4 },
        textAlign: 'center',
        backgroundColor: 'transparent',
        position: 'relative',
        overflow: 'hidden',
        
        // Refined border and shape
        border: `1px solid ${alpha(theme.palette.secondary.main, 0.15)}`,
        borderRadius: '8px',
        
        // Enhanced gradient background with secondary color
        background: `linear-gradient(135deg, 
          ${alpha(theme.palette.background.paper, 0.8)} 0%,
          ${alpha(theme.palette.secondary.dark, 0.1)} 100%)`,
        
        // Sophisticated shadow and glow effects
        boxShadow: `
          0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
          0 0 20px ${alpha(theme.palette.secondary.main, 0.1)},
          inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)}
        `,
        
        // Enhanced hover state
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: `
            0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
            0 0 30px ${alpha(theme.palette.secondary.main, 0.15)},
            inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)}
          `,
          '&::before': {
            opacity: 0.7,
          }
        },
    
        // Animated gradient border
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '2px',
          background: `linear-gradient(90deg, 
            ${alpha(theme.palette.secondary.main, 0)},
            ${theme.palette.secondary.main},
            ${alpha(theme.palette.secondary.main, 0)}
          )`,
          opacity: 0.5,
          transition: 'opacity 0.3s ease',
        },
    
        // Subtle background texture
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `radial-gradient(circle at top right, 
            ${alpha(theme.palette.secondary.main, 0.05)}, 
            transparent 70%)`,
          pointerEvents: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          position: 'relative',
          zIndex: 1,
        }}
      >
        {isCreator ? (
          <>
            <HourglassEmptyIcon
              sx={{ 
                fontSize: { xs: 40, sm: 48 }, 
                color: 'secondary.main',
                opacity: 0.9,
                mb: { xs: 1, sm: 2 },
                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
              }}
            />
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                fontWeight: 600,
                color: 'text.primary',
                textShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.2)}`
              }}
            >
              Waiting for {itemName}s
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ 
                maxWidth: 600, 
                mx: 'auto',
                fontSize: { xs: '0.875rem', sm: '1rem' },
                lineHeight: 1.6,
                opacity: 0.9
              }}
            >
              Your request is live and waiting for responses. Once {itemName}s
              start coming in, they will appear here.
            </Typography>
          </>
        ) : request.status === 'active' ? (
          <>
            <SearchOffIcon
              sx={{ 
                fontSize: { xs: 40, sm: 48 }, 
                color: 'secondary.main',
                opacity: 0.9,
                mb: { xs: 1, sm: 2 },
                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
              }}
            />
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                fontWeight: 600,
                color: 'text.primary',
                textShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.2)}`
              }}
            >
              Be the First to Respond
            </Typography>
            {isSignedIn ? (
              <>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ 
                    maxWidth: 600, 
                    mx: 'auto',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    lineHeight: 1.6,
                    opacity: 0.9
                  }}
                >
                  No {itemName}s have been submitted yet. This is your chance to
                  be the first to
                  {request.category === 'recommendation'
                    ? ' make a recommendation'
                    : request.category === 'job'
                    ? ' apply for this position'
                    : ' submit a proposal'}
                  .
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenBidForm}
                  sx={{
                    mt: 2,
                    px: 4,
                    py: 1.5,
                    borderRadius: '6px',
                    fontSize: { xs: '0.8rem', sm: '1rem' },
                    fontWeight: 600,
                    textTransform: 'none',
                    boxShadow: `0 4px 12px ${alpha(theme.palette.secondary.main, 0.3)}`,
                    '&:hover': {
                      boxShadow: `0 6px 16px ${alpha(theme.palette.secondary.main, 0.4)}`,
                    }
                  }}
                >
                  {request.category === 'recommendation'
                    ? 'Make a Recommendation'
                    : request.category === 'job'
                    ? 'Submit Application'
                    : 'Submit Proposal'}
                </Button>
              </>
            ) : (
                <>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ maxWidth: 600, mx: 'auto' }}
                  >
                    Sign in to be the first to submit a{' '}
                    {itemName.toLowerCase()}.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/login"
                    sx={{ mt: 2 }}
                  >
                    Sign In to Submit
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                No Submissions
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ maxWidth: 600, mx: 'auto' }}
              >
                {getStatusMessage(request.status, itemName)}
              </Typography>
            </>
          )}
        </Box>
      </Paper>
    );
  }

  return (
    <>
      {renderProcessingAlert()}
      {isSignedIn && isClosingBids && (
        <Alert severity="info" sx={{ mb: 3 }}>
          You are now in the process of closing bidding. Please select a winning{' '}
          {itemName.toLowerCase()} below.
        </Alert>
      )}
      <Divider sx={{ my: 3 }} />
      {isSignedIn && userDraftBid && renderBid(userDraftBid, 'Draft', true)}
      {bids.map((bid, index) => renderBid(bid, index + 1, false, index))}
      {['bidding_closed', 'in_progress', 'completed', 'bounty_released', 'bounty_withheld'].includes(
        request.status
      ) ? (
        <Alert
          severity="info"
          sx={{
            mt: 3,
            backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1),
            border: '1px solid',
            borderColor: 'info.main',
            borderRadius: 2,
            '& .MuiAlert-icon': {
              color: 'info.main',
            },
          }}
        >
          {getStatusMessage(request.status, itemName)}
        </Alert>
      ) : (
        !isSignedIn && (
          <Alert severity="info" sx={{ mt: 3 }}>
            Sign in to submit {itemName}s or vouches.
          </Alert>
        )
      )}
    </>
  );
};

export default React.memo(BidsList);
