// frontend/src/components/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  Typography, Button, Box, Container, Grid, alpha,
  useTheme, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

// Import icons
import CreateIcon from '@mui/icons-material/Create';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupIcon from '@mui/icons-material/Group';
import PaidIcon from '@mui/icons-material/Paid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import FeaturedRequestsSection from './FeaturedRequestsSection';

import PageTitle from './PageTitle';
import { CategorySlider } from './HomeComponents';

// Styled Components
const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(/images/hero-background.jpg)', // Replace with your image path
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.common.white,
  padding: theme.spacing(10, 0, 8),
  textAlign: 'center',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(18, 0, 12),
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    backgroundColor: alpha('#000', 0.6),
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
}));

const SubtleAccent = styled(Box)(({ theme }) => ({
  width: '80px',
  height: '4px',
  background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${alpha(theme.palette.secondary.main, 0.3)})`,
  margin: '20px auto',
  borderRadius: theme.shape.borderRadius,
}));

const WorkflowSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  background: alpha(theme.palette.background.paper, 0.5),
  backdropFilter: 'blur(10px)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '10%',
    right: '10%',
    height: '1px',
    background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}60, transparent)`,
  },
}));

const StyledCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.7)} 0%, ${alpha(theme.palette.background.default, 0.9)} 100%)`,
  backdropFilter: 'blur(12px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-6px)',
    boxShadow: `0 6px 24px ${alpha(theme.palette.primary.main, 0.25)}`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.25)}`,
  },
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  width: 70,
  height: 70,
  borderRadius: '50%',
  background: alpha(theme.palette.primary.main, 0.15),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: 36,
    color: theme.palette.primary.main,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.shape.borderRadius, // Reduced rounding
  fontWeight: 600,
  fontSize: '1rem',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: theme.shadows[6],
  },
}));

// Main Component
const Home = () => {
  const { isSignedIn, profile } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const workflowSteps = [
    {
      icon: <CreateIcon />,
      title: "Create Request",
      description: "Post requests for recommendations, project bids, or job applications with USDC bounties to incentivize quality responses.",
    },
    {
      icon: <RecommendIcon />,
      title: "Receive Responses",
      description: "Get detailed recommendations, competitive bids, or qualified applications from domain experts.",
    },
    {
      icon: <ThumbUpIcon />,
      title: "Community Validation",
      description: "Community members validate quality through USDC-backed vouches, sharing in rewards for successful outcomes.",
    },
    {
      icon: <SecurityIcon />,
      title: "Release Bounty",
      description: "Upon successful completion, bounties are distributed to vouchers and recommenders based on contribution.",
    },
  ];

  const benefits = [
    {
      icon: <VerifiedUserIcon />,
      title: "Trusted Validation",
      description: "Community members stake real value on their endorsements, ensuring genuine support.",
    },
    {
      icon: <AccountBalanceWalletIcon />,
      title: "Incentive Alignment",
      description: "USDC bounties and vouches create aligned interests for quality outcomes.",
    },
    {
      icon: <GroupIcon />,
      title: "Expert Network",
      description: "Access a community of validated domain experts across various fields.",
    },
    {
      icon: <PaidIcon />,
      title: "Merit-Based Rewards",
      description: "Contributors earn rewards based on successful outcomes and community validation.",
    },
  ];

  return (
    <>
      <PageTitle 
        title="Home" 
        description="Welcome to Vouch - A premium platform for trusted recommendations, project proposals, and job applications."
      />
      
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Container maxWidth="md">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography 
                variant="h2" 
                component="h1" 
                gutterBottom 
                sx={{ 
                  fontWeight: 800,
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' },
                  lineHeight: 1.2,
                }}
              >
                {isSignedIn && profile?.username ? (
                  <>
                    Welcome Back, {profile.username}
                  </>
                ) : (
                  <>
                    Premium Expertise,
                    Validated by Community
                  </>
                )}
              </Typography>
              <SubtleAccent />
              <Typography 
                variant="h5" 
                component="p" 
                paragraph 
                sx={{ 
                  mb: 4, 
                  opacity: 0.9,
                  fontSize: { xs: '1.15rem', sm: '1.25rem', md: '1.5rem' },
                  px: { xs: 2, sm: 0 },
                }}
              >
                 A new standard for professional recommendations and talent discovery, where trust is built through crypto-backed community validation.
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                justifyContent: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                px: { xs: 3, sm: 0 },
              }}>
                {isSignedIn ? (
                  <>
                    <ActionButton
                      component={Link}
                      to="/create-request"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Create Request
                    </ActionButton>
                    <ActionButton
                      component={Link}
                      to="/requests"
                      variant="outlined"
                      color="secondary"
                      size="large"
                    >
                      Explore Requests
                    </ActionButton>
                  </>
                ) : (
                  <>
                    <ActionButton
                      component={Link}
                      to="/requests"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Explore Requests
                    </ActionButton>
                    <ActionButton
                      component={Link}
                      to="/about"
                      variant="outlined"
                      color="secondary"
                      size="large"
                    >
                      Learn More
                    </ActionButton>
                  </>
                )}
                <ActionButton
                  component={Link}
                  to="/leaderboard"
                  variant="outlined"
                  color="secondary"
                  size="large"
                  startIcon={<LeaderboardIcon />}
                >
                  View Leaderboard
                </ActionButton>
              </Box>
            </motion.div>
          </Container>
        </HeroContent>
      </HeroSection>

      {/* Categories Preview */}
      <CategorySlider />

      {/* Featured Requests */}
      <FeaturedRequestsSection />

      {/* How It Works */}
      <WorkflowSection>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center"
            sx={{ 
              mb: 6,
              fontWeight: 700,
              fontSize: { xs: '2.25rem', sm: '2.75rem', md: '3rem' },
            }}
          >
            How It Works
          </Typography>
          
          <Grid container spacing={4}>
            {workflowSteps.map((step, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  viewport={{ once: true }}
                >
                  <StyledCard>
                    <FeatureIcon>
                      {step.icon}
                    </FeatureIcon>
                    <Typography 
                      variant="h6" 
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      {step.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ lineHeight: 1.6 }}
                    >
                      {step.description}
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </WorkflowSection>

      {/* Benefits Section */}
      <Box sx={{ py: { xs: 8, md: 12 } }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center"
            sx={{ 
              mb: 6,
              fontWeight: 700,
              fontSize: { xs: '2.25rem', sm: '2.75rem', md: '3rem' },
            }}
          >
            Platform Benefits
          </Typography>
          
          <Grid container spacing={4}>
            {benefits.map((benefit, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  viewport={{ once: true }}
                >
                  <StyledCard>
                    <FeatureIcon>
                      {benefit.icon}
                    </FeatureIcon>
                    <Typography 
                      variant="h6" 
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      {benefit.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ lineHeight: 1.6 }}
                    >
                      {benefit.description}
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box 
        sx={{ 
          py: { xs: 8, md: 12 },
          background: theme => `linear-gradient(135deg, 
            ${alpha(theme.palette.background.paper, 0.5)} 0%,
            ${alpha(theme.palette.background.default, 0.7)} 100%)`,
          backdropFilter: 'blur(12px)',
          borderTop: theme => `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
        }}
      >
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h3" 
            gutterBottom
            sx={{ 
              fontWeight: 700,
              fontSize: { xs: '2.25rem', sm: '2.75rem', md: '3rem' },
              mb: 3,
            }}
          >
            Get Started Today
          </Typography>
          <Typography 
            variant="h5" 
            color="text.secondary"
            sx={{ 
              mb: 6, 
              lineHeight: 1.6,
              fontSize: { xs: '1rem', sm: '1.15rem', md: '1.25rem' },
            }}
          >
            Join our community where trust is built through validated expertise 
            and aligned incentives.
          </Typography>
          <ActionButton
            component={Link}
            to={isSignedIn ? "/create-request" : "/requests"}
            variant="contained"
            color="primary"
            size="large"
            endIcon={<ArrowForwardIcon />}
            sx={{
              py: 2,
              px: 6,
              fontSize: { xs: '1rem', md: '1.1rem' },
              fontWeight: 600,
            }}
          >
            {isSignedIn ? "Create Your Request" : "Explore Requests"}
          </ActionButton>
        </Container>
      </Box>
    </>
  );
};

export default Home;
