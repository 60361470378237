// frontend/src/components/AuthCallback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleAuthCallback } from '../services/authService';
import { useAuth } from '../context/AuthContext';

const AuthCallback = () => {
  const navigate = useNavigate();
  const { updateUserInDatabase } = useAuth();

  useEffect(() => {
    const processCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        const success = await handleAuthCallback(code);
        if (success) {
          await updateUserInDatabase();
          navigate('/dashboard');
        } else {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };

    processCallback();
  }, [navigate, updateUserInDatabase]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;