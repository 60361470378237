// frontend/src/components/PaymentProcessingUI.js

import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Typography, 
  Box, 
  Button, 
  Grid,
  CircularProgress
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import PaymentIcon from '@mui/icons-material/Payment';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'none',
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[24],
    transition: 'all 0.3s ease-in-out',
    maxHeight: 'calc(100% - 64px)', // Ensure dialog doesn't exceed viewport height
    overflowY: 'auto', // Allow scrolling when content overflows
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: 'calc(100% - 16px)', // Ensure dialog doesn't exceed viewport width
      maxHeight: 'calc(100% - 16px)', // Adjust maxHeight for mobile devices
    },
  },
  '& .MuiBackdrop-root': {
    transition: 'opacity 0.3s ease-in-out',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 0,
  '& .MuiButton-root': {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    '& .MuiButton-root': {
      width: '100%',
    }
  },
  '& > :not(:first-of-type)': {
    marginLeft: 0,
  }
}));

const CountdownDisplay = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0, 191, 165, 0.05)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(0, 191, 165, 0.1)',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1.5),
  }
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.background.default, 0.8),
  backdropFilter: 'blur(4px)',
  zIndex: theme.zIndex.modal - 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SuccessAlert = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.success.main, 0.05),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.success.main, 0.1)}`,
  marginBottom: theme.spacing(3),
}));

export const PaymentRedirectDialog = React.memo(({
  open,
  onClose,
  paymentUrl,
  requestDetails,
  onCancel,
  onRedirectNow,
  showCreationSuccess = false,
  isEditing = false,
  isFormContext = false,
  requestId,
}) => {
  // Use longer timer for form context
  const [redirectCountdown, setRedirectCountdown] = useState(isFormContext ? 30 : 10);
  const [isRedirectPaused, setIsRedirectPaused] = useState(false);

  useEffect(() => {
    let countdownTimer;
    if (open && !isRedirectPaused) {
      countdownTimer = setInterval(() => {
        setRedirectCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownTimer);
            window.location.href = paymentUrl;
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownTimer) {
        clearInterval(countdownTimer);
      }
    };
  }, [open, isRedirectPaused, paymentUrl]);

  useEffect(() => {
    if (!open) {
      setRedirectCountdown(isFormContext ? 30 : 10);
      setIsRedirectPaused(false);
    }
  }, [open, isFormContext]);

  const handleRedirectNow = () => {
    setIsRedirectPaused(true);
    onRedirectNow();
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      keepMounted
      disableEscapeKeyDown
      scroll="paper"
      BackdropProps={{
        transitionDuration: 0,
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(4px)'
        }
      }}
      TransitionProps={{
        timeout: 0
      }}
    >
      <DialogTitle>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
          {showCreationSuccess 
            ? `Request ${isEditing ? 'Updated' : 'Created'} Successfully` 
            : 'Redirect to Payment'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {showCreationSuccess && (
          <SuccessAlert>
            <Typography variant="body1" sx={{ color: 'success.main', fontWeight: 500 }}>
              Your request has been {isEditing ? 'updated' : 'created'} successfully.
            </Typography>
            {isFormContext && (
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                To activate your request, you'll need to pay the bounty. You can do this now or later - 
                your request will remain in draft status until the bounty payment is completed.
              </Typography>
            )}
          </SuccessAlert>
        )}
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" gutterBottom>
            {isFormContext 
              ? "You'll be redirected to Coinbase Commerce to complete the bounty payment. You can also choose to pay later from your dashboard or the request detail page."
              : "You will be redirected to Coinbase Commerce to complete the payment for your request."}
          </Typography>
        </Box>
        {requestDetails && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
              Payment Details:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" component="div">
                  <Box component="span" sx={{ fontWeight: 600 }}>Amount:</Box> ${Number(requestDetails.bounty).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}


                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="div">
                  <Box component="span" sx={{ fontWeight: 600 }}>Request:</Box> {requestDetails.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="div">
                  <Box component="span" sx={{ fontWeight: 600 }}>Category:</Box> {requestDetails.category}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <CountdownDisplay>
          <Typography variant="body1" sx={{ 
            color: 'primary.main',
            fontWeight: 500,
            fontSize: {
              xs: '0.9rem',
              sm: '1rem'
            }
          }}>
            Redirecting in <strong>{redirectCountdown}</strong> seconds...
          </Typography>
        </CountdownDisplay>
      </DialogContent>
      <StyledDialogActions>
        <Button
          onClick={() => {
            if (isFormContext && requestId) {
              // If coming from form and we have a requestId, redirect to request detail
              window.location.href = `/request/${requestId}`;
            } else {
              // Otherwise use normal cancel behavior
              onCancel();
            }
          }}
          color="secondary"
          variant="outlined"
          fullWidth
        >
          {isFormContext ? "Pay Later" : "Cancel Payment"}
        </Button>
        <Button
          onClick={handleRedirectNow}
          color="primary"
          variant="contained"
          startIcon={<PaymentIcon />}
          fullWidth
        >
          {isFormContext ? "Pay Bounty Now" : "Go to Payment Now"}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
});

export const ProcessingOverlay = ({ show, message = "Initiating Payment Process..." }) => (
  show ? (
    <LoadingOverlay>
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress size={48} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {message}
        </Typography>
      </Box>
    </LoadingOverlay>
  ) : null
);