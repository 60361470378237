// frontend/src/components/CloseBiddingProcess.js

import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Grid, Paper, Stepper, Step, StepLabel,
  LinearProgress, CircularProgress, Alert, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
  alpha, Slide, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FarcasterUsername from './FarcasterUsername';
import { closeBidding } from '../services/api';
import useWebSocket from '../hooks/useWebSocket';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ProcessCompletionMessage from './ProcessCompletionMessage';

const ProcessContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
  boxShadow: theme.shadows[10],
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    transition: 'color 0.3s ease-in-out',
  },
  '& .MuiStepLabel-completed': {
    color: theme.palette.success.main,
  },
  '& .MuiStepIcon-root': {
    transition: 'all 0.3s ease-in-out',
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.success.main,
    transform: 'scale(1.2)',
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
    animation: 'pulse 2s infinite',
  },
}));

const ProcessStatus = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
}));

const AnimatedIcon = styled(motion.div)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  '& .MuiLinearProgress-root': {
    height: 8,
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
    backgroundImage: `linear-gradient(45deg, 
      ${theme.palette.primary.main} 25%, 
      ${theme.palette.primary.light} 50%, 
      ${theme.palette.primary.main} 75%
    )`,
    backgroundSize: '200% 200%',
    animation: 'gradient 2s linear infinite',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.info.main, 0.3)}`,
  backdropFilter: 'blur(10px)',
  '& .MuiAlert-icon': {
    color: theme.palette.info.main,
  },
}));

const BackgroundProgress = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 3,
  background: `linear-gradient(90deg, 
    ${theme.palette.primary.main}, 
    ${theme.palette.primary.light}, 
    ${theme.palette.primary.main}
  )`,
  backgroundSize: '200% 200%',
  animation: 'gradient 2s linear infinite',
  opacity: 0.5,
}));

const steps = [
  'Initiate Selection',
  'Process Vouches',
  'Finalize Selection'
];

const CloseBiddingProcess = ({ request, selectedBid, onClose, showToast, onBiddingClosed }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [processingStatus, setProcessingStatus] = useState('');
  const [isProcessComplete, setIsProcessComplete] = useState(false);
  const [vouchRefundProgress, setVouchRefundProgress] = useState(0);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { lastUpdate, isConnected } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, request?._id);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  // Clear notification timeout ref
  const notificationTimeoutRef = React.useRef(null);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (lastUpdate) {
      switch (lastUpdate.type) {
        case 'BIDDING_CLOSURE_STARTED':
          setActiveStep(0);
          setProcessingStatus('Initiating bidding closure...');
          setIsProcessing(true);
          break;
          
        case 'VOUCH_REFUND_PROGRESS':
          setActiveStep(1);
          const remaining = lastUpdate.data.total - lastUpdate.data.current;
          setProcessingStatus(
            remaining > 0 
              ? `Processing vouch refunds - ${remaining} ${remaining === 1 ? 'vouch' : 'vouches'} remaining...`
              : 'Finalizing vouch refunds...'
          );
          setVouchRefundProgress(lastUpdate.data.progress);
          break;
          
        case 'BIDDING_CLOSED':
          setActiveStep(2);
          setProcessingStatus('Bidding closure nearly complete...');
          setIsProcessComplete(true);
          
          // Add delay before showing completion dialog
          setTimeout(() => {
            setShowCompletionDialog(true);
            if (onBiddingClosed) {
              onBiddingClosed(lastUpdate.data);
            }
          }, 5000);
          break;
      }
    }
  }, [lastUpdate]);;

  const handleConfirm = async () => {
    try {
      setShowConfirmationDialog(false);
      setActiveStep(0);
      setProcessingStatus('Initiating selection process...');
      setError(null);
      setIsProcessing(true);

      showToast(
        'Selection process started. You can safely close this window - the process will continue in the background.',
        'info',
        8000
      );

      await closeBidding(request._id, selectedBid._id);
    } catch (error) {
      setError(error.message);
      setIsProcessing(false);
      showToast('Failed to start selection process: ' + error.message, 'error');
    }
  };

  const handleCompletionClose = () => {
    setShowCompletionDialog(false);
    if (onClose) {
      onClose();
    }
  };

  const handleStartSelection = () => {
    setShowConfirmationDialog(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const renderProcessingStatus = () => (
    <Slide direction="up" in={true}>
      <ProcessStatus>
        <BackgroundProgress />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {processingStatus.replace('response', 
                request.category === 'job' ? 'application' :
                request.category === 'project' ? 'bid' :
                'recommendation'
              )}
            </Typography>
            {activeStep === 1 && (
              <ProgressWrapper>
                <LinearProgress 
                  variant="determinate" 
                  value={vouchRefundProgress}
                />
                <Typography variant="caption" sx={{ position: 'absolute', right: 0, top: -20 }}>
                  {Math.round(vouchRefundProgress)}%
                </Typography>
              </ProgressWrapper>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimatePresence mode="wait">
              <AnimatedIcon
                key={activeStep}
                initial={{ scale: 0, rotate: -180 }}
                animate={{ scale: 1, rotate: 0 }}
                exit={{ scale: 0, rotate: 180 }}
                transition={{ duration: 0.5 }}
              >
                {activeStep === 0 && <GavelIcon color="primary" sx={{ fontSize: 48 }} />}
                {activeStep === 1 && <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 48 }} />}
                {activeStep === 2 && <CheckCircleIcon color="success" sx={{ fontSize: 48 }} />}
              </AnimatedIcon>
            </AnimatePresence>
          </Grid>
        </Grid>

        {!isProcessComplete && (
          <StyledAlert 
            severity="info" 
            icon={<AutorenewIcon />}
            sx={{ mt: 2 }}
          >
            Process running in background. You can safely close this window - 
            we'll notify you when it's complete.
          </StyledAlert>
        )}

        {!isConnected && (
          <StyledAlert 
            severity="warning" 
            sx={{ mt: 2 }}
          >
            Connection interrupted. Don't worry - the process will continue on our servers.
          </StyledAlert>
        )}
      </ProcessStatus>
    </Slide>
  );

  return (
    <>
      <ProcessContainer>
        <Typography variant="h4" gutterBottom color="primary">
          {isProcessComplete ? 'Selection Complete' : 'Confirm Selection'}
        </Typography>

        <StyledStepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </StyledStepper>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <InfoCard>
              <Typography variant="h6" gutterBottom>
                Selected {request.category === 'job' ? 'Application' : 
                        request.category === 'project' ? 'Bid' : 
                        'Recommendation'} Details
              </Typography>
              <Typography variant="body1" paragraph>
                {request.category === 'job' ? 'Application' : 
                request.category === 'project' ? 'Bid' : 
                'Recommendation'} #{selectedBid?.bidNumber || 'N/A'} by{' '}
                <FarcasterUsername username={selectedBid?.bidder || 'Unknown'} />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Vouched Amount: ${selectedBid?.totalVouchAmount?.toFixed(2) || '0.00'} USDC
              </Typography>
            </InfoCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <InfoCard>
              <Typography variant="h6" gutterBottom>
                Process Information
              </Typography>
              <Typography variant="body2" paragraph>
                • All other responses will be marked as not selected
              </Typography>
              <Typography variant="body2" paragraph>
                • Vouches on non-selected responses will be automatically refunded
              </Typography>
              <Typography variant="body2">
                • The request will move to "in progress" status
              </Typography>
            </InfoCard>
          </Grid>
        </Grid>

        {error ? (
          <Alert 
            severity="error" 
            sx={{ mt: 4 }}
            action={
              <Button 
                color="inherit" 
                size="small" 
                onClick={handleStartSelection}
                startIcon={<AutorenewIcon />}
              >
                Retry
              </Button>
            }
          >
            {error}
          </Alert>
        ) : (
          processingStatus && renderProcessingStatus()
        )}

        {!processingStatus && !isProcessComplete && (
          <Box 
          sx={{ 
            mt: 4, 
            display: 'flex', 
            flexDirection: isSmallScreen ? 'column' : 'row', 
            gap: 2,
            justifyContent: isSmallScreen ? 'center' : 'space-between',
          }}
        >
          <Button
            fullWidth={isSmallScreen}
            onClick={onClose}
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            fullWidth={isSmallScreen}
            onClick={handleStartSelection}
            variant="contained"
            color="primary"
            startIcon={isProcessing ? <CircularProgress size={20} /> : <GavelIcon />}
            disabled={!selectedBid || isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Confirm Selection'}
          </Button>
        </Box>
        )}
      </ProcessContainer>

      <Dialog
        open={showConfirmationDialog}
        onClose={handleCloseConfirmation}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <GavelIcon color="primary" sx={{ mr: 1 }} />
            Confirm {request.category === 'job' ? 'Application' : 
                    request.category === 'project' ? 'Bid' : 
                    'Recommendation'} Selection
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to select this {
              request.category === 'job' ? 'application' :
              request.category === 'project' ? 'bid' :
              'recommendation'
            }? This action cannot be undone.
          </Typography>
          <Box component="ul" sx={{ mt: 2, pl: 2 }}>
            <Typography variant="body2" component="li">
              {request.category === 'job' ? 'Application' :
              request.category === 'project' ? 'Bid' :
              'Recommendation'} #{selectedBid?.bidNumber} by{' '}
              <FarcasterUsername username={selectedBid?.bidder || 'Unknown'} />{' '}
              will be marked as selected
            </Typography>
            <Typography variant="body2" component="li">
              All other {
                request.category === 'job' ? 'applications' :
                request.category === 'project' ? 'bids' :
                'recommendations'
              } will be marked as not selected
            </Typography>
            <Typography variant="body2" component="li">
              Vouches on non-selected {
                request.category === 'job' ? 'applications' :
                request.category === 'project' ? 'bids' :
                'recommendations'
              } will be automatically refunded
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 1 }, // Consistent gap between buttons
          p: { xs: 2, sm: 3 }, // Consistent padding around all sides
          '& > button': {
            width: { xs: '100%', sm: 'auto' }, // Full width on mobile
            m: '0 !important', // Remove any default margins that might cause misalignment
          }
        }}>
          <Button 
            onClick={handleCloseConfirmation}
            variant="outlined" 
            color="secondary"
            startIcon={<CancelIcon />}
            fullWidth // Ensures button takes full width
            sx={{ 
              order: { xs: 2, sm: 1 },
              fontSize: { 
                xs: '0.875rem',
                sm: '1rem'
              },
              '& .MuiButton-startIcon': {
                marginRight: { xs: 1, sm: 2 }
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            startIcon={<GavelIcon />}
            fullWidth // Ensures button takes full width
            sx={{ 
              order: { xs: 1, sm: 2 },
              fontSize: { 
                xs: '0.875rem',
                sm: '1rem'
              },
              '& .MuiButton-startIcon': {
                marginRight: { xs: 1, sm: 2 }
              }
            }}
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showCompletionDialog}
        onClose={handleCompletionClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <DoneAllIcon color="success" sx={{ mr: 1 }} />
            Bidding Process Complete
          </Box>
        </DialogTitle>
        <DialogContent>
          <ProcessCompletionMessage processType="bidding" />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCompletionClose} 
            variant="contained" 
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CloseBiddingProcess;