// frontend/src/components/DocumentViewer.js

import React from 'react';
import { Box, Typography, Button, Paper, useTheme, useMediaQuery, IconButton, Collapse, Tooltip, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LaunchIcon from '@mui/icons-material/Launch';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.95)} 0%,
    ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)}
  `,
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)}
    `,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, 0.03)}, 
      transparent 70%)`,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const DocumentFrame = styled('iframe')(({ theme }) => ({
  width: '100%',
  border: 'none',
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  transition: 'height 0.3s ease-in-out',
  borderRadius: theme.shape.borderRadius,
}));

const DocumentHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  position: 'relative',
  zIndex: 1,

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },

  [`@media (max-width: 500px)`]: {
    flexDirection: 'column',
    alignItems: 'stretch',
  }
}));

const FileInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  minWidth: 0,
  flex: 1,

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
  },
}));

const FileNameTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flex: '1 1 auto',
  minWidth: 0,
  fontWeight: 500,
  
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },

  [`@media (max-width: 500px)`]: {
    fontSize: '0.8125rem',
    lineHeight: 1.4,
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  whiteSpace: 'nowrap',
  minWidth: 'auto',
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
  },
}));

const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexShrink: 0,
  marginLeft: 'auto',
  
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
  },
  
  [`@media (max-width: 500px)`]: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const StyledIcon = styled(DescriptionIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  filter: 'drop-shadow(0 2px 4px rgba(0, 191, 165, 0.2))',
  
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
  
  [`@media (max-width: 500px)`]: {
    fontSize: '1.125rem',
  }
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.error.main,
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.error.main, 0.1),
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(2),
  
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
    },
  },
}));

const constructFileUrl = (filename) => {
  const baseUrl = process.env.REACT_APP_API_URL || '';
  const cleanBaseUrl = baseUrl.replace(/\/+$/, '');
  return `${cleanBaseUrl}/uploads/${filename}`;
};

const DocumentViewer = ({ 
  document,
  context = 'request',
  initiallyExpanded = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery('(max-width:500px)');
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = React.useState(context === 'request' ? true : initiallyExpanded);
  
  const frameHeight = isExtraSmall ? 200 : isMobile ? 300 : isTablet ? 400 : 500;

  if (!document || !document.filename) return null;

  const fileUrl = constructFileUrl(document.filename);
  const isPdf = document.mimetype === 'application/pdf';
  const isWord = document.mimetype === 'application/msword' || 
                 document.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const renderDocumentContent = () => {
    if (isPdf) {
      return (
        <Box sx={{ 
          position: 'relative',
          height: frameHeight,
          backgroundColor: alpha(theme.palette.background.default, 0.6),
          overflow: 'hidden',
          borderRadius: 0,
        }}>
          <DocumentFrame
            src={fileUrl}
            title="Document Viewer"
            style={{ height: frameHeight }}
          />
        </Box>
      );
    }

    if (isWord) {
      return (
        <Box display="flex" alignItems="center" gap={2}>
          <ActionButton
            variant="contained"
            color="primary"
            href={fileUrl}
            download
            startIcon={<FileDownloadIcon />}
            size={isExtraSmall ? "small" : "medium"}
          >
            {isExtraSmall ? 'Download' : 'Download Document'}
          </ActionButton>
        </Box>
      );
    }

    return (
      <ErrorContainer>
        <ErrorOutlineIcon fontSize={isExtraSmall ? "small" : "medium"} />
        <Typography variant={isExtraSmall ? "body2" : "body1"}>
          Unsupported document type
        </Typography>
      </ErrorContainer>
    );
  };

  return (
    <StyledPaper elevation={3}>
      <DocumentHeader>
        <FileInfo>
          <StyledIcon color="primary" />
          <FileNameTypography variant={isExtraSmall ? "body2" : "subtitle1"}>
            {document.filename}
          </FileNameTypography>
        </FileInfo>
        <ActionButtonsContainer>
          {isPdf && (
            <>
              {context === 'bid' && (
                <Tooltip title={expanded ? "Collapse" : "Expand"}>
                  <IconButton
                    size={isExtraSmall ? "small" : "medium"}
                    onClick={() => setExpanded(!expanded)}
                    sx={{
                      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out',
                    }}
                  >
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Open in new tab">
                <ActionButton
                  variant="outlined"
                  color="primary"
                  href={fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size={isExtraSmall ? "small" : "medium"}
                  sx={{
                    minWidth: 0,
                    p: isExtraSmall ? 0.75 : 1,
                  }}
                >
                  <LaunchIcon fontSize={isExtraSmall ? "small" : "medium"} />
                </ActionButton>
              </Tooltip>
            </>
          )}
          {isWord && (
            <ActionButton
              variant="contained"
              color="primary"
              href={fileUrl}
              download
              startIcon={<FileDownloadIcon />}
              size={isExtraSmall ? "small" : "medium"}
            >
              {isExtraSmall ? 'Download' : 'Download Document'}
            </ActionButton>
          )}
        </ActionButtonsContainer>
      </DocumentHeader>
      <Collapse in={expanded} timeout="auto">
        {renderDocumentContent()}
      </Collapse>
    </StyledPaper>
  );
};

export default DocumentViewer;