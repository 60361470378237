// frontend/src/components/About.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import { 
  Container, Typography, Box, Grid, Button, Paper, alpha, 
  Tabs, Tab, Card, CardContent, Slide, Grow, Chip,
  Avatar, Tooltip, useTheme, useMediaQuery, Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SecurityIcon from '@mui/icons-material/Security';
import RecommendIcon from '@mui/icons-material/Recommend';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';
import PageTitle from './PageTitle';
import { HIGH_END_EXAMPLES } from '../data/exampleData';
import RequestCategoriesExplorer from './RequestCategoriesExplorer';
import LeadershipSection from './LeadershipSection';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.default, 0.95)}, 
    ${alpha(theme.palette.background.paper, 0.95)})`,
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}, transparent)`,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200%',
    height: '200%',
    background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.1)} 0%, transparent 70%)`,
    pointerEvents: 'none',
  }
}));

// Update the Section styled component to forward refs
const Section = styled(Box, {
  shouldForwardProp: prop => prop !== 'ref'
})(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
  },
  position: 'relative',
  '&:not(:last-child)::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    height: '1px',
    background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}40, transparent)`,
  },
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
  background: `linear-gradient(145deg, 
    ${alpha(theme.palette.background.paper, 0.8)}, 
    ${alpha(theme.palette.background.default, 0.9)})`,
  backdropFilter: 'blur(12px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-6px)',
    boxShadow: `0 6px 24px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

const ContentCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
  backdropFilter: 'blur(12px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-6px)',
    boxShadow: `0 6px 24px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

const AccentText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
}));

const IconBox = styled(Box)(({ theme }) => ({
  width: 56,
  height: 56,
  [theme.breakpoints.up('md')]: {
    width: 72,
    height: 72,
  },
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  transition: 'all 0.3s ease',
  '& svg': {
    fontSize: 28,
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
    transform: 'scale(1.05)',
  }
}));

const PreviewCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
  background: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  height: '100%',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: `0 8px 32px ${alpha(theme.palette.primary.main, 0.15)}`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  }
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

const StyledChip = styled(Chip)(({ theme, color = 'primary' }) => ({
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 12px ${alpha(theme.palette[color].main, 0.2)}`,
  }
}));

const FeeChip = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.common.white,
  padding: theme.spacing(0.75, 1.5),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
}));

// Create a proper React component for FeeDisplay
const FeeDisplay = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
      {isMobile ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <FeeChip>15%</FeeChip>
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'text.secondary',
              fontWeight: 500
            }}
          >
            Platform Fee
          </Typography>
        </Box>
      ) : (
        <StyledChip 
          label="15% Platform Fee"
          sx={{ width: '30%', bgcolor: 'grey.800' }}
        />
      )}
    </Box>
  );
};

const { recommendations, projects, jobs } = HIGH_END_EXAMPLES;

const ExampleDescription = styled(Box)(({ theme }) => ({
  '& ul, & ol': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '& li': {
      marginBottom: theme.spacing(1),
      position: 'relative',
      paddingLeft: theme.spacing(2),
      '&::before': {
        content: '"•"',
        position: 'absolute',
        left: -theme.spacing(2),
        color: theme.palette.primary.main,
      }
    }
  }
}));

const BidsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const VouchCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  background: alpha(theme.palette.primary.main, 0.05),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  marginTop: theme.spacing(2),
}));

const VouchGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const BidDivider = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  height: '1px',
  background: `linear-gradient(90deg, transparent, ${alpha(theme.palette.primary.main, 0.2)}, transparent)`,
}));

const MetricChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
  '& .MuiChip-label': {
    fontWeight: 600,
  }
}));

const ExampleCard = styled(PreviewCard)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  },
  '& .MuiChip-root': {
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
}));

const TabGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& .tab-label': {
    maxWidth: '120px',
    margin: '0 auto',
  },
}));

const TabLabel = styled('span')({
  display: 'block',
  textTransform: 'none',
});

const HorizontalTabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.25),
  },
}));

const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
  height: '100%',
  minHeight: 80,
  flex: 1,
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  border: `1px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(
    ['background-color', 'border-color', 'transform', 'box-shadow'],
    {
      duration: theme.transitions.duration.shorter,
    }
  ),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, active ? 0.15 : 0.05),
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    flexShrink: 0,
  },
  '& span.tab-label, & .MuiButton-label .tab-label': {
    fontSize: '0.875rem',
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%', // Only use ellipses when content overflows available space
    display: 'block',
    padding: theme.spacing(0, 0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.68rem', 
      letterSpacing: '-0.02em',
      padding: theme.spacing(0, 0.25),
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 0.25),
    minHeight: 70,
  },
}));

const ExampleLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-1.5),
  right: theme.spacing(3),
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
  backdropFilter: 'blur(8px)',
  zIndex: 1,
}));

const About = () => {
  const { isSignedIn } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const examplesRef = useRef(null);

  const [activeTab, setActiveTab] = useState(() => {
    const category = searchParams.get('category');
    if (category === 'recommendations') return 0;
    if (category === 'projects') return 1;
    if (category === 'jobs') return 2;
    return 0;
  });

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const getActiveSampleRequest = useCallback(() => {
    switch (activeTab) {
      case 0:
        return recommendations.request;
      case 1:
        return projects.request;
      case 2:
        return jobs.request;
      default:
        return recommendations.request;
    }
  }, [activeTab]);
  
  const getActiveSampleBids = useCallback(() => {
    switch (activeTab) {
      case 0:
        return recommendations.bids;
      case 1:
        return projects.bids;
      case 2:
        return jobs.bids;
      default:
        return recommendations.bids;
    }
  }, [activeTab, recommendations, projects, jobs]);

  // Add this useEffect to handle scrolling
  useEffect(() => {
    const section = searchParams.get('section');
    if (section === 'examples' && examplesRef.current) {
      // Add a small delay to ensure smooth transition
      setTimeout(() => {
        examplesRef.current.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  }, [location, searchParams]);

  return (
    <Box component="main" sx={{ pb: { xs: 8, md: 12 } }}>
      <PageTitle 
        title="About Vouch" 
        description="Discover how Vouch works: A decentralized platform where trust is built through USDC-backed vouches, enabling reliable recommendations, verified project bids, and trusted job applications."
      />
      <HeroSection>
        <Container maxWidth="lg">
          <Grid container spacing={8} alignItems="center">
            <Grid item xs={12} md={6}>
              <Slide direction="right" in timeout={1000}>
                <Box>
                  <GradientText 
                    variant="h1" 
                    component="h1"
                    sx={{
                      fontWeight: 800,
                      mb: { xs: 2, md: 3 },
                      fontSize: { xs: '2.25rem', sm: '3rem', md: '3.5rem', lg: '4rem' },
                      lineHeight: 1.2,
                    }}
                  >
                    Empowering Collaboration through Trusted, Community-Backed Endorsements
                  </GradientText>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      mb: { xs: 3, md: 4 },
                      opacity: 0.9,
                      lineHeight: 1.6,
                      fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
                      color: 'text.secondary',
                    }}
                  >
                    Vouch enables high-value professional connections through 
                    community-validated endorsements and crypto-incentivized trust. 
                    Join a platform where reputation is built on real stake in outcomes.
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Button
                      component={RouterLink}
                      to={isSignedIn ? "/create-request" : "/requests"}
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        py: 1.5,
                        px: 4,
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        fontWeight: 600,
                        width: { xs: '100%', sm: 'auto' },
                      }}
                    >
                      {isSignedIn ? "Create Request" : "Explore Requests"}
                    </Button>
                    <Button
                      component={RouterLink}
                      to="/requests"
                      variant="outlined"
                      color="primary"
                      size="large"
                      sx={{
                        py: 1.5,
                        px: 4,
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        fontWeight: 600,
                        width: { xs: '100%', sm: 'auto' },
                      }}
                    >
                      View Marketplace
                    </Button>
                  </Box>
                </Box>
              </Slide>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grow in timeout={1500}>
                <Box sx={{ 
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: -20,
                    right: -20,
                    bottom: -20,
                    left: -20,
                    background: `radial-gradient(circle, ${alpha('#00bfa5', 0.1)} 0%, transparent 70%)`,
                    zIndex: -1,
                  }
                }}>
                  <ExampleCard>
                    <Typography variant="h4" gutterBottom sx={{ 
                      color: 'primary.main', 
                      fontWeight: 700,
                      fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
                    }}>
                      Platform Highlights
                    </Typography>
                    <Grid container spacing={2}>
                      {[
                        { 
                          icon: <MonetizationOnIcon />, 
                          text: "Premium Bounties",
                          description: "Substantial rewards to unleash the wisdom of the crowd" 
                        },
                        { 
                          icon: <AccountBalanceWalletIcon />, 
                          text: "Crypto Incentives",
                          description: "USDC-backed rewards for quality contributions" 
                        },
                        { 
                          icon: <GroupIcon />, 
                          text: "Community Validation",
                          description: "Crowd-sourced quality assurance" 
                        },
                        { 
                          icon: <StarIcon />, 
                          text: "Merit-Based Rewards",
                          description: "Recognition for expertise" 
                        },
                      ].map((item, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            gap: 1,
                            p: 2,
                            height: '100%',
                            borderRadius: 2,
                            bgcolor: alpha('#00bfa5', 0.05),
                            border: `1px solid ${alpha('#00bfa5', 0.1)}`,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              bgcolor: alpha('#00bfa5', 0.08),
                              boxShadow: `0 4px 20px ${alpha('#00bfa5', 0.15)}`,
                            }
                          }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {item.icon}
                              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                {item.text}
                              </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                              {item.description}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </ExampleCard>
                </Box>
              </Grow>
            </Grid>
          </Grid>
        </Container>
      </HeroSection>

      {/* New Section with Our Vision and The Vouch Advantage */}
      <Section>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ContentCard>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                  Our Vision
                </Typography>
                <Typography variant="body1" paragraph sx={{ lineHeight: 1.8 }}>
                  At Vouch, we are revolutionizing how professionals connect, collaborate, and build credibility. By integrating community insights with crypto-backed incentives, we've created a platform where authentic endorsements and expertise find new possibilities. Here, trust and talent are earned through transparent, crowd-validated engagement.
                </Typography>
              </ContentCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContentCard>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                  The Vouch Advantage
                </Typography>
                <Typography variant="body1" paragraph sx={{ lineHeight: 1.8 }}>
                  Our unique <AccentText>crowdsourced vouching system</AccentText> empowers the community to identify, endorse, and reward quality work. Vouch doesn't just connect requesters with experts—it fosters a dynamic ecosystem of trust. With each vouch, the community amplifies reliability and authenticity, ensuring unmatched standards in recommendations, projects, and job applications.
                </Typography>
              </ContentCard>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            align="center"
            sx={{ 
              mb: { xs: 6, md: 8 },
              fontWeight: 700,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              background: `linear-gradient(45deg, ${alpha('#fff', 0.95)}, ${alpha('#fff', 0.8)})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            How Vouch Works
          </Typography>
          <Grid container spacing={4}>
          {[
            {
              icon: <MonetizationOnIcon />,
              title: "Value-Driven Requests",
              description: "Create premium opportunities with USDC bounties. For recommendations, the bounty rewards both the expert providing the recommendation and their vouchers. For projects and jobs, the bounty incentivizes quality vouching while actual work compensation is handled separately."
            },
            {
              icon: <GroupIcon />,
              title: "Community Validation",
              description: "Leverage collective wisdom through USDC-backed vouches. Community members stake real value on their endorsements, ensuring genuine support for quality contributions."
            },
            {
              icon: <SecurityIcon />,
              title: "Merit-Based Rewards",
              description: "Automated, transparent bounty distribution based on successful outcomes. For recommendations, both recommender and vouchers share in the bounty. For projects and jobs, vouchers are rewarded for identifying the best bids and applications."
            }
          ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  viewport={{ once: true }}
                >
                  <FeatureCard>
                    <IconBox>
                      {feature.icon}
                    </IconBox>
                    <Typography variant="h5" gutterBottom sx={{ 
                      fontWeight: 600,
                      fontSize: { xs: '1.25rem', md: '1.5rem' }
                    }}>
                      {feature.title}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      color="text.secondary" 
                      sx={{ 
                        lineHeight: 1.8,
                        fontSize: { xs: '0.875rem', md: '1rem' }
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </FeatureCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

      <RequestCategoriesExplorer />

      <Section id="examples" ref={examplesRef}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            align="center"
            sx={{ 
              mb: 2,
              fontWeight: 700,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Platform Overview
          </Typography>
          <Typography 
            variant="h5" 
            align="center"
            color="text.secondary"
            sx={{ 
              mb: { xs: 6, md: 8 }, 
              maxWidth: 800, 
              mx: 'auto',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
            }}
          >
            Experience how Vouch facilitates premium collaborations
          </Typography>
          
          <Box sx={{ mb: { xs: 4, md: 6 } }}>
          {isMobile ? (
            <TabGrid>
              {[
                { icon: <RecommendIcon />, label: 'Recommendations' },
                { icon: <BuildIcon />, label: 'Projects' },
                { icon: <WorkIcon />, label: 'Jobs' },
              ].map((tab, index) => (
                <TabButton
                  key={index}
                  onClick={() => setActiveTab(index)}
                  active={activeTab === index}
                >
                  {tab.icon}
                  <TabLabel className="tab-label">{tab.label}</TabLabel>
                </TabButton>
              ))}
            </TabGrid>
          ) : (
            <HorizontalTabContainer>
              {[
                { icon: <RecommendIcon />, label: 'Recommendations' },
                { icon: <BuildIcon />, label: 'Projects' },
                { icon: <WorkIcon />, label: 'Jobs' },
              ].map((tab, index) => (
                <TabButton
                  key={index}
                  onClick={() => handleTabChange(null, index)}
                  active={activeTab === index}
                >
                  {tab.icon}
                  <TabLabel className="tab-label">{tab.label}</TabLabel>
                </TabButton>
              ))}
            </HorizontalTabContainer>
          )}

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ExampleCard>
                <Box sx={{ p: { xs: 2, md: 3 } }}>
                  <Typography variant="h5" gutterBottom color="primary" sx={{ fontWeight: 600 }}>
                    Sample {activeTab === 0 ? 'Recommendation Request' : 
                          activeTab === 1 ? 'Project Opportunity' : 
                          'Job Position'}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                    <Avatar sx={{ 
                      bgcolor: 'primary.main',
                      width: 56,
                      height: 56,
                    }}>
                      {getActiveSampleRequest().creator[0].toUpperCase()}
                    </Avatar>
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {getActiveSampleRequest().creatorDetails.displayName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        @{getActiveSampleRequest().creator}
                      </Typography>
                    </Box>
                  </Box>

                  <Typography variant="h4" gutterBottom sx={{ 
                    fontWeight: 700,
                    fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                    mb: 3
                  }}>
                    {getActiveSampleRequest().title}
                  </Typography>

                  <ExampleDescription>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                      {getActiveSampleRequest().description}
                    </Typography>
                  </ExampleDescription>

                  <Box sx={{ mt: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <StyledChip 
                      label={`Bounty: $${getActiveSampleRequest().bounty.toLocaleString()} USDC`}
                      color="primary"
                    />
                    {getActiveSampleRequest().category === 'job' && (
                      <StyledChip 
                        label={`Salary: $${getActiveSampleRequest().salaryRange.min.toLocaleString()} - 
                              ${getActiveSampleRequest().salaryRange.max.toLocaleString()}`}
                        color="secondary"
                      />
                    )}
                    {getActiveSampleRequest().category === 'project' && (
                      <StyledChip 
                        label={`Budget: $${getActiveSampleRequest().budget.min.toLocaleString()} - 
                              ${getActiveSampleRequest().budget.max.toLocaleString()}`}
                        color="secondary"
                      />
                    )}
                  </Box>
                </Box>
              </ExampleCard>
            </Grid>

            <Grid item xs={12}>
              <ExampleCard>
                <Box sx={{ p: { xs: 2, md: 3 } }}>
                  <Typography variant="h5" gutterBottom color="primary" sx={{ fontWeight: 600 }}>
                    Expert Responses & Community Validation
                  </Typography>
                  
                  <BidsContainer>
                    {getActiveSampleBids().map((bid, index) => (
                      <React.Fragment key={bid.bidder}>
                        {index > 0 && <BidDivider />}
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                            <Avatar sx={{ 
                              bgcolor: 'secondary.main',
                              width: 48,
                              height: 48,
                            }}>
                              {bid.bidder[0].toUpperCase()}
                            </Avatar>
                            <Box>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                {bid.bidderDetails.displayName}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                @{bid.bidder}
                              </Typography>
                            </Box>
                          </Box>

                          <ExampleDescription>
                            <Typography variant="body1" sx={{ 
                              whiteSpace: 'pre-line',
                              mb: 3,
                            }}>
                              {bid.proposal}
                            </Typography>
                          </ExampleDescription>

                          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 3 }}>
                            {bid.amount && (
                              <MetricChip 
                                label={`Bid: $${bid.amount.toLocaleString()}`}
                              />
                            )}
                            {bid.salary && (
                              <MetricChip 
                                label={`Proposed Salary: $${bid.salary.toLocaleString()}/year`}
                              />
                            )}
                            <MetricChip 
                              label={`Total Vouched: $${bid.totalVouchAmount.toLocaleString()} USDC`}
                            />
                          </Box>

                          <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                            Community Vouches
                          </Typography>
                          
                          <VouchGrid container spacing={2}>
                            {bid.vouches.map((vouch) => (
                              <Grid item xs={12} md={4} key={vouch.voucher}>
                                <VouchCard>
                                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1.5 }}>
                                    <Avatar sx={{ width: 32, height: 32 }}>
                                      {vouch.voucher[0].toUpperCase()}
                                    </Avatar>
                                    <Box>
                                      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                        {vouch.voucherDetails.displayName}
                                      </Typography>
                                      <Typography variant="caption" color="text.secondary">
                                        @{vouch.voucher}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                    {vouch.voucherDetails.bio}
                                  </Typography>
                                  <StyledChip 
                                    label={`Vouched: $${vouch.amount.toLocaleString()} USDC`}
                                    size="small"
                                    color="success"
                                  />
                                </VouchCard>
                              </Grid>
                            ))}
                          </VouchGrid>
                        </Box>
                      </React.Fragment>
                    ))}
                  </BidsContainer>
                </Box>
              </ExampleCard>
            </Grid>
          </Grid>
          </Box>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            align="center"
            sx={{ 
              mb: 2,
              fontWeight: 700,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Incentive Structure
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            sx={{
              mb: { xs: 6, md: 8 },
              maxWidth: 800,
              mx: 'auto',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
            }}
          >
            Our unique reward system ensures fair compensation while protecting community participants
          </Typography>
          
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <motion.div whileHover={{ scale: 1.02 }}>
                <FeatureCard>
                  {/* Header */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <IconBox>
                      <RecommendIcon sx={{ fontSize: 32 }} />
                    </IconBox>
                    <Typography variant="h4" sx={{ 
                      fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
                    }}>
                      Recommendations
                    </Typography>
                  </Box>

                  {/* Bounty Distribution */}
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                      Bounty Distribution
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Tooltip
                        title="Recommenders receive their share of the bounty upon successful outcomes"
                        placement={isMobile ? "bottom" : "right"}
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        sx={{
                          maxWidth: { xs: '90vw', sm: 300 },
                          fontSize: { xs: '0.8rem', sm: '0.875rem' }
                        }}
                      >
                        <StyledChip 
                          label="42.5% to Expert Recommender"
                          color="primary"
                          sx={{ width: '85%' }}
                        />
                      </Tooltip>

                      <Tooltip
                        title="Vouchers receive both their share of the bounty plus their original vouch amount back"
                        placement={isMobile ? "bottom" : "right"}
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        sx={{
                          maxWidth: { xs: '90vw', sm: 300 },
                          fontSize: { xs: '0.8rem', sm: '0.875rem' }
                        }}
                      >
                        <StyledChip 
                          label="42.5% to Supporting Vouchers"
                          color="success"
                          sx={{ width: '85%' }}
                        />
                      </Tooltip>
                      <FeeDisplay />
                    </Box>
                  </Box>

                  {/* Vouch Protection */}
                  <Box sx={{ 
                    mt: 4, 
                    pt: 3, 
                    borderTop: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}` 
                  }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                      Vouch Protection
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Box sx={{ 
                        p: 2, 
                        borderRadius: 1, 
                        bgcolor: theme => alpha(theme.palette.success.main, 0.1),
                        border: theme => `1px solid ${alpha(theme.palette.success.main, 0.2)}`
                      }}>
                        <Typography variant="subtitle2" color="success.main" sx={{ mb: 1, fontWeight: 600 }}>
                          Successful Outcomes
                        </Typography>
                        <Typography variant="body2">
                          When recommendations are successful, vouchers receive both their original vouch amount back plus their share of the bounty reward.
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        p: 2, 
                        borderRadius: 1, 
                        bgcolor: theme => alpha(theme.palette.info.main, 0.1),
                        border: theme => `1px solid ${alpha(theme.palette.info.main, 0.2)}`
                      }}>
                        <Typography variant="subtitle2" color="info.main" sx={{ mb: 1, fontWeight: 600 }}>
                          Non-Selected Recommendations
                        </Typography>
                        <Typography variant="body2">
                          All vouches for non-selected recommendations are automatically refunded in full.
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        p: 2, 
                        borderRadius: 1, 
                        bgcolor: theme => alpha(theme.palette.error.main, 0.1),
                        border: theme => `1px solid ${alpha(theme.palette.warning.main, 0.2)}`
                      }}>
                        <Typography variant="subtitle2" color="warning.main" sx={{ mb: 1, fontWeight: 600 }}>
                          Unsatisfactory Outcomes
                        </Typography>
                        <Typography variant="body2">
                          Vouches are only at risk if the selected recommendation proves unsatisfactory to the requester.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </FeatureCard>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={6}>
              <motion.div whileHover={{ scale: 1.02 }}>
                <FeatureCard>
                  {/* Header */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <IconBox sx={{ display: 'flex', gap: 1 }}>
                      <BuildIcon sx={{ fontSize: 32 }} />
                      <WorkIcon sx={{ fontSize: 32 }} />
                    </IconBox>
                    <Typography variant="h4" sx={{ 
                      ml: 2,
                      fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
                    }}>
                      Projects & Jobs
                    </Typography>
                  </Box>

                  {/* Bounty Distribution */}
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                      Bounty Distribution
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Tooltip
                      title="Vouchers receive both their share of the bounty plus their original vouch amount back"
                      placement={isMobile ? "bottom" : "right"}
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      sx={{
                        maxWidth: { xs: '90vw', sm: 300 },
                        fontSize: { xs: '0.8rem', sm: '0.875rem' }
                      }}
                    >
                      <StyledChip 
                        label="85% to Supporting Vouchers"
                        color="primary"
                        sx={{ width: '85%' }}
                      />
                    </Tooltip>
                      <FeeDisplay />
                    </Box>
                  </Box>

                  {/* Vouch Protection */}
                  <Box sx={{ 
                    mt: 4, 
                    pt: 3, 
                    borderTop: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}` 
                  }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                      Vouch Protection
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Box sx={{ 
                        p: 2, 
                        borderRadius: 1, 
                        bgcolor: theme => alpha(theme.palette.success.main, 0.1),
                        border: theme => `1px solid ${alpha(theme.palette.success.main, 0.2)}`
                      }}>
                        <Typography variant="subtitle2" color="success.main" sx={{ mb: 1, fontWeight: 600 }}>
                          Successful Outcomes
                        </Typography>
                        <Typography variant="body2">
                          For successful placements and completions, vouchers receive their original vouch amount back plus their share of the bounty reward.
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        p: 2, 
                        borderRadius: 1, 
                        bgcolor: theme => alpha(theme.palette.info.main, 0.1),
                        border: theme => `1px solid ${alpha(theme.palette.info.main, 0.2)}`
                      }}>
                        <Typography variant="subtitle2" color="info.main" sx={{ mb: 1, fontWeight: 600 }}>
                          Non-Selected Applications
                        </Typography>
                        <Typography variant="body2">
                          All vouches for non-selected applications or bids are automatically refunded in full.
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        p: 2, 
                        borderRadius: 1, 
                        bgcolor: theme => alpha(theme.palette.error.main, 0.1),
                        border: theme => `1px solid ${alpha(theme.palette.warning.main, 0.2)}`
                      }}>
                        <Typography variant="subtitle2" color="warning.main" sx={{ mb: 1, fontWeight: 600 }}>
                          Unsatisfactory Outcomes
                        </Typography>
                        <Typography variant="body2">
                          Vouches are only at risk if the selected candidate or bid fails to meet the requester's expectations.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </FeatureCard>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <LeadershipSection />

      <Section sx={{ '&:after': { display: 'none' } }}>
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h2" 
            gutterBottom
            sx={{ 
              fontWeight: 800,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              mb: 3,
              background: `linear-gradient(45deg, #00bfa5, #33EAFF)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Get Started Today
          </Typography>
          <Typography 
            variant="h5" 
            color="text.secondary"
            sx={{ 
              mb: 6, 
              lineHeight: 1.6,
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
            }}
          >
            Join the future of professional collaboration where trust is backed by 
            real value and community validation.
          </Typography>
          <Button
            component={RouterLink}
            to={isSignedIn ? "/create-request" : "/requests"}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              py: 2,
              px: 6,
              fontSize: { xs: '1rem', md: '1.1rem' },
              fontWeight: 600,
              backgroundColor: '#00bfa5',
              '&:hover': {
                backgroundColor: '#00a693'
              }
            }}
          >
            {isSignedIn ? "Create Your Request" : "Explore Requests"}
          </Button>
        </Container>
      </Section>
    </Box>
  );
};

export default React.memo(About);