// frontend/src/components/WalletSetup.js

import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Chip, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Divider,
  alpha,
  useTheme,
  IconButton,
  Alert,
  Fade,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { updatePayoutAddress } from '../services/api';
import { useAuth } from '../context/AuthContext';
import { isValidEthereumAddress } from '../utilities/ethereumUtils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'none',
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.4)}`,
    padding: 0,
    overflow: 'hidden',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: alpha(theme.palette.common.black, 0.8),
    backdropFilter: 'blur(4px)',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 0, // Reset any default margins
  '& .MuiButton-root': {
    margin: 0, // Reset MUI's default button margins
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    '& .MuiButton-root': {
      width: '100%',
      margin: 0, // Remove vertical margins completely
    }
  },
  // Override MUI's default spacing between buttons
  '& > :not(:first-of-type)': {
    marginLeft: 0,
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: 120,
  margin: 0, // Reset any default margins
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  backdropFilter: 'blur(10px)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const WalletIconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: '50%',
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.default, 0.8),
    },
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.background.default, 0.8),
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
}));

const AddressChip = styled(Chip)(({ theme, isVerified }) => ({
  maxWidth: '100%',
  height: 'auto',
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  '& .MuiChip-label': {
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.875rem',
    width: '100%',
    textAlign: 'left',
  },
  '& .MuiChip-deleteIcon': {
    margin: theme.spacing(0, 0.5),
  },
  ...(isVerified && {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      transform: 'translateY(-1px)',
      boxShadow: `0 4px 8px ${alpha(theme.palette.common.black, 0.1)}`,
    },
    '&::before': {
      content: '"Click to use"',
      position: 'absolute',
      right: theme.spacing(1),
      fontSize: '0.75rem',
      color: theme.palette.primary.main,
      opacity: 0,
      transition: theme.transitions.create('opacity'),
    },
    '&:hover::before': {
      opacity: 1,
    },
  }),
  transition: theme.transitions.create(['background-color', 'box-shadow', 'transform']),
}));

const VerifiedAddressesSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  '& .verified-addresses-grid': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
  },
}));

const VerifiedAddressHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
}));

const WarningBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.warning.main, 0.05),
  border: `1px solid ${alpha(theme.palette.warning.main, 0.2)}`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '4px',
    height: '100%',
    backgroundColor: theme.palette.warning.main,
  }
}));

const WarningTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.warning.main,
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const WarningText = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.warning.main, 0.9),
  fontSize: '0.875rem',
  lineHeight: 1.6,
}));

const WalletSetup = ({ open, onClose, onComplete, currentAddress }) => {
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [copied, setCopied] = useState(false);
  const { user } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    if (currentAddress) {
      setAddress(currentAddress);
    }
  }, [currentAddress]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setError('');
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(currentAddress);
    setCopied(true);
  };

  const handleSubmit = async () => {
    if (!isValidEthereumAddress(address)) {
      setError('Invalid Ethereum address');
      return;
    }
  
    try {
      setIsSubmitting(true);
      console.log('WalletSetup: Submitting address:', address);
      
      await updatePayoutAddress(address);
      console.log('WalletSetup: Address updated successfully');
      
      if (onComplete) {
        console.log('WalletSetup: Calling onComplete callback');
        onComplete(address);  // Pass the address to onComplete
      }
    } catch (err) {
      console.error('WalletSetup: Error updating address:', err);
      setError(err.response?.data?.message || 'Failed to update payout address');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      TransitionProps={{
        enter: true,
        exit: true,
      }}
    >
      <HeaderSection>
        <WalletIconWrapper>
          <WalletIcon />
        </WalletIconWrapper>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {currentAddress ? 'Update Payout Wallet' : 'Set Up Payout Wallet'}
          </Typography>
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              display: 'block',
              mt: 0.5 
            }}
          >
            Must be compatible with USDC on Base
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose}
          size="small"
          sx={{ 
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.secondary',
          }}
        >
          <CloseIcon />
        </IconButton>
      </HeaderSection>

      {isSubmitting && (
        <LinearProgress 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 2,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          }} 
        />
      )}

      <DialogContent sx={{ p: 3 }}>
        <WarningBox>
          <WarningTitle variant="subtitle1">
            <InfoOutlinedIcon color="warning" />
            Important: Wallet Compatibility Notice
          </WarningTitle>
          <WarningText paragraph>
            This address must be able to receive USDC on the Base network on Ethereum. Most self-custody Ethereum wallets should work correctly.
          </WarningText>
          <WarningText>
            <strong>Exchange Wallet Users:</strong> If you're using an exchange wallet (like Coinbase), please verify that your address can accept USDC on Base. Using an incompatible address may result in permanent loss of funds.
          </WarningText>
        </WarningBox>

        <Divider sx={{ my: 3 }} />
        
        <Box mb={3}>
          <Typography variant="body1" paragraph>
            {user?.isTestUser
              ? 'This is your payout wallet address for testing purposes:'
              : currentAddress 
                ? 'Update your payout wallet address. This address will be used for receiving bounties and vouch returns in USDC.'
                : 'Please set up your payout wallet address. This address will be used for receiving bounties and vouch returns in USDC.'}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'text.secondary' }}>
            <InfoOutlinedIcon sx={{ fontSize: 16 }} />
            <Typography variant="caption">
              Make sure to enter an address that can receive USDC on Base.
            </Typography>
          </Box>
        </Box>
        
        {currentAddress && (
          <Fade in>
            <Box mb={3}>
              <Typography variant="subtitle2" gutterBottom color="text.secondary">
                Current address:
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <AddressChip 
                  label={currentAddress}
                  onDelete={handleCopyAddress}
                  deleteIcon={
                    copied ? 
                      <CheckCircleIcon sx={{ color: 'success.main' }} /> : 
                      <ContentCopyIcon />
                  }
                />
                <Fade in={copied}>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      position: 'absolute',
                      right: 0,
                      top: '100%',
                      mt: 0.5,
                      color: 'success.main',
                    }}
                  >
                    Address copied!
                  </Typography>
                </Fade>
              </Box>
            </Box>
          </Fade>
        )}

        <StyledTextField
          label="Ethereum Address"
          variant="outlined"
          fullWidth
          value={address}
          onChange={handleAddressChange}
          error={!!error}
          helperText={error || "Enter a valid Ethereum address that can receive USDC on Base"}
          disabled={user?.isTestUser || isSubmitting}
          sx={{ mb: 2 }}
        />

        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 2,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            {error}
          </Alert>
        )}

        {!user?.isTestUser && user?.verifications && user.verifications.length > 0 && (
          <VerifiedAddressesSection>
            <VerifiedAddressHeader>
              <Typography variant="subtitle2" color="text.secondary">
                Verified addresses
              </Typography>
              <InfoOutlinedIcon />
              <Typography variant="caption" color="text.secondary">
                Click an address to fill the input field
              </Typography>
            </VerifiedAddressHeader>
            <Box className="verified-addresses-grid">
              {user.verifications.map((verifiedAddress, index) => (
                <AddressChip 
                  key={index}
                  label={verifiedAddress}
                  onClick={() => {
                    setAddress(verifiedAddress);
                    setError('');
                  }}
                  isVerified={true}
                  sx={{
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                      borderRadius: 'inherit',
                      pointerEvents: 'none',
                      opacity: address === verifiedAddress ? 1 : 0,
                      transition: theme.transitions.create('opacity'),
                    },
                  }}
                />
              ))}
            </Box>
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ 
                display: 'block', 
                mt: 2,
                textAlign: 'center',
              }}
              >
              Select an address above and click "Update Payout Address" to confirm
            </Typography>
          </VerifiedAddressesSection>
        )}
      </DialogContent>

      <StyledDialogActions disableSpacing> {/* Add disableSpacing prop */}
        <ActionButton
          onClick={onClose}
          color="primary"
          disabled={isSubmitting}
          variant="outlined"
        >
          Cancel
        </ActionButton>
        {!user?.isTestUser && (
          <ActionButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isSubmitting || !address || !!error}
          >
            {isSubmitting ? 'Processing...' : currentAddress ? 'Update' : 'Set'} Payout Address
          </ActionButton>
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default WalletSetup;