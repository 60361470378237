// frontend/src/components/NotificationModal.js

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  LinearProgress,
  Fade
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#121212', // Very dark background
    backgroundImage: 'none',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(4px)',
  },
}));

const MessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const NotificationModal = ({ 
  open, 
  message, 
  title = "Notice",
  duration = 8000, // Default to 8 seconds
  onClose,
  showProgress = true
}) => {
  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    if (open && showProgress) {
      const startTime = Date.now();
      const timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const remainingProgress = Math.max(0, 100 - (elapsedTime / duration) * 100);
        
        if (remainingProgress <= 0) {
          clearInterval(timer);
          onClose();
        } else {
          setProgress(remainingProgress);
        }
      }, 100);

      return () => clearInterval(timer);
    }
  }, [open, duration, onClose, showProgress]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          pb: 2
        }}
      >
        <InfoIcon color="primary" sx={{ fontSize: 28 }} />
        <Typography variant="h6" component="span" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, pb: 3 }}>
        <MessageBox>
          <Typography 
            variant="body1" 
            component="div" 
            sx={{ 
              lineHeight: 1.6,
              color: 'text.primary',
            }}
          >
            {message.split('\n').map((text, i) => (
              <React.Fragment key={i}>
                {text}
                {i < message.split('\n').length - 1 && <Box sx={{ height: 16 }} />}
              </React.Fragment>
            ))}
          </Typography>
        </MessageBox>
        {showProgress && (
          <Fade in={open}>
            <Box sx={{ width: '100%', mt: 3 }}>
              <StyledLinearProgress variant="determinate" value={progress} />
            </Box>
          </Fade>
        )}
      </DialogContent>
      <DialogActions 
        sx={{ 
          px: 3, 
          pb: 3,
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          pt: 2
        }}
      >
        <Button 
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{
            fontWeight: 600,
            px: 4,
            py: 1,
            borderRadius: 1.5,
            textTransform: 'none',
          }}
        >
          I Understand
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default NotificationModal;