// frontend/src/components/admin/FailedPaymentsList.js

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@mui/material';
import { Refresh as RefreshIcon, Info as InfoIcon } from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getFailedPayments, retryFailedPayment } from '../../services/adminApi';
import { formatDistanceToNow } from 'date-fns';

const FailedPaymentsList = () => {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const queryClient = useQueryClient();

  const { data: failedPayments, isLoading, error, refetch } = useQuery(
    'failedPayments',
    getFailedPayments,
    {
      refetchInterval: 30000, // Refresh every 30 seconds
    }
  );

  const retryMutation = useMutation(retryFailedPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries('failedPayments');
    },
  });

  const handleRetry = async (payment) => {
    try {
      await retryMutation.mutateAsync(payment._id);
    } catch (error) {
      console.error('Error retrying payment:', error);
    }
  };

  const getPaymentTypeChip = (type) => {
    const types = {
      vouch_refund: { label: 'Vouch Refund', color: 'warning' },
      recommender_bounty_payout: { label: 'Recommender Bounty', color: 'info' },
      voucher_bounty_payout: { label: 'Voucher Bounty', color: 'secondary' }
    };
    const config = types[type] || { label: type, color: 'default' };
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading failed payments</Typography>;

  return (
    <>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Failed Payments</Typography>
        <Button
          startIcon={<RefreshIcon />}
          onClick={() => refetch()}
          disabled={isLoading}
        >
          Refresh
        </Button>
      </Box>

      {!failedPayments || failedPayments.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="success.main">
            No failed payments to display
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            All payments are processing normally
          </Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Recipient</TableCell>
                <TableCell>Amount (USDC)</TableCell>
                <TableCell>Failed At</TableCell>
                <TableCell>Retry Count</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {failedPayments.map((payment) => (
                <TableRow key={payment._id}>
                  <TableCell>{getPaymentTypeChip(payment.type)}</TableCell>
                  <TableCell>{payment.recipient}</TableCell>
                  <TableCell>{payment.amount.toFixed(2)}</TableCell>
                  <TableCell>
                    {formatDistanceToNow(new Date(payment.createdAt), { addSuffix: true })}
                  </TableCell>
                  <TableCell>{payment.retryCount}</TableCell>
                  <TableCell>
                    <Chip
                      label={payment.resolved ? 'Resolved' : 'Pending'}
                      color={payment.resolved ? 'success' : 'error'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton
                        size="small"
                        onClick={() => setSelectedPayment(payment)}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    {!payment.resolved && (
                      <Button
                        size="small"
                        onClick={() => handleRetry(payment)}
                        disabled={retryMutation.isLoading}
                        sx={{ ml: 1 }}
                      >
                        Retry
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={Boolean(selectedPayment)}
        onClose={() => setSelectedPayment(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Payment Details</DialogTitle>
        <DialogContent>
          {selectedPayment && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Request ID</Typography>
              <Typography paragraph>{selectedPayment.requestId}</Typography>

              <Typography variant="subtitle2">Recipient Address</Typography>
              <Typography paragraph>{selectedPayment.payoutAddress}</Typography>

              <Typography variant="subtitle2">Error Details</Typography>
              <Typography paragraph color="error">
                {selectedPayment.error.message}
              </Typography>

              {selectedPayment.resolved && (
                <>
                  <Typography variant="subtitle2">Resolution Notes</Typography>
                  <Typography paragraph>{selectedPayment.resolutionNotes}</Typography>

                  <Typography variant="subtitle2">Resolved At</Typography>
                  <Typography paragraph>
                    {formatDistanceToNow(new Date(selectedPayment.resolvedAt), { addSuffix: true })}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedPayment(null)}>Close</Button>
          {selectedPayment && !selectedPayment.resolved && (
            <Button
              onClick={() => handleRetry(selectedPayment)}
              disabled={retryMutation.isLoading}
              color="primary"
            >
              Retry Payment
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FailedPaymentsList;