// frontend/src/components/FarcasterAvatar.js

import React from 'react';
import { Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';

const StyledAvatar = styled(Avatar)(({ theme, size = 'medium' }) => {
  const sizes = {
    small: 24,
    medium: 40,
    large: 64,
    xlarge: 120
  };
  
  const finalSize = sizes[size] || sizes.medium;
  
  return {
    width: finalSize,
    height: finalSize,
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: `0 0 10px ${theme.palette.primary.main}40`,
    },
    backgroundColor: theme.palette.primary.main,
  };
});

const WrapperLink = styled(Link)({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
});

const FarcasterAvatar = ({ 
  username, 
  avatarUrl, 
  size = 'medium', 
  showTooltip = true,
  tooltipPlacement = 'top',
  onClick,
  sx = {}
}) => {
  const avatar = (
    <StyledAvatar
      src={avatarUrl}
      size={size}
      sx={sx}
      onClick={onClick}
    >
      {!avatarUrl && <PersonIcon />}
    </StyledAvatar>
  );

  const wrappedAvatar = onClick ? avatar : (
    <WrapperLink to={`/user/${username}`}>
      {avatar}
    </WrapperLink>
  );

  return showTooltip ? (
    <Tooltip title={username} placement={tooltipPlacement}>
      {wrappedAvatar}
    </Tooltip>
  ) : (
    wrappedAvatar
  );
};

export default FarcasterAvatar;