// frontend/src/components/Footer.js

import React from 'react';
import { Container, Box, Typography, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as FarcasterLogo } from '../assets/farcaster_purple_white.svg';
import { ReactComponent as XLogo } from '../assets/x_logo.svg';

const FooterWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0),
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    opacity: 0.8,
  },
}));

const Footer = () => {
  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          flexWrap: 'wrap',
          gap: 2
        }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Vouch. All rights reserved.
          </Typography>
          <Stack direction="row" spacing={1}>
            <SocialButton
              aria-label="Farcaster"
              href="https://warpcast.com/vouch"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FarcasterLogo style={{ width: 24, height: 24 }} />
            </SocialButton>
            <SocialButton
              aria-label="X (Twitter)"
              href="https://x.com/vouch_wtf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <XLogo style={{ width: 20, height: 20 }} />
            </SocialButton>
          </Stack>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;