// frontend/src/components/RequestActions.js

import React from 'react';
import { Button, Box, Alert, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import GavelIcon from '@mui/icons-material/Gavel';
import DoneIcon from '@mui/icons-material/Done';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import IncreaseBountyButton from './IncreaseBountyButton';

const RequestActions = ({
  request,
  isCreator,
  onEdit,
  onInitiatePayment,
  onCloseBidding,
  isClosingBids,
  onCancelCloseBidding,
  onIncreaseBounty,
  onMarkCompleted,
  onReleaseBounty,
  showToast,
}) => {
  const navigate = useNavigate();

  if (!isCreator) return null;

  const renderActions = () => {
    switch (request.status) {
      case 'draft':
      case 'payment_initiated':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onEdit}
                startIcon={<EditIcon />}
              >
                Edit Request
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onInitiatePayment}
                startIcon={<PaymentIcon />}
              >
                {request.status === 'draft' ? 'Initiate Bounty Payment' : 'Complete Bounty Payment'}
              </Button>
            </Grid>
          </Grid>
        );
      case 'active':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {isClosingBids ? (
                <Button
                  fullWidth
                  variant="outlined"
                  color="warning"
                  onClick={onCancelCloseBidding}
                  startIcon={<CancelIcon />}
                >
                  Cancel Close Bidding
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onCloseBidding}
                  startIcon={<GavelIcon />}
                >
                  Close Bidding
                </Button>
              )}
            </Grid>
            {!isClosingBids && (
              <Grid item xs={12} sm={6}>
                <IncreaseBountyButton
                  requestId={request._id}
                  currentBounty={request.totalBounty}
                  onIncrease={onIncreaseBounty}
                  showToast={showToast}
                  requestType={request.category}
                  buttonProps={{
                    fullWidth: true,
                    variant: 'contained',
                    color: 'secondary',
                    startIcon: <MonetizationOnIcon />,
                  }}
                />
              </Grid>
            )}
          </Grid>
        );
      case 'bidding_closed':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onMarkCompleted}
                startIcon={<DoneIcon />}
              >
                Mark Completed
              </Button>
            </Grid>
          </Grid>
        );
      case 'completed':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onReleaseBounty}
                startIcon={<MonetizationOnIcon />}
              >
                Bounty Decision
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      {renderActions()}
      {request.status === 'completed' && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Please make a decision to release or withhold the bounty.
        </Alert>
      )}
    </Box>
  );
};

export default RequestActions;