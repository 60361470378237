// frontend/src/components/GuideSection.js

import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';

const GuideSection = ({ title, mainDescription, points, documentSuggestions }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        mb: {
          xs: 2,
          sm: 3
        },
        background: theme => `linear-gradient(135deg, 
          ${alpha(theme.palette.background.paper, 0.95)} 0%,
          ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
        backdropFilter: 'blur(10px)',
        borderRadius: {
          xs: 1.5,
          sm: 2
        },
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        overflow: 'hidden',
        boxShadow: theme => `
          inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
          0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
          0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
        `,
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: theme => `radial-gradient(circle at top right, 
            ${alpha(theme.palette.primary.main, 0.03)}, 
            transparent 70%)`,
          pointerEvents: 'none',
        },
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          p: {
            xs: 1.5,
            sm: 2,
            md: 2.5
          },
          borderBottom: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          background: theme => `linear-gradient(90deg,
            ${alpha(theme.palette.primary.main, 0.05)} 0%,
            ${alpha(theme.palette.background.paper, 0.3)} 100%)`,
          backdropFilter: 'blur(10px)',
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            color: 'primary.main',
            fontWeight: 600,
            letterSpacing: '0.02em',
            fontSize: {
              xs: '1rem',
              sm: '1.125rem',
              md: '1.25rem'
            }
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            mt: 1,
            color: 'text.secondary',
            fontWeight: 500,
            fontSize: {
              xs: '0.875rem',
              sm: '0.9375rem',
              md: '1rem'
            },
            lineHeight: {
              xs: 1.4,
              sm: 1.5,
              md: 1.6
            }
          }}
        >
          {mainDescription}
        </Typography>
      </Box>

      {/* Points Grid */}
      <Box sx={{ 
        p: {
          xs: 1.5,
          sm: 2,
          md: 3
        }
      }}>
        <Grid container spacing={{ xs: 1.5, sm: 2, md: 3 }}>
          {points.map((point, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  gap: {
                    xs: 1,
                    sm: 1.5,
                    md: 2
                  },
                  p: {
                    xs: 1.5,
                    sm: 2
                  },
                  borderRadius: 1,
                  background: theme => `linear-gradient(135deg,
                    ${alpha(theme.palette.background.paper, 0.8)} 0%,
                    ${alpha(theme.palette.background.paper, 0.6)} 100%)`,
                  backdropFilter: 'blur(10px)',
                  border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.05)}`,
                  transition: 'all 0.2s ease-in-out',
                  position: 'relative',
                  '&:hover': {
                    transform: {
                      xs: 'translateX(4px)',
                      sm: 'translateX(8px)'
                    },
                    background: theme => `linear-gradient(135deg,
                      ${alpha(theme.palette.primary.main, 0.05)} 0%,
                      ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
                    border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    boxShadow: theme => `
                      0 4px 20px ${alpha(theme.palette.common.black, 0.15)},
                      0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
                    `,
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: theme => `radial-gradient(circle at top right, 
                      ${alpha(theme.palette.primary.main, 0.02)}, 
                      transparent 70%)`,
                    pointerEvents: 'none',
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: 24,
                      sm: 28,
                      md: 32
                    },
                    height: {
                      xs: 24,
                      sm: 28,
                      md: 32
                    },
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexShrink: 0,
                    background: theme => `linear-gradient(135deg,
                      ${alpha(theme.palette.primary.main, 0.2)} 0%,
                      ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
                    border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                    boxShadow: theme => `0 2px 8px ${alpha(theme.palette.primary.main, 0.1)}`
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.8125rem',
                        md: '0.875rem'
                      },
                      fontWeight: 600,
                      color: 'primary.main'
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.primary',
                    lineHeight: 1.6,
                    fontWeight: 500,
                    fontSize: {
                      xs: '0.8125rem',
                      sm: '0.875rem',
                      md: '0.9375rem'
                    }
                  }}
                >
                  {point}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Document Suggestions */}
        {documentSuggestions && (
          <Box sx={{ 
            mt: {
              xs: 2,
              sm: 3,
              md: 4
            }
          }}>
            <Typography
              variant="subtitle1"
              sx={{
                mb: {
                  xs: 1.5,
                  sm: 2
                },
                fontWeight: 600,
                color: 'primary.main',
                fontSize: {
                  xs: '0.9375rem',
                  sm: '1rem',
                  md: '1.125rem'
                }
              }}
            >
              Example Supporting Documents:
            </Typography>
            <Grid container spacing={{ xs: 1, sm: 1.5, md: 2 }}>
              {documentSuggestions.map((doc, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: {
                        xs: 1,
                        sm: 1.5
                      },
                      p: {
                        xs: 1.5,
                        sm: 2
                      },
                      borderRadius: 1,
                      background: theme => `linear-gradient(135deg,
                        ${alpha(theme.palette.background.paper, 0.8)} 0%,
                        ${alpha(theme.palette.background.paper, 0.6)} 100%)`,
                      backdropFilter: 'blur(10px)',
                      border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.05)}`,
                      transition: 'all 0.2s ease-in-out',
                      position: 'relative',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        background: theme => `linear-gradient(135deg,
                          ${alpha(theme.palette.primary.main, 0.05)} 0%,
                          ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
                        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                        boxShadow: theme => `
                          0 4px 20px ${alpha(theme.palette.common.black, 0.15)},
                          0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
                        `,
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: theme => `radial-gradient(circle at top right, 
                          ${alpha(theme.palette.primary.main, 0.02)}, 
                          transparent 70%)`,
                        pointerEvents: 'none',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          xs: 20,
                          sm: 24
                        },
                        height: {
                          xs: 20,
                          sm: 24
                        },
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: theme => `linear-gradient(135deg,
                          ${alpha(theme.palette.primary.main, 0.2)} 0%,
                          ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
                        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '0.6875rem',
                            sm: '0.75rem'
                          },
                          fontWeight: 600,
                          color: 'primary.main'
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        fontWeight: 500,
                        flex: 1,
                        fontSize: {
                          xs: '0.8125rem',
                          sm: '0.875rem',
                          md: '0.9375rem'
                        }
                      }}
                    >
                      {doc}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default GuideSection;