// frontend/src/ProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import LoadingSpinner from './components/LoadingSpinner';

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { isSignedIn, user, checkAdminAuth } = useAuth();
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin || false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      if (!isSignedIn) {
        navigate('/login', { replace: true });
        return;
      }

      if (adminOnly) {
        try {
          const adminStatus = await checkAdminAuth();
          setIsAdmin(adminStatus);
        } catch (error) {
          console.error('Admin auth check failed:', error);
          navigate('/', { replace: true });
        }
      }

      setIsLoading(false);
    };

    verifyAuth();
  }, [isSignedIn, adminOnly, checkAdminAuth, navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;