// frontend/src/components/LeadershipSection.js

import React from 'react';
import { Container, Typography, Box, Avatar, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { StyledCard, CardInner } from './CommonStyledCard';
import farcasterPurpleIcon from '../assets/farcaster_purple.svg';
import xLogo from '../assets/x_logo.svg';

const LeadershipSection = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 8, md: 12 },
        position: 'relative',
        '&:not(:last-child)::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '80%',
          height: '1px',
          background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}40, transparent)`,
        },
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          align="center"
          sx={{ 
            mb: 2,
            fontWeight: 700,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Behind Vouch
        </Typography>
        
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          sx={{
            mb: { xs: 4, md: 6 },
            maxWidth: 800,
            mx: 'auto',
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
            lineHeight: 1.8,
          }}
        >
          Led by veterans in decentralized systems and Web3 infrastructure, bringing trust-driven innovation to professional networks
        </Typography>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <StyledCard>
            <CardInner>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'center', md: 'flex-start' },
                gap: { xs: 3, md: 4 },
                mb: 3
              }}>
                <Avatar
                  src="/wahoo-frog.png"
                  sx={{
                    width: { xs: 120, sm: 140, md: 160 },
                    height: { xs: 120, sm: 140, md: 160 },
                    border: `3px solid ${theme.palette.primary.main}`,
                    boxShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.2)}`,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: `0 0 30px ${alpha(theme.palette.primary.main, 0.3)}`,
                    }
                  }}
                />
                
                <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' } }}>
                  <Typography variant="h4" gutterBottom sx={{ 
                    fontWeight: 700,
                    fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}>
                    wahoo.eth
                  </Typography>
                  
                  <Typography 
                    variant="h6" 
                    color="primary.main" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 600,
                      mb: 2,
                      fontSize: { xs: '1.1rem', sm: '1.25rem', md: '1.5rem' },
                    }}
                  >
                    Founder & Lead Developer
                  </Typography>

                  <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    sx={{ 
                      lineHeight: 1.8,
                      mb: 3,
                      fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' },
                      maxWidth: { md: '90%' },
                    }}
                  >
                    Building on extensive experience in decentralized systems and professional services, 
                    our team is pioneering new standards for trust in Web3. With deep expertise in 
                    crypto-native incentives and scalable infrastructure, we're creating a revolutionary 
                    platform that combines technical innovation with practical business applications. 
                    We're committed to building the future of professional collaboration.
                  </Typography>

                  <Box sx={{ 
                    display: 'flex', 
                    gap: 2, 
                    flexWrap: 'wrap', 
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    position: 'relative', 
                    zIndex: 2 
                  }}>
                    <Box
                      component="a"
                      href="https://warpcast.com/wahoo"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        gap: 1,
                        px: 2,
                        py: 1,
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        border: '1px solid',
                        borderColor: alpha(theme.palette.primary.main, 0.2),
                        borderRadius: 1,
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                        },
                      }}
                    >
                      <img 
                        src={farcasterPurpleIcon}
                        alt="Farcaster"
                        style={{ width: 20, height: 20 }}
                      />
                      @wahoo
                    </Box>

                    <Box
                      component="a"
                      href="https://x.com/wahoopunk"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        gap: 1,
                        px: 2,
                        py: 1,
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        border: '1px solid',
                        borderColor: alpha(theme.palette.primary.main, 0.2),
                        borderRadius: 1,
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                        },
                      }}
                    >
                      <img 
                        src={xLogo}
                        alt="X"
                        style={{ width: 20, height: 20 }}
                      />
                      @wahoopunk
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
                gap: { xs: 2, md: 3 },
                mt: 4,
                pt: 4,
                borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`
              }}>
                {[
                  { label: 'Development', value: 'Full Stack Engineering' },
                  { label: 'Experience', value: 'Professional Services' },
                  { label: 'Focus', value: 'Trust Networks' },
                ].map((metric, index) => (
                  <Box 
                    key={index} 
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: alpha(theme.palette.primary.main, 0.05),
                      border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
                        bgcolor: alpha(theme.palette.primary.main, 0.08),
                      }
                    }}
                  >
                    <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500 }}>
                      {metric.label}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600, color: 'primary.main' }}>
                      {metric.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </CardInner>
          </StyledCard>
        </motion.div>
      </Container>
    </Box>
  );
};

export default LeadershipSection;