// frontend/src/components/MyDashboard.js

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  getMyRequests,
  getMyBids,
  getMyVouches,
  handleApiError,
} from '../services/api';
import { useAuth } from '../context/AuthContext';
import RequestCard from './RequestCard';
import BidCard from './BidCard';
import VouchCard from './VouchCard';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Alert,
  Tooltip,
  Paper,
  Card,
  CardContent,
  TextField,
  Skeleton,
  IconButton,
  useTheme,
  useMediaQuery,
  alpha,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import WorkIcon from '@mui/icons-material/Work';
import RecommendIcon from '@mui/icons-material/Recommend';
import GavelIcon from '@mui/icons-material/Gavel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import usePaymentProcessing from '../hooks/usePaymentProcessing';
import WalletSetup from './WalletSetup';
import useWebSocket from '../hooks/useWebSocket';
import PageTitle from './PageTitle';
import RequestStatusDashboard from './RequestStatusDashboard';
import ConnectionAlert from './ConnectionAlert';

// Styled components
const SummaryCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const WalletPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const WalletInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '56px',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    gap: theme.spacing(2.5),
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    flexDirection: 'row',
  },
}));

const DashboardButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  transition: 'all 0.2s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(1.5),
    },
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
  '&.new-request': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '&.profile': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[4],
    fontSize: '0.875rem',
    maxWidth: 300,
    padding: theme.spacing(1.5),
  },
}));

const TabGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& .tab-label': {
    maxWidth: '120px',
    margin: '0 auto',
  },
}));

const TabLabel = styled('span')({
  display: 'block',
  textTransform: 'none',
});

const HorizontalTabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.between('sm', 'md')]: {
    '& .tab-label': {
      fontSize: '0.75rem',
    },
  },
}));

const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
  height: '100%',
  minHeight: 80,
  flex: 1,
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  border: `1px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(
    ['background-color', 'border-color', 'transform', 'box-shadow'],
    {
      duration: theme.transitions.duration.shorter,
    }
  ),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, active ? 0.15 : 0.05),
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    flexShrink: 0,
  },
  '& .tab-label': {
    fontSize: '0.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.2,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    padding: theme.spacing(0, 0.5),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    padding: theme.spacing(1.25),
    '& .tab-label': {
      fontSize: '0.75rem',
    },
  },
}));

const MyDashboard = ({ showToast }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [requests, setRequests] = useState({
    projects: {},
    jobs: {},
    recommendations: {},
  });
  const [myBids, setMyBids] = useState([]);
  const [vouches, setVouches] = useState({
    projects: [],
    jobs: [],
    recommendations: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const {
    isSignedIn,
    user,
    updateUserPayoutAddress,
  } = useAuth();
  const [showWalletSetup, setShowWalletSetup] = useState(false);
  const [payoutAddress, setPayoutAddress] = useState(user?.payoutAddress || null);
  const { isConnected, manualReconnect } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  const navigate = useNavigate();
  const { isPaymentProcessing, handleProcessPayment, PaymentUI } = usePaymentProcessing(showToast);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchDashboardData = useCallback(async () => {
    if (!isSignedIn) return;

    try {
      setIsLoading(true);
      setError(null);
      const [requestsResult, bidsResult, vouchesResult] = await Promise.all([
        getMyRequests(),
        getMyBids(),
        getMyVouches(),
      ]);

      categorizeRequests(requestsResult.data || []);
      setMyBids(bidsResult.data || []);
      categorizeVouches(vouchesResult.data || { confirmed: [], pending: [] });
    } catch (err) {
      const errorMessage = handleApiError(err);
      setError(errorMessage);
      showToast('Failed to fetch dashboard data: ' + errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [isSignedIn, showToast]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await Promise.all([
        fetchDashboardData(), // or fetchDashboardData() or fetchRequests()
        new Promise(resolve => setTimeout(resolve, 500)), // minimum refresh time
      ]);
      manualReconnect(); // Attempt to reconnect WebSocket
      return true;
    } catch (error) {
      console.error('Error refreshing data:', error);
      showToast('Failed to refresh data', 'error');
      return false;
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchDashboardData, manualReconnect, showToast]); 

  const handleRequestUpdate = useCallback(async (updatedRequest) => {
    await fetchDashboardData();
  }, [fetchDashboardData]);

  useEffect(() => {
    if (user) {
      setPayoutAddress(user.payoutAddress);
      setShowWalletSetup(!user.payoutAddress);
    }
  }, [user]);

  const handleWalletSetupComplete = useCallback(
    async (address) => {
      try {
        await updateUserPayoutAddress(address);
        setPayoutAddress(address);
        setShowWalletSetup(false);
        showToast('Payout address updated successfully', 'success');
      } catch (error) {
        showToast('Failed to update payout address', 'error');
      }
    },
    [showToast, updateUserPayoutAddress]
  );

  const categorizeRequests = (allRequests) => {
    const categorized = {
      projects: {},
      jobs: {},
      recommendations: {},
    };

    allRequests.forEach((request) => {
      const category =
        request.category === 'project'
          ? 'projects'
          : request.category === 'job'
          ? 'jobs'
          : 'recommendations';

      if (!categorized[category][request.status]) {
        categorized[category][request.status] = [];
      }

      if (
        (request.status === 'draft' || request.status === 'payment_pending') &&
        request.expiresAt
      ) {
        const now = new Date();
        const expiresAt = new Date(request.expiresAt);
        request.timeRemaining = Math.max(
          0,
          Math.floor((expiresAt - now) / 1000)
        );
      }

      categorized[category][request.status].push(request);
    });

    setRequests(categorized);
  };

  const categorizeVouches = (vouchesData) => {
    const categorized = {
      projects: [],
      jobs: [],
      recommendations: [],
    };

    const processVouches = (vouches) => {
      if (Array.isArray(vouches)) {
        vouches.forEach((vouch) => {
          const categoryKey =
            vouch.requestCategory === 'project'
              ? 'projects'
              : vouch.requestCategory === 'job'
              ? 'jobs'
              : 'recommendations';
          categorized[categoryKey].push(vouch);
        });
      }
    };

    processVouches(vouchesData.confirmed || []);
    processVouches(vouchesData.pending || []);

    setVouches(categorized);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePaymentInitiation = useCallback(
    async (requestId, version, requestDetails) => {
      if (!payoutAddress) {
        setShowWalletSetup(true);
        return;
      }
      try {
        await handleProcessPayment(requestId, version, requestDetails);
        setTimeout(() => {
          fetchDashboardData();
        }, 2000);
      } catch (error) {
        showToast('Failed to initiate payment. Please try again.', 'error');
      }
    },
    [handleProcessPayment, showToast, fetchDashboardData, payoutAddress]
  );

  const isCategoryEmpty = (categoryRequests) => {
    return !Object.values(categoryRequests || {}).some(
      (statusArray) => Array.isArray(statusArray) && statusArray.length > 0
    );
  };

  const renderRequestSection = useCallback(
    (title, requestList, isDraft = false, isPaymentPending = false) => {
      const filteredRequestList = Array.isArray(requestList)
        ? requestList
        : [];

      if (filteredRequestList.length === 0) {
        return null;
      }

      return (
        <Box sx={{ mb: { xs: 3, sm: 5 } }}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          {isDraft && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Reminder: You need to complete the bounty payment to activate your
              draft requests.
            </Alert>
          )}
          {isPaymentPending && (
            <Alert severity="info" sx={{ mb: 2 }}>
              Payment is being processed for these requests. They will be
              activated once the payment is confirmed.
            </Alert>
          )}
          <Grid container spacing={{ xs: 2, sm: 3 }}>
            {filteredRequestList.map((request) => (
              <Grid item xs={12} sm={12} md={6} key={request._id}>
                <RequestCard
                  request={request}
                  showManageOptions={true}
                  isMyRequest={true}
                  showToast={showToast}
                  onUpdate={handleRequestUpdate}
                  onProcessPayment={handlePaymentInitiation}
                  isPaymentProcessing={isPaymentProcessing}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    },
    [
      handlePaymentInitiation,
      isPaymentProcessing,
      handleRequestUpdate,
      showToast,
    ]
  );

  const renderTabContent = () => {
    if (isLoading) {
      return (
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Skeleton
                variant="rectangular"
                height={200}
                sx={{ borderRadius: 1 }}
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    switch (activeTab) {
      case 0: // Recommendations
        if (isCategoryEmpty(requests.recommendations)) {
          return (
            <Typography align="center" sx={{ mt: 3 }}>
              You have no recommendation requests.
            </Typography>
          );
        }
        return (
          <>
            {renderRequestSection(
              'Draft Recommendations',
              requests.recommendations.draft,
              true
            )}
            {renderRequestSection(
              'Recommendations with Payment Pending',
              requests.recommendations.payment_pending,
              false,
              true
            )}
            {renderRequestSection(
              'Active Recommendation Requests',
              requests.recommendations.active
            )}
            {renderRequestSection(
              'Closed Recommendation Requests',
              requests.recommendations.bidding_closed
            )}
            {renderRequestSection(
              'Recommendations In Progress',
              requests.recommendations.in_progress
            )}
            {renderRequestSection(
              'Completed Recommendations',
              [
                ...(requests.recommendations.completed || []),
                ...(requests.recommendations.bounty_released || []),
                ...(requests.recommendations.bounty_withheld || []),
              ]
            )}
          </>
        );
      case 1: // Projects
        if (isCategoryEmpty(requests.projects)) {
          return (
            <Typography align="center" sx={{ mt: 3 }}>
              You have no project requests.
            </Typography>
          );
        }
        return (
          <>
            {renderRequestSection(
              'Draft Projects',
              requests.projects.draft,
              true
            )}
            {renderRequestSection(
              'Projects with Payment Pending',
              requests.projects.payment_pending,
              false,
              true
            )}
            {renderRequestSection(
              'Projects Open for Bids',
              requests.projects.active
            )}
            {renderRequestSection(
              'Projects with Closed Bidding',
              requests.projects.bidding_closed
            )}
            {renderRequestSection(
              'Projects In Progress',
              requests.projects.in_progress
            )}
            {renderRequestSection(
              'Completed Projects',
              [
                ...(requests.projects.completed || []),
                ...(requests.projects.bounty_released || []),
                ...(requests.projects.bounty_withheld || []),
              ]
            )}
          </>
        );
      case 2: // Jobs
        if (isCategoryEmpty(requests.jobs)) {
          return (
            <Typography align="center" sx={{ mt: 3 }}>
              You have no job requests.
            </Typography>
          );
        }
        return (
          <>
            {renderRequestSection('Draft Jobs', requests.jobs.draft, true)}
            {renderRequestSection(
              'Jobs with Payment Pending',
              requests.jobs.payment_pending,
              false,
              true
            )}
            {renderRequestSection(
              'Jobs Accepting Applications',
              requests.jobs.active
            )}
            {renderRequestSection(
              'Jobs with Closed Applications',
              requests.jobs.bidding_closed
            )}
            {renderRequestSection(
              'Jobs In Progress',
              requests.jobs.in_progress
            )}
            {renderRequestSection(
              'Completed Jobs',
              [
                ...(requests.jobs.completed || []),
                ...(requests.jobs.bounty_released || []),
                ...(requests.jobs.bounty_withheld || []),
              ]
            )}
          </>
        );
      case 3: // My Responses
        if (myBids.length === 0) {
          return (
            <Typography align="center" sx={{ mt: 3 }}>
              You have not made any responses yet.
            </Typography>
          );
        }
        return renderMyBidsSection();
      case 4: // Vouches
        if (
          Object.values(vouches).every(
            (vouchList) => vouchList.length === 0
          )
        ) {
          return (
            <Typography align="center" sx={{ mt: 3 }}>
              You have not made any vouches yet.
            </Typography>
          );
        }
        return renderVouchesSection();
      default:
        return null;
    }
  };

  const renderMyBidsSection = useCallback(() => {
    return (
      <Box sx={{ mb: { xs: 3, sm: 5 } }}>
        <Typography
          variant="h6"
          component="h3"
          gutterBottom
          sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 'bold',
          }}
        >
          My Responses
        </Typography>
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          sx={{ mb: { xs: 3, sm: 5 } }}
        >
          {myBids.map((bid) => (
            <Grid item xs={12} sm={12} md={6} key={bid._id}>
              <BidCard
                bid={bid}
                requestCategory={bid.requestCategory}
                requestTitle={bid.requestTitle}
                requestId={bid.requestId}
                showToast={showToast}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }, [myBids, showToast]);

  const renderVouchesSection = useCallback(() => {
    const vouchCategories = [
      {
        key: 'recommendations',
        title: 'Recommendations',
        vouchType: 'Recommendation',
      },
      { key: 'projects', title: 'Project Bids', vouchType: 'Bid' },
      { key: 'jobs', title: 'Job Applications', vouchType: 'Application' },
    ];

    return (
      <Box sx={{ mb: { xs: 3, sm: 5 } }}>
        {vouchCategories.map(({ key, title, vouchType }) => {
          if (vouches[key].length === 0) {
            return null;
          }

          return (
            <React.Fragment key={key}>
              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                sx={{
                  fontSize: { xs: '1.25rem', sm: '1.5rem' },
                  fontWeight: 'bold',
                }}
              >
                My Vouches for {title}
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, sm: 3 }}
                sx={{ mb: { xs: 3, sm: 5 } }}
              >
                {vouches[key].map((vouch) => (
                  <Grid item xs={12} sm={12} md={6} key={vouch._id}>
                    <VouchCard
                      vouch={vouch}
                      bidderUsername={vouch.bidderUsername}
                      requestTitle={vouch.requestTitle}
                      requestId={vouch.requestId}
                      bidType={vouchType}
                      showToast={showToast}
                    />
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          );
        })}
      </Box>
    );
  }, [vouches, showToast]);

  // Tabs configuration
  const tabs = [
    {
      icon: <RecommendIcon />,
      label: 'Recommendations',
      tooltip: 'My Recommendation Requests',
    },
    {
      icon: <BuildIcon />,
      label: 'Projects',
      tooltip: 'My Project Requests',
    },
    {
      icon: <WorkIcon />,
      label: 'Jobs',
      tooltip: 'My Job Requests',
    },
    {
      icon: <GavelIcon />,
      label: 'My Responses',
      tooltip: 'My Recommendations, Bids, and Applications',
    },
    {
      icon: <ThumbUpIcon />,
      label: 'Vouches',
      tooltip: 'My Vouches',
    },
  ];

  if (!isSignedIn || !user) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <PageTitle 
          title="Sign In Required" 
          description="Please sign in to view your Vouch dashboard."
        />
        <Typography>Please sign in to view your dashboard.</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, px: { xs: 2, sm: 3 } }}>
      <PageTitle 
        title="My Dashboard" 
        description="View and manage your requests, responses, and vouches on Vouch."
      />
      {!isConnected && (
        <ConnectionAlert
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
        />
      )}

      {/* Header */}
      <Header>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: { xs: '1.75rem', sm: '2rem' },
            fontWeight: 'bold',
            lineHeight: 1.2,
          }}
        >
          My Dashboard
        </Typography>
        <ButtonGroup>
          <DashboardButton
            component={Link}
            to="/create-request"
            className="new-request"
            startIcon={<AddIcon />}
          >
            {isMobile ? 'New Request' : 'Create New Request'}
          </DashboardButton>
          <DashboardButton
            component={Link}
            to={`/user/${user.username}`}
            variant="outlined"
            className="profile"
            startIcon={<PersonIcon />}
          >
            My Profile
          </DashboardButton>
        </ButtonGroup>
      </Header>

      {/* Wallet Setup */}
      <WalletPaper>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              mr: 1,
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
            }}
          >
            Payout Wallet
          </Typography>
          <StyledTooltip
            title={
              <React.Fragment>
                <Typography variant="body2">
                  Your payout wallet address is used to receive:
                </Typography>
                <ul>
                  <li>Your share of bounties for successful vouches</li>
                  <li>Returns of vouches</li>
                </ul>
                <Typography variant="body2">
                  Ensure this address is secure and under your control.
                </Typography>
              </React.Fragment>
            }
            arrow
          >
            <InfoOutlinedIcon
              fontSize="small"
              color="action"
              sx={{ cursor: 'pointer' }}
            />
          </StyledTooltip>
        </Box>

        {payoutAddress ? (
          <Box>
            <Typography variant="body1" gutterBottom>
              Current Payout Address:
            </Typography>
            <WalletInputContainer>
              <StyledTextField
                fullWidth
                variant="outlined"
                value={payoutAddress}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  flexGrow: 1,
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowWalletSetup(true)}
                startIcon={<EditIcon />}
                sx={{
                  height: '56px',
                  whiteSpace: 'nowrap',
                  minWidth: { xs: '100%', sm: 'auto' },
                }}
              >
                Edit
              </Button>
            </WalletInputContainer>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowWalletSetup(true)}
            startIcon={<AddIcon />}
            fullWidth
            sx={{ height: '56px' }}
          >
            Set Up Payout Wallet
          </Button>
        )}
      </WalletPaper>

      {/* Error Alert */}
      {error && (
        <Alert
          severity="error"
          sx={{ mb: 3 }}
          action={
            <IconButton color="inherit" size="small" onClick={fetchDashboardData}>
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}

      {/* Request Status Dashboard */}
      <RequestStatusDashboard 
        requests={Object.values(requests)
          .flatMap(categoryRequests => 
            Object.entries(categoryRequests)
              .filter(([status]) => ['draft', 'active', 'bidding_closed', 'completed'].includes(status))
              .flatMap(([_, reqs]) => reqs)
          )
        }
        myBids={myBids}
        vouches={vouches}
      />

      {/* Tabs */}
      {isMobile ? (
        <TabGrid>
          {tabs.map((tab, index) => (
            <StyledTooltip key={index} title={tab.tooltip}>
              <TabButton
                onClick={() => handleTabChange(null, index)}
                active={activeTab === index}
              >
                {tab.icon}
                <TabLabel className="tab-label">{tab.label}</TabLabel>
              </TabButton>
            </StyledTooltip>
          ))}
        </TabGrid>
      ) : (
        <HorizontalTabContainer>
          {tabs.map((tab, index) => (
            <StyledTooltip key={index} title={tab.tooltip}>
              <TabButton
                onClick={() => handleTabChange(null, index)}
                active={activeTab === index}
              >
                {tab.icon}
                <TabLabel className="tab-label">{tab.label}</TabLabel>
              </TabButton>
            </StyledTooltip>
          ))}
        </HorizontalTabContainer>
      )}

      {/* Tab Content */}
      {renderTabContent()}

      {/* Payment UI */}
      {PaymentUI && <PaymentUI />}

      {/* Wallet Setup Dialog */}
      <WalletSetup
        open={showWalletSetup}
        onClose={() => setShowWalletSetup(false)}
        onComplete={handleWalletSetupComplete}
        currentAddress={payoutAddress}
      />
    </Container>
  );
};

export default React.memo(MyDashboard);
