// frontend/src/components/ReleaseBounty.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Typography, 
  Box, 
  Grid, 
  Paper,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  alpha,
  Slide,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WarningIcon from '@mui/icons-material/Warning';
import { releaseBounty } from '../services/api';
import ProcessCompletionMessage from './ProcessCompletionMessage';
import useWebSocket from '../hooks/useWebSocket';

const ProcessContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6), // Increased padding
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
  boxShadow: theme.shadows[10],
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  '& > .MuiTypography-h4': {
    marginBottom: theme.spacing(4), // Increased spacing after title
  }
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(6), // Increased from default spacing
  '& .MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    transition: 'color 0.3s ease-in-out',
    fontSize: '1rem', // Make text slightly larger
    marginTop: theme.spacing(1), // Add space between icon and text
  },
  '& .MuiStepLabel-completed': {
    color: theme.palette.success.main,
  },
  '& .MuiStepIcon-root': {
    transition: 'all 0.3s ease-in-out',
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.success.main,
    transform: 'scale(1.2)',
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
    animation: 'pulse 2s infinite',
  },
}));

const ProcessStatus = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
}));

const AnimatedIcon = styled(motion.div)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const MessageCard = styled(InfoCard)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4),
  '& .MuiTypography-h5': {
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  '& .MuiTypography-body1': {
    marginBottom: theme.spacing(2),
    lineHeight: 1.6,
  },
  '& .highlight': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  fontSize: '1.1rem',
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease-in-out',
  minHeight: 56, // Ensure consistent height
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(2),
  }
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  '& .MuiLinearProgress-root': {
    height: 8,
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
    backgroundImage: `linear-gradient(45deg, 
      ${theme.palette.primary.main} 25%, 
      ${theme.palette.primary.light} 50%, 
      ${theme.palette.primary.main} 75%
    )`,
    backgroundSize: '200% 200%',
    animation: 'gradient 2s linear infinite',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.info.main, 0.3)}`,
  backdropFilter: 'blur(10px)',
  '& .MuiAlert-icon': {
    color: theme.palette.info.main,
  },
}));

const steps = ['Confirm Decision', 'Process Distribution', 'Request Complete'];

const ReleaseBounty = ({ request, showToast, onBountyDecision }) => {
  const [action, setAction] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('');
  const [paymentProgress, setPaymentProgress] = useState(0);
  const [isProcessComplete, setIsProcessComplete] = useState(false);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const completionTimeoutRef = useRef(null);
  const { lastUpdate } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, request?._id);
  const hasHandledCompletion = useRef(false);

  useEffect(() => {
    if (lastUpdate) {
      console.debug('Processing bounty release update:', lastUpdate);

      switch (lastUpdate.type) {
        case 'BOUNTY_RELEASE_STARTED':
          setActiveStep(0);
          setProcessingStatus('Initiating bounty release process...');
          break;

        case 'BOUNTY_RELEASE_PROGRESS':
          const { step, progress, message, details } = lastUpdate.data;
          setActiveStep(1);
          
          // Enhanced status message with completion tracking
          if (details?.total && details?.current) {
            const remaining = details.total - details.current;
            const statusMessage = remaining > 0
              ? `Processing payment ${details.current} of ${details.total}: ${message}`
              : 'Finalizing all payments...';
            setProcessingStatus(statusMessage);
            // Add a slight delay before setting to 100% to ensure visibility
            if (details.current === details.total) {
              setTimeout(() => setPaymentProgress(100), 500);
            } else {
              setPaymentProgress((details.current / details.total) * 100);
            }
          } else {
            setProcessingStatus(message || 'Processing payments...');
            setPaymentProgress(progress || 0);
          }
          break;

        case 'BOUNTY_RELEASED':
        case 'BOUNTY_WITHHELD':
          setActiveStep(2);
          setProcessingStatus(lastUpdate.type === 'BOUNTY_RELEASED' ? 
            'Bounty released successfully!' : 
            'Bounty has been withheld');
          
          // Only set the completion state if we haven't already handled it
          if (!hasHandledCompletion.current) {
            hasHandledCompletion.current = true; // Set the flag to true
            setIsProcessComplete(true);
            setShowCompletionDialog(true);
          }
          break;

        case 'BOUNTY_RELEASE_ERROR':
          setError(lastUpdate.data.error);
          setProcessingStatus('Error processing bounty release');
          break;
      }
    }
  }, [lastUpdate, onBountyDecision]);

  const handleBountyAction = (selectedAction) => {
    setAction(selectedAction);
    setShowConfirmDialog(true);
  };

  const handleConfirm = async () => {
    try {
      setShowConfirmDialog(false);
      setActiveStep(0);
      setError(null);
      setIsProcessing(true);

      showToast(
        `Starting bounty ${action} process. You can safely close this window - the process will continue in the background.`,
        'info',
        8000
      );

      await releaseBounty(request._id, action === 'release');
    } catch (error) {
      console.error('Error starting bounty process:', error);
      setError(error.message);
      setIsProcessing(false);
      showToast(`Failed to ${action} bounty: ${error.message}`, 'error');
    }
  };

  const handleCompletionClose = useCallback(() => {
    setShowCompletionDialog(false);
    if (onBountyDecision) {
      onBountyDecision();
    }
  }, [onBountyDecision]);
  
  useEffect(() => {
    return () => {
      if (completionTimeoutRef.current) {
        clearTimeout(completionTimeoutRef.current);
      }
    };
  }, []);

  const renderProcessingStatus = () => (
    <Slide direction="up" in={true}>
      <ProcessStatus>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {processingStatus}
            </Typography>
            {activeStep === 1 && (
              <Box sx={{ mt: 2 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={paymentProgress}
                  sx={(theme) => ({
                    height: 8,
                    borderRadius: 4,
                    '& .MuiLinearProgress-bar': {
                      transition: 'transform 0.5s ease-out'
                    }
                  })}
                />
                <Typography variant="caption" sx={{ display: 'block', textAlign: 'right', mt: 0.5 }}>
                  {Math.round(paymentProgress)}%
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimatedIcon
              key={activeStep}
              initial={{ scale: 0, rotate: -180 }}
              animate={{ scale: 1, rotate: 0 }}
              exit={{ scale: 0, rotate: 180 }}
              transition={{ duration: 0.5 }}
            >
              {activeStep === 0 && <AttachMoneyIcon color="primary" sx={{ fontSize: 48 }} />}
              {activeStep === 1 && <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 48 }} />}
              {activeStep === 2 && <DoneAllIcon color="success" sx={{ fontSize: 48 }} />}
            </AnimatedIcon>
          </Grid>
        </Grid>

        {!isProcessComplete && (
          <StyledAlert 
            severity="info" 
            icon={<AutorenewIcon className="rotating-icon" />}
            sx={{ 
              mt: 2,
              '& .rotating-icon': {
                animation: 'spin 2s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' }
                }
              }
            }}
          >
            Process running in background. You can safely close this window - 
            we'll notify you when it's complete.
          </StyledAlert>
        )}
      </ProcessStatus>
    </Slide>
  );

  return (
    <>
      <ProcessContainer>
        <Typography variant="h4" gutterBottom color="primary">
          {isProcessComplete ? 'Bounty Decision Complete' : 'Bounty Decision'}
        </Typography>

        <StyledStepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </StyledStepper>

        {error ? (
          <Alert
            severity="error"
            sx={{ mt: 4 }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => handleBountyAction(action)}
                startIcon={<AutorenewIcon />}
              >
                Retry
              </Button>
            }
          >
            {error}
          </Alert>
        ) : isProcessing || processingStatus ? (
          renderProcessingStatus()
        ) : isProcessComplete ? (
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12}>
              <MessageCard>
                <Typography variant="h5">
                  {action === 'release' ? 'Bounty Released' : 'Bounty Withheld'}
                </Typography>
                <Typography variant="body1">
                  The bounty has been{' '}
                  {action === 'release' ? 'successfully released to the participants' : 'withheld'}.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={handleCompletionClose}
                >
                  Continue
                </Button>
              </MessageCard>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <InfoCard>
                  <Typography variant="h6" gutterBottom>
                    Request Details
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {request.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Bounty: $
                    {request?.totalBounty
                      ? request.totalBounty.toFixed(2)
                      : request?.bounty
                      ? request.bounty.toFixed(2)
                      : '0.00'}{' '}
                    USDC
                  </Typography>
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard>
                  <Typography variant="h6" gutterBottom>
                    Bounty Distribution
                  </Typography>
                  {request.category === 'recommendation' ? (
                    <>
                      <Typography variant="body2" paragraph>
                        • 42.5% to the recommender
                      </Typography>
                      <Typography variant="body2" paragraph>
                        • 42.5% distributed among vouchers
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2" paragraph>
                      • 85% distributed among vouchers
                    </Typography>
                  )}
                  <Typography variant="body2">• 15% retained by the system</Typography>
                </InfoCard>
              </Grid>
            </Grid>

            <Box sx={{ 
              mt: 6,  // Increased top margin
              display: 'flex', 
              flexDirection: 'column', // Always vertical
              alignItems: 'stretch',
              gap: 2,
              maxWidth: '320px', // Limit maximum width of buttons
              mx: 'auto', // Center the button container
            }}>
              <ActionButton
                variant="contained"
                color="success"
                onClick={() => handleBountyAction('release')}
                startIcon={isProcessing ? <CircularProgress size={24} /> : <CheckCircleIcon />}
                disabled={isProcessing}
                fullWidth // Make button full width
              >
                Release Bounty
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="error"
                onClick={() => handleBountyAction('withhold')}
                startIcon={<BlockIcon />}
                disabled={isProcessing}
                fullWidth // Make button full width
              >
                Withhold Bounty
              </ActionButton>
            </Box>
          </>
        )}
      </ProcessContainer>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {action === 'release' ? (
              <CheckCircleIcon color="success" sx={{ mr: 1 }} />
            ) : (
              <WarningIcon color="error" sx={{ mr: 1 }} />
            )}
            Confirm {action === 'release' ? 'Release' : 'Withhold'} Bounty
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to {action === 'release' ? 'release' : 'withhold'} the bounty?
            This action cannot be undone.
          </Typography>
          {action === 'release' ? (
            <StyledAlert severity="success" sx={{ mt: 2 }}>
              {request.category === 'recommendation'
                ? 'The recommender and vouchers will receive their portions of the bounty based on their contributions.'
                : 'The vouchers will receive their portions of the bounty based on their vouch amounts.'}
            </StyledAlert>
          ) : (
            <StyledAlert severity="warning" sx={{ mt: 2 }}>
              The bounty will be retained by the system. No funds will be distributed to
              {request.category === 'recommendation' ? ' the recommender or ' : ' '}vouchers.
            </StyledAlert>
          )}
        </DialogContent>
          <DialogActions sx={{ 
            flexDirection: { xs: 'column', sm: 'row' },
            '& > button': {
              width: { xs: '100%', sm: 'auto' },
              mb: { xs: 1, sm: 0 }
            },
            px: { xs: 2, sm: 3 },
            pb: { xs: 2, sm: 3 }
          }}>
            <Button
              onClick={() => setShowConfirmDialog(false)}
              color={action === 'release' ? 'inherit' : 'primary'}
              sx={{ 
                order: { xs: 2, sm: 1 },
                mr: { sm: 1 },
                fontSize: { 
                  xs: '0.875rem',
                  sm: '1rem'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color={action === 'release' ? 'success' : 'error'}
              variant="contained"
              autoFocus
              startIcon={action === 'release' ? <CheckCircleIcon /> : <BlockIcon />}
              sx={{ 
                order: { xs: 1, sm: 2 },
                fontSize: { 
                  xs: '0.875rem',
                  sm: '1rem'
                },
                '& .MuiButton-startIcon': {
                  marginRight: { xs: 1, sm: 2 }
                }
              }}
            >
              Confirm {action === 'release' ? 'Release' : 'Withhold'}
            </Button>
          </DialogActions>
      </Dialog>

      {/* Completion Dialog */}
      <Dialog
        open={showCompletionDialog}
        onClose={handleCompletionClose}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <DoneAllIcon color="success" sx={{ mr: 1 }} />
            Bounty Process Complete
          </Box>
        </DialogTitle>
        <DialogContent>
          <ProcessCompletionMessage processType="bounty" />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCompletionClose}
            variant="contained" 
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default ReleaseBounty;