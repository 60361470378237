// frontend/src/utilities/logrocketConfig.js

import LogRocket from 'logrocket';

// Store original console methods
const originalConsole = {
  log: console.log,
  warn: console.warn,
  error: console.error,
  info: console.info,
  debug: console.debug,
};

// Disable console in production
const disableConsole = () => {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
};

// Enable console for non-production
const enableConsole = () => {
  console.log = originalConsole.log;
  console.warn = originalConsole.warn;
  console.error = originalConsole.error;
  console.info = originalConsole.info;
  console.debug = originalConsole.debug;
};

export const initializeLogging = () => {
  const environment = process.env.REACT_APP_ENV || 'development';
  
  if (environment === 'development') {
    enableConsole();
    return;
  }

  // Use different app IDs for staging and production
  const appId = environment === 'production' 
    ? process.env.REACT_APP_LOGROCKET_PROD_ID
    : process.env.REACT_APP_LOGROCKET_STAGING_ID;

  LogRocket.init(appId, {
    console: {
      isEnabled: true,
      // In staging, only capture errors and warnings
      // In production, capture everything
      filter: environment === 'staging' 
        ? ['error', 'warn']
        : null
    },
    network: {
      // Sanitize sensitive data
      requestSanitizer: request => {
        if (request.url.includes('/api/auth') || 
            request.url.includes('/api/payments')) {
          request.body = '[REDACTED]';
        }
        return request;
      },
      responseSanitizer: response => {
        if (response.url.includes('/api/auth') || 
            response.url.includes('/api/payments')) {  // Fixed: using response instead of request
          response.body = '[REDACTED]';
        }
        return response;
      }
    }
  });

  // In production, disable console logs in browser
  if (environment === 'production') {
    disableConsole();
  }
};

export const identifyUser = (user) => {
  const environment = process.env.REACT_APP_ENV || 'development';
  
  if (environment !== 'development') {
    LogRocket.identify(user.username, {
      name: user.displayName,
      fid: user.fid,
      environment
    });
  }
};