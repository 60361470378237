// frontend/src/components/FarcasterUsername.js
import React from 'react';
import { Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const FarcasterUsername = ({ username }) => {
  if (!username) return null;

  return (
    <StyledLink component={RouterLink} to={`/user/${username}`} variant="body2">
      {username}
    </StyledLink>
  );
};

export default FarcasterUsername;
