// frontend/src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00bfa5',
      light: '#33ecd0',
      dark: '#008573',
    },
    secondary: {
      main: '#7C4DFF',
      light: '#9e7fff',
      dark: '#5635b2',
    },
    background: {
      default: '#121212',
      paper: '#171717',
      elevated: '#1E1E1E',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0BEC5',
    },
    error: {
      main: '#FF5252',
      light: '#FF867F',
      dark: '#C50E29',
    },
    warning: {
      main: '#FFB74D',
      light: '#FFC677',
      dark: '#C88719',
    },
    info: {
      main: '#64B5F6',
      light: '#9BE7FF',
      dark: '#2286C3',
    },
    success: {
      main: '#69F0AE',
      light: '#B9F6CA',
      dark: '#00C853',
    },
    farcaster: {
      main: '#8470FF',
      light: '#A594FF',
      dark: '#6B5ADB',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#ffffff',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Arial", sans-serif',
    h1: {
      fontWeight: 800,
      marginBottom: '24px',
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontWeight: 700,
      marginBottom: '20px',
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontWeight: 700,
      marginBottom: '16px',
      letterSpacing: '0em',
    },
    h4: {
      fontWeight: 600,
      marginBottom: '16px',
      letterSpacing: '0.00735em',
    },
    h5: {
      fontWeight: 600,
      marginBottom: '16px',
      letterSpacing: '0em',
    },
    h6: {
      fontWeight: 600,
      marginBottom: '16px',
      letterSpacing: '0.0075em',
    },
    body1: {
      marginBottom: '16px',
      letterSpacing: '0.00938em',
    },
    button: {
      letterSpacing: '0.02857em',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: {
          transition: 'all 0.3s ease-in-out',
        },
        body: {
          minHeight: '100vh',
          lineHeight: 1.6,
          background: '#121212',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#171717',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#00bfa5',
            borderRadius: '3px',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(23, 23, 23, 0.8)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontWeight: 600,
          textTransform: 'none',
          padding: '10px 24px',
          transition: 'all 0.2s ease-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0, 191, 165, 0.3)',
          },
        },
        containedPrimary: {
          backgroundColor: '#00bfa5',
          color: '#121212',
          '&:hover': {
            backgroundColor: '#33ecd0',
          },
        },
        containedSecondary: {
          backgroundColor: '#7C4DFF',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#9e7fff',
          },
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#171717',
          borderRadius: '12px',
          padding: '24px',
          marginBottom: '24px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          border: '1px solid rgba(255, 255, 255, 0.05)',
          transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 40px rgba(0, 191, 165, 0.15)',
          },
        },
      },
    },
    MuiPickersPopper: {
      defaultProps: {
        sx: {
          "& .MuiPaper-root": {
            backgroundColor: "#171717 !important",
          },
        },
      },
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "#171717 !important",
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '12px',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "#171717 !important",
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: "#171717 !important",
          '& .MuiPickersDay-root': {
            backgroundColor: "#171717 !important",
            color: '#FFFFFF',
            fontSize: '0.875rem',
            borderRadius: '4px !important',
            height: '36px',
            width: '36px',
            margin: '2px',
            transition: 'all 0.2s ease-in-out',
            "&:hover": {
              backgroundColor: "rgba(0, 191, 165, 0.1) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "#00bfa5 !important",
              color: '#000000',
              fontWeight: 600,
            },
            "&.MuiPickersDay-today": {
              border: 'none',
              color: '#00bfa5',
              fontWeight: 600,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 2,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '16px',
                height: '2px',
                backgroundColor: '#00bfa5',
              },
            },
          },
        },
      },
    },
    MuiPickersDay: {
      defaultProps: {
        sx: {
          borderRadius: 0,
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "#171717 !important",
          color: '#FFFFFF',
          fontSize: '0.875rem',
          height: '36px',
          width: '36px',
          margin: '2px',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: "rgba(0, 191, 165, 0.1) !important",
          },
          '&.Mui-selected': {
            backgroundColor: "#00bfa5 !important",
            color: '#000000',
            fontWeight: 600,
          },
          '&.MuiPickersDay-today': {
            border: 'none',
            color: '#00bfa5',
            fontWeight: 600,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 2,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '16px',
              height: '2px',
              backgroundColor: '#00bfa5',
            },
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          backgroundColor: "#171717 !important",
          paddingBottom: '8px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          '& .MuiTypography-root': {
            color: '#00bfa5',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            margin: '8px 0',
          },
        },
        weekContainer: {
          margin: '2px 0',
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          backgroundColor: "#171717 !important",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          backgroundColor: "#171717 !important",
          padding: '16px 16px 0 16px',
          '& .MuiPickersArrowSwitcher-button': {
            color: '#FFFFFF',
            padding: '8px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            },
          },
          '& .MuiPickersCalendarHeader-label': {
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: 500,
            textTransform: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(23, 23, 23, 0.8)',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.3s ease-in-out',
            '&:hover fieldset': {
              borderColor: '#33ecd0',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#00bfa5',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#00bfa5',
          transition: 'color 0.3s ease, transform 0.3s ease',
          '&:hover': {
            color: '#33ecd0',
            transform: 'translateY(-2px)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0, 191, 165, 0.2)',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(23, 23, 23, 0.9)',
          backdropFilter: 'blur(4px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '4px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: 4,
        },
        bar: {
          borderRadius: 4,
          backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent)',
          backgroundSize: '1rem 1rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(25, 25, 25, 0.8)',
          border: '1px solid rgba(255, 183, 77, 0.2)',
          borderRadius: 8,
          display: 'flex',
          gap: 8,
        },
        icon: {
          opacity: 0.8,
        },
        message: {
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(4px)',
          },
        },
        paper: {
          backgroundColor: '#171717',
          backgroundImage: 'none',
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
          padding: '24px',
          '& .MuiDialogTitle-root': {
            padding: '16px 24px',
            backgroundColor: 'transparent',
            '& .MuiTypography-root': {
              fontSize: '1.5rem',
              fontWeight: 600,
            },
          },
          '& .MuiDialogContent-root': {
            padding: '20px 24px',
            backgroundColor: 'transparent',
          },
          '& .MuiDialogActions-root': {
            padding: '16px 24px',
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

export default theme;