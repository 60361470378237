// frontend/src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Container, Box, IconButton, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { styled, alpha } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { SignInButton } from '@farcaster/auth-kit';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/vouch-logo.png';
import { ReactComponent as FarcasterLogo } from '../assets/farcaster_purple_white.svg';

const NavbarLogo = styled('img')(({ theme }) => ({
  height: 40,
  marginRight: '1rem',
  transition: 'filter 0.3s ease, transform 0.3s ease',
  '&:hover': {
    filter: 'drop-shadow(0 0 8px rgba(132, 112, 216, 0.8))',
    transform: 'scale(1.05)',
  },
}));

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  position: 'relative',
  '&:not(.logo-link)::after': {
    content: '""',
    position: 'absolute',
    width: '0',
    height: '2px',
    bottom: '-4px',
    left: '50%',
    background: theme.palette.primary.main,
    transition: 'all 0.3s ease-in-out',
  },
  '&:not(.logo-link):hover::after': {
    width: '100%',
    left: '0',
  },
}));

const UserButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 280,
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(10px)',
    borderRight: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: `0 4px 30px ${alpha(theme.palette.common.black, 0.2)}`,
    padding: theme.spacing(2),
    
    // Add subtle gradient background
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.background.paper, 0.95)} 0%,
      ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  },
}));

const StyledMobileNavItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    transform: 'translateX(4px)',
  },

  '& .MuiListItemText-root': {
    margin: 0,
  },

  '& .MuiListItemText-primary': {
    fontSize: '0.95rem',
    fontWeight: 500,
    letterSpacing: '0.015em',
  },

  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1.5),
    fontSize: '1.25rem',
    color: alpha(theme.palette.text.primary, 0.7),
  },
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius * 1.5,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.2)}`,
    
    // Add subtle gradient background
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.background.paper, 0.95)} 0%,
      ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  fontSize: '0.95rem',
  minWidth: 200,
  transition: 'all 0.2s ease-in-out',
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    transform: 'translateX(4px)',
  },

  '& svg': {
    fontSize: '1.25rem',
    color: alpha(theme.palette.text.primary, 0.7),
  },

  '&:not(:last-child)': {
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
  },
}));

const MenuItemIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  
  '& svg': {
    fontSize: '1rem',
    color: 'inherit',
  },
}));

const Navbar = () => {
  const { isSignedIn, isAdmin, handleSignOut, user, isDevelopmentMode, switchTestUser, isTestUser } = useAuth();
  console.log("Auth context in Navbar:", { isSignedIn, isAdmin, user, isDevelopmentMode, handleSignOut, switchTestUser, isTestUser });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchTestUser = async () => {
    console.log("Switch Test User button clicked");
    if (switchTestUser) {
      try {
        await switchTestUser();
        console.log("switchTestUser completed");
      } catch (error) {
        console.error("switchTestUser error:", error);
      }
    } else {
      console.error("switchTestUser is undefined");
    }
  };

  const handleSignOutClick = () => {
    console.log("Sign out clicked");
    handleClose();
    if (handleSignOut) {
      console.log("Calling handleSignOut");
      handleSignOut().then(() => {
        console.log("handleSignOut completed");
      }).catch((error) => {
        console.error("handleSignOut error:", error);
      });
    } else {
      console.error("handleSignOut is undefined");
    }
  };

  const navItems = [
    { text: 'Home', path: '/' },
    { text: 'Requests', path: '/requests' },
    ...(isSignedIn ? [{ text: 'My Dashboard', path: '/dashboard' }] : []),
    { text: 'About', path: '/about' },
  ];

  // Add admin dashboard link if the user is an admin
  if (isSignedIn && isAdmin) {
    console.log("User is admin, adding admin link");
    navItems.push({ text: 'Admin', path: '/admin', icon: <AdminPanelSettingsIcon /> });
  } else {
    console.log("User is not admin or not signed in", { isSignedIn, isAdmin });
  }

  const profileHref = user?.username ? `https://warpcast.com/${user.username}` : '#';

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <StyledRouterLink to="/" className="logo-link">
            <NavbarLogo src={logo} alt="Vouch Logo" />
          </StyledRouterLink>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setIsMenuOpen(true)}
              color="inherit"
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <StyledDrawer
              anchor="left"
              open={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              ModalProps={{
                BackdropProps: {
                  sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(4px)',
                  },
                },
              }}
            >
              <DrawerHeader>
                <RouterLink to="/" onClick={() => setIsMenuOpen(false)}>
                  <NavbarLogo src={logo} alt="Vouch Logo" sx={{ height: 32 }} />
                </RouterLink>
                <IconButton
                  onClick={() => setIsMenuOpen(false)}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DrawerHeader>
  
              <List sx={{ px: 1 }}>
                {navItems.map((item) => (
                  <StyledMobileNavItem
                    button
                    key={item.text}
                    component={RouterLink}
                    to={item.path}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.icon ? (
                      <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'primary.main',
                        }}
                      >
                        {item.icon}
                      </Box>
                    ) : (
                      <Box
                        component="span"
                        sx={{
                          width: 24,
                          height: 24,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.1),
                          color: 'primary.main',
                          marginRight: 1.5,
                          fontSize: '0.75rem',
                          fontWeight: 600,
                        }}
                      >
                        {item.text[0]}
                      </Box>
                    )}
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        sx: {
                          fontWeight: 500,
                          color: 'text.primary',
                        },
                      }}
                    />
                  </StyledMobileNavItem>
                ))}
              </List>
  
              {!isSignedIn && (
                <Box sx={{ p: 2, mt: 'auto' }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to="/login"
                    onClick={() => setIsMenuOpen(false)}
                    sx={{
                      py: 1.5,
                      fontSize: '0.95rem',
                      fontWeight: 600,
                      letterSpacing: '0.02em',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
              )}
            </StyledDrawer>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                component={StyledRouterLink}
                to={item.path}
                sx={{ my: 2, color: 'text.primary', display: 'block' }}
                startIcon={item.icon}
              >
                {item.text}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isSignedIn ? (
              <>
                <UserButton
                  onClick={handleClick}
                  endIcon={<ArrowDropDownIcon />}
                  startIcon={
                    <Avatar
                      src={user?.pfpUrl}
                      alt={user?.displayName || user?.username || 'User'}
                      sx={{ width: 32, height: 32 }}
                    >
                      {user?.username ? user.username[0].toUpperCase() : 'U'}
                    </Avatar>
                  }
                >
                  {user?.displayName || user?.username || 'Loading...'}
                </UserButton>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  elevation={0}
                >
                  {user?.username && (
                    <StyledMenuItem component={RouterLink} to={`/user/${user.username}`} onClick={handleClose}>
                      <MenuItemIcon>
                        <PersonIcon />
                      </MenuItemIcon>
                      {user.username} on Vouch
                    </StyledMenuItem>
                  )}
                  {user?.username && (
                    <StyledMenuItem 
                      component="a" 
                      href={profileHref} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      onClick={handleClose}
                    >
                      <MenuItemIcon>
                        <FarcasterLogo style={{ width: 14, height: 14 }} />
                      </MenuItemIcon>
                      {user.username} on Farcaster
                    </StyledMenuItem>
                  )}
                  <StyledMenuItem onClick={handleSignOutClick}>
                    <MenuItemIcon>
                      <LogoutIcon />
                    </MenuItemIcon>
                    Sign Out
                  </StyledMenuItem>
                </StyledMenu>
                {isDevelopmentMode && isTestUser && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSwitchTestUser}
                    startIcon={<SwapHorizIcon />}
                    sx={{ ml: 2 }}
                  >
                    Switch Test Account
                  </Button>
                )}
              </>
            ) : (
              <Box>
                <SignInButton />
                {isDevelopmentMode && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSwitchTestUser}
                    sx={{ ml: 2 }}
                  >
                    Use Test Account
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;