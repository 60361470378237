// frontend/src/components/VouchForm.js

import React, { useState, useEffect } from 'react';
import { 
  TextField, Button, Box, Typography, Alert, InputAdornment,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { createVouchCharge } from '../services/api';
import { useAuth } from '../context/AuthContext';
import FarcasterUsername from './FarcasterUsername';
import WalletSetup from './WalletSetup';
import { NumericFormat } from 'react-number-format';
import VouchSelector from './VouchSelector';

const MIN_VOUCH_AMOUNT = parseFloat(process.env.REACT_APP_MIN_VOUCH_AMOUNT || '0.01');

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 0,
  '& .MuiButton-root': {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    '& .MuiButton-root': {
      width: '100%',
      margin: 0,
    }
  },
  '& > :not(:first-of-type)': {
    marginLeft: 0,
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: 120,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

const TooltipIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

// NumberFormat Custom Component
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      allowNegative={false}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
    />
  );
});

const VouchForm = ({ bidId, bidNumber, bidderUsername, itemType, requestId, onVouchSubmitted, showToast, compact = false }) => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [showWalletSetup, setShowWalletSetup] = useState(false);
  const { isSignedIn, user } = useAuth();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (isSignedIn && user && !user.payoutAddress && !user.isTestUser) {
      setShowWalletSetup(true);
    }
  }, [isSignedIn, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSignedIn) {
      showToast('Please sign in to vouch', 'error');
      return;
    }
    if (!user.payoutAddress) {
      setShowWalletSetup(true);
      return;
    }
    try {
      setError(null);
      const vouchAmount = parseFloat(amount.replace(/,/g, ''));
      if (isNaN(vouchAmount) || vouchAmount < MIN_VOUCH_AMOUNT) {
        throw new Error(`Vouch amount must be at least ${MIN_VOUCH_AMOUNT} USDC`);
      }
      const response = await createVouchCharge({ bidId, amount: vouchAmount, requestId });
      if (response && response.data && response.data.paymentUrl) {
        setPaymentUrl(response.data.paymentUrl);
        setIsConfirmationOpen(true);
      } else {
        throw new Error('Failed to process vouch payment: Invalid response');
      }
    } catch (err) {
      console.error('Vouch submission error:', err);
      setError(err.message);
      showToast('Failed to submit vouch: ' + err.message, 'error');
    }
  };

  const handleConfirmVouch = () => {
    setIsConfirmationOpen(false);
    setIsRedirecting(true);
    showToast('Please complete the payment in the new window. Your vouch will appear once the payment is processed.', 'info', 5000);
    setTimeout(() => {
      window.location.href = paymentUrl;
    }, 3000);
    // Do not clear the amount here
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
    setPaymentUrl(''); // Clear the payment URL when closing the modal
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const capitalizedItemType = itemType.charAt(0).toUpperCase() + itemType.slice(1);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: compact ? 0 : 2 }}>
        {!compact && (
         <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
            Vouch for {capitalizedItemType} #{bidNumber} by {bidderUsername}
         </Typography>
        )}
        <Box>
          <VouchSelector
            value={parseFloat(amount) || 0}
            onChange={(newValue) => setAmount(newValue.toString())}
            minAmount={MIN_VOUCH_AMOUNT}
            itemType={itemType}
            compact={compact}
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              size="large"
              sx={{ 
                width: { xs: '100%', sm: 'auto' },
                minWidth: { sm: 200 }
              }}
              disabled={!isSignedIn || isRedirecting}
            >
              {isRedirecting ? (
                <>
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                  Redirecting...
                </>
              ) : (
                'Submit Vouch'
              )}
            </Button>
          </Box>
        </Box>

        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {!isSignedIn && (
          <Alert severity="info" sx={{ mt: 2 }}>
            Please sign in to submit a vouch.
          </Alert>
        )}
      </Box>

      <Dialog open={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirm Vouch</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            You are about to vouch ${formatNumber(parseFloat(amount.replace(/,/g, '')).toFixed(2))} USDC for:
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>{capitalizedItemType} #{bidNumber}</strong> by <FarcasterUsername username={bidderUsername} />
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Clicking 'Proceed to Payment' will take you to Coinbase Commerce to complete the payment.
          </Typography>
        </DialogContent>
        <StyledDialogActions disableSpacing>
          <ActionButton 
            onClick={handleCloseConfirmation} 
            color="primary"
            variant="outlined"
          >
            Cancel
          </ActionButton>
          <ActionButton 
            onClick={handleConfirmVouch} 
            color="primary" 
            variant="contained" 
            disabled={isRedirecting}
          >
            {isRedirecting ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                Redirecting...
              </>
            ) : (
              'Proceed to Payment'
            )}
          </ActionButton>
        </StyledDialogActions>
      </Dialog>

      {isSignedIn && (
        <WalletSetup 
          open={showWalletSetup} 
          onClose={() => setShowWalletSetup(false)}
        />
      )}
    </>
  );
};

export default VouchForm;
