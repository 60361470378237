// frontend/src/hooks/useWebSocket.js

import { useEffect, useRef, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { useQueryClient } from 'react-query';
import { debounce } from 'lodash';

// Constants
const RECONNECT_INTERVAL = 1000;
const MAX_RECONNECT_DELAY = 5000;
const MAX_RECONNECT_ATTEMPTS = 5;
const DEBOUNCE_DELAY = 100;
const HEARTBEAT_INTERVAL = 30000;
const HEARTBEAT_TIMEOUT = 35000;
const CONNECTION_TIMEOUT = 20000;
const UPDATE_CACHE_DURATION = 300000; // 5 minutes
const RETRY_DELAY = 3000;
const BACKOFF_MULTIPLIER = 1.5;

const useWebSocket = (url, requestId) => {
  const [isConnected, setIsConnected] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('initial');
  const socketRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const processedUpdatesRef = useRef(new Map());
  const lastHeartbeatRef = useRef(Date.now());
  const heartbeatIntervalRef = useRef(null);
  const queryClient = useQueryClient();
  const pendingUpdatesRef = useRef([]);
  const processingUpdateRef = useRef(false);

  const cleanupProcessedUpdates = useCallback(() => {
    const now = Date.now();
    const entries = Array.from(processedUpdatesRef.current.entries());
    entries.forEach(([key, data]) => {
      if (now - data.timestamp > UPDATE_CACHE_DURATION) {
        processedUpdatesRef.current.delete(key);
      }
    });
  }, []);

  const updateQueryCache = useCallback((update) => {
    if (!update || !update.type || !update.data) {
      console.debug('Invalid update received:', update);
      return;
    }

    console.debug(`Processing update of type: ${update.type}`, update.data);

    const updateKey = `${update.type}-${JSON.stringify(update.data)}`;
    const existingUpdate = processedUpdatesRef.current.get(updateKey);
    
    if (existingUpdate && Date.now() - existingUpdate.timestamp < UPDATE_CACHE_DURATION) {
      console.debug('Skipping recently processed update:', updateKey);
      return;
    }

    if (processingUpdateRef.current) {
      console.debug('Update processing in progress, queueing update:', update);
      pendingUpdatesRef.current.push(update);
      return;
    }

    processingUpdateRef.current = true;

    try {
      requestAnimationFrame(() => {
        queryClient.setQueryData(['request', requestId], (old) => {
          if (!old) return old;

          console.debug('Current request data:', old);
          let newData = { ...old };

          switch (update.type) {
            case 'REQUEST_UPDATE':
              console.debug('Processing REQUEST_UPDATE');
              newData = {
                ...newData,
                ...update.data,
                bids: newData.bids
              };
              break;

            case 'BOUNTY_RELEASE_STARTED':
              console.debug('Processing BOUNTY_RELEASE_STARTED');
              newData.status = 'processing_bounty_release';
              break;

            case 'BOUNTY_RELEASE_PROGRESS':
              console.debug('Processing BOUNTY_RELEASE_PROGRESS:', update.data);
              newData.processingStatus = update.data.message;
              newData.processingProgress = update.data.progress;
              break;

            case 'BOUNTY_RELEASED':
              console.debug('Processing BOUNTY_RELEASED');
              newData.status = 'bounty_released';
              newData.processingStatus = null;
              newData.processingProgress = null;
              break;

            case 'BOUNTY_WITHHELD':
              console.debug('Processing BOUNTY_WITHHELD');
              newData.status = 'bounty_withheld';
              newData.processingStatus = null;
              newData.processingProgress = null;
              break;

            case 'NEW_BID':
            case 'BID_UPDATED':
              console.debug(`Processing ${update.type}`);
              if (!newData.bids) newData.bids = [];
              const updatedBidData = {
                ...update.data,
                vouches: update.data.vouches || [],
                confirmedVouches: update.data.confirmedVouches || [],
                pendingVouches: update.data.pendingVouches || []
              };
              
              const bidIndex = newData.bids.findIndex(bid => bid._id === update.data._id);
              if (bidIndex === -1) {
                newData.bids = [...newData.bids, updatedBidData].sort(
                  (a, b) => b.totalVouchAmount - a.totalVouchAmount
                );
              } else {
                newData.bids = newData.bids.map(bid =>
                  bid._id === update.data._id ? updatedBidData : bid
                );
              }
              break;

            case 'BID_DELETED':
              console.debug('Processing BID_DELETED');
              if (newData.bids) {
                newData.bids = newData.bids.filter(bid => bid._id !== update.data.bidId);
              }
              break;

            case 'VOUCH_UPDATE':
              console.debug('Processing VOUCH_UPDATE:', update.data);
              if (!newData.bids) break;
              
              newData.bids = newData.bids.map(bid => {
                if (bid._id !== update.data.bidId) return bid;
                
                const updatedBid = { ...bid };
                const { vouch, type } = update.data;
            
                updatedBid.vouches = updatedBid.vouches || [];
                updatedBid.confirmedVouches = updatedBid.confirmedVouches || [];
                updatedBid.pendingVouches = updatedBid.pendingVouches || [];
            
                switch (type) {
                  case 'VOUCH_PENDING':
                    updatedBid.pendingVouches = [...updatedBid.pendingVouches, vouch];
                    break;
                    
                  case 'VOUCH_CONFIRMED':
                    updatedBid.pendingVouches = updatedBid.pendingVouches.filter(v => v._id !== vouch._id);
                    updatedBid.confirmedVouches = [...updatedBid.confirmedVouches, vouch];
                    break;
                    
                  case 'VOUCH_PAID':
                  case 'VOUCH_REFUNDED':
                  case 'VOUCH_REFUND_FAILED':
                    updatedBid.confirmedVouches = updatedBid.confirmedVouches.map(v => 
                      v._id === vouch._id ? { ...v, paymentStatus: vouch.paymentStatus } : v
                    );
                    break;
                }
            
                updatedBid.totalVouchAmount = updatedBid.confirmedVouches
                  .filter(v => v.paymentStatus !== 'refunded')
                  .reduce((sum, v) => sum + v.amount, 0);
            
                return updatedBid;
              }).sort((a, b) => b.totalVouchAmount - a.totalVouchAmount);
              break;
          }

          console.debug('Updated request data:', newData);
          return newData;
        });

        processedUpdatesRef.current.set(updateKey, {
          update,
          timestamp: Date.now()
        });

        processingUpdateRef.current = false;

        if (pendingUpdatesRef.current.length > 0) {
          console.debug(`Processing ${pendingUpdatesRef.current.length} pending updates`);
          const nextUpdate = pendingUpdatesRef.current.shift();
          updateQueryCache(nextUpdate);
        }

        cleanupProcessedUpdates();
      });
    } catch (error) {
      console.error('Error processing update:', error);
      processingUpdateRef.current = false;
    }
  }, [requestId, queryClient, cleanupProcessedUpdates]);

  const debouncedSetLastUpdate = useRef(
    debounce((update) => {
      setLastUpdate(update);
      updateQueryCache(update);
    }, DEBOUNCE_DELAY)
  ).current;

  const setupHeartbeat = useCallback(() => {
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
    }

    heartbeatIntervalRef.current = setInterval(() => {
      if (!socketRef.current?.connected) return;

      const now = Date.now();
      if (now - lastHeartbeatRef.current > HEARTBEAT_TIMEOUT) {
        console.debug('Heartbeat timeout, reconnecting...');
        setConnectionStatus('reconnecting');
        socketRef.current.disconnect();
        socketRef.current.connect();
      } else {
        socketRef.current.emit('heartbeat');
      }
    }, HEARTBEAT_INTERVAL);

    return () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
    };
  }, []);

  const connect = useCallback(() => {
    if (socketRef.current) {
      console.debug('Disconnecting existing socket connection');
      socketRef.current.disconnect();
    }
  
    let socketUrl;
    if (process.env.REACT_APP_NGROK_URL) {
      // For ngrok development
      socketUrl = process.env.REACT_APP_NGROK_URL
        .replace('/api', '')
        .replace('http://', 'https://');
    } else {
      socketUrl = process.env.REACT_APP_WEBSOCKET_URL;
    }
  
    console.debug('Initializing new socket connection to:', socketUrl);
  
    const attemptReconnect = () => {
      const backoffDelay = Math.min(
        RECONNECT_INTERVAL * Math.pow(2, reconnectAttemptsRef.current),
        MAX_RECONNECT_DELAY
      );
      
      setTimeout(() => {
        if (!socketRef.current?.connected && reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
          console.debug(`Attempting reconnection ${reconnectAttemptsRef.current + 1} of ${MAX_RECONNECT_ATTEMPTS}`);
          socketRef.current?.connect();
        }
      }, backoffDelay);
    };
  
    try {
      socketRef.current = io(socketUrl, {
        transports: ['polling', 'websocket'],
        reconnection: false,
        timeout: CONNECTION_TIMEOUT,
        forceNew: true,
        autoConnect: false,
        path: '/socket.io',
        withCredentials: true
      });
  
      socketRef.current.on('connect', () => {
        console.debug('WebSocket connected successfully');
        setIsConnected(true);
        setConnectionStatus('connected');
        reconnectAttemptsRef.current = 0;
        if (requestId) {
          console.debug(`Joining room: ${requestId}`);
          socketRef.current.emit('joinRoom', requestId);
        }
      });
  
      socketRef.current.on('disconnect', (reason) => {
        console.debug('WebSocket disconnected:', reason);
        setIsConnected(false);
        setConnectionStatus('disconnected');
        
        if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttemptsRef.current += 1;
          setConnectionStatus('reconnecting');
          console.debug(`Initiating reconnection attempt ${reconnectAttemptsRef.current} of ${MAX_RECONNECT_ATTEMPTS}`);
          attemptReconnect();
        } else {
          setConnectionStatus('failed');
          console.debug('Max reconnection attempts reached. Please refresh the page.');
        }
      });
  
      socketRef.current.on('connect_error', (error) => {
        console.debug('WebSocket connection error:', error);
        setConnectionStatus('error');
        
        if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttemptsRef.current += 1;
          setConnectionStatus('reconnecting');
          attemptReconnect();
        } else {
          setConnectionStatus('failed');
          console.debug('Max reconnection attempts reached. Please refresh the page.');
        }
      });
  
      socketRef.current.on('update', (update) => {
        if (!update) return;
        
        console.debug('Received WebSocket update:', update);
        
        if (update.type?.includes('BOUNTY_')) {
          console.debug('Bounty-related update received:', {
            type: update.type,
            data: update.data,
            timestamp: new Date().toISOString()
          });
        }
        
        debouncedSetLastUpdate(update);
      });
  
      socketRef.current.on('heartbeat', () => {
        lastHeartbeatRef.current = Date.now();
        console.debug('Heartbeat received');
      });
  
      // Initial connection attempt
      socketRef.current.connect();
      return setupHeartbeat();
    } catch (error) {
      console.error('Error initializing WebSocket:', error);
      setConnectionStatus('error');
      return () => {};
    }
  }, [url, requestId, debouncedSetLastUpdate, setupHeartbeat]);

  useEffect(() => {
    console.debug('Setting up WebSocket connection');
    const cleanup = connect();

    return () => {
      console.debug('Cleaning up WebSocket connection');
      cleanup();
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      processedUpdatesRef.current.clear();
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
    };
  }, [connect]);

  const manualReconnect = useCallback(() => {
    console.debug('Manually reconnecting WebSocket');
    reconnectAttemptsRef.current = 0;
    setConnectionStatus('reconnecting');
    if (socketRef.current) {
      socketRef.current.removeAllListeners();
      socketRef.current.disconnect();
    }
    connect();
  }, [connect]);

  return {
    isConnected,
    lastUpdate,
    connectionStatus,
    manualReconnect,
    forceUpdate: useCallback((update) => {
      console.debug('Forcing update:', update);
      updateQueryCache(update);
    }, [updateQueryCache])
  };
};

export default useWebSocket;