// frontend/src/services/api.js

import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.vouch.wtf/api'
  : (process.env.REACT_APP_NGROK_URL || process.env.REACT_APP_API_URL || 'http://localhost:5001/api');

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  withCredentials: true,
  retry: 3,
  retryDelay: 1000
});

// Add a retry mechanism
axiosInstance.interceptors.response.use(undefined, async (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  
  config.__retryCount = config.__retryCount || 0;
  
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err);
  }
  
  // Add exponential backoff
  config.__retryCount += 1;
  const delay = config.retryDelay * Math.pow(2, config.__retryCount - 1);
  
  // Log retry attempt
  console.log(`Retry attempt ${config.__retryCount} for ${config.url} after ${delay}ms`);
  
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(axiosInstance(config));
    }, delay);
  });
});

// Query key factory
export const queryKeys = {
  requests: ['requests'],
  request: (id) => ['request', id],
  bids: (requestId) => ['bids', requestId],
  vouches: (bidId) => ['vouches', bidId],
  user: (username) => ['user', username],
  myRequests: ['myRequests'],
  myBids: ['myBids'],
  myVouches: ['myVouches'],
  leaderboard: ['leaderboard'],
};

// React Query Hooks (new)
export const useRequests = (filters = {}) => {
  return useQuery(
    queryKeys.requests,
    () => getRequests(filters),
    {
      staleTime: 30000,
      cacheTime: 3600000,
    }
  );
};

export const useRequest = (id) => {
  return useQuery(
    queryKeys.request(id),
    () => getRequest(id),
    {
      enabled: !!id,
      staleTime: 30000,
    }
  );
};

export const useCreateRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.requests);
      queryClient.invalidateQueries(queryKeys.myRequests);
    },
  });
};

export const useUpdateRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKeys.request(data._id));
      queryClient.invalidateQueries(queryKeys.requests);
      queryClient.invalidateQueries(queryKeys.myRequests);
    },
  });
};

export const useBids = (requestId) => {
  return useQuery(
    queryKeys.bids(requestId),
    () => getBids(requestId),
    {
      enabled: !!requestId,
      staleTime: 30000,
    }
  );
};

export const useVouches = (bidId) => {
  return useQuery(
    queryKeys.vouches(bidId),
    () => getVouches(bidId),
    {
      enabled: !!bidId,
      staleTime: 30000,
    }
  );
};

// Requests
export const getRequests = (includeBids = false) => {
  console.log('Sending request to:', `${API_URL}/requests${includeBids ? '?includeBids=true' : ''}`);
  return axiosInstance.get(`/requests${includeBids ? '?includeBids=true' : ''}`)
    .then(response => {
      console.log('Received response:', response);
      const requestsWithTotalBounty = response.data.map(request => ({
        ...request,
        totalBounty: request.totalBounty || 
          (request.bounty + (request.additionalBounties || [])
            .reduce((sum, bounty) => sum + (bounty.amount || 0), 0))
      }));
      return { ...response, data: requestsWithTotalBounty };
    })
    .catch(error => {
      console.error('Error in getRequests:', error.response ? error.response.data : error.message);
      throw error;
    });
};

export const getRequest = async (id) => {
  try {
    console.log(`Sending request to: ${API_URL}/requests/${id}`);
    const response = await axiosInstance.get(`/requests/${id}`, {
      retry: 3,
      retryDelay: 1000,
      // Keep the request-specific timeout
      timeout: 30000
    });
    console.log('Received response:', response);
    return response;
  } catch (error) {
    console.error(`Error fetching request with ID ${id}:`, error.response ? error.response.data : error.message);
    console.error('Full error object:', error);
    
    // Enhanced error handling
    if (!error.response && error.code === 'ECONNABORTED') {
      throw new Error('Request timed out. Please check your connection and try again.');
    } else if (!error.response) {
      throw new Error('Network error. Please check your connection and try again.');
    } else {
      throw error;
    }
  }
};

export const createRequest = async (formData) => {
  try {
    const response = await axiosInstance.post('/requests', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('Request created successfully:', response.data);
    return response;
  } catch (error) {
    console.error('Error creating request:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateRequest = async (id, formData) => {
  console.log(`Updating request with ID ${id}. Form data:`, formData);
  try {
    const response = await axiosInstance.put(`/requests/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log(`Request ${id} updated successfully:`, response.data);
    return response;
  } catch (error) {
    console.error(`Error updating request ${id}:`, error.response ? error.response.data : error.message);
    console.error('Full error object:', error);
    throw error;
  }
};

export const updateRequestStatus = async (requestId, status) => {
  try {
    const response = await axiosInstance.patch(`/requests/${requestId}/status`, { status });
    return response.data;
  } catch (error) {
    console.error(`Error updating request ${requestId} status:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteRequest = (id) => {
  console.log(`Deleting request with ID: ${id}`);
  return axiosInstance.delete(`/requests/${id}`)
    .then(response => {
      console.log(`Request ${id} deleted successfully:`, response.data);
      return response;
    })
    .catch(error => {
      console.error(`Error deleting request ${id}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

export const getMyRequests = (includeBids = true) => { // Default to true
  console.log('Fetching user\'s requests');
  return axiosInstance.get(`/requests/my-requests${includeBids ? '?includeBids=true' : ''}`)
    .then(response => {
      console.log('Received user\'s requests:', response.data);
      return response;
    })
    .catch(error => {
      console.error('Error fetching user\'s requests:', error.response ? error.response.data : error.message);
      throw error;
    });
};

export const getMyBids = () => {
  console.log('Fetching user\'s bids');
  return axiosInstance.get('/bids/my-bids')
    .then(response => {
      console.log('Received user\'s bids:', response.data);
      return response;
    })
    .catch(error => {
      console.error('Error fetching user\'s bids:', error.response ? error.response.data : error.message);
      throw error;
    });
};

export const getMyVouches = () => {
  console.log('Fetching user\'s vouches');
  return axiosInstance.get('/vouches/my-vouches')
    .then(response => {
      console.log('Received user\'s vouches:', response.data);
      return {
        data: {
          confirmed: response.data.confirmed || [],
          pending: response.data.pending || []
        }
      };
    })
    .catch(error => {
      console.error('Error fetching user\'s vouches:', error.response ? error.response.data : error.message);
      throw error;
    });
};

// create bounty charge
export const createBountyCharge = async (requestId, version) => {
  try {
    console.log(`Creating bounty charge for request ${requestId} with version ${version}`);
    const response = await axiosInstance.post(`/requests/${requestId}/process-payment`, { version });
    console.log('Bounty charge response:', response.data);
    if (response.data.paymentUrl) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to create bounty charge');
    }
  } catch (error) {
    console.error('Error creating bounty charge:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw new Error(handleApiError(error));
  }
};

// increase bounty
export const increaseBounty = async (requestId, amount) => {
  try {
    const response = await axiosInstance.post(`/requests/${requestId}/add-bounty`, { amount });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

// Bids
export const getBids = (requestId) => {
  console.log(`Fetching bids for request ${requestId}`);
  return axiosInstance.get(`/bids/request/${requestId}`)
    .then(response => {
      console.log(`Received bids for request ${requestId}:`, response.data);
      // Calculate totalVouchAmount for each bid if it's not already included
      const bidsWithTotalVouchAmount = response.data.map(bid => ({
        ...bid,
        totalVouchAmount: bid.totalVouchAmount || 
          (bid.confirmedVouches ? bid.confirmedVouches.reduce((sum, vouch) => sum + vouch.amount, 0) : 0)
      }));
      // Sort bids by totalVouchAmount
      bidsWithTotalVouchAmount.sort((a, b) => b.totalVouchAmount - a.totalVouchAmount);
      console.log('Sorted bids with totalVouchAmount:', bidsWithTotalVouchAmount.map(b => ({ id: b._id, totalVouchAmount: b.totalVouchAmount })));
      return { ...response, data: bidsWithTotalVouchAmount };
    })
    .catch(error => {
      console.error(`Error fetching bids for request ${requestId}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

export const createBid = (formData) => {
  console.log('Creating new bid with form data:', formData);
  return axiosInstance.post('/bids', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => {
      console.log('Bid created successfully:', response.data);
      return response.data;  // Return the data directly instead of the whole response
    })
    .catch(error => {
      console.error('Error creating bid:', error.response ? error.response.data : error.message);
      throw error;
    });
};

export const getVouches = (bidId) => {
  console.log(`Fetching vouches for bid ${bidId}`);
  return axiosInstance.get(`/vouches/bid/${bidId}`)
    .then(response => {
      console.log(`Received vouches for bid ${bidId}:`, response.data);
      return {
        data: {
          confirmed: response.data.confirmed || [],
          pending: response.data.pending || []
        }
      };
    })
    .catch(error => {
      console.error(`Error fetching vouches for bid ${bidId}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

export const createVouchCharge = (vouch) => {
  console.log('Creating new vouch:', vouch);
  return axiosInstance.post('/vouches', vouch)
    .then(response => {
      console.log('Vouch created successfully:', response.data);
      return { data: response.data };
    })
    .catch(error => {
      console.error('Error creating vouch:', error.response ? error.response.data : error.message);
      throw error;
    });
};

export const verifyVouchPayment = (vouchId) => {
  console.log(`Verifying vouch payment for vouch ${vouchId}`);
  return axiosInstance.post(`/vouches/${vouchId}/verify-payment`, {})
    .then(response => {
      console.log(`Vouch payment verified for vouch ${vouchId}:`, response.data);
      return response;
    })
    .catch(error => {
      console.error(`Error verifying vouch payment for vouch ${vouchId}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// Check if a bid can be deleted
export const checkCanDeleteBid = (bidId) => {
  console.log(`Checking if bid ${bidId} can be deleted`);
  return axiosInstance.get(`/bids/${bidId}/can-delete`)
    .then(response => {
      console.log(`Received delete check result for bid ${bidId}:`, response.data);
      return response;
    })
    .catch(error => {
      console.error(`Error checking if bid ${bidId} can be deleted:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// Delete a bid
export const deleteBid = (bidId) => {
  console.log(`Deleting bid ${bidId}`);
  return axiosInstance.delete(`/bids/${bidId}`)
    .then(response => {
      console.log(`Bid ${bidId} deleted successfully:`, response.data);
      return response.data;
    })
    .catch(error => {
      console.error(`Error deleting bid ${bidId}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// Update a bid
export const updateBid = (bidId, formData) => {
  console.log(`Updating bid ${bidId} with form data:`, formData);
  return axiosInstance.put(`/bids/${bidId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => {
      console.log(`Bid ${bidId} updated successfully:`, response.data);
      return response.data;
    })
    .catch(error => {
      console.error(`Error updating bid ${bidId}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// get draft bids
export const getDraftBids = () => {
  return axiosInstance.get('/bids/my-draft-bids')
    .then(response => {
      console.log('Received draft bids:', response.data);
      return response;
    })
    .catch(error => {
      console.error('Error fetching draft bids:', error.response ? error.response.data : error.message);
      throw error;
    });
};

export const getBid = (id) => {
  return axiosInstance.get(`/bids/${id}`)
    .then(response => {
      console.log(`Received bid data for ID ${id}:`, response.data);
      return response;
    })
    .catch(error => {
      console.error(`Error fetching bid with ID ${id}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// Users
export const getUserProfile = (username, includeBids = true) => { // Default to true
  console.log(`Fetching user profile for ${username}`);
  return axiosInstance.get(`/users/${username}${includeBids ? '?includeBids=true' : ''}`)
    .then(response => {
      console.log(`Received user profile for ${username}:`, response.data);
      return response.data;
    })
    .catch(error => {
      console.error(`Error fetching user profile for ${username}:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// Request lifecycle functions

export const closeBidding = async (requestId, winningBidId) => {
  console.log(`Closing bidding for request ${requestId} with winning bid ${winningBidId}`);
  try {
    const response = await axiosInstance.post(`/requests/${requestId}/close-bidding`, { winningBidId }, {
      timeout: 120000 // Set a 2-minute timeout for this specific request
    });
    console.log(`Bidding closure initiated for request ${requestId}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error closing bidding for request ${requestId}:`, error.response ? error.response.data : error.message);
    throw handleApiError(error);
  }
};

export const releaseBounty = async (requestId, release) => {
  console.log(`${release ? 'Releasing' : 'Withholding'} bounty for request ${requestId}`);
  try {
    const response = await axiosInstance.post(`/requests/${requestId}/release-bounty`, { release });
    console.log(`Bounty ${release ? 'release' : 'withhold'} initiated for request ${requestId}:`, response.data);
    return response.data; // This will now include the 'status' field
  } catch (error) {
    console.error(`Error ${release ? 'releasing' : 'withholding'} bounty for request ${requestId}:`, error.response ? error.response.data : error.message);
    throw handleApiError(error); // Use handleApiError instead of throwing the raw error
  }
};

export const markRequestCompleted = (requestId) => {
  console.log(`Marking request ${requestId} as completed`);
  return axiosInstance.post(`/requests/${requestId}/complete`, {})
    .then(response => {
      console.log(`Request ${requestId} marked as completed:`, response.data);
      return response;
    })
    .catch(error => {
      console.error(`Error marking request ${requestId} as completed:`, error.response ? error.response.data : error.message);
      throw error;
    });
};

// leaderboard
export const getLeaderboardData = async () => {
  try {
    const response = await axiosInstance.get('/leaderboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
    throw error;
  }
};

export const handleApiError = (error) => {
  if (error.response) {
    console.error("Server responded with error:", error.response.data);
    
    // Handle specific error status codes
    switch (error.response.status) {
      case 404:
        return "The requested resource was not found";
      case 401:
        return "Please sign in to continue";
      case 403:
        return "You don't have permission to access this resource";
      case 408:
      case 504:
        return "Request timed out. Please try again";
      case 502:
      case 503:
        return "Service temporarily unavailable. Please try again in a moment";
      default:
        return error.response.data.message || "An unexpected error occurred";
    }
  } else if (error.code === 'ECONNABORTED') {
    console.error("Request timed out:", error);
    return "Request timed out. Please check your connection and try again.";
  } else if (error.request) {
    console.error("No response received:", error.request);
    return "Unable to reach the server. Please check your connection.";
  } else {
    console.error("Error setting up request:", error.message);
    return "Unable to complete your request. Please try again.";
  }
};

export const cancelPaymentIntent = async (paymentIntentId) => {
  try {
    const response = await axiosInstance.post(`/payments/cancel-intent/${paymentIntentId}`);
    return response.data;
  } catch (error) {
    console.error('Error canceling payment intent:', error);
    throw error;
  }
};

export const updatePayoutAddress = async (address) => {
  try {
    console.log('API base URL:', API_URL); 
    console.log('Updating payout address with:', address);
    
    // Make sure we're sending the data in the correct format
    const response = await axiosInstance.post('/users/update-payout-address', 
      { payoutAddress: address },  // Make sure the property name matches what backend expects
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    
    console.log('Update payout address response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating payout address:', error.response?.data || error.message);
    throw error;
  }
};

// Additional React Query Hooks (new)
export const useMyRequests = () => {
  return useQuery(
    queryKeys.myRequests,
    getMyRequests,
    {
      staleTime: 30000,
    }
  );
};

export const useMyBids = () => {
  return useQuery(
    queryKeys.myBids,
    getMyBids,
    {
      staleTime: 30000,
    }
  );
};

export const useMyVouches = () => {
  return useQuery(
    queryKeys.myVouches,
    getMyVouches,
    {
      staleTime: 30000,
    }
  );
};

export const useLeaderboard = () => {
  return useQuery(
    queryKeys.leaderboard,
    getLeaderboardData,
    {
      staleTime: 300000, // 5 minutes
    }
  );
};

export const useCloseBiddingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ requestId, winningBidId }) => closeBidding(requestId, winningBidId),
    {
      onSuccess: (data, { requestId }) => {
        queryClient.invalidateQueries(queryKeys.request(requestId));
        queryClient.invalidateQueries(queryKeys.bids(requestId));
      },
    }
  );
};

export const useReleaseBountyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ requestId, release }) => releaseBounty(requestId, release),
    {
      onSuccess: (data, { requestId }) => {
        queryClient.invalidateQueries(queryKeys.request(requestId));
        queryClient.invalidateQueries(queryKeys.bids(requestId));
        queryClient.invalidateQueries(queryKeys.vouches);
      },
    }
  );
};

export const useMarkRequestCompletedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    markRequestCompleted,
    {
      onSuccess: (data, requestId) => {
        queryClient.invalidateQueries(queryKeys.request(requestId));
        queryClient.invalidateQueries(queryKeys.requests);
      },
    }
  );
};

// Export the axios instance for direct use when needed
export const api = axiosInstance;