// frontend/src/components/VouchSelector.js

import React, { useState, useCallback } from 'react';
import {
  Box,
  Slider,
  Button,
  Typography,
  Paper,
  Stack,
  TextField,
  alpha,
  useTheme,
  InputAdornment
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { NumericFormat } from 'react-number-format';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StarIcon from '@mui/icons-material/Star';
import { formatCurrency } from '../utilities/formatters';

const PRESET_AMOUNTS = [
  { value: 100, label: `${formatCurrency(100)}`, icon: ShowChartIcon, confidence: "Support" },
  { value: 500, label: `${formatCurrency(500)}`, icon: TrendingUpIcon, confidence: "Confidence" },
  { value: 1000, label: `${formatCurrency(1000)}`, icon: StarIcon, confidence: "Strong Confidence" },
  { value: 5000, label: `${formatCurrency(5000)}`, icon: StarIcon, confidence: "High Conviction" },
  { value: 10000, label: `${formatCurrency(10000)}`, icon: StarIcon, confidence: "Very High Conviction" },
  { value: 25000, label: `${formatCurrency(25000)}`, icon: StarIcon, confidence: "Maximum Conviction" }
];

const SLIDER_MAX = 25000;

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue || '',  // Use floatValue or empty string
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowEmptyFormatting={false}
    />
  );
});

const getConfidenceLevel = (amount) => {
  if (amount >= 25000) return "Maximum Conviction";
  if (amount >= 10000) return "Very High Conviction";
  if (amount >= 5000) return "High Conviction";
  if (amount >= 1000) return "Strong Confidence";
  if (amount >= 500) return "Confidence";
  return "Support";
};

const getConfidenceColor = (theme, amount) => {
  if (amount >= 25000) return theme.palette.success.main;
  if (amount >= 10000) return theme.palette.success.light;
  if (amount >= 5000) return theme.palette.primary.main;
  if (amount >= 1000) return theme.palette.primary.light;
  if (amount >= 500) return theme.palette.info.main;
  return theme.palette.grey[500];
};

export default function VouchSelector({ value, onChange, minAmount = 100, itemType, compact = false }) {
  const theme = useTheme();
  const [customValue, setCustomValue] = useState(value ? value.toString() : '');
  const [sliderValue, setSliderValue] = useState(value ? Math.min(value, SLIDER_MAX) : minAmount);
  
  const handleSliderChange = useCallback((_, newValue) => {
    setSliderValue(newValue);
    setCustomValue(newValue.toString());
    onChange(newValue);
  }, [onChange]);

  const handleCustomValueChange = useCallback((event) => {
    const newValue = event.target.value;
    setCustomValue(newValue === '' ? '' : newValue.toString());
    
    if (newValue === '' || isNaN(newValue)) {
      setSliderValue(minAmount);
      onChange('');
    } else {
      const numericValue = parseFloat(newValue);
      onChange(numericValue);
      setSliderValue(Math.min(numericValue, SLIDER_MAX));
    }
  }, [onChange, minAmount]);

  const handlePresetClick = useCallback((amount) => {
    setCustomValue(amount.toString());
    setSliderValue(Math.min(amount, SLIDER_MAX));
    onChange(amount);
  }, [onChange]);

  // Update the confidence level calculation to handle empty/undefined values
  const getCurrentConfidenceLevel = useCallback((value) => {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) ? getConfidenceLevel(numericValue) : '';
  }, []);

  const getCurrentConfidenceColor = useCallback((theme, value) => {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) ? getConfidenceColor(theme, numericValue) : theme.palette.grey[500];
  }, []);

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <Stack spacing={3}>
        {/* Preset Amount Buttons */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)', // Stack on mobile
              sm: 'repeat(3, 1fr)', // 3 columns on tablet
              md: 'repeat(6, 1fr)', // All buttons in one line on desktop
            },
            gap: 2,
          }}
        >
          {PRESET_AMOUNTS.map(({ value: amount, label, icon: Icon, confidence }) => (
            <motion.div
              key={amount}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Button
                fullWidth
                variant={parseFloat(customValue) === amount ? 'contained' : 'outlined'}
                onClick={() => handlePresetClick(amount)}
                sx={{
                  p: 2,
                  height: '100%',
                  flexDirection: 'column',
                  gap: 1,
                  borderWidth: 2,
                  borderColor: (theme) =>
                    parseFloat(customValue) === amount
                      ? 'transparent'
                      : alpha(getConfidenceColor(theme, amount), 0.3),
                  bgcolor: (theme) =>
                    parseFloat(customValue) === amount
                      ? getConfidenceColor(theme, amount)
                      : 'transparent',
                  '&:hover': {
                    borderWidth: 2,
                    bgcolor: (theme) =>
                      parseFloat(customValue) === amount
                        ? getConfidenceColor(theme, amount)
                        : alpha(getConfidenceColor(theme, amount), 0.1),
                  },
                }}
              >
                <Icon
                  sx={{
                    fontSize: '1.5rem',
                    color: parseFloat(customValue) === amount ? 'inherit' : getConfidenceColor(theme, amount),
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {label}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: parseFloat(customValue) === amount ? 'inherit' : getConfidenceColor(theme, amount),
                  }}
                >
                  {confidence}
                </Typography>
              </Button>
            </motion.div>
          ))}
        </Box>

        {/* Custom Amount Input and Slider */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.6),
            backdropFilter: 'blur(10px)',
            border: '1px solid',
            borderColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          }}
        >
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Custom Amount (USDC)"
              value={customValue}
              onChange={handleCustomValueChange}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              helperText={`Minimum vouch: ${formatCurrency(minAmount)} USDC`}
            />
            
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              min={minAmount}
              max={SLIDER_MAX}
              step={100}
              marks={[
                { value: minAmount, label: `$${minAmount}` },
                { value: SLIDER_MAX, label: `$${SLIDER_MAX.toLocaleString()}+` },
              ]}
              sx={{
                '& .MuiSlider-thumb': {
                  height: 24,
                  width: 24,
                  backgroundColor: (theme) => getCurrentConfidenceColor(theme, customValue),
                },
                '& .MuiSlider-track': {
                  backgroundColor: (theme) => getCurrentConfidenceColor(theme, customValue),
                },
              }}
            />
          </Stack>
        </Paper>

        {/* Confidence Level Indicator */}
        <AnimatePresence>
          {customValue && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <Paper
                sx={{
                  p: 2,
                  bgcolor: (theme) => alpha(getCurrentConfidenceColor(theme, customValue), 0.1),
                  border: '1px solid',
                  borderColor: (theme) => getCurrentConfidenceColor(theme, customValue),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: (theme) => getCurrentConfidenceColor(theme, customValue),
                    textAlign: 'center',
                  }}
                >
                  {getCurrentConfidenceLevel(customValue)} Level Vouch
                </Typography>
              </Paper>
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
    </Box>
  );
}