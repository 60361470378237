// frontend/src/components/Toast.js

import React, { useState } from 'react';
import { Snackbar, Alert, Box, Typography, IconButton } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiAlert-root': {
    minWidth: '300px',
    maxWidth: '600px',
    width: '90%',  // Added to ensure proper mobile scaling
    margin: '0 auto', // Added to help with centering
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(30, 30, 30, 0.95)',
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 1.5,
    padding: theme.spacing(1.5, 2),
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
    animation: `${slideIn} 0.3s ease-out forwards`,
    '&.MuiAlert-filledSuccess': {
      backgroundColor: 'rgba(0, 191, 165, 0.95)',
      borderColor: theme.palette.success.dark,
    },
    '&.MuiAlert-filledError': {
      backgroundColor: 'rgba(244, 67, 54, 0.95)',
      borderColor: theme.palette.error.dark,
    },
    '&.MuiAlert-filledInfo': {
      backgroundColor: 'rgba(33, 150, 243, 0.95)',
      borderColor: theme.palette.info.dark,
    },
    '&.MuiAlert-filledWarning': {
      backgroundColor: 'rgba(255, 152, 0, 0.95)',
      borderColor: theme.palette.warning.dark,
    },
    '&.closing': {
      animation: `${slideOut} 0.3s ease-in forwards`,
    },
  },
  '& .MuiAlert-icon': {
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
}));

const ToastContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  left: 0,
  right: 0,  // Changed from transform-based centering to full width
  zIndex: theme.zIndex.snackbar + 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pointerEvents: 'none',
  '& > *': {
    pointerEvents: 'auto',
  },
}));

const ToastContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return <CheckCircleOutlineIcon fontSize="medium" />;
    case 'error':
      return <ErrorOutlineIcon fontSize="medium" />;
    case 'warning':
      return <WarningAmberIcon fontSize="medium" />;
    case 'info':
    default:
      return <InfoOutlinedIcon fontSize="medium" />;
  }
};

const Toast = ({ 
  message, 
  type = 'info', 
  open, 
  onClose, 
  autoHideDuration = 6000 
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  if (!message) return null;

  return (
    <ToastContainer>
      <StyledSnackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ width: '100%' }}  // Added this line
      >
        <Alert 
          icon={getIcon(type)}
          variant="filled"
          severity={type}
          className={isClosing ? 'closing' : ''}
          sx={{ width: '100%', maxWidth: '600px', mx: 'auto' }}  // Added this line
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <ToastContent>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {typeof message === 'string' ? message : React.cloneElement(message, { onClose: handleClose })}
            </Typography>
          </ToastContent>
        </Alert>
      </StyledSnackbar>
    </ToastContainer>
  );
};

export default Toast;