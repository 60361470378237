// frontend/src/components/Requests.js

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getRequests, handleApiError } from '../services/api';
import { useAuth } from '../context/AuthContext';
import RequestCard from './RequestCard';
import {
  Container,
  Typography,
  Grid,
  Box,
  Alert,
  Button,
  Paper,
  Chip,
  Tooltip,
  useTheme,
  useMediaQuery,
  alpha,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RecommendIcon from '@mui/icons-material/Recommend';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import FarcasterIcon from '@mui/icons-material/AlternateEmail';
import useWebSocket from '../hooks/useWebSocket';
import PageTitle from './PageTitle';
import ConnectionAlert from './ConnectionAlert';

const WelcomeBanner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.08)} 0%, ${alpha(theme.palette.primary.dark, 0.12)} 100%)`,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.12)}`,
  backdropFilter: 'blur(8px)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}40, transparent)`,
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const CTAButton = styled(Button)(({ theme }) => ({
  minWidth: { xs: '100%', sm: 'auto' },
  height: { xs: '48px', sm: '56px' },
  transition: 'all 0.3s ease-in-out',
  padding: theme.spacing(2, 4),
  fontWeight: 600,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  '&.learn-more': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const TabGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& .tab-label': {
    maxWidth: '120px',
    margin: '0 auto',
  },
  '@media (max-width:450px)': {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),
  }
}));

const TabLabel = styled('span')({
  display: 'block',
  textTransform: 'none',
});

const HorizontalTabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.25), 
  },
}));

const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2, 1.5),
  gap: theme.spacing(1),
  height: '100%',
  minHeight: 100,
  flex: 1,
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  border: `1px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(
    ['background-color', 'border-color', 'transform', 'box-shadow'],
    {
      duration: theme.transitions.duration.shorter,
    }
  ),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, active ? 0.15 : 0.05),
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    flexShrink: 0,
    marginBottom: theme.spacing(0.5),
    '@media (max-width:450px)': {
      fontSize: '1.5rem',
    },
  },
  '& span.tab-label, & .MuiButton-label .tab-label': {
    fontSize: '0.875rem',
    fontWeight: 600,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
    '@media (max-width:450px)': {
      fontSize: '0.875rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5, 1),
    minHeight: 90,
  },
  '@media (max-width:450px)': {
    flexDirection: 'row',
    padding: theme.spacing(2),
    minHeight: 72,
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
    width: '100%',
    '& .MuiSvgIcon-root': {
      marginBottom: 0,
    },
    '& .content-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(0.5),
    }
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
  },
}));

const Requests = ({ showToast }) => {
  const { isSignedIn, user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [requests, setRequests] = useState({
    projects: [],
    jobs: [],
    recommendations: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { isConnected, manualReconnect } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const result = await getRequests(true);
      console.log('API Response:', result);  // Add this
      console.log('Requests with bids:', result.data);  // Add this
      categorizeRequests(result.data);
    } catch (err) {
      const errorMessage = handleApiError(err);
      setError(errorMessage);
      showToast(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await Promise.all([
        fetchRequests(), // or fetchDashboardData() or fetchRequests()
        new Promise(resolve => setTimeout(resolve, 500)), // minimum refresh time
      ]);
      manualReconnect(); // Attempt to reconnect WebSocket
      return true;
    } catch (error) {
      console.error('Error refreshing data:', error);
      showToast('Failed to refresh data', 'error');
      return false;
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchRequests, manualReconnect, showToast]); 

  const refreshRequest = useCallback((updatedRequest) => {
    setRequests((prevRequests) => {
      const category =
        updatedRequest.category === 'project'
          ? 'projects'
          : updatedRequest.category === 'job'
          ? 'jobs'
          : 'recommendations';
  
      const updatedList = prevRequests[category]
        .map((req) => (req._id === updatedRequest._id ? updatedRequest : req))
        .sort(sortByTotalBounty);
  
      return {
        ...prevRequests,
        [category]: updatedList,
      };
    });
  }, []);

  // Simple sort function to use everywhere
  const sortByTotalBounty = (a, b) => {
    const totalBountyA = a.totalBounty || (a.bounty + (a.additionalBounties || [])
      .reduce((sum, bounty) => sum + (bounty.amount || 0), 0));
    const totalBountyB = b.totalBounty || (b.bounty + (b.additionalBounties || [])
      .reduce((sum, bounty) => sum + (bounty.amount || 0), 0));
    return totalBountyB - totalBountyA;
  };

  // Update categorizeRequests
  const categorizeRequests = (allRequests) => {
    const categorized = {
      projects: [],
      jobs: [],
      recommendations: [],
    };
  
    // First ensure all requests have totalBounty calculated
    const requestsWithTotalBounty = allRequests.map(request => ({
      ...request,
      totalBounty: request.totalBounty || 
        (request.bounty + (request.additionalBounties || [])
          .reduce((sum, bounty) => sum + (bounty.amount || 0), 0))
    }));
  
    requestsWithTotalBounty.forEach((request) => {
      if (
        request.status === 'active' ||
        request.status === 'payment_pending' ||
        request.status === 'bidding_closed' ||
        request.status === 'in_progress' ||
        request.status === 'completed' ||
        request.status === 'bounty_released' ||
        request.status === 'bounty_withheld'
      ) {
        if (request.category === 'project') {
          categorized.projects.push(request);
        } else if (request.category === 'job') {
          categorized.jobs.push(request);
        } else if (request.category === 'recommendation') {
          categorized.recommendations.push(request);
        }
      }
    });
  
    // Sort each category by totalBounty
    Object.keys(categorized).forEach(category => {
      categorized[category].sort(sortByTotalBounty);
    });
  
    setRequests(categorized);
  };

  // Update helper functions
  const activeRequests = (category) =>
    requests[category]
      .filter((r) => r.status === 'active' || r.status === 'payment_pending')
      .sort(sortByTotalBounty);

  const closedRequests = (category) =>
    requests[category]
      .filter((r) => r.status === 'bidding_closed')
      .sort(sortByTotalBounty);

  const inProgressRequests = (category) =>
    requests[category]
      .filter((r) => r.status === 'in_progress')
      .sort(sortByTotalBounty);

  const completedRequests = (category) =>
    requests[category]
      .filter((r) =>
        ['completed', 'bounty_released', 'bounty_withheld'].includes(r.status)
      )
      .sort(sortByTotalBounty);

  const sectionTitles = {
    recommendations: {
      active: 'Open Recommendation Requests',
      closed: 'Recommendations with Closed Submissions',
      inProgress: 'Recommendations In Progress',
      completed: 'Completed Recommendations',
    },
    projects: {
      active: 'Projects Open for Bids',
      closed: 'Projects with Closed Bidding',
      inProgress: 'Projects In Progress',
      completed: 'Completed Projects',
    },
    jobs: {
      active: 'Jobs Accepting Applications',
      closed: 'Jobs with Closed Applications',
      inProgress: 'Jobs In Progress',
      completed: 'Completed Jobs',
    },
  };

  const ExamplesCTA = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.08)} 0%, ${alpha(theme.palette.secondary.main, 0.12)} 100%)`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.12)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      textAlign: 'left',
      justifyContent: 'space-between',
    },
  }));

  const renderRequestSection = (title, requestList) => {
    // Debug log to verify sorting
    console.log(`${title} - Requests sorted by bounty:`, 
      requestList.map(r => ({
        title: r.title,
        bounty: r.bounty,
        additionalBounties: r.additionalBounties,
        totalBounty: r.totalBounty || 
          (r.bounty + (r.additionalBounties || [])
            .reduce((sum, bounty) => sum + (bounty.amount || 0), 0))
      }))
    );
  
    return (
      <Box sx={{ mb: { xs: 3, sm: 5 } }}>
        <SectionTitle variant="h5" component="h2">
          {title}
        </SectionTitle>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
        {requestList.map((request) => {
          const isCreator = user?.username === request.creator;
          console.log('Request being passed to RequestCard:', request); // Keeping the debug log
          return (
            <Grid item xs={12} sm={12} md={6} key={request._id}>
              <RequestCard 
                request={request}
                showManageOptions={false}
                showToast={showToast}
                onUpdate={refreshRequest}
                isMyRequest={isCreator}  // This will only be true if the logged-in user is the creator
                onProcessPayment={() => {}}
                isPaymentProcessing={false}
              />
            </Grid>
          );
        })}
        </Grid>
      </Box>
    );
  };

  const renderTabContent = (category) => {
    const sections = [
      {
        key: 'active',
        title: sectionTitles[category].active,
        requests: activeRequests(category),
      },
      {
        key: 'closed',
        title: sectionTitles[category].closed,
        requests: closedRequests(category),
      },
      {
        key: 'inProgress',
        title: sectionTitles[category].inProgress,
        requests: inProgressRequests(category),
      },
      {
        key: 'completed',
        title: sectionTitles[category].completed,
        requests: completedRequests(category),
      },
    ];

    const hasRequests = sections.some((section) => section.requests.length > 0);

    if (!hasRequests) {
      return (
        <ExamplesCTA>
          <Box>
            <Typography variant="h6" gutterBottom>
              New to Vouch?
            </Typography>
            <Typography color="text.secondary">
              Check out our example {category} to see how the Vouch marketplace connects quality opportunities with trusted expertise.
            </Typography>
          </Box>
          <Button
            component={Link}
            to={`/about?section=examples&category=${category}`}
            variant="contained"
            color="primary"
            sx={{
              px: 4,
              py: 1.5,
              fontWeight: 600,
              whiteSpace: 'nowrap',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 4,
              },
            }}
          >
            View Examples
          </Button>
        </ExamplesCTA>
      );
    }

    return (
      <>
        {sections.map(
          (section) =>
            section.requests.length > 0 &&
            renderRequestSection(section.title, section.requests)
        )}
      </>
    );
  };

  const tabs = [
    {
      icon: <RecommendIcon />,
      label: 'Recommendations',
      tooltipContent: (
        <React.Fragment>
          <Typography color="inherit" variant="subtitle1">
            Recommendations
          </Typography>
          <Typography variant="body2">
            High-value expert recommendations with community validation. 
            Request insights, share expertise, or vouch for quality 
            recommendations in your domain.
          </Typography>
        </React.Fragment>
      )
    },
    {
      icon: <BuildIcon />,
      label: 'Projects',
      tooltipContent: (
        <React.Fragment>
          <Typography color="inherit" variant="subtitle1">
            Projects
          </Typography>
          <Typography variant="body2">
            Community-validated project opportunities. Post projects, 
            submit proposals, or vouch for trusted service providers 
            in your network.
          </Typography>
        </React.Fragment>
      )
    },
    {
      icon: <WorkIcon />,
      label: 'Jobs',
      tooltipContent: (
        <React.Fragment>
          <Typography color="inherit" variant="subtitle1">
            Jobs
          </Typography>
          <Typography variant="body2">
            Quality job opportunities with trusted validation. List positions, 
            apply for roles, or vouch for exceptional talent in your 
            professional network.
          </Typography>
        </React.Fragment>
      )
    }
  ];

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, px: { xs: 2, sm: 3 } }}>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 1 }} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, px: { xs: 2, sm: 3 } }}>
      <PageTitle 
      title="Requests on the Marketplace" 
      description="Browse active requests for recommendations, project bids, and job applications. Find opportunities to contribute and earn through trusted endorsements on Vouch."
      />
      {!isConnected && (
        <ConnectionAlert
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
        />
      )}
  
      {!isSignedIn && (
        <WelcomeBanner>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" gutterBottom sx={{ 
              fontWeight: 600,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              Welcome to Vouch
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 600 }}>
              Join our trusted marketplace where recommendations, project bids, and job applications
              are validated by community backing. Create your first request or start bidding and vouching today.
            </Typography>
          </Box>
          <ButtonGroup>
            <CTAButton
              component={Link}
              to="/login"
              variant="contained"
              color="primary"
              startIcon={<FarcasterIcon />}
            >
              Sign in with Farcaster
            </CTAButton>
            <CTAButton
              component={Link}
              to="/about"
              className="learn-more"
              startIcon={<InfoOutlinedIcon />}
            >
              Learn More
            </CTAButton>
          </ButtonGroup>
        </WelcomeBanner>
      )}
  
      <Header>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            fontWeight: 'bold',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' } 
          }}
        >
          Requests on the Marketplace
        </Typography>
        {/* Only show header button for signed-in users */}
        {isSignedIn && (
          <CTAButton
            component={Link}
            to="/create-request"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Create New Request
          </CTAButton>
        )}
      </Header>
  
      {error && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: { xs: 4, md: 6 } }}>
        {isMobile ? (
          <TabGrid>
            {tabs.map((tab, index) => (
              <StyledTooltip key={index} title={tab.tooltipContent}>
                <TabButton
                  onClick={() => setActiveTab(index)}
                  active={activeTab === index}
                >
                  {tab.icon}
                  <Box 
                    className="content-wrapper"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: { xs: 'center', '@media (max-width:450px)': 'flex-start' },
                      gap: { xs: 1, '@media (max-width:450px)': 0.5 },
                      flex: { '@media (max-width:450px)': 1 }
                    }}
                  >
                    <TabLabel className="tab-label">{tab.label}</TabLabel>
                    <Chip
                      label={`${activeRequests(tab.label.toLowerCase()).length} Open`}
                      color={activeTab === index ? 'primary' : 'default'}
                      size="small"
                      sx={{ 
                        height: { xs: '20px', '@media (max-width:450px)': '20px' },
                        '& .MuiChip-label': {
                          px: { xs: 1, '@media (max-width:450px)': 1 },
                          fontSize: { xs: '0.75rem', '@media (max-width:450px)': '0.75rem' }
                        }
                      }}
                    />
                  </Box>
                </TabButton>
              </StyledTooltip>
            ))}
          </TabGrid>
        ) : (
          <HorizontalTabContainer>
            {tabs.map((tab, index) => (
              <StyledTooltip key={index} title={tab.tooltipContent}>
                <TabButton
                  onClick={() => setActiveTab(index)}
                  active={activeTab === index}
                >
                  {tab.icon}
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <TabLabel className="tab-label">{tab.label}</TabLabel>
                    <Chip
                      label={`${activeRequests(tab.label.toLowerCase()).length} Open`}
                      color={activeTab === index ? 'primary' : 'default'}
                      size="small"
                      sx={{ 
                        height: '24px',
                        '& .MuiChip-label': {
                          px: 1.5,
                          fontSize: '0.75rem'
                        }
                      }}
                    />
                  </Box>
                </TabButton>
              </StyledTooltip>
            ))}
          </HorizontalTabContainer>
        )}
      </Box>

      <Box>
        {activeTab === 0 && renderTabContent('recommendations')}
        {activeTab === 1 && renderTabContent('projects')}
        {activeTab === 2 && renderTabContent('jobs')}
      </Box>
    </Container>
  );
};

export default Requests;