// frontend/src/components/RequestDetail.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import {
  getRequest,
  getBids,
  getVouches,
  handleApiError,
  createBid,
  updateBid,
  deleteBid,
  increaseBounty,
} from '../services/api';
import { useAuth } from '../context/AuthContext';
import {
  Container,
  Typography,
  Button,
  Alert,
  Grid,
  Box,
  Paper,
  Divider,
  Tooltip,
  AlertTitle,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import usePaymentProcessing from '../hooks/usePaymentProcessing';
import useWebSocket from '../hooks/useWebSocket';
import BidForm from './BidForm';
import RequestHeader from './RequestHeader';
import RequestActions from './RequestActions';
import RequestDocumentViewer from './RequestDocumentViewer';
import BidsList from './BidsList';
import SkeletonLoader from './SkeletonLoader';
import SlidePanel from './SlidePanel';
import CloseBiddingProcess from './CloseBiddingProcess';
import ReleaseBounty from './ReleaseBounty';
import MarkCompleted from './MarkCompleted';
import { io } from 'socket.io-client';
import SyncIcon from '@mui/icons-material/Sync';
import ShareRequest from './ShareRequest';
import RequestDetailInfo from './RequestDetailInfo';
import PageTitle from './PageTitle';
import ConnectionAlert from './ConnectionAlert';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GavelIcon from '@mui/icons-material/Gavel';

const POLLING_INTERVAL = 60000;

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  position: 'relative',
  zIndex: 1,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  // Enhanced gradient background
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.95)} 0%,
    ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  
  // Enhanced shadow and glow
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)}
    `,
  },

  // Add subtle background pattern
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, 0.03)}, 
      transparent 70%)`,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 0,
  '& .MuiButton-root': {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    '& .MuiButton-root': {
      width: '100%',
      margin: 0,
    },
  },
  '& > :not(:first-of-type)': {
    marginLeft: 0,
  },
}));

const StatusBanner = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  border: '1px solid',
  borderColor: ({ severity }) => alpha(theme.palette[severity || 'info'].main, 0.2),
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '& .MuiAlert-icon': {
    opacity: 0.8,
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1)
  },
  '& .MuiAlert-message': {
    width: '100%',
    padding: theme.spacing(0, 1)
  },
  '& .MuiAlert-action': {
    padding: theme.spacing(0, 1),
    alignItems: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiAlert-action': {
      width: '100%',
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(6),
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: 120,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 20,
  right: 20,
  zIndex: 2000,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  boxShadow: `
    0 4px 20px ${alpha(theme.palette.common.black, 0.15)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  '& .MuiCircularProgress-root': {
    marginRight: theme.spacing(1),
  },
}));

const StatusTransition = styled(Box)(({ theme }) => ({
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  transform: 'translateY(0)',
  opacity: 1,
  
  '&.entering': {
    transform: 'translateY(-20px)',
    opacity: 0,
  },
  
  '&.exiting': {
    transform: 'translateY(20px)',
    opacity: 0,
  },

  // Enhance alerts inside StatusTransition
  '& .MuiAlert-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(10px)',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
      0 4px 20px ${alpha(theme.palette.common.black, 0.1)}
    `,

    '&.MuiAlert-standardSuccess': {
      borderColor: alpha(theme.palette.success.main, 0.2),
    },
    '&.MuiAlert-standardError': {
      borderColor: alpha(theme.palette.error.main, 0.2),
    },
    '&.MuiAlert-standardWarning': {
      borderColor: alpha(theme.palette.warning.main, 0.2),
    },
    '&.MuiAlert-standardInfo': {
      borderColor: alpha(theme.palette.info.main, 0.2),
    }
  }
}));

const TooltipIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(1),
  color: alpha(theme.palette.text.secondary, 0.8),
  cursor: 'help',
  transition: 'color 0.2s ease-in-out',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const RequestDetail = ({ showToast }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [request, setRequest] = useState(null);
  const [bids, setBids] = useState([]);
  const [loadingState, setLoadingState] = useState({
    initial: true,
    updating: false,
    error: null,
  });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { isSignedIn, user: profile } = useAuth();
  const [isClosingBids, setIsClosingBids] = useState(false);
  const [isBidFormOpen, setIsBidFormOpen] = useState(false);
  const [isCloseBiddingOpen, setIsCloseBiddingOpen] = useState(false);
  const [editingBid, setEditingBid] = useState(null);
  const [selectedBid, setSelectedBid] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isMarkCompletedOpen, setIsMarkCompletedOpen] = useState(false);
  const [isReleaseBountyOpen, setIsReleaseBountyOpen] = useState(false);
  const [isProcessComplete, setIsProcessComplete] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(null);
  const websocketUrl = useMemo(() => process.env.REACT_APP_WEBSOCKET_URL, []);
  const { isConnected, lastUpdate, manualReconnect } = useWebSocket(websocketUrl, id);
  const [userDraftBid, setUserDraftBid] = useState(null);
  const [bidFormKey, setBidFormKey] = useState(0);
  const [closeBiddingKey, setCloseBiddingKey] = useState(0);
  const [showError, setShowError] = useState(false);
  const errorTimerRef = useRef(null);
  const [error, setError] = useState(null);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const retryTimeoutRef = useRef(null);
  const maxRetries = 3;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {
    isPaymentProcessing,
    handleProcessPayment,
    PaymentUI
  } = usePaymentProcessing(showToast);

  const theme = useTheme();
  const username = profile?.username;

  const isCreator = useMemo(
    () => isSignedIn && username && request && username === request.creator,
    [isSignedIn, username, request]
  );

  const itemName = useMemo(() => {
    if (!request) return '';
    return request.category === 'job'
      ? 'Application'
      : request.category === 'project'
      ? 'Bid'
      : 'Recommendation';
  }, [request]);

  const fetchRequest = useCallback(async () => {
    try {
      setLoadingState((prev) => ({ ...prev, updating: true, error: null }));
      
      // Calculate exponential backoff delay if retrying
      if (retryAttempt > 0) {
        const backoffDelay = Math.min(1000 * Math.pow(2, retryAttempt), 5000);
        await new Promise(resolve => setTimeout(resolve, backoffDelay));
      }
  
      const response = await getRequest(id);
      const request = response.data;
      setRequest(request);
      setRetryAttempt(0); // Reset retry counter on success
  
      if (request.status !== 'draft' && request.status !== 'payment_initiated') {
        try {
          const bidsResponse = await getBids(id);
          const bidsData = bidsResponse.data;
          const vouchesData = await Promise.all(
            bidsData.map((bid) => getVouches(bid._id))
          );
  
          const bidsWithVouches = bidsData.map((bid, index) => {
            const { confirmed, pending } = vouchesData[index].data;
            const totalVouchAmount = confirmed.reduce((sum, vouch) => sum + vouch.amount, 0);
            return {
              ...bid,
              totalVouchAmount,
              confirmedVouches: confirmed,
              pendingVouches: pending,
            };
          });
  
          let filteredBids = bidsWithVouches.filter(
            (bid) =>
              bid.status !== 'draft' ||
              (isSignedIn && profile && bid.bidder === profile.username)
          );
  
          const userDraft = filteredBids.find(
            (bid) => bid.status === 'draft' && bid.bidder === profile?.username
          );
          if (userDraft) {
            setUserDraftBid(userDraft);
            filteredBids = filteredBids.filter((bid) => bid !== userDraft);
          } else {
            setUserDraftBid(null);
          }
  
          setBids(filteredBids.sort((a, b) => b.totalVouchAmount - a.totalVouchAmount));
        } catch (error) {
          console.error('Error fetching related data:', error);
          showToast('Some data may be temporarily unavailable', 'warning');
        }
      }
  
      setLoadingState((prev) => ({ ...prev, initial: false, updating: false, error: null }));
    } catch (error) {
      console.error('Error fetching request details:', error);
      if (retryAttempt < maxRetries) {
        setRetryAttempt(prev => prev + 1);
        setLoadingState(prev => ({
          ...prev,
          error: `Retrying... (Attempt ${retryAttempt + 1}/${maxRetries})`
        }));
        return fetchRequest();
      }
      
      setLoadingState((prev) => ({
        ...prev,
        initial: false,
        updating: false,
        error: handleApiError(error),
      }));
      showToast('Failed to fetch request details. Please try again.', 'error');
    }
  }, [id, isSignedIn, profile, showToast, retryAttempt, maxRetries]);

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await Promise.all([
        fetchRequest(), // or fetchDashboardData() or fetchRequests()
        new Promise(resolve => setTimeout(resolve, 500)), // minimum refresh time
      ]);
      manualReconnect(); // Attempt to reconnect WebSocket
      return true;
    } catch (error) {
      console.error('Error refreshing data:', error);
      showToast('Failed to refresh data', 'error');
      return false;
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchRequest, manualReconnect, showToast]); 

  useEffect(() => {
    if (lastUpdate && !isProcessComplete) {
      console.debug('Processing WebSocket update:', lastUpdate.type);

      const processUpdate = () => {
        switch (lastUpdate.type) {
          case 'REQUEST_UPDATE':
            setRequest((prevRequest) => ({
              ...prevRequest,
              ...lastUpdate.data,
            }));
            break;

          case 'NEW_BID':
          case 'BID_UPDATED':
            setBids((prevBids) => {
              const bidIndex = prevBids.findIndex((b) => b._id === lastUpdate.data._id);
              const newBids = [...prevBids];
              if (bidIndex === -1) {
                newBids.push(lastUpdate.data);
              } else {
                newBids[bidIndex] = lastUpdate.data;
              }
              return newBids.sort((a, b) => b.totalVouchAmount - a.totalVouchAmount);
            });
            break;

          case 'BID_DELETED':
            setBids((prevBids) => prevBids.filter((b) => b._id !== lastUpdate.data.bidId));
            break;

          case 'VOUCH_UPDATE':
            setBids((prevBids) => {
              return prevBids
                .map((bid) =>
                  bid._id === lastUpdate.data.bidId
                    ? handleVouchUpdate(bid, lastUpdate.data)
                    : bid
                )
                .sort((a, b) => b.totalVouchAmount - a.totalVouchAmount);
            });
            break;

          case 'BIDDING_CLOSED':
            setRequest((prevRequest) => ({
              ...prevRequest,
              status: 'bidding_closed',
              winningBidId: lastUpdate.data.request.winningBidId,
            }));
            setBids((prevBids) =>
              prevBids.map((bid) => ({
                ...bid,
                status: bid._id === lastUpdate.data.request.winningBidId ? 'accepted' : 'not_selected',
              }))
            );
            break;

          case 'BOUNTY_RELEASE_STARTED':
          case 'BOUNTY_RELEASE_PROGRESS':
          case 'BOUNTY_RELEASED':
          case 'BOUNTY_WITHHELD':
            if (lastUpdate.type === 'BOUNTY_RELEASED') {
              setRequest(prev => ({
                ...prev,
                status: 'bounty_released'
              }));
            } else if (lastUpdate.type === 'BOUNTY_WITHHELD') {
              setRequest(prev => ({
                ...prev,
                status: 'bounty_withheld'
              }));
            }
            break;

          default:
            console.debug('Unhandled update type:', lastUpdate.type);
        }
      };

      requestAnimationFrame(() => {
        processUpdate();
        setNotifications((prev) => [
          ...prev,
          `${lastUpdate.type.replace(/_/g, ' ').toLowerCase()}`,
        ]);
      });
    }
  }, [lastUpdate, isProcessComplete]);

  useEffect(() => {
    fetchRequest();

    let reconnectTimer;
    const handleVisibilityChange = () => {
      if (!document.hidden && !isConnected) {
        console.debug('Page became visible and WebSocket disconnected. Attempting reconnect...');
        clearTimeout(reconnectTimer);
        reconnectTimer = setTimeout(() => {
          try {
            const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
              forceNew: true,
              transports: ['websocket'],
              reconnectionAttempts: 5,
              reconnectionDelay: 1000,
              timeout: 10000,
            });

            socket.on('connect', () => {
              console.debug('WebSocket reconnected successfully');
              socket.emit('joinRoom', id);
            });

            socket.on('connect_error', (error) => {
              console.error('WebSocket reconnection failed:', error);
            });
          } catch (error) {
            console.error('Error initializing WebSocket connection:', error);
          }
        }, 1000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(reconnectTimer);
    };
  }, [fetchRequest, isConnected, id]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentSuccess = params.get('payment_success');

    if (paymentSuccess) {
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        console.log('Page became visible. Refreshing data...');
        fetchRequest();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchRequest]);

  useEffect(() => {
    let timer;
    if (
      request &&
      (request.status === 'draft' || request.status === 'payment_initiated') &&
      request.expiresAt
    ) {
      const updateTimeRemaining = () => {
        const now = new Date();
        const expiresAt = new Date(request.expiresAt);
        const diff = expiresAt - now;
        if (diff > 0) {
          setTimeRemaining(Math.floor(diff / 1000));
        } else {
          setTimeRemaining(0);
          clearInterval(timer);
        }
      };

      updateTimeRemaining();
      timer = setInterval(updateTimeRemaining, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [request]);

  useEffect(() => {
    if (isProcessComplete) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isProcessComplete]);

  useEffect(() => {
    return () => {
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  const handleVouchUpdate = useCallback((bid, updateData) => {
    const { vouch, type } = updateData;
    let updatedBid = { ...bid };

    switch (type) {
      case 'VOUCH_PENDING':
        updatedBid = {
          ...updatedBid,
          pendingVouches: [...(updatedBid.pendingVouches || []), vouch],
        };
        break;

      case 'VOUCH_CONFIRMED':
        updatedBid = {
          ...updatedBid,
          pendingVouches: (updatedBid.pendingVouches || []).filter((v) => v._id !== vouch._id),
          confirmedVouches: [...(updatedBid.confirmedVouches || []), vouch],
        };
        break;

      case 'VOUCH_REFUNDED':
      case 'VOUCH_REFUND_FAILED':
        updatedBid = {
          ...updatedBid,
          confirmedVouches: (updatedBid.confirmedVouches || []).map((v) =>
            v._id === vouch._id
              ? {
                  ...v,
                  paymentStatus: type === 'VOUCH_REFUNDED' ? 'refunded' : 'refund_failed',
                }
              : v
          ),
        };
        break;

      case 'VOUCHES_UNPAID':
        updatedBid = {
          ...updatedBid,
          confirmedVouches: (updatedBid.confirmedVouches || []).map((v) => ({
            ...v,
            paymentStatus: 'unpaid',
          })),
        };
        break;

      case 'VOUCH_PAID':
        updatedBid = {
          ...updatedBid,
          confirmedVouches: (updatedBid.confirmedVouches || []).map((v) =>
            v._id === vouch._id ? { ...v, paymentStatus: 'paid' } : v
          ),
        };
        break;
    }

    updatedBid.totalVouchAmount = (updatedBid.confirmedVouches || [])
      .filter((v) => v.paymentStatus !== 'refunded')
      .reduce((sum, v) => sum + v.amount, 0);

    return updatedBid;
  }, []);

  const handleBiddingClosed = useCallback(
    (data) => {
      console.log('Bidding closed with data:', data);
      setRequest((prevRequest) => ({
        ...prevRequest,
        status: 'bidding_closed',
        winningBidId: data.request.winningBidId,
      }));
      setBids((prevBids) =>
        prevBids.map((bid) => ({
          ...bid,
          status: bid._id === data.request.winningBidId ? 'accepted' : 'not_selected',
        }))
      );
      setIsCloseBiddingOpen(false);
      setSelectedBid(null);
      showToast('Bidding process completed successfully', 'success');
    },
    [showToast]
  );

  const handleOpenBidForm = useCallback(() => {
    setBidFormKey((prevKey) => prevKey + 1);
    setIsBidFormOpen(true);
  }, []);

  const handleCloseBidForm = useCallback(() => {
    setIsBidFormOpen(false);
    setEditingBid(null);
    setBidFormKey((prevKey) => prevKey + 1);
  }, []);

  const handleCreateBid = useCallback(
    async (formData) => {
      if (isSignedIn && username !== request.creator && request.status === 'active') {
        try {
          formData.append('requestId', id);
          await createBid(formData);
          showToast('Bid submitted successfully', 'success');
          fetchRequest();
          handleCloseBidForm();
        } catch (error) {
          const errorMessage = error.message || handleApiError(error);
          showToast(errorMessage, 'error');
        }
      } else {
        showToast('You cannot bid on this request', 'error');
      }
    },
    [id, isSignedIn, username, request, showToast, handleCloseBidForm, fetchRequest]
  );

  const handleEditBid = useCallback(
    (bidId) => {
      const bidToEdit = bids.find((bid) => bid._id === bidId) || userDraftBid;
      setEditingBid(bidToEdit);
      handleOpenBidForm();
      showToast(`Editing your ${itemName.toLowerCase()}. The form has opened.`, 'info');
    },
    [bids, userDraftBid, itemName, showToast, handleOpenBidForm]
  );

  const handleUpdateBid = useCallback(
    async (bidId, formData) => {
      try {
        await updateBid(bidId, formData);
        showToast('Bid updated successfully', 'success');
        fetchRequest();
        handleCloseBidForm();
      } catch (error) {
        const errorMessage = error.message || handleApiError(error);
        showToast(errorMessage, 'error');
      }
    },
    [showToast, handleCloseBidForm, fetchRequest]
  );

  const handleDeleteBid = useCallback(
    async (bidId) => {
      try {
        await deleteBid(bidId);
        showToast('Bid deleted successfully', 'success');
        fetchRequest();
        handleCloseBidForm();
      } catch (error) {
        const errorMessage = error.message || handleApiError(error);
        showToast(errorMessage, 'error');
      }
    },
    [showToast, handleCloseBidForm, fetchRequest]
  );

  const handleEditRequest = useCallback(() => {
    navigate(`/edit-request/${id}`);
  }, [navigate, id]);

  const handlePaymentInitiation = useCallback(async () => {
    try {
      if (!request || !request._id || request.version === undefined) {
        throw new Error('Invalid request data');
      }
      await handleProcessPayment(request._id, request.version, {
        title: request.title,
        category: request.category,
        bounty: request.bounty,
      });
    } catch (error) {
      console.error('Error initiating payment:', error);
      showToast(error.message || 'Failed to process payment. Please try again.', 'error');
    }
  }, [request, handleProcessPayment, showToast]);
  

  const handleConfirmPayment = useCallback(async () => {
    setIsConfirmationOpen(false);
    try {
      if (!request || !request._id || request.version === undefined) {
        throw new Error('Invalid request data');
      }
      await handleProcessPayment(request._id, request.version, {
        title: request.title,
        category: request.category,
        bounty: request.bounty,
      });
    } catch (error) {
      console.error('Error initiating payment:', error);
      showToast(error.message || 'Failed to process payment. Please try again.', 'error');
    }
  }, [request, handleProcessPayment, showToast]);

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleCloseBidding = useCallback(() => {
    setIsClosingBids(true);
    setSelectedBid(null);
    showToast('Closing bidding initiated. Select a winning bid.', 'info');
  }, [showToast]);

  const handleCancelCloseBidding = useCallback(() => {
    setIsClosingBids(false);
    setSelectedBid(null);
    showToast('Bid closing cancelled. Bidding remains open.', 'info');
  }, [showToast]);

  const handleBidSelection = (bid, index) => {
    setSelectedBid({
      ...bid,
      bidNumber: index + 1,
    });
    setIsCloseBiddingOpen(true);
    setIsClosingBids(false);
    setCloseBiddingKey((prevKey) => prevKey + 1);
  };

  const handleCloseCloseBidding = useCallback(() => {
    setIsCloseBiddingOpen(false);
    setSelectedBid(null);
    setIsClosingBids(false);
    setCloseBiddingKey((prevKey) => prevKey + 1);
  }, []);

  const handleMarkCompleted = useCallback(() => {
    setIsMarkCompletedOpen(true);
  }, []);

  const handleReleaseBounty = useCallback(() => {
    setIsReleaseBountyOpen(true);
  }, []);

  const handleIncreaseBounty = useCallback(
    async (amount) => {
      try {
        const result = await increaseBounty(id, amount);
        showToast(
          `Bounty increased by $${amount} USDC. New total: $${result.totalBounty} USDC`,
          'success'
        );
        fetchRequest();
      } catch (error) {
        showToast(`Failed to increase bounty: ${error.message}`, 'error');
      }
    },
    [id, showToast, fetchRequest]
  );

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotifications((prev) => prev.slice(1));
  };

  if (loadingState.initial) {
    return <SkeletonLoader />;
  }

  if (loadingState.error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              startIcon={retryAttempt > 0 ? <CircularProgress size={16} /> : <RefreshIcon />}
              onClick={() => {
                setRetryAttempt(0);
                fetchRequest();
              }}
              disabled={retryAttempt > 0}
            >
              {retryAttempt > 0 ? 'Retrying...' : 'Retry'}
            </Button>
          }
        >
          {loadingState.error}
        </Alert>
      </Container>
    );
  }

  if (!request) return <Typography>Request not found</Typography>;

  return (
    <StyledContainer maxWidth="lg">
      <PageTitle 
        title={request.title || 'Request Details'}
        description={request?.description?.slice(0, 155) || 'View request details and manage recommendations, applications, and bids on Vouch'}
      />
      {!isConnected && (
        <ConnectionAlert
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
        />
      )}

      {lastUpdate && !isProcessComplete && lastUpdate.type.includes('PROGRESS') && (
        <StatusTransition>
          <Alert
            severity="info"
            icon={<SyncIcon className="rotating-icon" />}
            sx={{
              mb: 2,
              backdropFilter: 'blur(8px)',
              backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
              border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              '& .rotating-icon': {
                animation: 'spin 2s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' },
                },
              },
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Background Updates in Progress
              </Typography>
              <Typography variant="body2">
                Status changes are being processed and synchronized. Some information may take a few
                minutes to update.
              </Typography>
            </Box>
          </Alert>
        </StatusTransition>
      )}

      {loadingState.updating && (
        <StatusTransition>
          <LoadingOverlay>
            <CircularProgress size={20} />
            <Typography 
              variant="caption" 
              sx={{ 
                ml: 1,
                fontWeight: 500,
                letterSpacing: '0.015em'
              }}
            >
              Updating...
            </Typography>
          </LoadingOverlay>
        </StatusTransition>
      )}

      {showError && error && (
        <StatusTransition>
          <Alert
            severity="error"
            sx={{ 
              mb: 3,
              backdropFilter: 'blur(8px)',
              backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
              border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.1)}`,
            }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  setShowError(false);
                  fetchRequest();
                }}
              >
                Retry
              </Button>
            }
          >
            {error}
          </Alert>
        </StatusTransition>
      )}

      {isProcessComplete && (
        <StatusTransition>
          <Alert 
            severity="success" 
            sx={{ 
              mb: 2,
              backdropFilter: 'blur(8px)',
              backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
              border: (theme) => `1px solid ${alpha(theme.palette.success.main, 0.1)}`,
            }}
          >
            Process completed.
          </Alert>
        </StatusTransition>
      )}

      {isCreator && (
        <>
          {(request.status === 'draft' || request.status === 'payment_initiated') &&
            timeRemaining !== null && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  color: 'warning.main',
                }}
              >
                <AccessTimeIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                  Time remaining to activate: {formatTime(timeRemaining)}
                </Typography>
              </Box>
          )}

          {request.status === 'bidding_closed' && (
            <StatusBanner
              severity="info"
              icon={<DoneIcon />}
              action={
                <Button 
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={handleMarkCompleted}
                  startIcon={<CheckCircleIcon />}
                  sx={{ 
                    minWidth: { sm: 200 },
                    width: { xs: '100%', sm: 'auto' },
                    fontWeight: 600,
                    textTransform: 'none',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: theme => theme.shadows[4]
                    }
                  }}
                >
                  Mark Completed
                </Button>
              }
            >
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Ready to Progress?
                </Typography>
                <Typography variant="body2">
                  {request.category === 'recommendation' 
                    ? "Once you've acted on the recommendation"
                    : request.category === 'job'
                    ? "Once the applicant has started and initial evaluation is complete"
                    : "Once the project deliverables have been received"}
                  , mark this request as completed to proceed with bounty distribution.
                </Typography>
              </Box>
            </StatusBanner>
          )}

          {request.status === 'completed' && (
            <StatusBanner
              severity="success"
              icon={<MonetizationOnIcon />}
              action={
                <Button 
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={handleReleaseBounty}
                  startIcon={<PaymentIcon />}
                  sx={{ 
                    minWidth: { sm: 200 },
                    width: { xs: '100%', sm: 'auto' },
                    fontWeight: 600,
                    textTransform: 'none',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: theme => theme.shadows[4]
                    }
                  }}
                >
                  Make Bounty Decision
                </Button>
              }
            >
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Bounty Decision Required
                </Typography>
                <Typography variant="body2">
                  {request.category === 'recommendation'
                    ? "Based on the quality of the recommendation and its outcome, decide whether to release the bounty to the recommender and vouchers."
                    : request.category === 'job'
                    ? "Based on the applicant's performance, decide whether to release the bounty to the vouchers."
                    : "Based on the project completion, decide whether to release the bounty to the vouchers."}
                </Typography>
              </Box>
            </StatusBanner>
          )}
        </>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledPaper>
            <RequestHeader
              request={request}
              isCreator={isCreator}
              pendingAdditionalBounties={request.pendingAdditionalBounties}
            />
            <Divider sx={{ my: 2 }} />
            {request.status !== 'draft' && 
            request.status !== 'payment_initiated' && 
            request.status !== 'payment_pending' && (
              <>
                <ShareRequest request={request} />
                <Divider sx={{ my: 2 }} />
              </>
            )}
            <RequestDocumentViewer documents={request.documents} />
          </StyledPaper>
        </Grid>

        {isCreator && !isProcessComplete && (
          <Grid item xs={12}>
            <StyledPaper>
              <RequestActions
                request={{
                  ...request,
                  totalBounty:
                    request.bounty +
                    (request.additionalBounties?.reduce(
                      (sum, bounty) => sum + bounty.amount,
                      0
                    ) || 0),
                }}
                isCreator={isCreator}
                onEdit={handleEditRequest}
                onInitiatePayment={handlePaymentInitiation}
                onCloseBidding={handleCloseBidding}
                isClosingBids={isClosingBids}
                onCancelCloseBidding={handleCancelCloseBidding}
                onIncreaseBounty={handleIncreaseBounty}
                onReleaseBounty={handleReleaseBounty}
                onMarkCompleted={handleMarkCompleted}
                showToast={showToast}
              />
            </StyledPaper>
          </Grid>
        )}

      {request.status !== 'draft' &&
        request.status !== 'payment_initiated' &&
        request.status !== 'payment_pending' && (
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h5" component="h2" gutterBottom>
                {`${itemName}s`}
                <Tooltip
                  title={`List of ${itemName.toLowerCase()}s submitted for this request.`}
                  placement="top"
                >
                  <TooltipIcon />
                </Tooltip>
              </Typography>

              {!isSignedIn && (
  <Alert 
    severity="info" 
    sx={{ 
      mt: { xs: 2, sm: 3, md: 4 },
      mb: { xs: 2, sm: 3, md: 4 },
      mx: { xs: 2, sm: 3, md: 'auto' },
      maxWidth: '800px',
      backgroundColor: 'background.paper',
      border: '1px solid rgba(255, 255, 255, 0.12)',
      '& .MuiAlert-icon': {
        color: 'primary.main',
        display: { xs: 'none', sm: 'flex' }
      },
      '& .MuiAlertTitle-root': {
        color: 'text.primary',
        fontWeight: 600,
        fontSize: { xs: '1rem', sm: '1.1rem' },
        mb: { xs: 0.5, sm: 1 }
      },
      '& .MuiAlert-message': {
        width: '100%'
      }
    }}
  >
    <AlertTitle>Sign in to interact</AlertTitle>
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        gap: { xs: 2, sm: 1 },
        width: '100%'
      }}
    >
      <Typography 
        variant="body2" 
        sx={{ 
          flex: 1,
          fontSize: { xs: '0.875rem', sm: '1rem' }
        }}
      >
        To submit{' '}
        {request.category === 'job'
          ? 'applications'
          : request.category === 'project'
          ? 'bids'
          : 'recommendations'}{' '}
        or vouches, please
      </Typography>
      <Button
        component={Link}
        to="/login"
        variant="contained"
        color="primary"
        size="small"
        fullWidth={false}
        sx={{
          textTransform: 'none',
          fontWeight: 500,
          minWidth: '80px',
          whiteSpace: 'nowrap'
        }}
      >
        Sign In
      </Button>
    </Box>
  </Alert>
)}

              {isSignedIn &&
                !isCreator &&
                request.status === 'active' &&
                !isProcessComplete &&
                bids.length > 0 && (
                  <Box mb={3}>
                    {!bids.some((bid) => bid.bidder === username) && !userDraftBid && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenBidForm}
                      >
                        Submit {itemName}
                      </Button>
                    )}
                  </Box>
                )}

              {request.status === 'active' && (
                <RequestDetailInfo request={request} />
              )}

              {isRefreshing ? (
                <Box display="flex" justifyContent="center" my={4}>
                  <CircularProgress />
                </Box>
              ) : (
                <BidsList
                  bids={bids}
                  userDraftBid={userDraftBid}
                  request={request}
                  isCreator={isCreator}
                  currentUser={profile}
                  showToast={showToast}
                  onVouchSubmitted={fetchRequest}
                  itemName={itemName}
                  isClosingBids={isClosingBids}
                  onBidSelection={handleBidSelection}
                  selectedBidId={selectedBid?._id}
                  onEditBid={handleEditBid}
                  onDeleteBid={handleDeleteBid}
                  isSignedIn={isSignedIn}
                  isProcessComplete={isProcessComplete}
                  onOpenBidForm={handleOpenBidForm}
                />
              )}
            </StyledPaper>
          </Grid>
        )}

        {request.status === 'payment_pending' && (
          <Grid item xs={12}>
            <StyledPaper>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  py: 4,
                }}
              >
                <CircularProgress size={48} />
                <Typography variant="h6" gutterBottom>
                  {isCreator ? 
                    'Awaiting Bounty Payment Confirmation' : 
                    `${itemName}s Coming Soon`
                  }
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 600, mx: 'auto', textAlign: 'center' }}>
                  {isCreator ? (
                    'Your bounty payment is being confirmed on the blockchain. This typically takes a few minutes. Once confirmed, users will be able to submit responses to your request.'
                  ) : (
                    `This request will be open for ${itemName.toLowerCase()}s as soon as the bounty payment is confirmed on the blockchain, which typically takes just a few minutes.`
                  )}
                </Typography>
              </Box>
            </StyledPaper>
          </Grid>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifications.length > 0}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        message={notifications[0]}
      />

      <SlidePanel isOpen={isBidFormOpen} onClose={handleCloseBidForm}>
        <BidForm
          key={bidFormKey}
          request={request}
          requestCategory={request.category}
          onSubmit={editingBid ? handleUpdateBid : handleCreateBid}
          showToast={showToast}
          existingBid={editingBid}
          onDelete={handleDeleteBid}
          onCancel={handleCloseBidForm}
        />
      </SlidePanel>

      <SlidePanel isOpen={isCloseBiddingOpen} onClose={handleCloseCloseBidding}>
        <CloseBiddingProcess
          key={closeBiddingKey}
          request={request}
          selectedBid={selectedBid}
          onClose={handleCloseCloseBidding}
          showToast={showToast}
          onBiddingClosed={handleBiddingClosed}
        />
      </SlidePanel>

      <SlidePanel
        isOpen={isMarkCompletedOpen}
        onClose={() => setIsMarkCompletedOpen(false)}
      >
        <MarkCompleted
          request={request}
          showToast={showToast}
          onClose={() => setIsMarkCompletedOpen(false)}
          onCompleted={() => {
            setIsMarkCompletedOpen(false);
            fetchRequest();
          }}
        />
      </SlidePanel>

      <SlidePanel
        isOpen={isReleaseBountyOpen}
        onClose={() => setIsReleaseBountyOpen(false)}
      >
        <ReleaseBounty
          request={request}
          showToast={showToast}
          onClose={() => setIsReleaseBountyOpen(false)}
          onBountyDecision={() => {
            setIsReleaseBountyOpen(false);
            setIsProcessComplete(true);
            fetchRequest();
          }}
        />
      </SlidePanel>

      {/* Confirmation Dialog */}
      <Dialog
        open={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Payment</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            You are about to initiate a bounty payment of {request.bounty} USDC for the request:
          </Typography>
          <Typography variant="h6" gutterBottom>
            {request.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Category: {request.category}
          </Typography>
          <Typography variant="body2">
            Completing this payment will activate your request on the platform.
          </Typography>
        </DialogContent>
        <StyledDialogActions disableSpacing>
          <ActionButton
            onClick={handleCloseConfirmation}
            color="primary"
            variant="outlined"
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleConfirmPayment}
            color="primary"
            variant="contained"
            startIcon={<PaymentIcon />}
            disabled={isPaymentProcessing}
          >
            {isPaymentProcessing ? 'Processing...' : 'Confirm Payment'}
          </ActionButton>
        </StyledDialogActions>
      </Dialog>

     {/* Payment UI */}
     <PaymentUI request={request} />

    </StyledContainer>
  );
};

export default React.memo(RequestDetail);
