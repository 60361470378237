// frontend/src/components/admin/UsersList.js

import React, { useState } from 'react';
import { 
  Button, 
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Box
} from '@mui/material';
import GenericList from './GenericList';
import { getUsersList } from '../../services/adminApi';
import { useMutation, useQueryClient } from 'react-query';
import { toggleUserStatus } from '../../services/adminApi';
import { useAuth } from '../../context/AuthContext';

const UsersList = () => {
  const queryClient = useQueryClient();
  const { user: currentUser, isSignedIn } = useAuth();
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    username: null,
    action: null
  });
  
  const toggleStatusMutation = useMutation(
    (username) => toggleUserStatus(username),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        setConfirmDialog({ open: false, username: null, action: null });
      },
    }
  );

  const handleActionClick = (username, currentStatus) => {
    const action = currentStatus ? 'unsuspend' : 'suspend';
    // Don't allow suspension of admin's own account
    if (currentUser && username === currentUser.username && action === 'suspend') {
      return;
    }
    setConfirmDialog({
      open: true,
      username,
      action
    });
  };

  const handleConfirm = () => {
    if (confirmDialog.username) {
      toggleStatusMutation.mutate(confirmDialog.username);
    }
  };

  const handleCancel = () => {
    setConfirmDialog({ open: false, username: null, action: null });
  };

  // If auth is not yet loaded, show loading state
  if (!isSignedIn) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  const columns = [
    { id: 'username', label: 'Username', minWidth: 170 },
    {
      id: 'suspended',
      label: 'Status',
      minWidth: 100,
      format: (value, row) => (
        <Chip
          label={value === true ? 'Suspended' : 'Active'}
          color={value === true ? 'error' : 'success'}
          size="small"
        />
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      format: (value, row) => {
        // Only render button if we have currentUser and row data
        if (!currentUser || !row || !row.username) return null;
        
        const isSelfAction = row.username === currentUser.username;
        
        return (
          <Button
            size="small"
            color={row?.suspended === true ? 'primary' : 'error'}
            onClick={() => handleActionClick(row.username, row.suspended)}
            disabled={isSelfAction} // Disable button for admin's own account
            sx={{
              display: isSelfAction ? 'none' : 'inline-flex' // Hide button completely for admin's own account
            }}
          >
            {row?.suspended === true ? 'Unsuspend' : 'Suspend'}
          </Button>
        );
      },
    },
    {
      id: 'created_at',
      label: 'Created At',
      minWidth: 170,
      format: (value) => value ? new Date(value).toLocaleString() : 'N/A',
    },
  ];

  return (
    <>
      <GenericList fetchData={getUsersList} columns={columns} />
      
      <Dialog
        open={confirmDialog.open}
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Confirm User {confirmDialog.action === 'suspend' ? 'Suspension' : 'Unsuspension'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {confirmDialog.action === 'suspend' ? (
              `Are you sure you want to suspend user "${confirmDialog.username}"? This will prevent them from accessing the platform.`
            ) : (
              `Are you sure you want to unsuspend user "${confirmDialog.username}"? This will restore their access to the platform.`
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="inherit">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            color={confirmDialog.action === 'suspend' ? 'error' : 'primary'}
            variant="contained"
            disabled={toggleStatusMutation.isLoading}
          >
            {confirmDialog.action === 'suspend' ? 'Suspend User' : 'Unsuspend User'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersList;