// frontend/src/components/BountySelector.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Collapse,
  InputAdornment,
  Paper,
  alpha,
  styled,
  Tooltip,
  Slider,
  Stack,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NumericFormat } from 'react-number-format';
import { motion, AnimatePresence } from 'framer-motion';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import { formatCurrency } from '../utilities/formatters';

const PRESET_AMOUNTS = [
  { value: 1000, label: `${formatCurrency(1000)}` },
  { value: 5000, label: `${formatCurrency(5000)}` },
  { value: 10000, label: `${formatCurrency(10000)}` },
  { value: 25000, label: `${formatCurrency(25000)}` },
  { value: 50000, label: `${formatCurrency(50000)}` },
  { value: 100000, label: `${formatCurrency(100000)}`, showFor: ['job', 'project'] },
];

const SLIDER_MAX = 100000;

const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: alpha(theme.palette.background.paper, 0.98),
    color: theme.palette.text.primary,
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backdropFilter: 'blur(8px)',
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

export default function BountySelector({ value, onChange, minAmount = 1, requestType }) {
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customValue, setCustomValue] = useState(value ? value.toString() : '');
  const [sliderValue, setSliderValue] = useState(value || minAmount);

  const handlePresetClick = (amount) => {
    onChange(amount);
    setCustomValue(amount.toString());
    setShowCustomInput(false);
    setSliderValue(amount);
  };

  const handleCustomValueChange = (event) => {
    const newValue = event.target.value;
    setCustomValue(newValue);
    onChange(Number(newValue));
    setSliderValue(Number(newValue));
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setCustomValue(newValue.toString());
    onChange(newValue);
  };

  const getBountyInfo = (requestType) => {
    switch (requestType) {
      case 'recommendation':
        return {
          distribution: "42.5% to recommender, 42.5% to vouchers, 15% platform fee",
          details: `Higher bounties attract premium recommendations and stronger validation. The bounty is paid in USDC to activate your request and is only released upon your satisfaction. The chosen recommender and their vouchers share the reward for a successful recommendations.`
        };
      case 'project':
        return {
          distribution: "85% to vouchers, 15% platform fee",
          details: `Higher bounties encourage thorough vetting of project bids. The bounty rewards community members who help identify the best bids. Project payment is handled separately with the selected provider.`
        };
      case 'job':
        return {
          distribution: "85% to vouchers, 15% platform fee",
          details: `Higher bounties incentivize industry experts to validate candidates. The bounty rewards those who help identify the best talent. Salary is handled separately with the hired candidate.`
        };
    }
  };

  const tooltipContent = (
    <Box>
      <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 600, color: 'primary.main' }}>
        How It Works
      </Typography>
      <Typography variant="body2" gutterBottom>
        {getBountyInfo(requestType).details}
      </Typography>
      <Typography variant="subtitle2" gutterBottom sx={{ mt: 1.5, fontWeight: 600, color: 'primary.main' }}>
        {`Bounty Distribution for ${requestType.charAt(0).toUpperCase() + requestType.slice(1)} Requests`}
      </Typography>
      <Typography variant="body2">
        {getBountyInfo(requestType).distribution}
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: 1,
        mb: 3 
      }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: 'primary.main',
          }}
        >
          Select Bounty Amount
        </Typography>
        <InfoTooltip 
          title={tooltipContent}
          placement="top-start"
          arrow
          enterTouchDelay={0}
          leaveTouchDelay={3000}
        >
          <InfoOutlinedIcon 
            sx={{ 
              fontSize: '1.1rem', 
              color: 'text.secondary',
              cursor: 'help',
              '&:hover': {
                color: 'primary.main'
              }
            }} 
          />
        </InfoTooltip>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            '@media (max-width: 420px)': {
              gridTemplateColumns: '1fr',
            },
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(5, 1fr)',
          },
          gap: 2,
          mb: 3,
        }}
      >
        {PRESET_AMOUNTS.filter(
          (amount) => !amount.showFor || amount.showFor.includes(requestType)
        ).map(({ value: amount, label }) => (
          <motion.div key={amount} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Button
              fullWidth
              variant={Number(customValue) === amount ? 'contained' : 'outlined'}
              onClick={() => handlePresetClick(amount)}
              sx={{
                p: 2,
                borderRadius: 2,
                height: '100%',
                borderWidth: 2,
                position: 'relative',
                overflow: 'hidden',
                color: Number(customValue) === amount ? 'background.paper' : 'text.primary',
                '@media (max-width: 420px)': {
                  py: 1.5,
                },
                '&:hover': {
                  borderWidth: 2,
                  '&::before': {
                    opacity: 0.1,
                  },
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: (theme) =>
                    `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                  opacity: Number(customValue) === amount ? 1 : 0,
                  transition: 'opacity 0.2s ease-in-out',
                  zIndex: 0,
                },
              }}
            >
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: 'inherit',
                  }}
                >
                  {label}
                </Typography>
              </Box>
            </Button>
          </motion.div>
        ))}
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => setShowCustomInput(!showCustomInput)}
          startIcon={showCustomInput ? <AttachMoneyIcon /> : <EditIcon />}
          sx={{
            mb: 2,
            borderRadius: 2,
            px: 3,
            py: 1,
          }}
        >
          {showCustomInput ? 'Hide Custom Amount' : 'Enter Custom Amount'}
        </Button>
      </Box>

      <Collapse in={showCustomInput}>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.6),
            backdropFilter: 'blur(10px)',
            border: '1px solid',
            borderColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          }}
        >
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Custom Amount (USDC)"
              value={customValue}
              onChange={handleCustomValueChange}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              helperText={`Minimum bounty: ${formatCurrency(minAmount)} USDC`}
            />
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              min={minAmount}
              max={SLIDER_MAX}
              step={100}
              marks={[
                { value: minAmount, label: `$${minAmount}` },
                { value: SLIDER_MAX, label: `$${SLIDER_MAX.toLocaleString()}+` },
              ]}
              sx={{
                '& .MuiSlider-thumb': {
                  height: 24,
                  width: 24,
                  backgroundColor: 'primary.main',
                },
                '& .MuiSlider-track': {
                  backgroundColor: 'primary.main',
                },
              }}
            />
          </Stack>
        </Paper>
      </Collapse>

      <AnimatePresence>
        {Number(customValue) >= 10000 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            <Paper
              sx={{
                mt: 3,
                p: 2,
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.success.main, 0.1),
                border: '1px solid',
                borderColor: 'success.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body1" color="success.main" sx={{ fontWeight: 500 }}>
                High-value bounties attract more qualified participants and stronger vouches!
              </Typography>
            </Paper>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}