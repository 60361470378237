// frontend/src/components/ActivitySummarySection.js

import React from 'react';
import { Box, Typography, alpha } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupsIcon from '@mui/icons-material/Groups';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FarcasterAvatar from './FarcasterAvatar';
import FarcasterUsername from './FarcasterUsername';

const ActivitySummarySection = ({ request, isCreator }) => {
  const renderBidsInfo = () => {
    if (!request.bids?.length) return null;
  
    return (
      <>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' }, // Align left on mobile, center on desktop
          gap: { xs: 1, sm: 0 } // Add gap for mobile stacked view
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Activity Summary
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {request.bids.length} {request.bids.length === 1 ? 
              `${request.category === 'recommendation' ? 'Recommendation' : 
                request.category === 'project' ? 'Bid' : 'Application'}` : 
              `${request.category === 'recommendation' ? 'Recommendations' : 
                request.category === 'project' ? 'Bids' : 'Applications'}`}
          </Typography>
        </Box>

        {request.topBid && (
          <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Top {request.category === 'recommendation' ? 'Recommendation' : 
                request.category === 'project' ? 'Bid' : 'Application'} by Total Vouch Amount:
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' }, 
              gap: 1.5,
              p: 1.5,
              background: theme => alpha(theme.palette.background.paper, 0.3),
              borderRadius: 1,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                background: theme => alpha(theme.palette.background.paper, 0.4),
                transform: 'translateX(4px)',
              }
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1.5,
                width: { xs: '100%', sm: 'auto' }
              }}>
                <FarcasterAvatar
                  username={request.topBid.bidder}
                  avatarUrl={request.topBid.bidderDetails?.avatarUrl}
                  size="small"
                  sx={{ width: 28, height: 28 }}
                />
                <FarcasterUsername 
                  username={request.topBid.bidder} 
                  sx={{ fontWeight: 600 }}
                />
              </Box>
              <Typography 
                variant="body2" 
                color="primary.main" 
                sx={{ 
                  fontWeight: 600,
                  ml: { xs: '36px', sm: 'auto' },
                  width: { xs: '100%', sm: 'auto' }
                }}
              >
                ${request.topBid.totalVouchAmount?.toLocaleString()} USDC
              </Typography>
            </Box>
          </Box>
        )}
        
        {request.winningBidId && request.bids?.find(b => b._id === request.winningBidId) && (
          <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Selected {request.category === 'recommendation' ? 'Recommendation' : 
                      request.category === 'project' ? 'Bid' : 'Application'}:
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' }, 
              gap: 1.5,
              p: 1.5,
              background: theme => alpha(theme.palette.success.main, 0.1),
              borderRadius: 1,
              border: theme => `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
            }}>
              {(() => {
                const selectedBid = request.bids.find(b => b._id === request.winningBidId);
                return (
                  <>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 1.5,
                      width: { xs: '100%', sm: 'auto' }
                    }}>
                      <FarcasterAvatar
                        username={selectedBid.bidder}
                        avatarUrl={selectedBid.bidderDetails?.avatarUrl}
                        size="small"
                        sx={{ width: 28, height: 28 }}
                      />
                      <FarcasterUsername 
                        username={selectedBid.bidder} 
                        sx={{ fontWeight: 600 }}
                      />
                    </Box>
                    <Typography 
                      variant="body2" 
                      color="success.main" 
                      sx={{ 
                        fontWeight: 600,
                        ml: { xs: '36px', sm: 'auto' },
                        width: { xs: '100%', sm: 'auto' }
                      }}
                    >
                      ${selectedBid.totalVouchAmount?.toLocaleString()} USDC
                    </Typography>
                  </>
                );
              })()}
            </Box>
          </Box>
        )}
      </>
    );
  };

  const renderEmptyState = () => {
    const getStatusContent = () => {
      switch (request.status) {
        case 'active':
          if (isCreator) {
            return {
              icon: <RocketLaunchIcon sx={{ fontSize: '2rem', color: 'primary.main' }} />,
              title: 'Request is Live',
              message: request.category === 'recommendation' 
                ? 'Waiting for expert recommendations' 
                : request.category === 'project' 
                ? 'Awaiting project proposals'
                : 'Awaiting qualified candidates',
              description: request.category === 'recommendation'
                ? 'Share your request to attract expert insights and valuable recommendations.'
                : request.category === 'project'
                ? 'Share your project to attract qualified professionals and competitive bids.'
                : 'Share your position to attract talented candidates and strong applications.'
            };
          }
          return {
            icon: <RocketLaunchIcon sx={{ fontSize: '2rem', color: 'primary.main' }} />,
            title: 'Open for Submissions',
            message: `Be the first to ${request.category === 'recommendation' ? 'recommend' : 
              request.category === 'project' ? 'bid' : 'apply'}!`,
            description: `This ${request.category} request is active and awaiting ${
              request.category === 'recommendation' ? 'recommendations' : 
              request.category === 'project' ? 'bids' : 'applications'
            }.`
          };
        case 'payment_pending':
          return {
            icon: <UpdateIcon sx={{ fontSize: '2rem', color: 'warning.main' }} />,
            title: 'Payment Processing',
            message: 'Request will be live soon',
            description: 'The bounty payment is being processed. Once confirmed, submissions will be accepted.'
          };
        case 'completed':
        case 'bounty_released':
        case 'bounty_withheld':
          return {
            icon: <CheckCircleIcon sx={{ fontSize: '2rem', color: 'success.main' }} />,
            title: 'Request Completed',
            message: 'No submissions received',
            description: 'This request has been completed without receiving any submissions.'
          };
        default:
          return {
            icon: <GroupsIcon sx={{ fontSize: '2rem', color: 'info.main' }} />,
            title: 'Status Update',
            message: `No ${request.category === 'recommendation' ? 'recommendations' : 
              request.category === 'project' ? 'bids' : 'applications'} yet`,
            description: 'Stay tuned for activity updates.'
          };
      }
    };

    const content = getStatusContent();

    return (
      <Box sx={{ textAlign: 'center', py: 2 }}>
        {content.icon}
        <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
          {content.title}
        </Typography>
        <Typography variant="subtitle1" color="primary.main" sx={{ mb: 1, fontWeight: 500 }}>
          {content.message}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {content.description}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        p: 2.5,
        background: theme => `linear-gradient(135deg, 
          ${alpha(theme.palette.background.paper, 0.4)} 0%,
          ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
        backdropFilter: 'blur(8px)',
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          background: theme => `linear-gradient(135deg, 
            ${alpha(theme.palette.background.paper, 0.5)} 0%,
            ${alpha(theme.palette.background.paper, 0.2)} 100%)`,
          transform: 'translateY(-2px)',
        }
      }}
    >
      {request.bids?.length > 0 ? renderBidsInfo() : renderEmptyState()}
    </Box>
  );
};

export default ActivitySummarySection;