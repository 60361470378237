// frontend/src/components/UserProfile.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Box, 
  Avatar, 
  Grid, 
  Paper, 
  Tabs, 
  Tab, 
  Divider, 
  CircularProgress, 
  Link,
  useMediaQuery,
  Tooltip,
  alpha,
  Chip,
  Button
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import FarcasterUsername from './FarcasterUsername';
import RequestCard from './RequestCard';
import BidCard from './BidCard';
import VouchCard from './VouchCard';
import { getUserProfile } from '../services/api';
import { useAuth } from '../context/AuthContext';
import LaunchIcon from '@mui/icons-material/Launch';
import RecommendIcon from '@mui/icons-material/Recommend';
import GavelIcon from '@mui/icons-material/Gavel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PageTitle from './PageTitle';

const ProfilePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
  background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  marginBottom: theme.spacing(2),
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const StatBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.3s ease-in-out',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.75rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  lineHeight: 1.3,
}));

const TabGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& .tab-label': {
    maxWidth: '120px',
    margin: '0 auto',
  },
}));

const TabLabel = styled('span')({
  display: 'block',
  textTransform: 'none',
});

const HorizontalTabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.between('sm', 'md')]: {
    '& .tab-label': {
      fontSize: '0.75rem',
    },
  },
}));

const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
  height: '100%',
  minHeight: 80,
  flex: 1,
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  border: `1px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(
    ['background-color', 'border-color', 'transform', 'box-shadow'],
    {
      duration: theme.transitions.duration.shorter,
    }
  ),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, active ? 0.15 : 0.05),
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    flexShrink: 0,
  },
  '& .tab-label': {
    fontSize: '0.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.2,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    padding: theme.spacing(0, 0.5),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    padding: theme.spacing(1.25),
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[4],
    fontSize: '0.875rem',
    maxWidth: 220,
    padding: theme.spacing(2),
  },
}));

const FarcasterLink = styled(Link)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transform: 'translateY(-2px)',
  },
}));

const UserProfile = () => {
  const { username } = useParams();
  const { isSignedIn, profile: authProfile, user: currentUser } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const data = await getUserProfile(username);
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile');
        setLoading(false);
      }
    };
    fetchProfile();
  }, [username]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!profile) {
    return <Typography>No profile data available.</Typography>;
  }

  const activeRequests = profile.requests?.filter(request => request.status !== 'draft') || [];

  const tabs = [
    {
      icon: <RecommendIcon />,
      label: 'Requests',
      tooltipContent: 'View user\'s requests',
    },
    {
      icon: <GavelIcon />,
      label: 'Bids',
      tooltipContent: 'View user\'s bids and applications',
    },
    {
      icon: <ThumbUpIcon />,
      label: 'Vouches',
      tooltipContent: 'View user\'s vouches',
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: { xs: 2, sm: 4 },
        px: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <PageTitle 
      title={`${profile.displayName || profile.username}'s Profile`}
      description={`View ${profile.username}'s professional activity - requests, responses, and vouches ${profile.bio ? `| ${profile.bio.slice(0, 100)}${profile.bio.length > 100 ? '...' : ''}` : ''}`}
      />
      <ProfilePaper elevation={3}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LargeAvatar src={profile.avatarUrl} alt={profile.displayName || profile.username} />
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              <FarcasterUsername username={profile.username} />
            </Typography>
            {profile.displayName && (
              <Typography variant="h6" align="center" gutterBottom color="text.secondary">
                {profile.displayName}
              </Typography>
            )}
            <FarcasterLink
              href={`https://warpcast.com/${profile.username}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mt: 1 }}
            >
              Farcaster Profile <LaunchIcon fontSize="small" sx={{ ml: 0.5 }} />
            </FarcasterLink>
            {profile.fid && (
              <Chip
                label={`FID: ${profile.fid}`}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ mt: 2 }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {profile.bio && (
              <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                {profile.bio}
              </Typography>
            )}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={12} md={6}>
                <StatBox>
                  <StatValue>{profile.projectsCompleted || 0}</StatValue>
                  <StatLabel>Projects Completed</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <StatBox>
                  <StatValue>{profile.jobsCompleted || 0}</StatValue>
                  <StatLabel>Jobs Completed</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <StatBox>
                  <StatValue>{profile.vouchesReceived || 0}</StatValue>
                  <StatLabel>Vouches Received</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <StatBox>
                  <StatValue>
                    {profile.requestsWithBountyDecision > 0 
                      ? `${profile.bountyReleasePercentage}%`
                      : '—'  // Em dash for no data
                    }
                  </StatValue>
                  <StatLabel>
                    Bounty Release Rate
                    {profile.requestsWithBountyDecision === 0 && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block',
                          mt: 0.5,
                          color: theme => theme.palette.primary.main,
                          fontSize: '0.7rem'
                        }}
                      >
                        No bounty decisions yet
                      </Typography>
                    )}
                  </StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <StatBox>
                  <StatValue>{activeRequests.length}</StatValue>
                  <StatLabel>Active Requests</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <StatBox>
                  <Typography variant="caption" color="text.secondary">
                    Member since
                  </Typography>
                  <StatValue sx={{ fontSize: '1.25rem' }}>
                    {new Date(profile.created_at).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short'
                    })}
                  </StatValue>
                </StatBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ProfilePaper>

      {isMobile ? (
        <TabGrid>
          {tabs.map((tab, index) => (
            <StyledTooltip key={index} title={tab.tooltipContent}>
              <TabButton
                onClick={() => handleTabChange(index)}
                active={tabValue === index}
              >
                {tab.icon}
                <TabLabel className="tab-label">{tab.label}</TabLabel>
              </TabButton>
            </StyledTooltip>
          ))}
        </TabGrid>
      ) : (
        <HorizontalTabContainer>
          {tabs.map((tab, index) => (
            <StyledTooltip key={index} title={tab.tooltipContent}>
              <TabButton
                onClick={() => handleTabChange(index)}
                active={tabValue === index}
              >
                {tab.icon}
                <TabLabel className="tab-label">{tab.label}</TabLabel>
              </TabButton>
            </StyledTooltip>
          ))}
        </HorizontalTabContainer>
      )}

      <Divider sx={{ mb: 3 }} />

      {tabValue === 0 && (
        <Grid container spacing={3}>
          {activeRequests.length > 0 ? (
            activeRequests.map((request) => (
              <Grid item xs={12} sm={12} md={6} key={request._id}>
                <RequestCard 
                  request={{
                    ...request,
                    creatorDetails: {
                      username: request.creator,
                      avatarUrl: profile.avatarUrl
                    }
                  }} 
                  showManageOptions={false}
                  isMyRequest={currentUser?.username === request.creator}  // Add this line
                />
              </Grid>
            ))
          ) : (
            <Box sx={{ width: '100%', textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No active requests available
              </Typography>
            </Box>
          )}
        </Grid>
      )}

      {tabValue === 1 && (
        <Grid container spacing={3}>
          {profile.bids && profile.bids.length > 0 ? (
            profile.bids.map((bid) => (
              <Grid item xs={12} sm={12} md={6} key={bid._id}>
                <BidCard 
                  bid={{
                    ...bid,
                    bidderDetails: {
                      username: profile.username,
                      avatarUrl: profile.avatarUrl
                    }
                  }}
                  requestCategory={bid.requestCategory}
                  requestTitle={bid.requestTitle}
                  requestId={bid.requestId?._id} // Changed this line
                />
              </Grid>
            ))
          ) : (
            <Box sx={{ width: '100%', textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No bids available
              </Typography>
            </Box>
          )}
        </Grid>
      )}

      {tabValue === 2 && (
        <Grid container spacing={3}>
          {profile.vouches && profile.vouches.length > 0 ? (
            profile.vouches.map((vouch) => {
              console.log('Vouch data:', vouch); // Add this line to inspect the data
              return (
                <Grid item xs={12} sm={12} md={6} key={vouch._id}>
                  <VouchCard 
                    vouch={{
                      ...vouch,
                      voucherDetails: {
                        username: profile.username,
                        avatarUrl: profile.avatarUrl
                      }
                    }}
                    bidderUsername={vouch.bidder}
                    requestTitle={vouch.request?.title}
                    requestId={vouch.request?._id || vouch.requestId}
                    bidType={vouch.request?.category === 'job' ? 'Application' : 
                            vouch.request?.category === 'project' ? 'Bid' : 
                            'Recommendation'}
                  />
                </Grid>
              );
            })
          ) : (
            <Box sx={{ width: '100%', textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No vouches available
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default UserProfile;