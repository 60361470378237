// frontend/src/components/VouchCard.js

import React from 'react';
import { Typography, Box, Chip, Link, Grid, Tooltip, alpha } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FarcasterUsername from './FarcasterUsername';
import FarcasterAvatar from './FarcasterAvatar';
import { StyledCard, CardInner, StyledAmountDisplay, UnifiedCardTitle, TitleBox } from './CommonStyledCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HeaderBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  textAlign: 'center',
}));

const StatusChipBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& .MuiChip-root': {
    boxShadow: `0 2px 8px ${alpha(theme.palette.common.black, 0.1)}`,
  }
}));

const AmountLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  marginBottom: theme.spacing(0.5),
}));

const AmountValue = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '2rem',
  color: theme.palette.primary.main,
  textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',  // Smaller on mobile
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '1.75rem', // Medium on tablets
  }
}));

const TooltipIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const getPaymentStatusInfo = (status) => {
  switch (status) {
    case 'completed':
      return { label: 'Completed', color: 'success' };
    case 'pending':
      return { label: 'Pending', color: 'warning' };
    case 'refunded':
      return { label: 'Refunded', color: 'info' };
    case 'failed':
      return { label: 'Failed', color: 'error' };
    default:
      return { label: status || 'Unknown', color: 'default' };
  }
};

const VouchCard = ({ vouch, bidderUsername, requestTitle, requestId, bidType }) => {
  const statusInfo = getPaymentStatusInfo(vouch.paymentStatus);
  
  const finalRequestId = vouch.request?._id || vouch.requestId || requestId;
  const finalRequestTitle = vouch.request?.title || vouch.requestTitle || requestTitle;
  const finalBidType = vouch.request?.category === 'job' ? 'Application' :
                      vouch.request?.category === 'project' ? 'Bid' :
                      vouch.request?.category === 'recommendation' ? 'Recommendation' :
                      bidType || 'Response';

  return (
    <StyledCard>
      <CardInner>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <HeaderBox>
              <TitleBox>
                <Box 
                  sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <ThumbUpIcon />
                    <UnifiedCardTitle
                      variant="h2"
                      component="h2"
                      sx={{
                        fontSize: {
                          xs: '1.25rem !important',
                          sm: '1.35rem !important',
                          md: '1.5rem !important'
                        },
                        textAlign: 'center',
                        display: 'inline', // Keeps the title text together
                        color: 'text.primary',
                        '&:hover': {
                          color: 'text.primary'
                        },
                        '& *': {
                          fontSize: 'inherit !important'
                        },
                        '& a': {
                          fontSize: 'inherit !important',
                          fontWeight: 'inherit'
                        }
                      }}
                    >
                      Vouch for{' '}
                      <FarcasterUsername 
                        username={bidderUsername || 'Unknown Bidder'}
                      />
                    </UnifiedCardTitle>
                  </Box>
                </Box>
              </TitleBox>
              <StatusChipBox>
                <Chip
                  label={statusInfo.label}
                  color={statusInfo.color}
                  size="small"
                  sx={{
                    minWidth: 100,
                    height: 32,
                    '& .MuiChip-label': {
                      fontSize: '0.875rem',
                      fontWeight: 600,
                      padding: '0 16px',
                    },
                  }}
                />
              </StatusChipBox>
            </HeaderBox>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <FarcasterAvatar
                username={vouch.voucherDetails?.username || vouch.voucher}
                avatarUrl={vouch.voucherDetails?.avatarUrl}
                size="medium"
                sx={{ mr: 2 }}
              />
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Vouched by
                </Typography>
                <Typography variant="subtitle1">
                  <FarcasterUsername username={vouch.voucher} />
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <StyledAmountDisplay>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AttachMoneyIcon sx={{ color: 'primary.main', mr: 1 }} />
                <AmountLabel>
                  Vouch Amount
                </AmountLabel>
                <Tooltip title="Amount vouched to support this response">
                  <TooltipIcon />
                </Tooltip>
              </Box>
              <Box>
                <AmountValue>
                  ${vouch.amount ? vouch.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'} USDC
                </AmountValue>
              </Box>
            </StyledAmountDisplay>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <FarcasterAvatar
                username={bidderUsername}
                avatarUrl={vouch.bidderAvatarUrl}
                size="small"
                sx={{ mr: 2 }}
              />
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Recipient
                </Typography>
                <Typography variant="subtitle1">
                  <FarcasterUsername username={bidderUsername} />
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {finalBidType} for request:
            </Typography>
            {finalRequestId && finalRequestId !== 'unknown' ? (
              <Link
                component={RouterLink}
                to={`/request/${finalRequestId}`}
                color="primary"
                sx={{
                  display: 'block',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {finalRequestTitle || 'View Request'}
              </Link>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Request no longer available
              </Typography>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, pt: 2, borderTop: '1px solid', borderColor: 'divider' }}>
          <Typography variant="caption" color="text.secondary">
            Vouched on:{' '}
            {vouch.created_at
              ? new Date(vouch.created_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : 'Unknown date'}
          </Typography>
        </Box>
      </CardInner>
    </StyledCard>
  );
};

export default VouchCard;