// frontend/src/components/IncreaseBountyButton.js

import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Paper,
  Slider,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  alpha,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { NumericFormat } from 'react-number-format';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StarIcon from '@mui/icons-material/Star';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { increaseBounty } from '../services/api';
import { formatCurrency } from '../utilities/formatters';

const MIN_INCREASE_AMOUNT = 2;
const SLIDER_MAX = 50000;
const BREAKPOINT_XS = '@media (max-width:420px)';

const IMPACT_LEVELS = [
  { value: 1000, label: formatCurrency(1000), icon: ShowChartIcon, impact: 'Notable Increase' },
  { value: 5000, label: formatCurrency(5000), icon: TrendingUpIcon, impact: 'Significant Boost' },
  { value: 10000, label: formatCurrency(10000), icon: StarIcon, impact: 'Major Enhancement' },
  { value: 25000, label: formatCurrency(25000), icon: StarIcon, impact: 'Maximum Impact' },
];

const getImpactLevel = (amount) => {
  if (amount >= 25000) return 'Maximum Impact';
  if (amount >= 10000) return 'Major Enhancement';
  if (amount >= 5000) return 'Significant Boost';
  if (amount >= 1000) return 'Notable Increase';
  return 'Minor Increase';
};

const getImpactColor = (theme, amount) => {
  if (amount >= 25000) return theme.palette.success.main;
  if (amount >= 10000) return theme.palette.primary.main;
  if (amount >= 5000) return theme.palette.primary.light;
  if (amount >= 1000) return theme.palette.info.main;
  return theme.palette.grey[500];
};

const getBenefitsText = (requestType) => {
  switch (requestType) {
    case 'recommendation':
      return [
        'Attract top-tier recommendations from experienced experts.',
        'Motivate in-depth research and comprehensive advice.',
        'Encourage a broader community to endorse quality recommendations.',
      ];
    case 'project':
      return [
        'Draw the attention of highly skilled professionals to your project.',
        'Incentivize detailed and innovative project proposals.',
        'Foster community engagement in reviewing and supporting top bids.',
      ];
    case 'job':
      return [
        'Reach a wider pool of qualified job candidates.',
        'Encourage applicants to submit thorough and tailored applications.',
        'Enhance community involvement in highlighting outstanding candidates.',
      ];
    default:
      return [
        'Attract higher-quality responses to your request.',
        'Encourage thoughtful and detailed contributions.',
        'Increase community engagement in evaluating top submissions.',
      ];
  }
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(20px)',
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: `
      0 4px 20px ${alpha(theme.palette.common.black, 0.2)},
      0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
    `,
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
    [BREAKPOINT_XS]: {
      margin: theme.spacing(0.5),
      maxHeight: '95vh',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '2px',
      background: `linear-gradient(90deg, 
        ${alpha(theme.palette.primary.main, 0)},
        ${theme.palette.primary.main},
        ${alpha(theme.palette.primary.main, 0)}
      )`,
      opacity: 0.5,
    },
  },

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(4px)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  fontWeight: 600,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  textTransform: 'none',
  fontSize: '0.9375rem',
  letterSpacing: '0.02em',

  '&.MuiButton-contained': {
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    color: theme.palette.common.black,
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,

    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
      boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
      transform: 'translateY(-2px)',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      padding: '8px 16px',
    },
  },

  '&.MuiButton-outlined': {
    borderColor: alpha(theme.palette.primary.main, 0.5),

    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      transform: 'translateY(-2px)',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      padding: '8px 16px',
    },
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 0,
  borderTop: `1px solid ${theme.palette.divider}`,
  '& .MuiButton-root': {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    '& .MuiButton-root': {
      width: '100%',
      margin: 0,
    },
  },
  [BREAKPOINT_XS]: {
    padding: theme.spacing(1.5),
  },
  '& > :not(:first-of-type)': {
    marginLeft: 0,
  },
}));

const GlassyPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },

  [BREAKPOINT_XS]: {
    padding: theme.spacing(1.5),
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      valueIsNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
});

const IncreaseBountyButton = ({
  requestId,
  currentBounty,
  onIncrease,
  showToast,
  requestType,
  buttonProps = {},
}) => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [sliderValue, setSliderValue] = useState(MIN_INCREASE_AMOUNT);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setAmount('');
    setSliderValue(MIN_INCREASE_AMOUNT);
  };

  const handleAmountChange = useCallback((event) => {
    const newValue = event.target.value;
    setAmount(newValue);
    if (newValue && !isNaN(newValue)) {
      setSliderValue(Math.min(parseFloat(newValue), SLIDER_MAX));
    }
  }, []);

  const handleSliderChange = useCallback((_, newValue) => {
    setSliderValue(newValue);
    setAmount(newValue.toString());
  }, []);

  const handlePresetClick = useCallback((presetAmount) => {
    setAmount(presetAmount.toString());
    setSliderValue(Math.min(presetAmount, SLIDER_MAX));
  }, []);

  const handleIncrease = async () => {
    if (parseFloat(amount) < MIN_INCREASE_AMOUNT) {
      showToast(`Minimum increase amount is ${formatCurrency(MIN_INCREASE_AMOUNT)} USDC`, 'error');
      return;
    }
    setIsProcessing(true);
    try {
      const result = await increaseBounty(requestId, parseFloat(amount));
      if (result.paymentUrl) {
        showToast('Additional bounty payment initiated. Redirecting to payment page...', 'success');
        window.location.href = result.paymentUrl;
      } else {
        throw new Error('Failed to get payment URL');
      }
    } catch (error) {
      showToast(`Failed to increase bounty: ${error.message}`, 'error');
    } finally {
      setIsProcessing(false);
      handleClose();
    }
  };

  const formattedCurrentBounty =
    typeof currentBounty === 'number' ? currentBounty.toFixed(2) : '0.00';

  const benefitsText = getBenefitsText(requestType);

  return (
    <>
      <StyledButton
        {...buttonProps}
        onClick={handleOpen}
        startIcon={<MonetizationOnIcon />}
        variant="contained"
      >
        Increase Bounty
      </StyledButton>

      <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            p: { xs: 2, sm: 3 },
            pb: { xs: 1.5, sm: 2 },
            [BREAKPOINT_XS]: {
              p: 1.5,
              pb: 1,
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <MonetizationOnIcon
              sx={{
                fontSize: { xs: '1.75rem', sm: '2rem' },
                color: 'primary.main',
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
              }}
            >
              Increase Bounty
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            p: { xs: 2, sm: 3 },
            [BREAKPOINT_XS]: {
              p: 1.5,
            },
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => alpha(theme.palette.primary.main, 0.2),
              borderRadius: '3px',
            },
          }}
        >
          <Stack spacing={{ xs: 2, sm: 3 }}>
            <Typography
              variant="h6"
              sx={{
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontSize: { xs: '1rem', sm: '1.25rem' },
              }}
            >
              <TrendingUpIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />
              Current Total Bounty: ${formattedCurrentBounty} USDC
            </Typography>

            {/* Expandable Benefits Section */}
            <Accordion
              sx={{
                backgroundColor: alpha('#ffffff', 0.6),
                backdropFilter: 'blur(10px)',
                borderRadius: 2,
                border: '1px solid',
                borderColor: (theme) => alpha(theme.palette.primary.main, 0.2),
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: 'primary.main',
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                  }}
                >
                  <InfoOutlinedIcon />
                  Why Increase Your Bounty?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  {benefitsText.map((benefit, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'text.secondary',
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                        }}
                      >
                        •
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'text.secondary',
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                        }}
                      >
                        {benefit}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <GlassyPaper
              sx={{
                px: { xs: 2.5, sm: 3.5 },
                py: { xs: 3, sm: 4 },
              }}
            >
              <Stack spacing={{ xs: 2.5, sm: 3 }}>
                <TextField
                  fullWidth
                  label="Custom Amount (USDC)"
                  value={amount}
                  onChange={handleAmountChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputComponent: NumberFormatCustom,
                  }}
                  helperText={`Minimum increase: ${formatCurrency(MIN_INCREASE_AMOUNT)} USDC`}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                    },
                  }}
                />

                <Box sx={{ px: { xs: 1.5, sm: 2 }, mt: 1, mb: 2 }}>
                  <Slider
                    value={sliderValue}
                    onChange={handleSliderChange}
                    min={MIN_INCREASE_AMOUNT}
                    max={SLIDER_MAX}
                    step={100}
                    marks={[
                      { value: MIN_INCREASE_AMOUNT, label: `$${MIN_INCREASE_AMOUNT}` },
                      { value: SLIDER_MAX, label: `$${SLIDER_MAX.toLocaleString()}+` },
                    ]}
                    sx={{
                      '& .MuiSlider-thumb': {
                        height: { xs: 20, sm: 24 },
                        width: { xs: 20, sm: 24 },
                        backgroundColor: (theme) => getImpactColor(theme, sliderValue),
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: (theme) => getImpactColor(theme, sliderValue),
                      },
                      '& .MuiSlider-markLabel': {
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                        color: 'text.secondary',
                      },
                    }}
                  />
                </Box>
              </Stack>
            </GlassyPaper>

            <AnimatePresence>
              {amount && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                >
                  <Paper
                    sx={{
                      p: { xs: 1.5, sm: 2 },
                      bgcolor: (theme) => alpha(getImpactColor(theme, parseFloat(amount)), 0.1),
                      border: '1px solid',
                      borderColor: (theme) => getImpactColor(theme, parseFloat(amount)),
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        color: (theme) => getImpactColor(theme, parseFloat(amount)),
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                      }}
                    >
                      {getImpactLevel(parseFloat(amount))} - Your increased bounty can make a big difference!
                    </Typography>
                  </Paper>
                </motion.div>
              )}
            </AnimatePresence>

            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                fontWeight: 500,
                textAlign: 'center',
                mt: { xs: 1, sm: 2 },
                fontSize: { xs: '0.875rem', sm: '1rem' },
              }}
            >
              Quick Select Amount
            </Typography>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(4, 1fr)',
                },
                gap: { xs: 1, sm: 2 },
              }}
            >
              {IMPACT_LEVELS.map(({ value, label, icon: Icon, impact }) => (
                <motion.div key={value} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                  <Button
                    fullWidth
                    variant={parseFloat(amount) === value ? 'contained' : 'outlined'}
                    onClick={() => handlePresetClick(value)}
                    sx={{
                      p: { xs: 1.5, sm: 2 },
                      height: '100%',
                      minHeight: { xs: '80px', sm: '100px' },
                      flexDirection: 'column',
                      gap: 1,
                      borderWidth: 2,
                      borderColor: (theme) =>
                        parseFloat(amount) === value
                          ? 'transparent'
                          : alpha(getImpactColor(theme, value), 0.3),
                      bgcolor: (theme) =>
                        parseFloat(amount) === value
                          ? getImpactColor(theme, value)
                          : 'transparent',
                      '&:hover': {
                        borderWidth: 2,
                        bgcolor: (theme) =>
                          parseFloat(amount) === value
                            ? getImpactColor(theme, value)
                            : alpha(getImpactColor(theme, value), 0.1),
                      },
                    }}
                  >
                    <Icon
                      sx={(theme) => ({
                        fontSize: { xs: '1.25rem', sm: '1.5rem' },
                        color:
                          parseFloat(amount) === value ? 'inherit' : getImpactColor(theme, value),
                      })}
                    />
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, fontSize: { xs: '0.875rem', sm: '1rem' } }}
                    >
                      {label}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={(theme) => ({
                        color:
                          parseFloat(amount) === value ? 'inherit' : getImpactColor(theme, value),
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                      })}
                    >
                      {impact}
                    </Typography>
                  </Button>
                </motion.div>
              ))}
            </Box>
          </Stack>
        </DialogContent>

        <StyledDialogActions>
          <StyledButton
            onClick={handleClose}
            variant="outlined"
            size="small"
            sx={{
              px: { xs: 2, sm: 3 },
              py: { xs: 1, sm: 1.5 },
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleIncrease}
            variant="contained"
            disabled={isProcessing || !amount || parseFloat(amount) < MIN_INCREASE_AMOUNT}
            size="small"
            sx={(theme) => ({
              px: { xs: 2, sm: 3 },
              py: { xs: 1, sm: 1.5 },
              background: isProcessing
                ? alpha(theme.palette.primary.main, 0.7)
                : `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
            })}
          >
            {isProcessing ? 'Processing...' : 'Increase Bounty'}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};

export default IncreaseBountyButton;
