// frontend/src/components/admin/VouchesList.js

import React from 'react';
import GenericList from './GenericList';
import { getVouchesList } from '../../services/adminApi';
import { Chip } from '@mui/material';

const getPaymentStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return 'success';
    case 'pending':
      return 'warning';
    case 'failed':
    case 'refund_failed':
      return 'error';
    case 'refunded':
      return 'info';
    default:
      return 'default';
  }
};

const columns = [
  { 
    id: 'voucher', 
    label: 'Voucher', 
    minWidth: 170,
  },
  { 
    id: 'bidInfo', 
    label: 'Bidder', 
    minWidth: 170,
    format: (value) => value.bidder
  },
  {
    id: 'bidInfo',
    label: 'Request',
    minWidth: 200,
    format: (value) => value.requestTitle
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 100,
    format: (value) => `$${parseFloat(value).toFixed(2)}`,
  },
  {
    id: 'paymentStatus',
    label: 'Payment Status',
    minWidth: 150,
    format: (value) => (
      <Chip 
        label={value.charAt(0).toUpperCase() + value.slice(1)} 
        color={getPaymentStatusColor(value)}
        size="small"
      />
    ),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 170,
    format: (value) => new Date(value).toLocaleString(),
  },
];

const VouchesList = () => {
  return <GenericList fetchData={getVouchesList} columns={columns} />;
};

export default VouchesList;