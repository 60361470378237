// frontend/src/components/RequestDetailInfo.js

import React, { useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  Divider
} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion } from 'framer-motion';

const InfoContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.15)}`
  }
}));

const HeaderWithIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const RankingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: { xs: '1rem', sm: '1.5rem' },
  letterSpacing: '0.015em',
  flexGrow: 1
}));

const CollapsibleHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: theme.spacing(1, 0),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  }
}));

const ExpandIcon = styled(ExpandMoreIcon)(({ theme, $expanded }) => ({
  transform: $expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease-in-out',
  fontSize: { xs: 24, sm: 28 },
  color: theme.palette.text.primary
}));

const ContentSection = styled(Box)(({ theme, $expanded }) => ({
  marginTop: $expanded ? theme.spacing(2) : 0,
  height: $expanded ? 'auto' : 0,
  opacity: $expanded ? 1 : 0,
  overflow: 'hidden',
  transition: 'all 0.2s ease',
  borderTop: $expanded ? `1px solid ${alpha(theme.palette.primary.main, 0.1)}` : 'none',
  paddingTop: $expanded ? theme.spacing(2) : 0
}));

const BulletList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5)
}));

const BulletItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  color: theme.palette.text.secondary
}));

const Dot = styled(Box)(({ theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  flexShrink: 0,
  marginTop: 8,
  opacity: 0.8
}));

const RequestDetailInfo = ({ request }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const getCategoryIcon = () => {
    const props = {
      sx: {
        color: 'primary.main',
        opacity: 1,
        fontSize: { xs: 24, sm: 28 }
      }
    };

    switch (request.category) {
      case 'recommendation':
        return <AutoGraphIcon {...props} />;
      case 'project':
        return <IntegrationInstructionsIcon {...props} />;
      case 'job':
        return <WorkspacePremiumIcon {...props} />;
      default:
        return null;
    }
  };

  const content = {
    recommendation: {
      points: [
        'Experts provide tailored recommendations addressing the requester\'s needs',
        'Community members vouch for the most valuable recommendations with USDC stakes',
        'Requester reviews and selects the most promising recommendation',
        'If successful, the chosen recommender receives 42.5% of the bounty for their expertise',
        'Supporting vouchers share 42.5% proportionally to their vouch amounts',
        'Vouchers who supported other recommendations receive their vouch amounts back'
      ]
    },
    project: {
      points: [
        'Bidders submit detailed proposals based on the project requirements',
        'Community experts vouch for the best bids and most capable providers with USDC stakes',
        'Requester evaluates and selects the winning bid to begin work',
        'When the selected provider delivers successfully, their vouchers share 85% of the bounty',
        'Vouchers who supported other bids receive their vouch amounts back upon selection'
      ]
    },
    job: {
      points: [
        'Candidates submit applications based on the job posting requirements', 
        'Industry experts vouch for the strongest candidates with USDC stakes',
        'Requester reviews applications and selects the final candidate',
        'When the selected candidate proves successful in the role, their vouchers share 85% of the bounty',
        'Vouchers who supported other candidates receive their vouch amounts back upon selection'
      ]
    }
  }[request.category];

  const rankingText = `${
    request.category === 'job'
      ? 'Applications'
      : request.category === 'project'
      ? 'Bids'
      : 'Recommendations'
  } are ranked by total amount vouched`;

  const collapsibleHeaderText = `How This ${
    request.category.charAt(0).toUpperCase() + request.category.slice(1)
  } Request Works`;

  return (
    <motion.div>
      <InfoContainer elevation={0}>
        <HeaderWithIcon>
          {getCategoryIcon()}
          <RankingText>{rankingText}</RankingText>
        </HeaderWithIcon>

        <Divider sx={{ my: 2 }} />

        <CollapsibleHeader onClick={() => setExpanded(!expanded)}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontSize: { xs: '0.9rem', sm: '1rem' }
            }}
          >
            {collapsibleHeaderText}
          </Typography>
          <ExpandIcon $expanded={expanded} />
        </CollapsibleHeader>

        <ContentSection $expanded={expanded}>
          <BulletList>
            {content.points.map((point, index) => (
              <BulletItem key={index}>
                <Dot />
                <Typography variant="body2">{point}</Typography>
              </BulletItem>
            ))}
          </BulletList>
        </ContentSection>
      </InfoContainer>
    </motion.div>
  );
};

export default RequestDetailInfo;
