// frontend/src/components/SubcategorySelector.js

import React from 'react';
import { Box, Typography, Grid, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { getSubcategoryIcon } from '../utilities/subcategoryUtils';

const SubcategoryCard = styled(motion.div)(({ theme, selected }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius * 2,
  cursor: 'pointer',
  background: selected 
    ? `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.15)}, ${alpha(theme.palette.primary.dark, 0.1)})` 
    : `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
  backdropFilter: 'blur(12px)',
  border: `1px solid ${selected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  position: 'relative',
  overflow: 'hidden',

  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.2)}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3px',
    background: selected 
      ? `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`
      : 'transparent',
    opacity: selected ? 1 : 0,
    transition: 'opacity 0.3s ease',
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  '& svg': {
    fontSize: 24,
    color: theme.palette.primary.main,
    transition: 'transform 0.3s ease',
  }
}));

const SubcategorySelector = ({ category, value, onChange, subcategories }) => {
  // Move useMemo before any conditional returns
  const groups = React.useMemo(() => {
    if (!subcategories) return [];
    
    try {
      return Object.entries(subcategories).map(([groupKey, groupData]) => ({
        title: groupData.label,
        items: Object.entries(groupData.subcategories).map(([key, label]) => ({
          key,
          label
        }))
      }));
    } catch (error) {
      console.error('Error processing subcategories:', error);
      return [];
    }
  }, [subcategories]);

  if (!subcategories) {
    console.log('No subcategories provided');
    return null;
  }

  if (groups.length === 0) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {groups.map((group) => (
        <Box key={group.title} sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontWeight: 600,
              color: 'text.primary',
              fontSize: '1.1rem',
            }}
          >
            {group.title}
          </Typography>
          <Grid container spacing={2}>
            {group.items.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.key}>
                <SubcategoryCard
                  selected={value === item.key}
                  onClick={() => onChange(item.key)}
                  whileHover={{ y: -4 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <IconWrapper>
                    {getSubcategoryIcon(item.key)}
                  </IconWrapper>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      fontWeight: 500,
                      color: value === item.key ? 'primary.main' : 'text.primary',
                    }}
                  >
                    {item.label}
                  </Typography>
                </SubcategoryCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default SubcategorySelector;