// frontend/src/components/BidForm.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Fade,
  Paper,
  TextField,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/CustomQuill.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { NumericFormat } from 'react-number-format';
import WalletSetup from './WalletSetup';
import { useAuth } from '../context/AuthContext';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.95)} 0%,
    ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  
  boxShadow: `
    inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.05)},
    0 4px 20px ${alpha(theme.palette.common.black, 0.1)},
    0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
  `,
  
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `
      inset 0 0 0 1px ${alpha(theme.palette.common.white, 0.1)},
      0 8px 30px ${alpha(theme.palette.common.black, 0.15)},
      0 0 30px ${alpha(theme.palette.primary.main, 0.15)}
    `,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.primary.main, 0.03)}, 
      transparent 70%)`,
    pointerEvents: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(8px)',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    
    '& fieldset': {
      borderColor: alpha(theme.palette.text.primary, 0.1),
      transition: 'all 0.2s ease-in-out',
    },
    
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      '& fieldset': {
        borderColor: alpha(theme.palette.primary.main, 0.3),
      },
    },
    
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.background.paper, 1),
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  
  '& .MuiInputLabel-root': {
    color: alpha(theme.palette.text.primary, 0.7),
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: alpha(theme.palette.text.primary, 0.5),
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(1.5, 3),
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  fontSize: '0.9375rem',
  letterSpacing: '0.02em',
  textTransform: 'none',
  lineHeight: 1.5,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.1)})`,
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
  },

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 16px ${alpha(theme.palette.primary.main, 0.25)}`,
    '&::before': {
      opacity: 1,
    },
  },

  '&:active': {
    transform: 'translateY(0)',
  },

  '&.MuiButton-contained': {
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    color: theme.palette.common.black,
    border: 'none',
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,

    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
      boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
    },
  },
}));

const DraftButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderColor: alpha(theme.palette.secondary.main, 0.5),
  
  '&:hover': {
    borderColor: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
  },
}));

const QuillWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  
  '& .ql-container': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(8px)',
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderTop: 'none',
    transition: 'all 0.2s ease-in-out',
    fontSize: {
      xs: '0.875rem',
      sm: '0.925rem',
      md: '1rem'
    },
    position: 'relative',
    zIndex: 1
  },
  
  '& .ql-toolbar': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(8px)',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderBottom: 'none',
    padding: {
      xs: '6px',
      sm: '8px',
      md: '8px 12px'
    },
    display: 'flex',
    position: 'relative',
    zIndex: 2,
    
    // Allow wrapping below 500px
    '@media (max-width: 499px)': {
      flexWrap: 'wrap',
      '& .ql-formats': {
        display: 'inline-flex',
        flexWrap: 'wrap',
        margin: '2px',
      }
    },
    // Prevent wrapping above 500px
    '@media (min-width: 500px)': {
      flexWrap: 'nowrap',
      '& .ql-formats': {
        display: 'inline-flex',
        flexShrink: 0,
        margin: '0 2px',
      }
    },
    gap: {
      xs: '2px',
      sm: '4px'
    },
    
    '& .ql-formats': {
      alignItems: 'center',
      gap: {
        xs: '1px',
        sm: '2px',
        md: '3px'
      },
      // Allow proper wrapping on small screens
      '@media (max-width: 499px)': {
        width: 'auto',
        flexWrap: 'wrap',
      }
    },
    
    '& button': {
      padding: {
        xs: '2px',
        sm: '2px',
        md: '3px'
      },
      width: {
        xs: '24px',
        sm: '26px',
        md: '28px'
      },
      height: {
        xs: '24px',
        sm: '26px',
        md: '28px'
      },
      flexShrink: 0
    },
    
    '& .ql-picker': {
      color: alpha(theme.palette.text.primary, 0.7),
      height: {
        xs: '24px',
        sm: '26px',
        md: '28px'
      },
      flexShrink: 0,
      position: 'relative',
      
      '& .ql-picker-label': {
        padding: {
          xs: '0 2px',
          sm: '0 3px',
          md: '0 4px'
        },
        fontSize: {
          xs: '0.813rem',
          sm: '0.825rem',
          md: '0.875rem'
        }
      },
      
      '& .ql-picker-options': {
        position: 'absolute',
        top: '100%',
        left: 0,
        backgroundColor: alpha(theme.palette.background.paper, 0.95),
        backdropFilter: 'blur(10px)',
        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
        borderRadius: theme.shape.borderRadius,
        padding: {
          xs: '2px 4px',
          sm: '4px 8px'
        },
        marginTop: '4px',
        zIndex: 1000,
        minWidth: '120px',
        boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.2)}`,
        
        '& .ql-picker-item': {
          padding: '8px 12px',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          color: theme.palette.text.primary,
          
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          
          '&.ql-selected': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main,
          }
        }
      },
      
      '&.ql-expanded': {
        '& .ql-picker-options': {
          display: 'block',
        },
        '& .ql-picker-label': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        }
      }
    },
    
    '& .ql-stroke': {
      stroke: alpha(theme.palette.text.primary, 0.7),
      strokeWidth: {
        xs: '1.5px',
        sm: '2px'
      }
    },
    
    '& .ql-fill': {
      fill: alpha(theme.palette.text.primary, 0.7),
    },
    
    '& button:hover .ql-stroke': {
      stroke: theme.palette.primary.main,
    },
    
    '& button:hover .ql-fill': {
      fill: theme.palette.primary.main,
    },

    '@media (min-width: 500px) and (max-width: 900px)': {
      '& .ql-formats': {
        margin: '0 2px',
      },
      '& button': {
        padding: '2px',
        width: '26px',
        height: '26px'
      },
      '& .ql-picker-label': {
        padding: '0 3px'
      }
    }
  },
  
  '& .ql-editor': {
    minHeight: {
      xs: 150,
      sm: 180,
      md: 200
    },
    fontSize: {
      xs: '0.875rem',
      sm: '0.925rem',
      md: '1rem'
    },
    color: theme.palette.text.primary,
    padding: {
      xs: theme.spacing(1.5),
      sm: theme.spacing(2)
    },
    
    '&::placeholder': {
      color: alpha(theme.palette.text.primary, 0.5),
    },
    
    '& p, & h1, & h2, & h3': {
      marginBottom: {
        xs: '0.5em',
        sm: '0.75em',
        md: '1em'
      }
    },
    
    '& ol, & ul': {
      paddingLeft: {
        xs: '1.2em',
        sm: '1.5em',
        md: '2em'
      }
    }
  },
  
  boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
  marginBottom: theme.spacing(3),
  
  '&:hover': {
    '& .ql-toolbar, & .ql-container': {
      borderColor: alpha(theme.palette.primary.main, 0.4),
    },
  },
  
  '&:focus-within': {
    '& .ql-toolbar, & .ql-container': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
  
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    
    '& .ql-snow.ql-toolbar button': {
      padding: '2px',
    },
    
    '& .ql-formats': {
      marginRight: '0',
    }
  },
  
  '& .ql-container': {
    WebkitOverflowScrolling: 'touch',
  },
  
  '@media (max-width: 600px)': {
    '& .ql-picker-options': {
      minWidth: '120px',
      '& .ql-picker-item': {
        padding: '8px 12px',
      }
    }
  }
}));

const CustomQuill = (props) => (
  <QuillWrapper>
    <ReactQuill {...props} />
  </QuillWrapper>
);

const DragDropZone = styled(Box)(({ theme }) => ({
  border: `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: `
      0 4px 20px ${alpha(theme.palette.common.black, 0.2)},
      0 0 20px ${alpha(theme.palette.primary.main, 0.1)}
    `,
  },
  
  '& .MuiDialogTitle-root': {
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.background.paper, 0.95)} 0%,
      ${alpha(theme.palette.background.paper, 0.9)} 100%)`,
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  },
  
  '& .MuiDialogContent-root': {
    backgroundColor: 'transparent',
  },
  
  '& .MuiDialogActions-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 0,
  '& .MuiButton-root': {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    '& .MuiButton-root': {
      width: '100%',
      margin: 0,
    },
  },
  '& > :not(:first-of-type)': {
    marginLeft: 0,
  },
}));

const TooltipIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(1),
  color: alpha(theme.palette.text.secondary, 0.8),
  cursor: 'help',
  transition: 'color 0.2s ease-in-out',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

// NumberFormat Custom Component
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      allowNegative={false}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
    />
  );
});

// Utility functions
const getProposalLabel = (requestCategory) => {
  switch (requestCategory) {
    case 'job':
      return 'Cover Letter';
    case 'project':
      return 'Project Proposal';
    case 'recommendation':
      return 'Recommendation';
    default:
      return 'Proposal';
  }
};

const getResponseType = (requestCategory) => {
  switch (requestCategory) {
    case 'job':
      return 'Job Application';
    case 'project':
      return 'Project Bid';
    case 'recommendation':
      return 'Recommendation';
    default:
      return 'Response';
  }
};

const getNegotiableTooltip = (requestCategory) => {
  if (requestCategory === 'project') {
    return "Check this if you're open to negotiating the bid amount.";
  } else if (requestCategory === 'job') {
    return "Check this if you're open to negotiating the salary.";
  }
  return '';
};

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
const ALLOWED_FILE_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

const BidForm = ({ request, requestCategory, onSubmit, showToast, existingBid, onDelete, onCancel }) => {
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDraft, setIsDraft] = useState(existingBid?.status === 'draft' || false);
  const [showHighlight, setShowHighlight] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [submissionType, setSubmissionType] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [documents, setDocuments] = useState([]);
  const [existingDocuments, setExistingDocuments] = useState([]);
  const { user } = useAuth();
  const [showWalletSetup, setShowWalletSetup] = useState(false);

  const itemName = getResponseType(requestCategory);
  const [deadlinePassed, setDeadlinePassed] = useState(false);
  const [deletedDocuments, setDeletedDocuments] = useState([]);

  useEffect(() => {
    const currentTime = new Date();
    let deadline = null;
    
    if (requestCategory === 'job' && request?.applicationDeadline) {
      deadline = new Date(request.applicationDeadline);
    } else if (requestCategory === 'project' && request?.bidDeadline) {
      deadline = new Date(request.bidDeadline);
    } else if (requestCategory === 'recommendation' && request?.recommendationDeadline) {
      deadline = new Date(request.recommendationDeadline);
    }

    if (deadline && deadline < currentTime) {
      setDeadlinePassed(true);  
      setErrorMessage(`${getResponseType(requestCategory)} deadline has passed.`);
    }
  }, [request, requestCategory]);

  useEffect(() => {
    if (user && !user.payoutAddress && requestCategory === 'recommendation' && !user.isTestUser) {
      setShowWalletSetup(true);
    }
  }, [user, requestCategory]);

  useEffect(() => {
    if (existingBid) {
      setIsDraft(existingBid.status === 'draft');
      setShowHighlight(true);
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => setShowHighlight(false), 3000);
      if (existingBid.documents) {
        setExistingDocuments(existingBid.documents);
      }
    }
  }, [existingBid]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    bidProposal: Yup.string().required(`${getProposalLabel(requestCategory)} is required`),
    isBidNegotiable: Yup.boolean(),
    documents: Yup.array(),
    ...(requestCategory === 'project' && {
      bidAmount: Yup.number()
        .typeError('Bid amount must be a number')
        .required('Bid amount is required'),
    }),
    ...(requestCategory === 'job' && {
      bidSalary: Yup.number()
        .typeError('Salary must be a number')
        .required('Salary is required'),
    }),
  });

  // Map fields to steps
  const fieldStepMapping = {
    bidAmount: 0,
    bidSalary: 0,
    isBidNegotiable: 0,
    bidProposal: requestCategory !== 'recommendation' ? 1 : 0,
    documents: requestCategory !== 'recommendation' ? 2 : 1,
  };

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      bidAmount: existingBid?.amount?.toString() || '',
      bidSalary: existingBid?.salary?.toString() || '',
      isBidNegotiable: existingBid?.isNegotiable || false,
      bidProposal: existingBid?.proposal || '',
      documents: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleFormSubmit(values, isSavingDraft);
    },
  });

  const handleRemoveExistingDocument = (index) => {
    const documentToRemove = existingDocuments[index];
    setDeletedDocuments(prev => [...prev, documentToRemove.filename]);
    setExistingDocuments(prevDocs => prevDocs.filter((_, i) => i !== index));
  };

  // Handle form submission
  const handleFormSubmit = async (values, saveAsDraft = false) => {
    setIsSavingDraft(false);

    if (saveAsDraft) {
      await submitBid(values, true);
    } else {
      const isNewBid = !existingBid;
      const wasDraft = isDraft;
      if (isNewBid || wasDraft) {
        setSubmissionType('live');
      } else {
        setSubmissionType('update');
      }
      setShowSubmitConfirmation(true);
    }
  };

  const submitBid = async (values, saveAsDraft) => {
    setIsSubmitting(true);
  
    // Check if there are Word documents that will need conversion
    const hasWordDocs = documents.some(doc => 
      doc instanceof File && (
        doc.type === 'application/msword' || 
        doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      )
    );
  
    if (hasWordDocs) {
      showToast('Converting Word documents to PDF...', 'info', null, 'document-conversion');
    }
  
    try {
      const formData = new FormData();
      formData.append('proposal', values.bidProposal);
      formData.append('isNegotiable', values.isBidNegotiable);
      formData.append('status', saveAsDraft ? 'draft' : 'pending');
      if (requestCategory === 'project') {
        formData.append('amount', values.bidAmount.replace(/,/g, ''));
      } else if (requestCategory === 'job') {
        formData.append('salary', values.bidSalary.replace(/,/g, ''));
      }
      
      // Append existing documents that weren't deleted
      existingDocuments.forEach((doc, index) => {
        formData.append(`existingDocuments[${index}]`, JSON.stringify(doc));
      });
  
      // Append the list of deleted documents
      if (deletedDocuments.length > 0) {
        formData.append('deletedDocuments', JSON.stringify(deletedDocuments));
      }
  
      // Append new documents
      documents.forEach((doc) => {
        formData.append('documents', doc);
      });
  
      if (existingBid) {
        await onSubmit(existingBid._id, formData);
      } else {
        await onSubmit(formData);
      }
      showToast(
        `${getResponseType(requestCategory)} ${saveAsDraft ? 'saved as draft' : 'submitted'} successfully`,
        'success'
      );
      onCancel();
    } catch (error) {
      showToast(
        error.message ||
          `Error ${saveAsDraft ? 'saving' : 'submitting'} ${getResponseType(requestCategory).toLowerCase()}. Please try again.`,
        'error'
      );
    } finally {
      setIsSubmitting(false);
      // Clear the document conversion toast if it exists
      if (hasWordDocs) {
        showToast(null, null, 0, 'document-conversion');
      }
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(existingBid._id);
      showToast(`${getResponseType(requestCategory)} deleted successfully`, 'success');
      onCancel();
    } catch (error) {
      showToast(
        error.message || `Error deleting ${getResponseType(requestCategory).toLowerCase()}. Please try again.`,
        'error'
      );
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  // File upload with react-dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    onDrop: (acceptedFiles) => {
      const validFiles = acceptedFiles.filter(file => {
        if (file.size > MAX_FILE_SIZE) {
          showToast(`File ${file.name} is too large. Maximum size is 5MB.`, 'error');
          return false;
        }
        return true;
      });
      setDocuments(prevDocuments => [...prevDocuments, ...validFiles]);
      formik.setFieldValue('documents', [...formik.values.documents, ...validFiles]);
    },
  });

  const handleRemoveDocument = (index) => {
    const updatedDocuments = [...documents];
    updatedDocuments.splice(index, 1);
    setDocuments(updatedDocuments);
    formik.setFieldValue('documents', updatedDocuments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (requestCategory === 'recommendation' && !user.payoutAddress) {
      setShowWalletSetup(true);
      return;
    }
    setIsSavingDraft(false);
    setErrorMessage('');
    
    try {
      // Validate form
      const errors = await formik.validateForm();
      if (Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const errorStep = fieldStepMapping[firstErrorField] || 0;
        setActiveStep(errorStep);
        setErrorMessage('Please fix the errors before submitting.');
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
  
      // Validate new documents
      const newDocs = documents.filter(doc => doc instanceof File);
      const invalidFiles = newDocs.filter(file => !ALLOWED_FILE_TYPES.includes(file.type));
      if (invalidFiles.length > 0) {
        setErrorMessage(`Invalid file type(s): ${invalidFiles.map(f => f.name).join(', ')}. Only PDF and Word documents are allowed.`);
        setActiveStep(fieldStepMapping['documents'] || 0);
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
  
      const oversizedFiles = newDocs.filter(file => file.size > MAX_FILE_SIZE);
      if (oversizedFiles.length > 0) {
        setErrorMessage(`File(s) exceed the maximum size of 5MB: ${oversizedFiles.map(f => f.name).join(', ')}`);
        setActiveStep(fieldStepMapping['documents'] || 0);
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
  
      // Update formik values with current document state
      await formik.setFieldValue('documents', [
        ...existingDocuments.map(doc => ({
          filename: doc.filename,
          originalname: doc.originalname || doc.filename,
          path: doc.path,
          mimetype: doc.mimetype,
          size: doc.size
        })),
        ...newDocs
      ]);
  
      // Submit the form
      await formik.submitForm();
      
    } catch (error) {
      console.error('Error submitting bid:', error);
      setErrorMessage('An error occurred while submitting the bid. Please try again.');
      showToast('Failed to submit bid. Please try again.', 'error');
    }
  };

  const handleSaveAsDraft = async () => {
    setIsSavingDraft(true);
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0];
      const errorStep = fieldStepMapping[firstErrorField] || 0;
      setActiveStep(errorStep);
      setErrorMessage('Please fix the errors before saving as draft.');
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      formik.handleSubmit();
    }
  };

  const getProposalHelperText = (requestCategory) => {
    switch (requestCategory) {
      case 'job':
        return {
          title: 'Cover Letter',
          description: 'Write a compelling cover letter that highlights your relevant experience, skills, and why you are ' +
                      'the ideal candidate for this position. Include specific examples that demonstrate your expertise ' +
                      'and align with the job requirements.',
          tooltip: 'A strong cover letter should be concise, professional, and tailored to the specific role and company'
        };
      case 'project':
        return {
          title: 'Project Proposal',
          description: 'Provide a detailed project proposal, including methodology, timeline, and key deliverables. ' +
                      'Describe your relevant experience and how you plan to achieve the project objectives.',
          tooltip: 'Focus on demonstrating your understanding of the requirements and your unique value proposition'
        };
      case 'recommendation':
        return {
          title: 'Recommendation',
          description: 'Provide a detailed, well-reasoned recommendation based on your expertise and experience. ' +
                      'Include specific insights, analysis, and supporting evidence for your recommendation.',
          tooltip: 'Strong recommendations are thorough, objective, and backed by concrete examples or data'
        };
    }
  };
  
  const getDocumentHelperText = (requestCategory) => {
    switch (requestCategory) {
      case 'job':
        return {
          title: 'Supporting Documents',
          description: 'Attach your resume and any additional relevant documents such as certifications, portfolio ' +
                      'samples, or letters of recommendation.',
          fileTypes: 'PDF or Word documents (Max 5MB each)'
        };
      case 'project':
        return {
          title: 'Supporting Documents',
          description: 'Include relevant project plans, technical specifications, work samples, or any other ' +
                      'documentation that demonstrates your capabilities and approach.',
          fileTypes: 'PDF or Word documents (Max 5MB each)'
        };
      case 'recommendation':
        return {
          title: 'Supporting Documents',
          description: 'Attach any relevant research, analysis, case studies, or other materials that support ' +
                      'your recommendation.',
          fileTypes: 'PDF or Word documents (Max 5MB each)'
        };
    }
  };

  const getConfirmationText = () => {
    const actionType = submissionType === 'live' ? 'submit' : 'update';
    const responseType = getResponseType(requestCategory).toLowerCase();

    return (
      <>
        <Typography variant="h6" gutterBottom>
          {actionType.charAt(0).toUpperCase() + actionType.slice(1)} {responseType}?
        </Typography>
        <Typography variant="body2" component="div">
          <ul>
            <li>Your {responseType} will be visible to the requester and other users.</li>
            <li>It will be eligible to receive vouches.</li>
            <li>You can only have one {responseType} per request.</li>
            <li>Edits are only possible if no vouches have been received.</li>
          </ul>
        </Typography>
      </>
    );
  };

  const steps = [
    {
      label: getProposalLabel(requestCategory),
      content: (
        <Fade in={true}>
          <Box sx={{ position: 'relative' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box sx={{ 
                  mb: 3,
                  backgroundColor: theme => alpha(theme.palette.background.paper, 0.6),
                  backdropFilter: 'blur(10px)',
                  padding: 3,
                  border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}>
                  <Typography variant="h6" gutterBottom>
                    {getProposalHelperText(requestCategory).title}
                    <Tooltip title={getProposalHelperText(requestCategory).tooltip} placement="top">
                      <TooltipIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {getProposalHelperText(requestCategory).description}
                  </Typography>
                </Box>
                
                <Box sx={{
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: theme => `radial-gradient(circle at top right, 
                      ${alpha(theme.palette.primary.main, 0.03)}, 
                      transparent 70%)`,
                    pointerEvents: 'none',
                  }
                }}>
                  <CustomQuill
                    value={formik.values.bidProposal}
                    onChange={(value) => formik.setFieldValue('bidProposal', value)}
                    theme="snow"
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link'],
                        ['clean']
                      ],
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link'
                    ]}
                  />
                </Box>
    
                {formik.touched.bidProposal && formik.errors.bidProposal && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {formik.errors.bidProposal}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </Box>
        </Fade>
      ),
    },
    ...(requestCategory !== 'recommendation'
      ? [{
          label: requestCategory === 'job' ? 'Compensation Details' : 'Bid Details',
          content: (
            <Fade in={true}>
              <Box>
                <Grid container spacing={3}>
                  {requestCategory === 'project' && (
                    <Grid item xs={12}>
                      <Box sx={{ 
                        mb: 3,
                        backgroundColor: theme => alpha(theme.palette.background.paper, 0.6),
                        backdropFilter: 'blur(10px)',
                        padding: 3,
                        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                      }}>
                        <Typography variant="h6" gutterBottom>
                          Project Bid Amount
                          <Tooltip title="Specify your bid amount based on the project scope and requirements" placement="top">
                            <TooltipIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                          </Tooltip>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Consider the following when setting your bid:
                          <ul style={{ marginTop: '0.5em', marginBottom: 0 }}>
                            <li>Project complexity and timeline</li>
                            <li>Required resources and expertise</li>
                            <li>Market rates for similar work</li>
                            <li>Any additional costs or contingencies</li>
                          </ul>
                        </Typography>
                      </Box>
                      <StyledTextField
                        label="Bid Amount (USDC)"
                        name="bidAmount"
                        value={formik.values.bidAmount}
                        onChange={formik.handleChange}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          inputComponent: NumberFormatCustom,
                        }}
                        error={formik.touched.bidAmount && Boolean(formik.errors.bidAmount)}
                        helperText={formik.touched.bidAmount && formik.errors.bidAmount}
                      />
                    </Grid>
                  )}
                  {requestCategory === 'job' && (
                    <Grid item xs={12}>
                      <Box sx={{ 
                        mb: 3,
                        backgroundColor: theme => alpha(theme.palette.background.paper, 0.6),
                        backdropFilter: 'blur(10px)',
                        padding: 3,
                        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                      }}>
                        <Typography variant="h6" gutterBottom>
                          Salary Requirements
                          <Tooltip title="Specify your annual salary expectations for this role" placement="top">
                            <TooltipIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                          </Tooltip>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Consider these factors when specifying your salary:
                          <ul style={{ marginTop: '0.5em', marginBottom: 0 }}>
                            <li>Your experience and expertise level</li>
                            <li>Industry standards for similar roles</li>
                            <li>Role requirements and responsibilities</li>
                            <li>Location and cost of living</li>
                          </ul>
                        </Typography>
                      </Box>
                      <StyledTextField
                        label="Requested Salary ($ / year)"
                        name="bidSalary"
                        value={formik.values.bidSalary}
                        onChange={formik.handleChange}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          inputComponent: NumberFormatCustom,
                        }}
                        error={formik.touched.bidSalary && Boolean(formik.errors.bidSalary)}
                        helperText={formik.touched.bidSalary && formik.errors.bidSalary}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isBidNegotiable"
                            checked={formik.values.isBidNegotiable}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Negotiable"
                      />
                      <Tooltip title={getNegotiableTooltip(requestCategory)} placement="top">
                        <TooltipIcon />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          ),
        }]
      : []),
    {
      label: 'Supporting Documents',
      content: (
        <Fade in={true}>
          <Box>
            <Grid item xs={12}>
              <Box sx={{ 
                mb: 3,
                backgroundColor: theme => alpha(theme.palette.background.paper, 0.6),
                backdropFilter: 'blur(10px)',
                padding: 3,
                border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              }}>
                <Typography variant="h6" gutterBottom>
                  {getDocumentHelperText(requestCategory).title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {getDocumentHelperText(requestCategory).description}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {getDocumentHelperText(requestCategory).fileTypes}
                </Typography>
              </Box>
    
              <DragDropZone {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography variant="body2">
                  Drag and drop files here, or click to select files
                </Typography>
              </DragDropZone>
    
              <List>
                {existingDocuments.map((doc, index) => (
                  <ListItem key={`existing-${index}`}>
                    <ListItemText 
                      primary={doc.originalname || doc.filename} 
                      secondary={doc.size ? `Size: ${(doc.size / 1024 / 1024).toFixed(2)} MB` : 'Existing document'}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveExistingDocument(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {documents.map((doc, index) => (
                  <ListItem key={`new-${index}`}>
                    <ListItemText 
                      primary={doc.name || doc.originalname || doc.filename} 
                      secondary={doc.size ? `${(doc.size / 1024 / 1024).toFixed(2)} MB` : ''}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDocument(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Box>
        </Fade>
      ),
    },
  ];

  return (
    <StyledPaper elevation={3} ref={formRef}>
      <Typography variant="h5" gutterBottom>
        {existingBid ? `Edit ${itemName}` : `Submit ${itemName}`}
      </Typography>

      {deadlinePassed && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {requestCategory === 'recommendation' && !user?.payoutAddress && (
        <Alert 
          severity="info" 
          sx={{ mb: 2 }}
        >
          You need to set up a payout wallet address to make recommendations. This is required to receive your share of the bounty if your recommendation is selected and the bounty is released.
        </Alert>
      )}

      <Fade in={showHighlight}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: '4px', bgcolor: 'secondary.main' }} />
      </Fade>
      
      {isDraft && (
        <Alert severity="info" sx={{ mb: 2 }}>
          This is a draft {getResponseType(requestCategory).toLowerCase()}. It's only visible to you and cannot be
          vouched for until submitted.
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={activeStep > index}>
              <StepLabel error={index === activeStep && Boolean(errorMessage)}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>{steps[activeStep] && steps[activeStep].content}</div>

        {isSubmitting && (
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              mt: 2,
              mb: 2
            }}
          >
            <CircularProgress size={24} />
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                textAlign: 'center',
                animation: 'fade 1.5s ease-in-out infinite',
                '@keyframes fade': {
                  '0%, 100%': { opacity: 0.6 },
                  '50%': { opacity: 1 }
                }
              }}
            >
              {documents.some(doc => 
                doc instanceof File && (
                  doc.type === 'application/msword' || 
                  doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                )
              ) ? 'Submitting your response... This may take a moment while we convert your Word documents to PDF format.' : 'Processing your submission...'}
            </Typography>
          </Box>
        )}

        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile
          gap: 2,  // Consistent spacing
          mt: 3
        }}>
          {activeStep > 0 && (
            <StyledButton
              type="button"
              onClick={() => {
                setErrorMessage('');
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              variant="outlined"
              disabled={isSubmitting}
              sx={{
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              Back
            </StyledButton>
          )}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            width: { xs: '100%', sm: 'auto' }
          }}>
            {activeStep < steps.length - 1 ? (
              <StyledButton
                variant="contained"
                type="button"
                onClick={() => {
                  setErrorMessage('');
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
                sx={{
                  width: { xs: '100%', sm: 'auto' }
                }}
              >
                Next
              </StyledButton>
            ) : (
              <>
                {(!existingBid || isDraft) && (
                  <DraftButton
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={handleSaveAsDraft}
                    type="button"
                    sx={{
                      width: { xs: '100%', sm: 'auto' }
                    }}
                  >
                    Save as Draft
                  </DraftButton>
                )}
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting || deadlinePassed}
                  aria-label="Submit your bid"
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                    position: 'relative'
                  }}
                >
                 {isSubmitting ? (
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <CircularProgress size={20} />
                      <Typography 
                        variant="body2"
                        component="span"
                      >
                        {documents.some(doc => 
                          doc instanceof File && (
                            doc.type === 'application/msword' || 
                            doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                          )
                        ) ? 'Processing submission...' : 'Processing...'}
                      </Typography>
                    </Box>
                  ) : existingBid ? (
                    isDraft ? 'Submit' : 'Update'
                  ) : (
                    'Submit'
                  )}
                </StyledButton>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
        Note: Please ensure your {getResponseType(requestCategory).toLowerCase()} is thorough and finalized when you submit it. You can only submit one {getResponseType(requestCategory).toLowerCase()} per request. You may edit or
        delete your {getResponseType(requestCategory).toLowerCase()} as long as there are no vouches on it. You can also work on it as a draft before it goes live and is visible to others.
      </Typography>

      <StyledDialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {getResponseType(requestCategory).toLowerCase()}? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <StyledDialogActions disableSpacing>
          <StyledButton onClick={() => setShowDeleteConfirmation(false)} variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDelete} color="error" variant="contained" autoFocus>
            Delete
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>

      <StyledDialog open={showSubmitConfirmation} onClose={() => setShowSubmitConfirmation(false)}>
        <DialogTitle>{submissionType === 'live' ? 'Confirm Submission' : 'Confirm Update'}</DialogTitle>
        <DialogContent>{getConfirmationText()}</DialogContent>
        <StyledDialogActions disableSpacing>
          <StyledButton 
            onClick={() => setShowSubmitConfirmation(false)} 
            variant="outlined"
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => {
              setShowSubmitConfirmation(false);
              submitBid(formik.values, false);
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            {submissionType === 'live' ? 'Submit' : 'Update'}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>

      <WalletSetup 
        open={showWalletSetup} 
        onClose={() => setShowWalletSetup(false)}
      />
    </StyledPaper>
  );
};

export default BidForm;