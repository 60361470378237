import { createAppClient, generateNonce } from '@farcaster/auth-kit';

const API_URL = process.env.REACT_APP_NGROK_URL || process.env.REACT_APP_API_URL || 'http://localhost:5001';
const REDIRECT_URI = `${API_URL.replace('/api', '')}/auth/callback`;

const appClient = createAppClient({
  redirectUri: REDIRECT_URI, // Construct the redirect URI dynamically
});

export const loginWithFarcaster = async () => {
  try {
    const nonce = await generateNonce(appClient);
    const signInURI = appClient.getSignInURI(nonce);
    window.location.href = signInURI;
  } catch (error) {
    console.error('Farcaster login failed:', error);
  }
};

export const handleAuthCallback = async (code) => {
  try {
    const response = await fetch(`${API_URL}/auth/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (response.ok) {
      const { token } = await response.json();
      localStorage.setItem('farcasterToken', token);
      return true;
    } else {
      console.error('Failed to verify Farcaster sign-in message');
      return false;
    }
  } catch (error) {
    console.error('Farcaster auth callback failed:', error);
    return false;
  }
};
