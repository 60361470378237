// frontend/src/components/CategoryItem.js

import React from 'react';
import { Box, Typography, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getSubcategoryIcon } from '../utilities/subcategoryUtils';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import RecommendIcon from '@mui/icons-material/Recommend';

const CategoryItemContainer = styled(Box)(({ theme, isCompact = false, variant = 'default' }) => ({
  padding: isCompact ? theme.spacing(1, 1.5) : theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  transition: 'all 0.2s ease',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  cursor: 'default',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: 'fit-content',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(135deg, 
      ${alpha(theme.palette.background.paper, 0.1)} 0%,
      ${alpha(theme.palette.background.paper, 0)} 100%)`,
    pointerEvents: 'none',
  },

  ...(variant === 'category' && {
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    borderColor: alpha(theme.palette.secondary.main, 0.1),
    '& .category-icon': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      '& svg': {
        color: theme.palette.secondary.main,
      },
    },
  }),

  '&:hover': {
    backgroundColor: variant === 'category' 
      ? alpha(theme.palette.secondary.main, 0.1)
      : alpha(theme.palette.primary.main, 0.1),
    transform: 'translateY(-1px)',
    boxShadow: `0 4px 8px ${alpha(
      variant === 'category' ? theme.palette.secondary.main : theme.palette.primary.main, 
      0.15
    )}`,
  },
}));

const CategoryIcon = styled(Box)(({ theme, variant = 'default' }) => ({
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: alpha(
      variant === 'category' ? theme.palette.secondary.main : theme.palette.primary.main,
      0.1
    ),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease',
    className: 'category-icon',
    
    '& svg': {
      fontSize: 18,
      color: variant === 'category' ? theme.palette.secondary.main : theme.palette.primary.main,
    },
}));

const getCategoryIcon = (category) => {
  switch (category) {
    case 'job':
      return <WorkIcon />;
    case 'project':
      return <BuildIcon />;
    case 'recommendation':
      return <RecommendIcon />;
    default:
      return <BuildIcon />;
  }
};

export const CategoryItem = ({ 
  label, 
  subcategoryKey, 
  isCategory = false, 
  isCompact = false 
}) => {
  return (
    <CategoryItemContainer isCompact={isCompact} variant={isCategory ? 'category' : 'default'}>
      <CategoryIcon variant={isCategory ? 'category' : 'default'}>
        {isCategory ? getCategoryIcon(subcategoryKey) : getSubcategoryIcon(subcategoryKey)}
      </CategoryIcon>
      <Typography 
        variant="body2" 
        sx={{ 
          fontWeight: 500,
          fontSize: isCompact ? '0.8125rem' : '0.875rem',
          lineHeight: 1.4,
          color: isCategory ? 'secondary.main' : 'primary.main',
        }}
      >
        {label}
      </Typography>
    </CategoryItemContainer>
  );
};

export default CategoryItem;